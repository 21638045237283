
interface CSV {
  headers: string[];
  rows: string[][];
}


function downloadGeneratedCSV(uri: string, name:string):void {
  const link = document.createElement('a');
  link.setAttribute('href', uri);
  link.setAttribute('download', name);
  document.body.appendChild(link);
  link.click();
}

export function downloadCSV(csv: CSV, name: string): void {
  let csvContent = 'data:text/csv;charset=utf-8,';
  csvContent += csv.headers.join(',') + '\n';
  csv.rows.forEach(row => csvContent += row.join(',') + '\n');
  const encodedUri = encodeURI(csvContent);
  downloadGeneratedCSV(encodedUri, name);
}