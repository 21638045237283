import React, { ReactNode } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { clientsPath } from '../../Routes';
import { AlertMuteAffectedUserType, AlertTypes } from '../../managers/Alerts';
import { AlertContainer } from '../Alert';

export interface ClientTabsProps {
  details: ReactNode;

  // Secondary tabs, disabled with boolean below.
  safetyPlan: ReactNode;
  supportPlan: ReactNode;
  contacts: ReactNode;
  insights: ReactNode;
  goals: ReactNode;
  sessions: ReactNode;
  messages: ReactNode;
  agreements: ReactNode;
  userOpens: ReactNode;
  adminManagement: ReactNode;

  disableSecondaryTabs: boolean;
}

export const ClientTabs: React.FC<ClientTabsProps> = (props) => {
  const tabbedMatch = useRouteMatch({ path: `${clientsPath}/:id/:tab` });
  const noTabbedMatch = useRouteMatch({ path: `${clientsPath}/:id/` });

  const match = noTabbedMatch || tabbedMatch;

  const tab = (tabbedMatch?.params as any)?.tab || 'details';
  const id = (match?.params as any)?.id;
  const history = useHistory();
  
  function isActive(tabName: string): boolean {
    return tab === tabName;
  }
  
  return (
    <Tabs
      defaultActiveKey={tab}
      id="uncontrolled-tab-example"
      onSelect={tab => history.push(`${clientsPath}/${id}/${tab}`)}
    >
      <Tab
        eventKey="details"
        title="Details"
      >
        {isActive('details') && props.details}
      </Tab>
      <Tab
        eventKey="safetyPlan"
        title="Safety Plan"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('safetyPlan') && props.safetyPlan}
      </Tab>
      <Tab
        eventKey="supportPlan"
        title={
          <>
            Support Plan
            {
              tab !== 'supportPlan' && (
                <AlertContainer
                  userType={AlertMuteAffectedUserType.client}
                  userId={id}
                  alertTypes={[AlertTypes.planExpired]}
                  subscribe={false}
                />
              )
            }
          </>
        }
        disabled={props.disableSecondaryTabs}
      >
        {isActive('supportPlan') && props.supportPlan}
      </Tab>
      <Tab
        eventKey="contacts"
        title="Contacts"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('contacts') && props.contacts}
      </Tab>

      <Tab
        eventKey="goals"
        title="Goals"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('goals') && props.goals}
      </Tab>

      <Tab
        eventKey="sessions"
        title="Sessions"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('sessions') && props.sessions}
      </Tab>

      <Tab
        eventKey="messages"
        title="Messages"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('messages') && props.messages}
      </Tab>
      <Tab
        eventKey="agreements"
        title="Agreements"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('agreements') && props.agreements}
      </Tab>
      <Tab
        eventKey="apps"
        title="Apps"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('apps') && props.userOpens}
      </Tab>

      <Tab
        eventKey="insights"
        title={
          <>
            Insights
            {
              tab !== 'insights' && (
                <AlertContainer
                  userType={AlertMuteAffectedUserType.client}
                  userId={id}
                  alertTypes={[AlertTypes.clientInactive7, AlertTypes.clientInactive14, AlertTypes.budgetOverdrawn]}
                  subscribe={true}
                />
              )
            }
          </>}
        disabled={props.disableSecondaryTabs}
      >
        {isActive('insights') && props.insights}
      </Tab>

      <Tab
        eventKey="adminManagement"
        title="Management"
        disabled={props.disableSecondaryTabs}
      >
        {isActive('adminManagement') && props.adminManagement}
      </Tab>
    </Tabs>
  );
};