import moment from 'moment';
import React from 'react';
import { Check } from 'react-bootstrap-icons';
import { BudgetAdjustmentType, getBudgetAdjustmentDescription } from '../../../../enums/BudgetAdjustmentType';
import { formatMoney } from '../../../../helpers';
import { BudgetAdjustmentModel } from '../../../../models/BudgetAdjustment';

export interface BudgetAdjustmentStatusListProps {
  budgetAdjustments: BudgetAdjustmentModel[];
  notPaidBudgetType: BudgetAdjustmentType;
}

export const BudgetAdjustmentStatusList: React.FC<BudgetAdjustmentStatusListProps> = (
  { budgetAdjustments, notPaidBudgetType },
) => {
  if (!budgetAdjustments || budgetAdjustments.length === 0) {
    return (
      <>
        <label
          htmlFor="adjustmentSummary"
          className="col-2 col-form-label text-right"
        >{getBudgetAdjustmentDescription(notPaidBudgetType)}</label>
        <div className="col-9 col-form-label">
          <p>Not paid</p>
        </div>
      </>
    );
  }

  return (
    <>
      {
        budgetAdjustments.map((adjustment) => {
          const type = adjustment.type() || BudgetAdjustmentType.Other;
          const supportPlan = adjustment.supportPlanSync();
          const supportNeed = adjustment.supportNeedSync();

          if (!supportPlan || !supportNeed) {
            return null;
          }

          let startDateStr = '';
          const mDate = moment(supportPlan.startDate());
          if (mDate.isValid()) {
            startDateStr = mDate.format('DD/MM/YY');
          }
          
          return (
            <React.Fragment key={`adjustment-status-${adjustment.id}`}>
              <label
                htmlFor="adjustmentSummary"
                className="col-2 col-form-label text-right"
              >{getBudgetAdjustmentDescription(type)}</label>
              <div
                className="col-1 col-form-label d-flex"
                style={{
                  maxWidth: '20px',
                  fontSize: '26px',
                }}
              >
                <Check
                  className="mr-2"
                  style={{ position: 'absolute', marginTop: '-4px', marginLeft: '-5px' }}
                />
              </div>
              <div className="col-9 col-form-label">
                <p>
                  Paid: {formatMoney(Math.abs(adjustment.amount()))}
                  <br />
                  (Support plan starting {startDateStr})
                </p>
              </div>
            </React.Fragment>
          );
        })
      }
    </>

  );
};
