import memoize from 'memoizee';
import moment from 'moment';
import Parse from 'parse';
import Session from '../../Session';
import { publisher } from '../../core/pubsub';
import { AlertMuteModel } from '../../models/AlertMute';
import ClientManager from '../ClientManager';
import WorkerManager from '../Workers';

export enum AlertTypes {
  planExpired='planExpired',
  clientInactive14='clientInactive14',
  clientInactive7='clientInactive7',
  budgetOverdrawn='budgetOverdrawn',
  workerPendingDocumentsLate='workerPendingDocumentsLate',
  billableCancellations='billableCancellations',
}

export enum AlertSeverity {
  info='info',
  warning='warning',
  error='error'
}

export enum AlertMuteAffectedUserType {
  client='client',
  worker='worker'
}

export interface Alert {
  type: AlertTypes;
  severity:AlertSeverity;
  path: string;
  description: string;
  meta?: {
    finishingOrFinished?: boolean;
    date?: string;
    billableCancellations?: number;
    billableCancellationWindows?: {
      sessions: number;
      start: string;
      end: string;
    }[];
  };
}

export type PlanExpiryAlertsResponse = {
  planExpiryDate: string;
  planExpiryType: 'success' | 'warning' | 'danger';
  planExpiryLabel: string;
  dayValue: number;
}

export type EngagementAlertsResponse = {
  lastEngagementDate: string;
  lastEngagementType: 'success' | 'warning' | 'danger';
  lastEngagementLabel: string;
  dayValue: number;
}

export type CancellationAlertsResponse = {
  cancellationsCount: number;
  cancellationsType: 'success' | 'warning' | 'danger';
  cancellationsLabel: string;
}

export function isPlanExpiryAlert(obj: any): obj is PlanExpiryAlertsResponse {
  return obj && 'planExpiryType' in obj;
}

export function isEngagementAlert(obj: any): obj is EngagementAlertsResponse {
  return obj && 'lastEngagementType' in obj;
}

export function isCancellationAlert(obj: any): obj is CancellationAlertsResponse {
  return obj && 'cancellationsType' in obj;
}

async function _getAllClientAlerts(): Promise<{[id: string] : Alert[]}> {
  return await Parse.Cloud.run('clientAlertsBulk');
}

async function _getPlanExpiryAlerts(clientIds: string[]): Promise<{[id: string] : PlanExpiryAlertsResponse}> {
  return await Parse.Cloud.run('getPlanExpiryAlerts', { clientIds });
}

async function _getEngagementAlerts(clientIds: string[]): Promise<{[id: string] : EngagementAlertsResponse}> {
  return await Parse.Cloud.run('getEngagementAlerts', { clientIds });
}

async function _getCancellationAlerts(clientIds: string[]): Promise<{[id: string] : CancellationAlertsResponse}> {
  return await Parse.Cloud.run('getCancellationAlerts', { clientIds });
}

async function _getAllWorkerAlerts(): Promise<{[id: string] : Alert[]}> {
  return await Parse.Cloud.run('workerAlertsBulk');
}

const getAllClientAlerts = memoize(_getAllClientAlerts);
// TODO: memoize the new one?
const getAllWorkerAlerts = memoize(_getAllWorkerAlerts);

export async function getClientAlerts(clientId: string): Promise<Alert[]> {
  const alerts = await getAllClientAlerts();
  return alerts[clientId] || [];
}

export async function getPlanExpiryAlerts(clientIds: string[]): Promise<Record<string, PlanExpiryAlertsResponse>> {
  const alerts = await _getPlanExpiryAlerts(clientIds);
  return alerts;
}

export async function getEngagementAlerts(clientIds: string[]): Promise<Record<string, EngagementAlertsResponse>> {
  const alerts = await _getEngagementAlerts(clientIds);
  return alerts;
}

export async function getCancellationAlerts(clientIds: string[]): Promise<Record<string, CancellationAlertsResponse>> {
  const alerts = await _getCancellationAlerts(clientIds);
  return alerts;
}

export async function getWorkerAlerts(workerId: string): Promise<Alert[]> {
  const alerts = await getAllWorkerAlerts();
  return alerts[workerId] || [];
}

export const CLIENT_MUTES_TOPIC = 'client-mutes';
export const WORKER_MUTES_TOPIC = 'worker-mutes';

async function _getAllClientMutes(): Promise<{[id: string]: Parse.Object[]}> {
  return Parse.Cloud.run('clientMutesBulk');
}

async function _getAllWorkerMutes(): Promise<{[id: string]: Parse.Object[]}> {
  return Parse.Cloud.run('workerMutesBulk');
}

const getAllClientMutes = memoize(_getAllClientMutes);
const getAllWorkerMutes = memoize(_getAllWorkerMutes);

export async function getMutesForClient(clientId: string): Promise<AlertMuteModel[]> {
  const allMutes = await getAllClientMutes();
  const parseObjects = allMutes[clientId] || [];
  const mutes = parseObjects.map(object => new AlertMuteModel(object));

  publisher.notifyUrgent(CLIENT_MUTES_TOPIC, mutes);
  return mutes;
}

export async function getMutesForWorker(workerId: string): Promise<AlertMuteModel[]> {
  const allMutes = await getAllWorkerMutes();
  const parseObjects = allMutes[workerId] || [];
  const mutes = parseObjects.map(object => new AlertMuteModel(object));

  publisher.notifyUrgent(WORKER_MUTES_TOPIC, mutes);
  return mutes;
}

export async function getMutesForAdmin(adminId: string): Promise<AlertMuteModel[]> {
  const query = new Parse.Query('AlertMute');
  query.equalTo('adminId', adminId);

  const parseObjects = await query.find();
  return parseObjects.map(object => new AlertMuteModel(object));
}

async function clearClientMuteCaches() {
  return Promise.all([
    getAllClientAlerts.clear(),
    getAllClientMutes.clear(),
    getActiveMuteGroups.clear(),
  ]);
}

async function clearWorkerMuteCaches() {
  return Promise.all([
    getAllWorkerAlerts.clear(),
    getAllWorkerMutes.clear(),
    getActiveMuteGroups.clear(),
  ]);
}

export async function deleteMute(mute: AlertMuteModel): Promise<void> {
  if (mute.affectedUserType() === AlertMuteAffectedUserType.client) {
    await clearClientMuteCaches();
  } else if (mute.affectedUserType() === AlertMuteAffectedUserType.worker) {
    await clearWorkerMuteCaches();
  }
  return mute.destroy();
}

export async function createMute(affectedUserId: string, userType: AlertMuteAffectedUserType, type: AlertTypes, duration?: number):  Promise<AlertMuteModel> {
  const loggedInAdminId = Session.getUser()?.user.id;
  if (!loggedInAdminId) { throw new Error('No one is logged in?'); }

  const effectiveUntil = duration ? moment().add(duration, 'days').toDate() : undefined;

  const newMute = await AlertMuteModel.new().create(type, loggedInAdminId, affectedUserId, userType, effectiveUntil).save();

  // Refresh mutes for client so the subscribers get called
  if (userType === AlertMuteAffectedUserType.client) {
    await clearClientMuteCaches();
    await getMutesForClient(affectedUserId);
  } else if (userType === AlertMuteAffectedUserType.worker) {
    await clearWorkerMuteCaches(); 
    await getMutesForWorker(affectedUserId);
  }

  return newMute;
}

export interface AlertMuteGroup {
  user: string;
  userId:string;
  mutes: AlertMuteModel[];
}

async function _getActiveMuteGroups(adminId: string): Promise<AlertMuteGroup[]> {
  const query = new Parse.Query('AlertMute');
  query.equalTo('adminId', adminId);

  const parseObjects = await query.find();

  const mutes = parseObjects.map(object => new AlertMuteModel(object)).filter(mute => {
    const effectiveUntil = mute.effectiveUntil();
    if (!effectiveUntil) { return true; }
    return effectiveUntil > new Date();
  });


  const groups: {[id: string]: AlertMuteModel[]} = {};
  mutes.forEach(mute => groups[mute.affectedUserId()] = [...(groups[mute.affectedUserId()] || []), mute]);

  return Promise.all(Object.keys(groups).map(async userId => {
    let user = 'Unknown';
    const type  = groups[userId][0].affectedUserType();
    if (type === AlertMuteAffectedUserType.client) {
      user  = await ClientManager.getClient(userId).then(client => client ? client.fullName() : 'Unknown client');
    }
    if (type === AlertMuteAffectedUserType.worker) {
      user = await WorkerManager.instance().getWorker(userId).then(worker => worker ? worker.fullName() : 'Unknown worker');
    }

    return {
      user,
      userId,
      mutes: groups[userId],
    };
  }),
  );
}

export const getActiveMuteGroups = memoize(_getActiveMuteGroups);
