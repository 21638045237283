import React from 'react';
import { CheckCircleFill, ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { AlertFilterType, MonitoringLoadingState } from '.';
import { clientsPath } from '../../Routes';
import { UserStatus } from '../../enums/UserStatus';
import { BrandColors } from '../../helpers';
import { CancellationAlertsResponse, EngagementAlertsResponse, PlanExpiryAlertsResponse, isCancellationAlert, isEngagementAlert, isPlanExpiryAlert } from '../../managers/Alerts';
import { ClientModel } from '../../models/Client';

export interface MonitoringClientTileAlerts {
  expiry: PlanExpiryAlertsResponse;
  engagement: EngagementAlertsResponse;
  cancellations: CancellationAlertsResponse;
}

interface MonitoringClientTileProps {
  client: ClientModel;
  alerts: MonitoringClientTileAlerts;
  alertTypeFilters: Set<AlertFilterType>;
  loadingState: MonitoringLoadingState;
}

function getAlertIcon(alert: PlanExpiryAlertsResponse | EngagementAlertsResponse | CancellationAlertsResponse): JSX.Element {
  let alertType: 'success' | 'danger' | 'warning' = 'danger';  

  if (isPlanExpiryAlert(alert)) {
    if (alert.planExpiryLabel === '') {
      alertType = 'danger';
    } else {
      alertType = alert.planExpiryType;
    }
  } else if (isEngagementAlert(alert)) {
    alertType = alert.lastEngagementType;
  } else if (isCancellationAlert(alert)) {
    alertType = alert.cancellationsType;
  }

  switch (alertType) {
    case 'danger':
      return <ExclamationTriangleFill
        className="mr-1"
        style={{ transform: 'translateY(-2px)' }}
        color={BrandColors.red}
      />;
    case 'warning':
      return <ExclamationTriangleFill
        className="mr-1"
        style={{ transform: 'translateY(-2px)' }}
        color={BrandColors.orange}
      />;
    case 'success':
      return <CheckCircleFill
        className="mr-1"
        style={{ transform: 'translateY(-2px)' }}
        color={BrandColors.green}
      />; 
  }
}

function renderStatusBadge(client: ClientModel): JSX.Element {
  let color = BrandColors.green;

  switch (client.status()) {
    case UserStatus.PendingReapproval:
      color = BrandColors.orange;
      break;
    case UserStatus.Blocked: {
      color = BrandColors.red;
      break;
    }
  }

  return <span
    className="ml-2 sm-pill badge badge-pill text-white text-uppercase ml-1"
    style={{
      backgroundColor: color,
    }}
  >{client.statusDescription().toUpperCase()}
  </span>;
}

/**
 * Renders a friendly text representation of an alert type
 */
function renderAlertContent(alert: PlanExpiryAlertsResponse | EngagementAlertsResponse | CancellationAlertsResponse): JSX.Element | null {
  let alertLabel: string = '';

  if (isPlanExpiryAlert(alert)) {
    alertLabel = alert.planExpiryLabel;
  } else if (isEngagementAlert(alert)) {
    alertLabel = alert.lastEngagementLabel;
  } else if (isCancellationAlert(alert)) {
    alertLabel = alert.cancellationsLabel;
  }

  if (alertLabel === '') {
    alertLabel = 'No support plan';
  }

  return (
    <p className="mt-2 mb-0">
      {getAlertIcon(alert)}
      {alertLabel}
    </p>
  );
}

/**
 * Render a spinner with a label while alerts are loading
 */
function renderLoader(label: string): JSX.Element {
  return (
    <div className="d-flex flex-direction-row mt-2 mb-0">
      <div style={{ transform: 'scale(0.4)', height: 0, marginTop: 4, marginLeft: -10 }}>
        <div className="spinner-border text-grey mb-0 pb-0" />
      </div>
      <div>{label}</div>
    </div>
  );
}

export const  MonitoringClientTile = (props: MonitoringClientTileProps): JSX.Element => {
  const history = useHistory();
  const { client, alerts, loadingState } = props;
  // const filters = props.alertTypeFilters;
  const path = `${clientsPath}/${client.id()}`;
  const photoUrl = client?.photo() ?? `${window.location.origin}/logo192.png`;
  const isLoading = loadingState.loading;
  let caseManagerName = client.caseManagerDetailsSync().name;
  if (client.caseManagerDetailsSync().deletedAt !== undefined) {
    caseManagerName = '';
  }
  let eoName = client.experienceOfficerDetailsSync().name;
  if (client.experienceOfficerDetailsSync().deletedAt !== undefined) {
    eoName = '';
  }

  return (
    <a
      className="link-tile cursor-pointer p-1 text-left h-100"
      href={path}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        history.push(path);
      }}
    >
      <div
        className="d-flex flex-column border rounded p-2 hover-item-active h-100"
      >
        <div className="d-flex flex-row justify-content-between w-100 h-100">
          <div className="d-flex flex-column text-left">
            <div className="d-flex flex-row">
              <div className="pr-3">
                <img
                  src={photoUrl}
                  alt="avatar"
                  style={{ width: 56, height: 56, borderRadius: '50%', margin: 'auto', objectFit: 'cover' }}
                />
              </div>
              <div>
                <h5 className="pr-0 mr-1 mb-1">{client.fullName()}</h5>
                <p className="mb-0">Case manager: {caseManagerName || 'Unassigned'}</p>
                {eoName && <p>EO: {eoName}</p>}
              </div>
            </div>
          </div>
            
          <div className="d-flex flex-column text-right">
            {renderStatusBadge(client)}
          </div>
        </div>
        <div>
          <div style={{
            width: 'calc(100% + 1.5rem)',
            borderTop: `1px solid ${BrandColors.lightGrey}`,
            marginLeft: '-0.75rem',
            marginTop: '1rem',
          }} />
          {!isLoading && loadingState.loadingExpiryAlerts && renderLoader('Support plan expiry: checking...')}
          {!isLoading && !loadingState.loadingExpiryAlerts && alerts && renderAlertContent(alerts.expiry)}
          {!isLoading && loadingState.loadingEngagementAlerts && renderLoader('Last engaged: checking...')}
          {!isLoading && !loadingState.loadingEngagementAlerts && alerts && renderAlertContent(alerts.engagement)}
          {!isLoading && loadingState.loadingCancellationAlerts && renderLoader('Billable cancellations last 60 days: checking...')}
          {!isLoading && !loadingState.loadingCancellationAlerts && alerts && renderAlertContent(alerts.cancellations)}
        </div>
      </div>
    </a>
  );
};