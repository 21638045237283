import { getAtsiStatusDescription } from '../../enums/AtsiStatus';
import { getCovidVaccinationStatusDescription } from '../../enums/CovidVaccinationStatus';
import { getGenderDescription } from '../../enums/Gender';
import { getLanguageDescription, Language } from '../../enums/Language';
import { getUserStatusDescription } from '../../enums/UserStatus';
import { getAgeRangeFromDOB } from '../../helpers';
import { ClientModel } from '../../models/Client';
import { WorkerModel } from '../../models/Worker';
import { downloadCSV } from './util';

function rowForClient(client: ClientModel): string[] {
  return [
    client.firstName() ?? '',
    client.lastName() ?? '',
    client.email() ?? '',
    client.address()?.replaceAll(', ', ' ') ?? '',
    client.postcode() ?? '',
    client.statusDescription(),
    getGenderDescription(client.gender()),
    getAgeRangeFromDOB(client.dateOfBirth()),
    getCovidVaccinationStatusDescription(client.covidVaccinationStatus()),
    getAtsiStatusDescription(client.atsiStatus()),
    client.addressGoogleId() ?? '',
    client.caseManagerDetailsSync().name,
    client.experienceOfficerDetailsSync().name,
  ];
}

export function exportClients(clients: ClientModel[]): void {
  const headers = ['First name', 'Last name', 'Email', 'Address', 'Postcode', 'Status', 'Gender', 'Age', 'COVID vax status', 'ATSI status', 'Google Places ID', 'Case Manager', 'Experience Officer'];
  const rows = clients.map(client => rowForClient(client));
  return downloadCSV({ headers, rows }, 'clients.csv');
}

export function exportClientsBudget(clients: ClientModel[]): void {
  // TODO fix this if we enable the bugdet report again on the dashboard
  // const headers = ['First name', 'Last name', 'Email', 'Address', 'Postcode', 'Status', 'Gender', 'Age', 'COVID vax status', 'ATSI status', 'Google Places ID', 'Case Manager', 'Experience Officer'];
  // const rows = clients.map(client => rowForClient(client));
  // return downloadCSV({ headers, rows }, 'clients.csv');
}

function rowForWorker(worker: WorkerModel): string[] {
  const days = worker.availableDaysSync();
  const dayCells = ['Unavailable', 'Unavailable', 'Unavailable', 'Unavailable', 'Unavailable', 'Unavailable', 'Unavailable'];
  if (days) {
    days.forEach(day => dayCells[day.day() - 1] = [day.startTime(), day.endTime()].join('-'));
  }

  let languages = Array.from(worker.languages()).map((lang) => getLanguageDescription(lang));
  if (languages.length === 0) {
    languages = [getLanguageDescription(Language.English)];
  }

  return [
    worker.firstName() ?? '',
    worker.lastName() ?? '',
    worker.email() ?? '',
    worker.address()?.replaceAll(', ', ' ') ?? '',
    worker.postCode() ?? '',
    getUserStatusDescription(worker.status()),
    getGenderDescription(worker.gender()),
    getAgeRangeFromDOB(worker.dateOfBirth()),
    worker.workingWithChildrenCheck() === true ? 'Yes' : 'No',
    getCovidVaccinationStatusDescription(worker.covidVaccinationStatus()),
    getAtsiStatusDescription(worker.atsiStatus()),
    `"${languages.join(', ')}"`,
    ...dayCells,
    worker.addressGoogleId() ?? '',
    worker.otherEmployers() ?? '',
  ];
}

export function exportWorkers(workers: WorkerModel[]): void {
  const headers = ['First name', 'Last name', 'Email', 'Address', 'Postcode', 'Status', 'Gender', 'Age', 'WWCC', 'COVID vax status', 'ATSI status', 'Languages', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday', 'Google Places ID', 'Other Employers'];
  const rows = workers.map(worker => rowForWorker(worker));
  return downloadCSV({ headers, rows }, 'support-workers.csv');
}