import React from 'react';
import { X } from 'react-bootstrap-icons';
import { AlertMuteGroup, AlertTypes, deleteMute } from '../../managers/Alerts';
import { AlertMuteModel } from '../../models/AlertMute';


function muteDescription(mute: AlertMuteModel) {
  const type = mute.type();
  switch (type) {
    case AlertTypes.planExpired: return 'Don\'t alert when plan is expired';
    case AlertTypes.clientInactive14: return 'Don\'t alert when client has been inactive for more than 14 days';
    case AlertTypes.clientInactive7: return 'Don\'t alert when client has been inactive for more than 7 days';
    case AlertTypes.budgetOverdrawn: return 'Don\'t alert when client has overdrawn their budget';
    default: return AlertTypes[type];
  }
}


const MuteGroup: React.FC<{group: AlertMuteGroup; onDeleted: MuteDeleted}> = ({ group, onDeleted }) => {
  const { user, mutes } = group;
  return (
    <>
      <h4>{user}</h4>
      {mutes.map(mute => <div
        key={mute.id()}
        className="d-flex"
      >
        <div>{muteDescription(mute)}</div>
        <X
          className="text-danger pl-1 pb-2"
          size="34"
          onClick={() => onDeleted(group, mute)}
        />
      </div>)}
      <hr />
    </>
  );
};

type MuteDeleted = (group: AlertMuteGroup, mute: AlertMuteModel) => void

interface MutesViewProps {
  mutes: AlertMuteGroup[];
  onDeleted: MuteDeleted;
}

export const MutesView: React.FC<MutesViewProps> = ({ mutes, onDeleted }) => {
  return (
    <div>
      {mutes.map(group => <MuteGroup
        key={group.userId}
        group={group}
        onDeleted={(group, mute) => deleteMute(mute).then(() => onDeleted(group, mute))}
      />)}
    </div>
  );
};