import React from 'react';
import { formatDate } from '../../helpers';
import { AdminNoteModel } from '../../models/AdminNote';
import './AdminNoteTile.css';


type AdminNoteTileProps =
{
  adminNote: AdminNoteModel;
}

export const AdminNoteTile: React.FC<AdminNoteTileProps> = ({ adminNote }) => {
  const authorName = adminNote.author() ? `${adminNote.author()?.user.get('firstName')} ${adminNote.author()?.user.get('lastName')}` : 'Unknown author';
  
  return (
    <div className="admin-note-tile rounded m-1 p-2 bg-white">
      <div className="col-12">
        <p className="text-main">{adminNote.text()}</p>
      </div>
      <div className="col-12">
        <div className="d-flex flex-row justify-content-end">
          <div className="d-flex flex-column mr-2">
            <p className="font-weight-bold mb-0 text-author">{authorName}</p>
            <p className="mb-0 text-date">{formatDate(adminNote.createdAt(), 'D MMMM YYYY, H:mm a')}</p>
          </div>
          <img
            src={adminNote.author()?.photoUrl() || `${window.location.origin}/logo512.png`}
            alt=""
            style={{ width: 56, height: 56, borderRadius: '50%', objectFit: 'cover' }}
          />
        </div>
      </div>
    </div>
  );
};