import Parse from 'parse';
import platform from 'platform';
import { useState } from 'react';
import { AnalyticsEventType } from '../../enums/AnalyticsEventType';
import { sleep } from '../../helpers';
import { ParseObject } from '../../models/util/parseTypes';
import Session from '../../Session';

export interface AnalyticsEvent {
  eventType: AnalyticsEventType;
  objectId: string;
}

interface AnalyticsEventPayload {
  eventType: AnalyticsEventType;
  targetObjectId: string;
  userId: string;
  model?: string;
  deviceType?: string;
  manufacturer?: string;
  osVersion?: string;
}

export interface LastEvent {
  date: Date | undefined;
  eventType: string;
  userName: string;
}

export interface UseAnalytics {
  isLoading: boolean;
  lastEvent: LastEvent | undefined;
  logEvents(events: AnalyticsEvent[]): Promise<boolean>;
  getLastEventInfo(eventTypes: AnalyticsEventType[], objectIds: string[]): Promise<LastEvent | undefined>;
}

/**
 * Use Analytics hook
 * 
 * Provides functionality for working with analytics events
 */
const useAnalytics = (): UseAnalytics => {
  const [lastEvent, setLastEvent] = useState<LastEvent | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  
  /**
   * Saves an event to Parse and adds common attributes
   */
  async function saveEvent(event: AnalyticsEvent): Promise<void> {
    const payload: AnalyticsEventPayload = {
      eventType: event.eventType,
      targetObjectId: event.objectId,
      userId: Session.getUser()?.user.id ?? '',
      model: String(platform.version),
      deviceType: String(platform.name ?? platform.os),
      manufacturer: String(platform.manufacturer),
      osVersion: String(platform.description),
    };

    const userPointer = new Parse.Object('_User');
    userPointer.id = payload.userId;

    const model: Parse.Object = new (Parse.Object.extend('AnalyticsEvent'))() as Parse.Object;
    model.set('targetObjectId', payload.targetObjectId);
    model.set('name', payload.eventType);
    model.set('user', userPointer);
    model.set('model', payload.model);
    model.set('deviceType', payload.deviceType);
    model.set('manufacturer', payload.manufacturer);
    model.set('osVersion', payload.osVersion);
    await model.save();
  }

  /**
   * Logs a list of events to Parse
   */
  async function logEvents(events: AnalyticsEvent[]): Promise<boolean> {
    try {
      for (let i = 0; i < events.length; i++) {
        await saveEvent(events[i]);  
      }
    } catch (error) {
      return false;
    }

    return true;
  }

  /**
   * Finds the most recent analytics event for a group of event types and object IDs
   */
  async function getLastEventInfo(eventTypes: AnalyticsEventType[], objectIds: string[]): Promise<LastEvent | undefined> {
    setLastEvent(undefined);

    if (eventTypes.length === 0 || objectIds.length === 0) {
      return;
    }
    
    setIsLoading(true);
    await sleep(1000);
    const query = new Parse.Query('AnalyticsEvent');
    query.include('user');
    query.containedIn('name', eventTypes);
    query.containedIn('targetObjectId', objectIds);
    query.addDescending('createdAt');
    query.limit(1_000_000);
    const event = await query.first();
    const user = event?.get('user') as ParseObject;

    if (!event) {
      return undefined;
    }

    const res: LastEvent = {
      date: new Date(event.get('createdAt')),
      eventType: event.get('name') as AnalyticsEventType,
      userName: '',
    };

    if (user) {
      let userQuery: Parse.Query = new Parse.Query(Parse.Object.extend('Admin'));

      switch (user.get('type')) {
        case 3: // admin
          userQuery = new Parse.Query(Parse.Object.extend('Admin'));
          break;
        case 2: // worker
          userQuery = new Parse.Query(Parse.Object.extend('Worker'));
          break;
        case 1: // client
          userQuery = new Parse.Query(Parse.Object.extend('Client'));
          break;
      }

      userQuery.equalTo('user', user);
      const loadedUser = await userQuery.first();
      if (loadedUser) {
        res.userName = `${loadedUser.get('firstName')} ${loadedUser.get('lastName')}`;
        console.log('[debug] loaded user: ', res.userName);
        
      }
    }

    setLastEvent(res);
    setIsLoading(false);
    return res;
  }

  return {
    isLoading,
    lastEvent,
    logEvents,
    getLastEventInfo,
  };
};

export default useAnalytics;
