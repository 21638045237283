import classNames from 'classnames';
import React, { ReactNode } from 'react';

interface FormItemProps {
  title: string;
  value?: string | number | undefined;
  readOnly?: boolean;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void);

  valueNode?: ReactNode;
  isValid?: boolean;
  adjustCentering?: boolean;
}

export const FormItem: React.FC<FormItemProps> = ({ title, value, readOnly, onChange, valueNode, isValid, adjustCentering }) => (
  <>
    <label
      htmlFor={title}
      className={classNames('col-2 col-form-label text-right', { 'pt-0': adjustCentering === true })}
    >{title}</label>

    {!!valueNode &&
      <>{valueNode}</>
    }

    {!valueNode &&
      <input
        className={classNames('col-4 form-control', { 'is-invalid': isValid === false })}
        type="text"
        value={value}
        readOnly={readOnly === undefined ? false : readOnly}
        id={title}
        onChange={onChange}
      />
    }
  </>
);