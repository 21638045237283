import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import WorkerManager from '../../../managers/Workers';
import { ModalManager } from '../../../Modals/Error/manager';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { WorkerModel } from '../../../models/Worker';
import { workersPath } from '../../../Routes';
import { WorkerDetailsView } from '../../../views/Worker/Tabs/Details';

export interface WorkerDetailsContainerProps {
  worker: WorkerModel;
  onKeyChange: (key:string, value: string) => void;

  isLoading: boolean;
}

export const WorkerDetailsContainer: React.FC<WorkerDetailsContainerProps> = ({ worker, onKeyChange, isLoading }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [stateWorker, setStateWorker] = useState<WorkerModel | undefined>(worker);
  const history = useHistory();

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {stateWorker &&
        <WorkerDetailsView
          worker={stateWorker}
          disableInteraction={isSaving}
          onKeyChange={onKeyChange}
          key={worker.id()}
          onSave={async (cli, state) => {
            try {
              setIsSaving(true);
              const updated = await WorkerManager.instance().updateWorkerDetails(stateWorker, state);
              setStateWorker(updated);
              setIsSaving(false);
              ToastManager.shared().show('Worker details saved.');
              history.push(`${workersPath}/${worker?.id()}`);
            } catch {
              ModalManager.shared().show('Error', 'There was an error saving the workers\'s details. Please try again.');
            }
          }
          }
        />
      }
      {(!worker && !isLoading) &&
        <div className="text-center mt-2">No worker with that ID</div>
      }
    </>
  );
};