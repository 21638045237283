import { useEffect, useRef } from 'react';

export const useIsMounted = (): React.MutableRefObject<boolean> => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = true;
    return () => {
      isMountRef.current = false;
    };
  }, []);
  return isMountRef;
};
