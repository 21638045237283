import React from 'react';
import { AdminUser } from '../../models/AdminUser';
import { LogoImageSizeable } from '../Logo';
import { Menu } from './Menu';
import { UserMenu } from './UserMenu';

export interface LeftSideMenuProps {
  adminUser: AdminUser;
}

export const LeftSideMenu:React.FC<LeftSideMenuProps> = ({ adminUser }) => (
  <div
    className="left-side-menu"
    style={{ background: '#313a46', paddingTop: '0px' }}
  >
    <div className="sidebar-header ml-3 mb-4 mt-2">
      <LogoImageSizeable width="90%" />
    </div>

    <Menu />

    <div className="row">
      <hr className="col-9 offset-md-1" />
    </div>

    <UserMenu adminUser={adminUser} />
  </div>
);