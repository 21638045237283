
import moment from 'moment';
import React, { useState } from 'react';
import { ServiceAgreement } from '../../types/parseConfig';

interface ServiceAgreementViewProps {
  onAdded:(agreement: ServiceAgreement) => Promise<void>;
  agreements: ServiceAgreement[];
  disableInteraction: boolean;
}

export const ServiceAgreementView: React.FC<ServiceAgreementViewProps> = ({ agreements, onAdded, disableInteraction }) => {

  const [newAgreement, setNewAgreement] = useState('');

  const formatDateDisplay = (date: string) => {
    return moment(date).format('D MMM YYYY');
  };

  const formatDateSave = (date: Date) => {
    return moment(date).format('yyyy-MM-DD');
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-end mb-2">
        <div className="font-weight-bold">Add service agreement version</div>
        <button
          type="button"
          className="btn btn-primary m-1"
          disabled={newAgreement.length === 0 || disableInteraction}
          onClick={async () => {
            await onAdded({ text: newAgreement, date: formatDateSave(new Date()), version: agreements.length + 1 });
            setNewAgreement('');
          }}
        >Save</button>
      </div>
      <textarea
        id="title"
        className="form-control mb-4"
        rows={7}
        disabled={disableInteraction}
        onChange={e => setNewAgreement(e.target.value)}
        value={newAgreement}
      />

      {agreements.sort((agg1, agg2) => agg2.version - agg1.version).map((agreement, index) => {
        return (
          <>
            <div className="d-flex mb-2">
              <div className="font-weight-bold">{`Version ${agreement.version}`}</div>
              <div className="mx-2">•</div>
              <div>{`${formatDateDisplay(agreement.date)} - ${agreements[index - 1] ? formatDateDisplay(agreements[index - 1].date) : 'Current'}`}</div>
            </div>

            <textarea
              id="title"
              className="form-control mb-4"
              rows={7}
              disabled
              value={agreement.text}
            />
          </>
        );
      })}
    </div>
  );
};
