import React, { Component, lazy, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { ClientContainer } from './containers/Client';
import { VerticalLayout } from './containers/Layout';
import { WorkerReportContainer } from './containers/WorkerReport';
import { ModalContainer } from './Modals/Error/ErrorModal';
import { ToastContainer } from './Modals/Toasts/Toast';
import { AdminUser } from './models/AdminUser';
import session, { SessionListener } from './Session';
import { Billing } from './views/Billing';
import { JobPost } from './views/JobPost';
import { JobPosts } from './views/JobPosts';
import { Monitoring } from './views/Monitoring';
import { Sessions } from './views/Sessions';
import { Settings } from './views/Settings';
import { Worker } from './views/Worker';
import { WorkerReports } from './views/WorkerReports';
import { Workers } from './views/Workers';

const Login = lazy(() => import('./views/Login'));
const Clients = lazy(() => import('./views/Clients'));

interface RoutesState {
  isUserLoggedIn: boolean;
}

export const clientsPath = '/clients';
export const workersPath = '/workers';
export const sessionsPath = '/sessions';
export const sessionRequestsPath = '/session-requests';
export const billingPath = '/billing';
export const loginPath = '/login';
export const settingsPath = '/settings';
export const workerReportsPath = '/worker-reports';
export const jobPostsPath = '/jobs';
export const monitoringPath = '/monitoring';

export default class Routes extends Component implements SessionListener {
  state: RoutesState = {
    isUserLoggedIn: session.isUserLoggedIn(),
  };

  componentDidMount() {
    session.registerListener(this);
  }

  componentWillUnmount() {
    session.unregisterListener(this);
  }

  onUserChanged(user: AdminUser | undefined): void {
    this.setState({ isUserLoggedIn: user !== undefined });
  }

  render(): JSX.Element {
    const rootPath = '/';
    return (
      <BrowserRouter>
        <LastLocationProvider>

          {this.state.isUserLoggedIn ? <></> : <Redirect to={loginPath} />}
          <VerticalLayout>

            <Suspense fallback={<div />}>
              <Switch>
                <Route
                  exact
                  path={loginPath}
                >
                  {this.state.isUserLoggedIn
                    ? <Redirect to={rootPath} />
                    : <Login />}
                </Route>
                <Route
                  exact
                  path={rootPath}
                ><Clients /></Route>
                <Route
                  exact
                  path={clientsPath}
                ><Clients /></Route>
                <Route
                  exact
                  path={`${clientsPath}/:id/`}
                ><ClientContainer /></Route>
                {/* Note: The following route contains nested routes in ClientSupportPlanContainer */}
                <Route
                  path={`${clientsPath}/:id/:tab`}
                ><ClientContainer /></Route>
                <Route
                  exact
                  path={workersPath}
                ><Workers /></Route>
                <Route
                  exact
                  path={`${workersPath}/:id`}
                ><Worker /></Route>
                <Route
                  exact
                  path={`${workersPath}/:id/:tab`}
                ><Worker /></Route>
                <Route
                  exact
                  path={`${sessionsPath}`}
                ><Sessions /></Route>

                <Route
                  exact
                  path={`${sessionsPath}/:filter`}
                ><Sessions /></Route>

                <Route
                  exact
                  path={`${sessionRequestsPath}/:filter`}
                ><Sessions /></Route>

                <Route
                  exact
                  path={`${billingPath}/:filter`}
                ><Billing /></Route>

                <Route
                  exact
                  path={`${jobPostsPath}`}
                ><JobPosts /></Route>
                <Route
                  exact
                  path={`${jobPostsPath}/:id`}
                ><JobPost /></Route>

                <Route
                  exact
                  path={`${monitoringPath}`}
                ><Monitoring /></Route>

                <Route
                  exact
                  path="/settings"
                ><Settings /></Route>

                <Route
                  exact
                  path={workerReportsPath}
                ><WorkerReports /></Route>
                <Route
                  exact
                  path={`${workerReportsPath}/:id`}
                ><WorkerReportContainer /></Route>
                <Route
                  exact
                  path={`${settingsPath}/:tab`}
                ><Settings /></Route>
                <Route><div /></Route>
              </Switch>
            </Suspense>
          </VerticalLayout>

          <ToastContainer />
          <ModalContainer />
        </LastLocationProvider>

      </BrowserRouter>
    );
  }
}
