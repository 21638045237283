import _sortBy from 'lodash/sortBy';

export enum Language {
  English = 1,
  Arabic = 2,
  Bengali = 3,
  French = 4,
  Hindustani = 5,
  Malay = 6,
  Mandarin = 7,
  Portuguese = 8,
  Russian = 9,
  Spanish = 10,
  Cantonese = 11,
  Vietnamese = 12,
  Italian = 13,
  Greek = 14,
  German = 15,
  Punjabi = 16,
  SerboCroatian = 17,
  Japanese = 18,
  Korean = 19
}

const LanguageKeys = Object.keys(Language).filter(key => typeof Language[key as any] === 'number');
export const LanguageCases = LanguageKeys.map(key => Number(Language[key as any]) as Language);

export function LanguageCasesAlphabetical(): Language[] {
  const values: Language[] = [];
  
  _sortBy(LanguageKeys).forEach((lang: string) => {
    const value: unknown = Language[lang as any];
    if (value !== Language.English) {
      values.push(value as Language);
    }
  });
  
  return [Language.English, ...values];
}

export function getLanguageDescription(language: Language): string {
  switch (language) {
    case Language.SerboCroatian: return 'Serbo-Croatian';
    default: return Language[language];
  }
}