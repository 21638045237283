import React from 'react';
import { Link } from 'react-router-dom';
import { SessionStatus } from '../../models/Sessions';

interface BillabilityReasonProps {
  sessionStatus: SessionStatus;
  billedCost: number;
  billedProportion: number;
  billedRebookedSessions: string[] | undefined;
  isSessionRequestMode?: boolean;
}

function formatRebookedSessionLinks(sessionIds: string[]): JSX.Element {
  const elements: JSX.Element[] = [];
  const links = sessionIds.map((sid) => <Link
    key={sid}
    to={`/sessions/${sid}`}
  ><a>{sessionIds.length > 1 ? 'this session' : 'another session'}</a></Link>);

  if (links.length === 1) {
    return links[0];
  }

  links.forEach((link, index) => {
    elements.push(link);
    if (index === links.length - 2) {
      elements.push(<span> and </span>);
    } else if (index < links.length - 2) {
      elements.push(<span>, </span>);
    }
  });

  return (
    <>{elements.map((el) => el)}</>
  );
}

export function getBillabilityReason(status: SessionStatus, billedCost: number, billedProportion: number, billedRebookedSessions: string[] | undefined, isSessionRequestMode = false): string | JSX.Element {
  let reason = '';

  switch (status) {
    case SessionStatus.cancelledByWorker:
      reason = 'Cancelled by worker. Not billable.';
      break;
    case SessionStatus.cancelledByAdmin:
      reason = 'Cancelled by admin. Not billable.';
      break;
    case SessionStatus.declined:
      reason = 'Declined by worker. Not billable.';
      break;
    case SessionStatus.notAccepted:
    case SessionStatus.pendingWorkerApproval:
      reason = 'Not accepted by worker. Not billable.';
      break;
    case SessionStatus.pendingClientApproval:
      reason = 'Not accepted by client. Not billable.';
      break;
    case SessionStatus.suggested:
      reason = 'Suggestion not accepted by client. Not billable.';
      break;
    case SessionStatus.cancelledByClient:
    case SessionStatus.noShow:
      // Return straight away here if the session is a session request only
      if (isSessionRequestMode) {
        reason = 'Cancelled by client with sufficient notice. Not billable.';
        break;
      }

      // No show is always billable
      if (status === SessionStatus.noShow) {
        reason = 'No show by client. Fully billable.';
        break;
      }

      if (billedCost <= 0) {
        reason = 'Cancelled by client with sufficient notice. Not billable.';
      } else if (billedCost > 0) {
        if (billedProportion < 1) {
          if (billedRebookedSessions && billedRebookedSessions.length > 0) {
            return (<span>Cancelled by client with insufficient notice. Session time partially used by {formatRebookedSessionLinks(billedRebookedSessions)}. Partially billable.</span>);
          } else {
            reason = 'Cancelled by client with insufficient notice. Partially billable.';  
          }
        } else {
          reason = 'Cancelled by client with insufficient notice. Fully billable.';
        }
        
      }
      break;
  }

  return reason;
}

export const BillabilityReason: React.FC<BillabilityReasonProps> = ({ sessionStatus, billedCost, billedProportion, billedRebookedSessions, isSessionRequestMode }) => {
  const reason = getBillabilityReason(sessionStatus, billedCost, billedProportion, billedRebookedSessions, isSessionRequestMode);

  if (reason === '') {
    return null;
  }
  
  return (
    <>
      <label
        className="col-2 col-form-label text-right mb-1"
      >Billing</label>
      <p className="col-10 mb-1 d-flex align-items-center">
        {reason}
      </p>
    </>
  ); 
};