import { Document, Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import React, { useRef } from 'react';
import { ClientModel } from '../models/Client';
import { GoalModel } from '../models/Goal';
import { HR } from './components/hr';
import { Spacer } from './components/spacer';
import { Table, TableCell, TableCol, TableRow } from './components/table';

export interface ClientGoalsPDFProps {
  client: ClientModel;
  goals: GoalModel[];
}



function formatDate(date: Date) {
  const DateFormat = 'YYYY-MM-DD';
  return moment(date).format(DateFormat);
}

export const ClientGoalsPDF = ({ client, goals }: ClientGoalsPDFProps) => {
  const currentGoals = goals.filter(goal => goal.activatedAt() !== undefined && (goal.completedDates()?.length === 0 || goal.completedDates() === undefined));
  const completedGoals = goals.filter(goal => goal.completedDates() !== undefined && goal.completedDates()?.length !== 0);

  const tableIndex = useRef(0);
  return (
    <Document>
      <Page
        style={styles.body}
        orientation="landscape"
      >
        <Image
          style={styles.image}
          src={`${window.location.origin}/suitsme.png`}
        />
        <Spacer height={8} />
        <HR />
        <Spacer height={8} />
        <Text style={styles.h3}>Client goal report</Text>
        <Spacer height={8} />

        <View style={styles.horizontal}>
          <Text style={styles.bold}>Name: </Text>
          <Text>{client.fullName()}</Text>
        </View>
        <View style={styles.horizontal}><Text style={styles.bold}>ID: </Text><Text>{client.id()}</Text></View>
        <View style={styles.horizontal}><Text style={styles.bold}>Report Date: </Text><Text>{formatDate(new Date())}</Text></View>
        <Spacer height={8} />
        <HR />
        <Spacer height={8} />
        {currentGoals.length > 0 &&
          <>
            <Text style={styles.heading}>Current goals</Text>
            <Table>
              <TableRow
                key="header"
                even={false}
              >
                <TableCol
                  isHeader
                  cols={1}
                >
                  <TableCell><Text>Date set</Text></TableCell>
                </TableCol>
                <TableCol
                  isHeader
                  cols={5}
                >
                  <TableCell><Text>I want to</Text></TableCell>
                </TableCol>

                <TableCol
                  isHeader
                  cols={5}
                >
                  <TableCell><Text>To achieve this I will</Text></TableCell>
                </TableCol>
              </TableRow>

              {goals.filter(goal => goal.activatedAt() !== undefined).map((goal, index) => {
                return (
                  <TableRow
                    key={goal.id()}
                    even={(index + 1) % 2 === 0}
                  >
                    <TableCol cols={1}>
                      <TableCell><Text>{formatDate(goal.activatedAt()!)}</Text></TableCell>
                    </TableCol>
                    <TableCol cols={5}>
                      <TableCell><Text>{goal.title()}</Text></TableCell>
                    </TableCol>
                    <TableCol cols={5}>
                      <TableCell><Text>{goal.criteria()}</Text></TableCell>
                    </TableCol>
                  </TableRow>
                );
              })}
            </Table>
            <Spacer height={4} />
            <HR />
          </>
        }

        {completedGoals.length > 0 &&
          <>
            <Spacer height={8} />
            <Text style={styles.heading}>Completed goals</Text>
            <Table>
              <TableRow
                key="header"
                even={false}
              >
                <TableCol
                  isHeader
                  cols={1}
                >
                  <TableCell><Text>Date set</Text></TableCell>
                </TableCol>

                <TableCol
                  isHeader
                  cols={5}
                >
                  <TableCell><Text>I want to</Text></TableCell>
                </TableCol>

                <TableCol
                  isHeader
                  cols={5}
                >
                  <TableCell><Text>To achieve this I will</Text></TableCell>
                </TableCol>

                <TableCol
                  isHeader
                  cols={1}
                >
                  <TableCell><Text>Completed</Text></TableCell>
                </TableCol>
              </TableRow>

              {completedGoals.map(goal => {
                return goal.completedDates()?.map(date => {
                  tableIndex.current += 1;
                  return (
                    <TableRow
                      key={goal.id()}
                      even={tableIndex.current % 2 === 0}
                    >
                      <TableCol cols={1}>
                        <TableCell><Text>{formatDate(goal.createdAt())}</Text></TableCell>
                      </TableCol>
                      <TableCol cols={5}>
                        <TableCell><Text>{goal.title()}</Text></TableCell>
                      </TableCol>
                      <TableCol cols={5}>
                        <TableCell><Text>{goal.criteria()}</Text></TableCell>
                      </TableCol>
                      <TableCol cols={1}>
                        <TableCell><Text>{formatDate(date)}</Text></TableCell>
                      </TableCol>
                    </TableRow>
                  );
                });
              })}
            </Table>
          </>
        }

      </Page>
    </Document>
  );

};

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/opensans/v13/IgZJs4-7SA1XX_edsoXWog.ttf' },
    { src: 'https://fonts.gstatic.com/s/opensans/v13/k3k702ZOKiLJc3WVjuplzC3USBnSvpkopQaUR-2r7iU.ttf', fontWeight: 'bold' },
  ],
});
// https://gist.github.com/karimnaaji/b6c9c9e819204113e9cabf290d580551


const styles = StyleSheet.create({
  body: {
    padding: 16,
    fontFamily: 'Open Sans',
    fontSize: 10,
    color: '#4b4b4d',
  },
  bold: {
    fontWeight: 'bold',
  },
  h1: {
    fontSize: 40,
  },
  h2: {
    fontSize: 32,
  },
  h3: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  h4: {
    fontSize: 22,
  },
  horizontal: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    objectFit: 'contain',
    objectPositionX: 0,
    height: 50,
    width: 150,
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 12,
    paddingLeft: 2,
    paddingBottom: 2,
  },
});

