import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { BudgetAdjustmentType, BudgetAdjustmentTypeCases, getBudgetAdjustmentDescription } from '../../../enums/BudgetAdjustmentType';
import { SupportNeedModelType } from '../../../enums/SupportNeedModelType';
import { BudgetAdjustmentState } from '../../../managers/BudgetAdjustments';
import { CurrencyInput } from '../../CurrencyInput';
import { Radio } from '../../Radio';
import type { SelectorOption } from '../../Selector';
import { Selector } from '../../Selector';

type ErrorKeys = Extract<keyof Pick<BudgetAdjustmentState, 'amount'>, string>;

function formatDateStr(date: Date | undefined): string {
  const mDate = moment(date);
  if (mDate.isValid()) {
    return mDate.format('DD/MM/YY');
  }
  return '';
}

export interface BudgetAdjustmentEditorProps {
  state: BudgetAdjustmentState;
  supportNeedType: SupportNeedModelType;
  onStateUpdate: (state: BudgetAdjustmentState) => void;
  onPromptDelete: (state: BudgetAdjustmentState) => void;
  onValidStateChange?: (itemId: string, isValid: boolean) => void;
  disableInteraction?: boolean;
}

type BudgetType = 'credit' | 'debit';

export const BudgetAdjustmentEditor: React.FC<BudgetAdjustmentEditorProps> = ({
  state,
  supportNeedType,
  onStateUpdate,
  onPromptDelete,
  onValidStateChange,
  disableInteraction,
}) => {
  const typeOptions: SelectorOption[] = BudgetAdjustmentTypeCases
    .filter((option) => option !== BudgetAdjustmentType.Other)
    .map((option) => ({
      name: getBudgetAdjustmentDescription(option),
      key: option,
      disabled: option === BudgetAdjustmentType.EstablishmentFee && supportNeedType !== SupportNeedModelType.SocialCommunityRecreation,
    }));
  typeOptions.push({
    key: BudgetAdjustmentType.Other,
    name: getBudgetAdjustmentDescription(BudgetAdjustmentType.Other),
  });
  const [budgetType, setBudgetType] = useState<BudgetType>(state.amount >= 0 ? 'debit' : 'credit');
  const [formErrors, setFormErrors] = useState<Record<ErrorKeys, string | undefined>>({ amount: '' });

  function getAmount(amount: number): number {
    if (budgetType === 'debit' && amount < 0) { return -amount; }
    if (budgetType === 'credit' && amount >= 0) { return -amount; }
    return amount;
  }

  function handleChangeOption(event: React.ChangeEvent<HTMLSelectElement>) {
    const type = parseInt(event.target.value) as BudgetAdjustmentType;
    onStateUpdate({ ...state, type });
  }

  function handleChangeAmount(valueCents: number) {
    onStateUpdate({ ...state, amount: getAmount(valueCents) });
    validateForm();
  }
  
  function handleChangeBudgetType(event: React.ChangeEvent<HTMLInputElement>) {
    setBudgetType(event.target.value as BudgetType);
  }

  function handleChangeNotes(event: React.ChangeEvent<HTMLTextAreaElement>) {
    onStateUpdate({ ...state, notes: event.target.value });
  }

  function handleClickDelete() {
    onPromptDelete(state);
  }

  function validateForm(): boolean  {
    const errors = { ...formErrors };
    errors.amount = state.amount === 0 ? 'You cannot use zero as an adjustment amount' : '';
    setFormErrors(errors);
    
    const numErrors = Object.keys(errors)
      .map((key) => errors[key as ErrorKeys])
      .filter((value) => value?.length).length;

    return numErrors === 0;
  }

  useEffect(() => {
    onStateUpdate({ ...state, amount: getAmount(state.amount) });
  }, [budgetType]);

  useEffect(() => {
    const isValid = validateForm();
    if (onValidStateChange && state.id) {
      onValidStateChange(state.id, isValid);
    }
  }, [state]);

  return (
    <div className="container">
      <form>
        <fieldset disabled={disableInteraction}>
          <div className="row mx-0">
            <div className="col-4">
              <div className="row form-group">
                <label
                  htmlFor="adjustmentReason"
                  className="col-4 col-form-label text-right"
                >Reason</label>
                <Selector
                  id="adjustmentReason"
                  className="col-8 form-control"
                  options={typeOptions}
                  onChange={handleChangeOption}
                  initial={state.type}
                />
              </div>
              <div className="row form-group">
                <CurrencyInput
                  id="amount"
                  label="Amount"
                  value={Math.abs(state.amount)}
                  labelClassName="col-4 col-form-label text-right"
                  inputClassName="col-8 form-control"
                  onChange={handleChangeAmount}
                />
              </div>
              <div className="row form-group">
                <label
                  className="col-4 col-form-label text-right"
                >Type</label>
                <div className="col-8 col-form-label d-flex">
                  <div className="mr-2">
                    <Radio
                      id={`${state.id}-debit`}
                      value="debit"
                      label="Debit"
                      isSelected={budgetType === 'debit'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeBudgetType(e)}
                    />
                  </div>
                  <div className="mr-2">
                    <Radio
                      id={`${state.id}-credit`}
                      value="credit"
                      label="Credit"
                      isSelected={budgetType === 'credit'}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeBudgetType(e)}
                    />
                  </div>
                </div>
              </div>
              <div className="row form-group mb-0">
                <span className="text-secondary">Entered: {formatDateStr(state.createdAt)}</span>
              </div>
            </div>
            <div className="col-8">
              <div className="row form-group">
                <label
                  htmlFor="adjustmentNotes"
                  className="col-2 col-form-label text-right"
                >Notes</label>
                <textarea
                  id="adjustmentNotes"
                  className="col-9 form-control mb-2"
                  rows={4}
                  value={state.notes}
                  onChange={handleChangeNotes}
                />
                <X
                  className="col-1 text-danger"
                  size={24}
                  style={{ cursor: 'pointer' }}
                  onClick={handleClickDelete}
                />
              </div>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
};