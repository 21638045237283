
export interface MenuItem {
  path: string;
  title: string;
  icon?: string;
  children: MenuItem[];
}

// Icons from: https://coderthemes.com/hyper/saas/icons-unicons.html

export const SUITS_ME_DASHBOARD_MENU_ITEMS: MenuItem[] = [
  {
    title: 'Users',
    path: '#',
    icon: 'uil-users-alt',
    children: [
      {
        title: 'Clients',
        path: '/clients',
        children: [],
      },
      {
        title: 'Workers',
        path: '/workers',
        children: [],
      },
    ],
  },
  {
    title: 'Sessions',
    path: '/sessions',
    icon: 'uil-calendar-alt',
    children: [],
  },
  {
    title: 'Billing',
    path: '#',
    // icon: 'uil-credit-card-search',
    icon: 'uil-atm-card',
    children: [
      {
        title: 'NDIA',
        path: '/billing/ndia',
        children: [],
      },
      {
        title: 'Invoices',
        path: '/billing/invoices',
        children: [],
      },
      {
        title: 'Payroll',
        path: '/billing/payroll',
        children: [],
      },
    ],
  },
  {
    title: 'Jobs board',
    path: '/jobs',
    icon: 'uil-map-pin-alt',
    children: [],
  },
  {
    title: 'Monitoring',
    path: '/monitoring',
    icon: 'uil-tachometer-fast',
    children: [],
  },
  {
    title: 'Worker reports',
    path: '/worker-reports',
    icon: 'uil-exclamation-triangle',
    children: [],
  },
  {
    title: 'Settings',
    path: '/settings',
    icon: 'uil-wrench',
    children: [],
  },
];