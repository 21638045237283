import Parse from 'parse';
import { BudgetAdjustmentType } from '../../enums/BudgetAdjustmentType';
import { BudgetAdjustmentModel } from '../../models/BudgetAdjustment';

export interface BudgetAdjustmentState {
  type: BudgetAdjustmentType;
  amount: number;
  notes: string;
  id: string | undefined;
  clientId: string | undefined;
  supportNeedId: string | undefined;
  deletedAt: Date | undefined;
  createdAt: Date | undefined;
  updatedAt: Date | undefined;
}

/**
 * Parses a model object into a state object
 *
 * @param adj {BudgetAdjustmentModel}
 * @returns BudgetAdjustmentState
 */
export function getBudgetAdjustmentState(adj?: BudgetAdjustmentModel): BudgetAdjustmentState {
  return {
    id: adj?.id(),
    type: adj?.type() ?? BudgetAdjustmentType.NonFaceToFace,
    amount: adj?.amount() ?? 0,
    notes: adj?.notes() ?? '',
    supportNeedId: adj?.supportNeedId(),
    clientId: adj?.clientId(),
    deletedAt: adj?.deletedAt() || undefined,
    createdAt: adj?.createdAt(),
    updatedAt: adj?.updatedAt(),
  };
}

/**
 * Returns a subset of an adjustment model to use as a comparison between a state object
 * 
 * @param adj {BudgetAdjustmentModel}
 * @returns Partial<BudgetAdjustmentState>
 */
export function getBudgetAdjustmentStateForCompare(adj: BudgetAdjustmentModel | BudgetAdjustmentState): Partial<BudgetAdjustmentState> {
  if (adj instanceof BudgetAdjustmentModel) {
    return {
      type: adj.type() ?? BudgetAdjustmentType.Other,
      amount: adj.amount(),
      notes: adj.notes(),
      deletedAt: adj.deletedAt() ?? undefined,
    };
  } else {
    return {
      type: adj.type ?? BudgetAdjustmentType.Other,
      amount: adj.amount,
      notes: adj.notes,
      deletedAt: adj.deletedAt ?? undefined,
    };
  }
}

/**
 * Retrieves all budget adjustments linked to a support need item
 *
 * @param needId {String} - ID of a support need object
 */
export async function getBudgetAdjustmentsForSupportNeed(needId: string): Promise<BudgetAdjustmentModel[]> {
  const baQuery = new Parse.Query('BudgetAdjustment');
  const needQuery = new Parse.Query('SupportNeed');
  needQuery.equalTo('objectId', needId);
  baQuery.matchesQuery('supportNeed', needQuery);
  baQuery.equalTo('deletedAt', undefined);
  baQuery.limit(100_000);

  const results: Parse.Object[] = await baQuery.find();
  if (!results) {
    return [];
  }

  return results.map((result) => new BudgetAdjustmentModel(result));
}

/**
 * Retrieves all budget adjustments for a specific client, with an option
 * to filter by BudgetAdjustmentType
 *
 * @param clientId {String}
 * @param filterByType? {BudgetAdjustmentType}
 */
export async function getBudgetAdjustmentsForClient(
  clientId: string,
  filterByType?: BudgetAdjustmentType[],
): Promise<BudgetAdjustmentModel[]> {
  const baQuery = new Parse.Query('BudgetAdjustment');
  const clientQuery = new Parse.Query('Client');
  clientQuery.equalTo('objectId', clientId);
  baQuery.matchesQuery('client', clientQuery);
  baQuery.include('supportNeed.supportPlan');
  baQuery.equalTo('deletedAt', undefined);

  if (filterByType?.length) {
    baQuery.containedIn('type', filterByType);
  }
  
  const results: Parse.Object[] = await baQuery.find();
  if (!results) {
    return [];
  }

  return results.map((result) => new BudgetAdjustmentModel(result));
}

/**
 * Saves a budget adjustment object
 *
 * @param adjustment {BudgetAdjustmentModel}
 * @param saveState {BudgetAdjustmentState}
 * @returns BudgetAdjustmentModel
 */
export async function saveBudgetAdjustment(
  model: BudgetAdjustmentModel,
  saveState: BudgetAdjustmentState,
): Promise<BudgetAdjustmentModel> {
  model.loadState(saveState);
  const updatedModel = await model.save();
  return updatedModel;
}