import React from 'react';
import { SupportSessionRequestTile } from '../../../../components/SupportSessionRequestTile';
import { SupportSessionTile } from '../../../../components/SupportSessionTile';
import { SupportSessionModel } from '../../../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../../../models/Sessions/SupportSessionRequest';

interface SessionsForNeedViewProps {
  billableItems: SupportSessionModel[];
  otherItems: Array<SupportSessionModel | SupportSessionRequestModel>;
}

export const SessionsForNeedView:React.FC<SessionsForNeedViewProps> = ({ billableItems, otherItems }) => {
  return (
    <div className="container pb-2 pt-4">
      <h4 className="text-left">Billable</h4>
      {billableItems.length > 0 &&
        <div className="row">
          {billableItems.map(session => <div
            key={session.id()}
            className="col-sm-12 col-md-12 col-lg-6 p-0"
          >
            <SupportSessionTile
              key={session.id()}
              session={session}
            />
          </div>)}
        </div>
      }
      {(billableItems.length === 0) &&
        <div className="text-info text-center mb-4">This support need has no billable support sessions or support session requests</div>
      }
      <h4 className="text-left">Other</h4>
      {otherItems.length > 0 &&
        <div className="row">
          {
            otherItems.map(sessionOrRequest => {
              if (sessionOrRequest instanceof SupportSessionModel) {
                return (
                  <div
                    key={sessionOrRequest.id()}
                    className="col-sm-12 col-md-12 col-lg-6  p-0"
                  >
                    <SupportSessionTile
                      session={sessionOrRequest}
                    />
                  </div>
                );
              } else if (sessionOrRequest instanceof SupportSessionRequestModel) {
                return (<div
                  key={sessionOrRequest.id()}
                  className="col-sm-12 col-md-12 col-lg-6  p-0"
                >
                  <SupportSessionRequestTile
                    session={sessionOrRequest}
                  />
                </div>);
              }
            })
          }
        </div>
      }
      {(otherItems.length === 0) &&
        <div className="text-info text-center">This support need has no unbillable support sessions or support session requests</div>
      }
    </div>
  );
};