import Parse from 'parse';
import { ClientModel } from '../../models/Client';
import { PlanManagerModel } from '../../models/PlanManager';

export async function GetPlanManagers(clientId: string): Promise<PlanManagerModel[]> {
  const query = new Parse.Query(Parse.Object.extend('PlanManager'));
  query.equalTo('client', new Parse.Object('Client', { objectId: clientId }));
  query.descending('createdAt');
  return query.find().then(managers => managers.map(manager => new PlanManagerModel(manager)));
}

export function UpdatePlanManager(model: PlanManagerModel, state: PlanManagerState, client: ClientModel): Promise<PlanManagerModel> {
  model.setContactName(state.contact);
  model.setOrganisationName(state.organisation);
  model.setAddress(state.address);
  model.setClient(client);

  return model.save();
}

export  function CreatePlanManager(state: PlanManagerState, client: ClientModel): Promise<PlanManagerModel>  {
  const planManager = PlanManagerModel.new();
  return UpdatePlanManager(planManager, state, client);
}

export interface PlanManagerState {
  organisation: string;
  address: string;
  contact: string;
}

export function getPlanManagerState(model?: PlanManagerModel):PlanManagerState  {
  return {
    organisation: model?.organisationName() ?? '',
    address: model?.address() ?? '',
    contact: model?.contactName() ?? '',
  };
}
