import React, { useEffect, useState } from 'react';
import WorkerManager from '../../managers/Workers';
import { WorkerModel } from '../../models/Worker';
import { WorkersView } from '../../views/Workers';


export const WorkersContainer: React.FC = () => {
  const [workers, setWorkers] = useState<WorkerModel[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const downloadWorkers = async () => {
      const workers = await WorkerManager.instance().getWorkers();
      setWorkers(workers);
    };
    setIsLoading(true);
    void downloadWorkers().then(() => setIsLoading(false));
  }, []);

  return (
    <WorkersView
      key={workers?.length}
      workers={workers}
      isLoading={isLoading}
    />
  );

};
