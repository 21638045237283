
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { Funnel } from 'react-bootstrap-icons';

interface ToolBarProps {
  title: string;
  top: React.ReactNode;
  filters: React.ReactNode;
}

export const ToolBar: React.FC<ToolBarProps> = ({ title, top, filters }) => {
  const [drawOpen, setDrawOpen] = useState<boolean>(true);

  return (
    <div className="bg-dark rounded">
      <div className="row d-flex mt-3 pl-3 pr-3 pt-1 pb-1">
        <div className="mr-auto h4 font-weight-bold text-white pt-1">{title}</div>
        {top}
        <Funnel
          size={42}
          className="btn col-auto text-white"
          onClick={() => setDrawOpen(!drawOpen)}
        />
      </div>

      <Collapse in={drawOpen}>
        <div>
          <hr className="mt-0" />
          {filters}
        </div>
      </Collapse>
    </div>
  );
};