import Parse, { GeoPoint } from 'parse';
import { AustralianState } from '../../enums/AustralianState';
import { ClientModel } from '../../models/Client';
import { JobPostModel } from '../../models/JobPost';

export interface JobPostState {
  description: string;
  adminNotes: string;
  client?: ClientModel;
  clientFirstName?: string;
  clientAddress?: string;
  clientAddressCoordinates?: GeoPoint;
  clientAustralianState?: AustralianState;
  isClosed: boolean;
  isDeleted: boolean;
}

class JobPostManager {
  private static _instance: JobPostManager;

  private jobCache: {[id: string]: JobPostModel} = {};
  private jobsCache: JobPostModel[] = [];

  static instance(): JobPostManager {
    if (!JobPostManager._instance) {
      JobPostManager._instance = new JobPostManager();
    }
    return JobPostManager._instance;
  }

  async getJobPosts(): Promise<JobPostModel[]> {
    if (this.jobsCache.length > 0) { return this.jobsCache; }

    const query = new Parse.Query('JobPost');
    query.limit(100_000);
    query.include('client');
    query.include('client.caseManager');
    query.include('client.experienceOfficer');
    query.ascending('createdAt');
    query.equalTo('deletedAt', undefined);
    const parseModels = await query.find();
    const models = parseModels.map(parseModel => new JobPostModel(parseModel));

    // Prime caches
    this.jobsCache = models;
    models.forEach(job => this.jobCache[job.id()] = job);
    return models;
  }

  async getJobPost(id: string, includeWorkerPointers: boolean): Promise<JobPostModel | undefined> {
    const cached = this.jobCache[id];
    if (cached && !includeWorkerPointers) { return cached; }

    const query = new Parse.Query('JobPost');
    query.include('client');
    query.equalTo('objectId', id);
    query.equalTo('deletedAt', undefined);

    if (includeWorkerPointers) {
      query.include('workers');
    }

    const parseModel = await query.first();
    if (parseModel) {
      const result =  new JobPostModel(parseModel);
      this.jobCache[id] = result;
      return result;
    }
    return undefined;
  }

  async createJobPost(state: JobPostState): Promise<JobPostModel> {
    const newModel = JobPostModel.new();
    newModel.setDescription(state.description);
    newModel.setAdminNotes(state.adminNotes);
    newModel.setClosed(false);
    
    if (state.client) {
      newModel.setClient(state.client);
      newModel.setClientFirstName('');
      newModel.setClientAddress('');
      newModel.setClientAddressCoordinates(undefined);
      newModel.setClientAustralianState(AustralianState.unknown);
    } else {
      newModel.unsetClient();
      newModel.setClientFirstName(state.clientFirstName ?? '');
      newModel.setClientAddress(state.clientAddress ?? '');
      newModel.setClientAddressCoordinates(state.clientAddressCoordinates ?? undefined);
      newModel.setClientAustralianState(state.clientAustralianState ?? AustralianState.unknown);
    }

    const savedModel = await newModel.save();
    this.jobsCache.push(savedModel);
    this.jobCache[savedModel.id()] = savedModel;
    return savedModel;
  }

  async updateJobPostDetails(jobPost: JobPostModel, state: JobPostState): Promise<JobPostModel> {
    jobPost.setDescription(state.description);
    jobPost.setAdminNotes(state.adminNotes);
    jobPost.setDeleted(state.isDeleted);
    jobPost.setClosed(state.isClosed);

    if (state.client) {
      jobPost.setClient(state.client);
      jobPost.setClientFirstName('');
      jobPost.setClientAddress('');
      jobPost.setClientAddressCoordinates(undefined);
      jobPost.setClientAustralianState(AustralianState.unknown);
    } else {
      jobPost.unsetClient();
      jobPost.setClientFirstName(state.clientFirstName ?? '');
      jobPost.setClientAddress(state.clientAddress ?? '');
      jobPost.setClientAddressCoordinates(state.clientAddressCoordinates ?? undefined);
      jobPost.setClientAustralianState(state.clientAustralianState ?? AustralianState.unknown);
    }

    const savedModel = await jobPost.save();
    this.jobCache[savedModel.id()] = savedModel;
    return savedModel;
  }

  deleteJobPost(id: string): void {
    delete this.jobCache[id];
    this.jobsCache = this.jobsCache.filter(job => job.id() !== id);
  }
}

export default JobPostManager;