import React from 'react';
import { Radio } from '../../../components/Radio';
import { getNDISBookingsDescription, NDISBookings, NDISBookingsCases } from '../../../enums/NDISBookings';

export interface NDISBookingsSelectorProps {
  booking: NDISBookings;
  onChange:(booking: NDISBookings) => void;
}

export const NDISBookingsSelector: React.FC<NDISBookingsSelectorProps> = ({ booking, onChange }) => {
  return (
    <>
      <label
        htmlFor="ndisBookings"
        className="col-2 col-form-label text-right"
      >NDIS bookings</label>
      <div className="col-3 col-form-label d-flex">
        {NDISBookingsCases.map(bookingCase => <div
          className="mr-2"
          key={bookingCase}
        ><Radio
            value={bookingCase}
            label={getNDISBookingsDescription(bookingCase)}
            isSelected={booking === bookingCase}
            onChange={option => onChange(parseInt(option.target.value))}
          /></div>)}
      </div>
      <div className="col-6" />
    </>

  );
};
