import moment from 'moment';
import { AustralianState, ianaTimezoneForState } from '../enums/AustralianState';
import { SupportNeedModelType } from '../enums/SupportNeedModelType';
import { SupportNeedModel } from '../models/SupportNeed';
import { SupportPlanModel } from '../models/SupportPlan';
import { PublicHolidays } from '../types/parseConfig';
import { getPublicHolidays, isPublicHoliday } from './publicHolidays';

/**
 * Sort plans by start date ascending
 */
export function sortPlansByStartDateAsc(item1: SupportPlanModel, item2: SupportPlanModel): number {
  const date1 = moment(item1.startDate()).valueOf();
  const date2 = moment(item2.startDate()).valueOf();
  return date1 - date2;
}

/**
 * Finds the appropriate support plan for a given start date
 */
export function findSupportPlanForDate(plans: SupportPlanModel[], date: Date): SupportPlanModel {
  const result = plans[0];
  const sortedPlans = plans.sort(sortPlansByStartDateAsc);
  console.log('sorted plans: ', sortedPlans);
  
  /*
  plans.forEach((plan) => {
    if (plan.startDate().getTime() >= date.getTime()) {
      plan = result;
    }
  });
  */

  return result;
}

/**
 * Checks if a given support need type can be delivered on a specific date
 */
export function isNeedTypeAvailableForDate(type: SupportNeedModelType, date: Date | undefined, state: AustralianState | undefined, publicHolidays: PublicHolidays): boolean {
  let result = true;
  const momentDate = moment(date).tz(ianaTimezoneForState(state ?? AustralianState.wa));
  const isWeekend = momentDate.isoWeekday() === 6 || momentDate.isoWeekday() === 7;
  const isHoliday = isPublicHoliday(date, publicHolidays, state);
  
  if (!date) {
    return true;
  }

  if (isWeekend || isHoliday) {
    if ([
      SupportNeedModelType.Accommodation,
      SupportNeedModelType.SocialSkills,
      SupportNeedModelType.Transport,
    ].includes(type)) {
      result = false;
    }
  }
  
  return result;
}

/**
 * Checks if a given need type and date is valid for the needs in a support plan
 */
export async function isNeedAndDateValidForPlan(
  plan: SupportPlanModel,
  planNeeds: SupportNeedModel[],
  needType: SupportNeedModelType,
  date: Date,
  state: AustralianState | undefined,
): Promise<boolean> {
  const publicHolidays = await getPublicHolidays();
  const validNeedTypes = planNeeds.map((need) => need.type());

  if (!validNeedTypes.includes(needType)) {
    return false;
  }

  if (!isNeedTypeAvailableForDate(needType, date, state, publicHolidays)) {
    return false;
  }

  return true;
}