import Parse from 'parse';
import { last } from '../../helpers';
import { SupportPlanModel } from '../../models/SupportPlan';

export async function GetPlansForClient(clientId: string): Promise<SupportPlanModel[]> {
  const query = new Parse.Query('SupportPlan');

  const clientQuery = new Parse.Query('Client');
  clientQuery.equalTo('objectId', clientId);
  query.matchesQuery('client', clientQuery);
  query.include('supportNeeds');
  query.equalTo('deletedAt', undefined);

  const plans: Parse.Object[] = await query.find();

  return plans.map(plan => new SupportPlanModel(plan));
}


/**
 * Gets the user's active support plan including needs.
 * 
 * @deprecated To be removed as part of the new plan changes
 */
export async function GetActiveSupportPlanWithNeeds(clientId: string): Promise<SupportPlanModel | undefined> {
  const planQuery = new Parse.Query('SupportPlan');
  const clientQuery = new Parse.Query('Client');
  clientQuery.equalTo('objectId', clientId);

  planQuery.equalTo('isActive', true);
  planQuery.matchesQuery('client', clientQuery);
  planQuery.descending('createdAt');

  planQuery.include('supportNeeds.planManager');

  const parseObject = await planQuery.first();
  if (!parseObject) { return undefined; }

  return new SupportPlanModel(parseObject);
}

/**
 * Gets the support plans for a client including supportNeeds and planManager
 */
export async function GetSupportPlansWithNeeds(clientId: string): Promise<SupportPlanModel[]> {
  const planQuery = new Parse.Query('SupportPlan');
  const clientQuery = new Parse.Query('Client');
  clientQuery.equalTo('objectId', clientId);
  planQuery.equalTo('deletedAt', undefined);
  planQuery.matchesQuery('client', clientQuery);
  planQuery.descending('createdAt');
  planQuery.include('supportNeeds.planManager');

  const plans: Parse.Object[] = await planQuery.find();
  return plans.map(plan => new SupportPlanModel(plan));
}

export async function GetPlanForSession(sessionId: string): Promise<SupportPlanModel> {
  const sessionQuery = new Parse.Query('SupportSession');
  sessionQuery.include('supportNeed.supportPlan');

  const session: Parse.Object = await sessionQuery.get(sessionId);

  if (!session) {
    throw new Error('No session with this ID');
  }

  return new SupportPlanModel(session.get('supportNeed').get('supportPlan'));
}

export async function GetPlanForSessionRequest(sessionId: string): Promise<SupportPlanModel> {
  const sessionQuery = new Parse.Query('SupportSessionRequest');
  sessionQuery.include('supportNeed.supportPlan');

  const session: Parse.Object = await sessionQuery.get(sessionId);

  if (!session) {
    throw new Error('No session with this ID');
  }

  return new SupportPlanModel(session.get('supportNeed').get('supportPlan'));
}

/**
 * Finds the current support plan from a list of plans
 */
export function findCurrentSupportPlan(plans: SupportPlanModel[], sessionDate?: Date | undefined): SupportPlanModel {
  const compareDate = sessionDate ? sessionDate : new Date();
  let result = last(plans);

  const filtered = plans
    .filter((plan) => plan.startDate().getTime() < compareDate.getTime())
    .sort((planA: SupportPlanModel, planB: SupportPlanModel) => {
      return planA.startDate().getTime() > planB.startDate().getTime() ? -1 : 1;
    });

  if (filtered.length > 0) {
    result = filtered[0];
  }

  return result;
}