import Parse from 'parse';
import { GoalState, UnsavedGoalPrefix } from '../containers/Goals';
import { GoalStatus } from '../enums/GoalStatus';



export class GoalModel {
  constructor(public object: Parse.Object) { }

  static new(): GoalModel {
    return new GoalModel(new (Parse.Object.extend('ClientGoal'))());
  }

  id(): string { return this.object.id; }
  title(): string | undefined { return this.object.get('title'); }
  criteria(): string | undefined { return this.object.get('criteria'); }
  status(): GoalStatus  {
    const activatedAt = this.object.get('activatedAt');
    const deletedAt = this.object.get('deletedAt');
    const pausedAt = this.object.get('pausedAt');
    const completedAtDates = this.object.get('completedAtDates');

    if (activatedAt !== undefined) { return GoalStatus.current; }
    else if (deletedAt !== undefined) { return GoalStatus.deleted; }
    else if (pausedAt !== undefined) { return GoalStatus.inactive; }
    else if (completedAtDates && completedAtDates.length) { return GoalStatus.completed; }
    // This fallback shouldn't be possible, because current goals should always have an activatedAt date
    else { return GoalStatus.current; }
  }

  isReportable(): boolean {
    return this.status() === GoalStatus.completed || this.status() === GoalStatus.current;
  }

  createdAt(): Date {
    return this.object.get('createdAt');
  }


  completedDates(): Date[] | undefined {
    const dates = this.object.get('completedAtDates');
    return dates;
  }

  activatedAt(): Date | undefined {
    return this.object.get('activatedAt');
  }

  setActivatedDate(date: Date) {
    this.object.set('activatedAt', date);
  }

  static fromState(state: GoalState, clientId: string): GoalModel {
    const obj = new Parse.Object('ClientGoal');

    if (state.id && !state.id.startsWith(UnsavedGoalPrefix)) {
      obj.id = state.id;
    }

    obj.set('criteria', state.criteria);
    obj.set('title', state.title);

    const client = new Parse.Object('Client');
    client.id = clientId;
    obj.set('client', client);

    if (state.isDeleted) {
      obj.set('deletedAt', new Date());
      obj.unset('activatedAt');
      obj.unset('pausedAt');
    }

    return new GoalModel(obj);
  }

  rawObject() { return this.object; }
}
