import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import Parse from 'parse';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { ModalManager } from '../../../../Modals/Error/manager';
import { Radio } from '../../../../components/Radio';
import { Selector } from '../../../../components/Selector';
import { AlertContainer } from '../../../../containers/Alert';
import { AtsiStatusCases, getAtsiStatusDescription } from '../../../../enums/AtsiStatus';
import { AustralianState, getAustralianStateDescription } from '../../../../enums/AustralianState';
import { CovidVaccinationStatusCases, getCovidVaccinationStatusDescription } from '../../../../enums/CovidVaccinationStatus';
import { GenderCases, genderOptions } from '../../../../enums/Gender';
import { HobbyCases, getHobbyDescription } from '../../../../enums/Hobby';
import { LanguageCasesAlphabetical, getLanguageDescription } from '../../../../enums/Language';
import { WorkerStatuses, getUserStatusDescription } from '../../../../enums/UserStatus';
import { WorkerLevel, workerLevelOptions } from '../../../../enums/WorkerLevel';
import { resizeFile } from '../../../../helpers';
import { AlertMuteAffectedUserType, AlertTypes } from '../../../../managers/Alerts';
import { WorkerDetailsSave } from '../../../../managers/Workers';
import { WorkerModel } from '../../../../models/Worker';
import { AddressInput } from '../../../Client/Details/addressInput';
import { SaveButton } from '../../../Client/SaveButton';
import { Checkbox } from './Checkbox';
import { FormItem } from './FormItem';

export interface WorkerDetailsViewProps {
  worker: WorkerModel;
  onKeyChange: (key:string, value: string) => void;
  onSave: (Worker: WorkerModel, state: WorkerDetailsSave) => Promise<void>;

  disableInteraction: boolean;
}

function stateFromWorker(worker: WorkerModel): WorkerDetailsSave {
  return {
    firstName: worker.firstName(),
    lastName: worker.lastName(),
    dateOfBirth: worker.dateOfBirth(),
    gender: worker.gender() || -1,
    address: worker.address() || '',
    postcode: worker.postCode() || '',
    phoneNumber: worker.phoneNumber() || '',
    addressCoordinates: worker.addressCoordinates(),
    addressGoogleId: worker.addressGoogleId(),
    australianState: worker.australianState() ?? 0,
    photoUrl: worker.photo(),
    photoBytes: [],
    userStatus: worker.status(),
    languages: worker.languages(),
    hobbies: worker.hobbies(),
    bio: worker.bio() || '',
    level: worker.level() || WorkerLevel.One,
    completedInduction: worker.completedInductionAt(),
    otherEmployers: worker.otherEmployers(),
    unrestrictedVisibility: worker.unrestrictedVisibility(),
    workingWithChildrenCheck: worker.workingWithChildrenCheck(),
    covidVaccinationStatus: worker.covidVaccinationStatus(),
    atsiStatus: worker.atsiStatus(),
  };
}


export const WorkerDetailsView: React.FC<WorkerDetailsViewProps> = ({ worker, onKeyChange, onSave, disableInteraction }) => {
  const [state, setState] = useState<WorkerDetailsSave>(stateFromWorker(worker));
  const photoInputFileRef = useRef<HTMLInputElement | null | undefined>();
  const [canSave, setCanSave] = useState(false);
  const [canCancel, setCanCancel] = useState(false);
  
  const isFirstNameValid = (): boolean => { return state.firstName.trim().length > 0; };

  const isLastNameValid = (): boolean => { return state.lastName.trim().length > 0; };

  const isDateOfBirthValid = (): boolean => {
    if (!moment(state.dateOfBirth).isValid()) { return false; }
    const now = moment();
    return moment(state.dateOfBirth).isBefore(now);
  };

  const isGenderValid = (): boolean => { return GenderCases.includes(state.gender); };

  const isAddressValid = (): boolean => { return state.address.trim().length > 0; };

  const isPhoneNumberValid = (): boolean => {
    const val = `${state.phoneNumber}`.replaceAll(' ', '').trim();
    return /^\d{8,12}$/.test(val);
  };

  const isUserStatusValid = (): boolean => {
    return WorkerStatuses.includes(state.userStatus);
  };

  const onPhotoFileSelected = async (files: FileList | null)  => {
    if (files == null) { return; }
    let file = files.item(0);
    if (file == null) { return; }
    file = await resizeFile(file);

    const urlReader = new FileReader();
    urlReader.readAsDataURL(file);
    urlReader.onloadend = e => {
      const url = e.target?.result as string;
      // url is in the format 'data:image/png;base64,...' or 'data:image/jpeg;base64,...'

      if (url.startsWith('data:image/') && file != null) {
        setState({ ...state, photoUrl: url });

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = e => {
          const result = e.target?.result as ArrayBuffer;
          if (result == null) { return; }
          const array = new Uint8Array(result);
          const bytes = [];
          for (let i = 0; i < array.length; i++) {
            bytes.push(array[i]);
          }
          setState({ ...state, photoBytes: bytes, photoUrl: url }); // Set photo url again because state may not be up to date by the time this is called.
        };
      } else {
        ModalManager.shared().show('Unsuitable photo file',  'Please choose an image file.');
      }
    };
  };

  const removePhoto = () => {
    setState({ ...state, photoUrl: undefined, photoBytes: undefined });
    if (photoInputFileRef.current) {
      photoInputFileRef.current.value = '';
    }
  };

  const revertPhoto = () => { // Revert back to current photo stored in backend
    setState({ ...state, photoUrl: worker.photo(), photoBytes: undefined });
    if (photoInputFileRef.current) {
      photoInputFileRef.current.value = '';
    }
  };

  useEffect(() => {
    const startingSate = stateFromWorker(worker);
    const same = _.isEqual(state, startingSate);

    const valid = [isFirstNameValid(), isDateOfBirthValid(), isGenderValid(), isAddressValid(), isPhoneNumberValid(), isUserStatusValid()].filter(valid => !valid).length === 0;
    setCanSave(!same && valid);
    setCanCancel(!same);
    // Interested in a few keys being bubbled up for the header to change
    onKeyChange('firstName', state.firstName);
    onKeyChange('lastName', state.lastName);
    if (state.photoUrl) {
      onKeyChange('photoUrl', state.photoUrl);
    }
    // eslint-disable-next-line
  }, [state, onKeyChange, worker]);

  return (
    <div className="container pb-2 pt-4">
      <form>
        <div className="row form-group">
          <FormItem
            title="Id"
            value={worker.id()}
            readOnly
          />

          <FormItem
            title="Email"
            value={worker.email()}
            readOnly
          />
        </div>

        <div className="row form-group">
          <FormItem
            title="First name"
            value={state.firstName}
            isValid={isFirstNameValid()}
            onChange={e => setState({ ...state, firstName: e.target.value })}
          />

          <FormItem
            title="Last name"
            value={state.lastName}
            isValid={isLastNameValid()}
            onChange={e => setState({ ...state, lastName: e.target.value })}
          />
        </div>

        <div className="row form-group">
          <FormItem
            title="Date of birth"
            valueNode={
              <div className={classNames('col-4 form-control border', { 'border-danger': !isDateOfBirthValid() })}>
                <DatePicker
                  className="w-100 border-0 text-secondary"
                  onChange={(date:Date) => setState({ ...state, dateOfBirth: date })}
                  selected={moment(state.dateOfBirth).isValid() ? state.dateOfBirth : undefined}
                  dateFormat="d/M/yyyy"
                  minDate={moment('1900-01-01').toDate()}
                  maxDate={new Date()}
                  showYearDropdown
                />
              </div>
            }
          />

          <FormItem
            title="Gender"
            valueNode={
              <Selector
                options={genderOptions(state.gender === -1)}
                initial={state.gender}
                onChange={e => setState({ ...state, gender: parseInt(e.target.value) })}
                isValid={isGenderValid()}
              />
            }
          />
        </div>

        {/* <div className="row form-group">
          <FormItem
            title="Address"
            value={state.address}
            onChange={e => setState({ ...state, address: e.target.value })}
            isValid={isAddressValid()}
          />

          <FormItem
            title="Postcode"
            value={state.postcode}
            onChange={e => setState({ ...state, postcode: e.target.value })}
          />
        </div> */}

        <div className="row form-group">
          <label
            htmlFor="address"
            className="col-2 col-form-label text-right"
          >Address</label>
          <AddressInput
            key={state.address}
            startingAddress={state.address}
            onChange={address => {
              setState({ ...state,
                address: address.address,
                australianState: address.state,
                postcode: address.postcode,
                addressCoordinates: new Parse.GeoPoint(address.geo.lat, address.geo.lon),
                addressGoogleId: address.id,
              });

            }}
          />

          <FormItem
            title="Postcode"
            value={state.postcode}
            readOnly
            onChange={e => setState({ ...state, postcode: e.target.value })}
          />
        </div>

        <div className="row form-group">
          <FormItem
            title="State"
            value={getAustralianStateDescription(state.australianState as AustralianState)}
            readOnly
          />

          <FormItem
            title="Coords"
            value={state.addressCoordinates ? `${state.addressCoordinates.latitude}, ${state.addressCoordinates.longitude}` : ''}
            readOnly
          />
        </div>

        <div className="row form-group">
          <FormItem
            title="Level"
            valueNode={
              <Selector
                options={workerLevelOptions()}
                initial={state.level}
                onChange={e => setState({ ...state, level: parseInt(e.target.value) })}
              />
            }
          />
          <FormItem
            title="Phone"
            value={state.phoneNumber}
            onChange={e => setState({ ...state, phoneNumber: e.target.value })}
            isValid={isPhoneNumberValid()}
          />
        </div>

        <div className="row form-group">
          <div className="col-6" />

          <FormItem
            title="Photo"
            valueNode={
              <>
                <div className="col-4  form-control border-0 bg-transparent pl-0">
                  <div style={{ display: 'flex' }}>
                    <img
                      hidden={!state.photoUrl}
                      src={state.photoUrl}
                      className="pr-1 bg-transparent"
                      alt=""
                      style={{ width: 35, height: 35, objectFit: 'cover' }}
                    />
                    <button
                      type="button"
                      className="btn btn-outline-secondary py-1"
                      onClick={() => { photoInputFileRef.current?.click();
                      }}
                    >Choose a photo</button>
                    <input
                      ref={el => photoInputFileRef.current = el}
                      type="file"
                      onChange={e => onPhotoFileSelected(e.target.files)}
                      style={{ display: 'none' }}
                    />
                  </div>
                </div>
                <div className="col-4 offset-8 pl-0 mt-1">
                  <button
                    hidden={!((state.photoUrl !== undefined) || (state.photoBytes !== undefined))}
                    type="button"
                    className="btn btn-link pt-1 pl-0"
                    onClick={() => removePhoto()}
                  >Remove photo</button>
                  <button
                    hidden={!((state.photoUrl !== undefined) || (state.photoBytes !== undefined))}
                    type="button"
                    className="btn btn-link pt-1 pl-0"
                    onClick={() => revertPhoto()}
                  >Revert photo</button>
                </div>
              </>
            }
          />

        </div>

        <hr />

        <div className="row form-group">
          <label className={classNames('col-2 col-form-label text-right', { 'is-invalid': !isUserStatusValid() })}>Status</label>
          <div className="col-3 col-form-label">
            {WorkerStatuses.slice(0, 3).map(status => {
              return <Radio
                key={status}
                label={getUserStatusDescription(status)}
                value={status}
                onChange={() => setState({ ...state, userStatus: status })}
                isSelected={state.userStatus === status}
              />;
            })}
            <div className="pt-2">
              <AlertContainer
                userType={AlertMuteAffectedUserType.worker}
                userId={worker.id()}
                alertTypes={[AlertTypes.workerPendingDocumentsLate]}
                subscribe={true}
                description={<span className="ml-1">Pending documents are late</span>}
              />
            </div>
          </div>

          <div className="col-3 col-form-label">
            {WorkerStatuses.slice(3).map(status => <Radio
              key={status}
              label={getUserStatusDescription(status)}
              value={status}
              onChange={() => setState({ ...state, userStatus: status })}
              isSelected={state.userStatus === status}
            />,
            )}
          </div>
        </div>
        <div className="row form-group">

          <label className="col-2 col-form-label text-right">COVID vaccinated</label>
          <div className="col-3 col-form-label">
            {CovidVaccinationStatusCases.map(status => {
              return <Radio
                key={status}
                label={getCovidVaccinationStatusDescription(status)}
                value={status}
                onChange={() => setState({ ...state, covidVaccinationStatus: status })}
                isSelected={state.covidVaccinationStatus === status}
              />;
            })}
          </div>
        </div>

        <div className="row form-group">

          <label className="col-2 col-form-label text-right">ATSI Status</label>
          <div className="col-3 col-form-label">
            {AtsiStatusCases.map(status => {
              return <Radio
                key={status}
                label={getAtsiStatusDescription(status)}
                value={status}
                onChange={() => setState({ ...state, atsiStatus: status })}
                isSelected={state.atsiStatus === status}
              />;
            })}
          </div>
        </div>

        <div className="row form-group">

          <label className="col-2 col-form-label text-right">Has Working with Children Check</label>
          <div className="col-3 col-form-label">
            <Checkbox
              key="workingWithChildrenCheck"
              label=""
              value={state.workingWithChildrenCheck ? 0 : 1}
              onChange={() =>  {
                setState({ ...state, workingWithChildrenCheck: !state.workingWithChildrenCheck });
              }}
              isSelected={state.workingWithChildrenCheck}
            />
          </div>
        </div>

        <div className="row form-group">
          <FormItem
            title="Watched information video"
            adjustCentering
            valueNode={
              <div className="col-4 form-control border">
                <DatePicker
                  className="w-100 border-0 text-secondary"
                  onChange={(date:Date) => setState({ ...state, completedInduction: date })}
                  selected={moment(state.completedInduction).isValid() ? state.completedInduction : undefined}
                  dateFormat="d/M/yyyy hh:mm a"
                  minDate={moment('1900-01-01').toDate()}
                  maxDate={new Date()}
                  showYearDropdown
                />
              </div>
            }
          />
        </div>

        <div className="row form-group">
          <FormItem
            title="Other employers"
            value={state.otherEmployers}
            onChange={e => setState({ ...state, otherEmployers: e.target.value })}
          />
        </div>

        <hr />
        <div className="row form-group">
          <label className="col-2 col-form-label text-right">Language</label>
          <div className="col-3 col-form-label">
            {LanguageCasesAlphabetical().map(language => {
              return <Checkbox
                key={language}
                label={getLanguageDescription(language)}
                value={language}
                onChange={(e) =>  {
                  const language = parseInt(e.target.value);
                  const languages = new Set(state.languages);
                  languages.has(language) ? languages.delete(language) : languages.add(language);
                  setState({ ...state, languages });
                }}
                isSelected={state.languages.has(language)}
              />;
            })}
          </div>
          <label className="col-2 col-form-label text-right">Hobbies</label>
          <div className="col-3 col-form-label">
            {HobbyCases.map(hobby => {
              return <Checkbox
                key={hobby}
                label={getHobbyDescription(hobby)}
                value={hobby}
                onChange={(e) =>  {
                  const hobby = parseInt(e.target.value);
                  const hobbies = new Set(state.hobbies);
                  hobbies.has(hobby) ? hobbies.delete(hobby) : hobbies.add(hobby);
                  setState({ ...state, hobbies });
                }}
                isSelected={state.hobbies.has(hobby)}
              />;
            },

            )}
          </div>
        </div>

        <div className="row form-group">
          <FormItem
            title="About me"
            value={worker.id()}
            valueNode={
              <textarea
                id="bio"
                className="col-10 form-control"
                rows={4}
                value={state.bio}
                onChange={e => setState({ ...state, bio: e.target.value })}
              />
            }
          />
        </div>

        <div className="row form-group">
          <FormItem
            title="Show to pending clients"
            value={worker.id()}
            valueNode={
              <div className="form-check mb-1 pt-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={state.unrestrictedVisibility}
                  onChange={e => setState({ ...state, unrestrictedVisibility: e.target.checked })}
                />
              </div>
            }
          />
        </div>


        <hr />

        <SaveButton
          onSave={() => onSave(worker, state)}
          onCancel={() => setState(stateFromWorker(worker))}
          disableCancel={disableInteraction || !canCancel}
          disableSave={disableInteraction || !canSave}
          saveText="Save"
        />
      </form>

    </div>);
};
