import React, { useEffect, useState } from 'react';
import { ModalManager } from '../../Modals/Error/manager';
import { ToastManager } from '../../Modals/Toasts/manager';
import Session from '../../Session';
import { Loading } from '../../components/Loading';
import { createAdminNote, getAdminNotes } from '../../managers/AdminNotes';
import { getAdminUsers } from '../../managers/AdminUsers';
import { AdminNoteModel } from '../../models/AdminNote';
import { AdminUser } from '../../models/AdminUser';
import { ClientModel } from '../../models/Client';
import { WorkerModel } from '../../models/Worker';
import AdminManagementView from '../../views/AdminManagement';

export interface AdminManagementContainerProps {
  person?: ClientModel | WorkerModel;
}

export const AdminManagementContainer: React.FC<AdminManagementContainerProps> = ({ person }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingNotes, setIsLoadingNotes] = useState(true);
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [isClient, setIsClient] = useState(false);
  const [cancelCount, setCancelCount] = useState<number>(0);


  // Data which is specific only for Clients
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [caseManager, setCaseManager] = useState<AdminUser | undefined>(undefined);
  const [experienceOfficer, setExperienceOfficer] = useState<AdminUser | undefined>(undefined);
  const [preferredContactMethod, setPreferredContactMethod] = useState('');
  const [adminNotes, setAdminNotes] = useState<AdminNoteModel[]>([]);

  async function loadInitialData(): Promise<void> {
    let checkIsClient = false;

    // Load admin users, case manager and experience officer for the client
    const admins = await getAdminUsers();
    setAdminUsers(admins);
    setIsLoading(true);
    
    if (person instanceof ClientModel) {
      checkIsClient = true;
      setCaseManager(await person.getCaseManager());
      setExperienceOfficer(await person.getExperienceOfficer());
      setIsClient(checkIsClient);
    }

    setIsLoading(false);

    // Load admin notes
    if (person?.id()) {
      setIsLoadingNotes(true);
      const adminNotesRes = await getAdminNotes(person.id(), checkIsClient ? 'client' : 'worker');
      setAdminNotes(adminNotesRes);
      setIsLoadingNotes(false);
    }

    // Set contact method
    try {
      if (checkIsClient) {
        setPreferredContactMethod((person as ClientModel).preferredContactMethod() ?? '');
      } else {
        setPreferredContactMethod((person as WorkerModel).preferredContactMethod() ?? '');
      }
    } catch (error) {
      console.log(`[debug] error setting contact method`, error);
    }
  }

  useEffect(() => {
    void loadInitialData();
  }, []);

  /**
   * Save changes to the case manager/experience officer (these two fields are only applicable for Clients and not Workers)
   * and save the optional preferred contact method
   */
  async function handleSaveClient(caseManager?: AdminUser, experienceOfficer?: AdminUser): Promise<void> {
    if (!person) {
      return;
    }

    try {
      setIsSaving(true);

      if (isClient && caseManager) {
        await (person as ClientModel).saveAdminUsers(caseManager, experienceOfficer);
      }

      ToastManager.shared().show('Changes saved successfully.');
    } catch {
      ModalManager.shared().show('Error', 'There was an error saving the changes for this person. Please try again.');
    }

    setIsSaving(false);
  }

  /**
   * Save changes only for the preferredContactMethod
   */
  async function handleSaveContactMethod(newContactMethod?: string | undefined): Promise<void> {
    if (isClient) {
      await (person as ClientModel).savePreferredContactMethod(newContactMethod ?? '');
    } else {
      setIsSaving(true);
      await (person as WorkerModel).savePreferredContactMethod(newContactMethod ?? '');
      setIsSaving(false);
    }
    setPreferredContactMethod(newContactMethod ?? '');
  }

  /**
   * Generic handleSave function for a client 
   */
  async function handleSave(caseManager?: AdminUser, experienceOfficer?: AdminUser): Promise<void> {
    if (isClient) {
      return handleSaveClient(caseManager, experienceOfficer);
    }
  }

  /**
   * Save a new admin note for the client
   */
  async function handleSaveAdminNote(text: string): Promise<void> {
    if (!person) {
      return;
    }

    setIsSavingNotes(true);

    try {
      let adminUser: AdminUser | undefined = undefined;
      
      const user = Session.getUser();
      if (user) {
        adminUser = user;
      }

      const clientUser: ClientModel | undefined = isClient ? person as ClientModel : undefined;
      const workerUser: WorkerModel | undefined = !isClient ? person as WorkerModel : undefined;
      let newNoteModel: AdminNoteModel;

      if (!clientUser && !workerUser) {
        return;
      }

      if (clientUser) {
        console.log('[debug] creating admin note for client with ID: ', clientUser?.id());
        newNoteModel = await createAdminNote(clientUser, 'client', adminUser, text) as AdminNoteModel;
      } else {
        console.log('[debug] creating admin note for worker with ID: ', workerUser?.id());
        newNoteModel = await createAdminNote(workerUser, 'worker', adminUser, text) as AdminNoteModel;
      }

      if (newNoteModel) {
        const newNotes = [newNoteModel, ...adminNotes];
        setAdminNotes(newNotes);
      }
      ToastManager.shared().show('Changes saved successfully.');
    } catch {
      ModalManager.shared().show('Error', 'There was an error saving the note for this person. Please try again.');
    }
  
    setIsSavingNotes(false);
  }

  if (isLoading || isLoadingNotes) {
    return <><Loading /></>;
  }
  
  return (
    <>
      <AdminManagementView
        key={'AdminManagement_' + cancelCount}
        adminUsers={adminUsers}
        clientCaseManager={caseManager}
        clientExperienceOfficer={experienceOfficer}
        initialContactMethod={preferredContactMethod}
        adminNotes={adminNotes}
        isClient={isClient}
        onSaveClient={handleSave}
        onSaveAdminNote={handleSaveAdminNote}
        onSaveContactMethod={handleSaveContactMethod}
        onReset={() => setCancelCount(cancelCount + 1)}
        disableInteraction={isSaving || isSavingNotes}
        isSaving={isSaving}
        isSavingNotes={isSavingNotes}
      />
    </>
  );
};