import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { AnalyticsEventType } from '../../../enums/AnalyticsEventType';
import { GoalStatus } from '../../../enums/GoalStatus';
import useAnalytics, { AnalyticsEvent } from '../../../hooks/useAnalytics';
import ClientManager from '../../../managers/ClientManager';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { ClientModel } from '../../../models/Client';
import { GoalModel } from '../../../models/Goal';
import { ClientGoalView, ClientGoalViewState } from '../../../views/Client/Goals';
import { GoalState } from '../../Goals';
import { DEFAULT_DELAY, useCanLoad } from '../util';

export interface ClientGoalsContainerProps {
  client?: ClientModel;
}

export const ClientGoalsContainer: React.FC<ClientGoalsContainerProps> = ({ client }) => {
  const [goals, setGoals] = useState<GoalModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [updateCount, setUpdateCount] = useState<number>(0);
  const [triggerEventReload, setTriggerEventReload] = useState(false);
  const { logEvents, getLastEventInfo, isLoading: isLastEventLoading, lastEvent } = useAnalytics();

  const canLoad = useCanLoad('goals', DEFAULT_DELAY);

  useEffect(() => {
    if (!canLoad || !client) { return; }
    setIsLoading(true);
    void client.getGoals().then(goals => {
      setIsLoading(false);
      setGoals(goals || []);
      setTriggerEventReload(true);
    });
  }, [canLoad, client]);

  useEffect(() => {
    if (triggerEventReload && goals) {
      setTriggerEventReload(false);
      void getLastEventInfo([
        AnalyticsEventType.clientGoalCreated,
        AnalyticsEventType.clientGoalDeleted,
        AnalyticsEventType.clientGoalEdited,
      ], goals.map(goal => goal.id()));
    }
  }, [triggerEventReload, goals]);

  /**
   * Detect changes between goal save state to create events for analytics
   */
  function getChangeEvents(current: GoalModel[], updated: GoalState[]): { events: AnalyticsEvent[]; newItemTitles: Array<string | undefined> } {
    const changes: AnalyticsEvent[] = [];
    const newItemTitles: Array<string | undefined> = [];

    updated.forEach((newGoal) => {
      let eventType: AnalyticsEventType | undefined = undefined;

      const prevGoal = current.find(item => item.id() === newGoal.id);
      if (prevGoal) {
        if (newGoal.isDeleted && prevGoal.status() !== GoalStatus.deleted) {
          eventType = AnalyticsEventType.clientGoalDeleted;
        } else if (newGoal.title !== prevGoal.title() || newGoal.criteria !== prevGoal.criteria()) {
          eventType = AnalyticsEventType.clientGoalEdited;
        }
      } else {
        eventType = AnalyticsEventType.clientGoalCreated;
      }
      
      if (eventType !== undefined) {
        newItemTitles.push(prevGoal === undefined ? String(newGoal.title) : undefined);
        changes.push({
          eventType,
          objectId: prevGoal?.id() ?? 'UNKNOWN',
        });
      }
    });

    return { events: changes, newItemTitles };
  }

  /**
   * Handle saving changes
   */
  async function handleSave(state: ClientGoalViewState) {
    if (!client) {
      return;
    }

    // Get change events here before saving
    const changeEvents = getChangeEvents([...goals], [...state.goals]);

    setIsSaving(true);
    const savedGoals = await ClientManager.saveClientGoals(client, state);
    setIsSaving(false);
    setGoals(savedGoals || []);
    setUpdateCount(updateCount + 1);
    ToastManager.shared().show('Client goals saved');

    changeEvents.newItemTitles.forEach((newTitle, index) => {
      if (newTitle) {
        const newGoal = savedGoals.find(goal => String(goal.title()) === newTitle);
        if (newGoal) {
          changeEvents.events[index].objectId = newGoal.id();
        }
      }
    });

    void logEvents(changeEvents.events);
    setTriggerEventReload(true);
  }

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {(client && goals && !isLoading) &&
        <ClientGoalView
          client={client}
          key={updateCount + goals.map(goal => goal.id()).join()}
          goals={goals}
          onSave={handleSave}
          onReset={() => setUpdateCount(updateCount + 1)}
          disableInteraction={isSaving}
          lastGoalEvent={isLastEventLoading ? undefined : lastEvent}
        />
      }
    </>
  );
};