import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { ClientModel } from '../../../models/Client';
import { MessageModel } from '../../../models/Message';
import Session from '../../../Session';
import { ClientMessagesView } from '../../../views/Client/Messages';


export interface ClientMessagesContainerProps {
  client?: ClientModel;
}

async function getMessages(client: ClientModel) {
  const query = new Parse.Query('Message');
  const clientQuery = new Parse.Query('Client');
  clientQuery.equalTo('objectId', client.id());
  query.matchesQuery('client', clientQuery);
  query.include('client');
  query.include('worker');
  query.ascending('createdAt');

  query.limit(10_000);
  const messages = await query.find();
  return messages.map(message => new MessageModel(message));
}

export const ClientMessagesContainer: React.FC<ClientMessagesContainerProps> = ({ client }) => {
  const [messages, setMessages] = useState<MessageModel[] | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const adminUserId = Session.getUser()?.admin?.id ?? '';

  useEffect(() => {
    if (!client) { return; }
    setIsLoading(true);
    void getMessages(client).then(messages => {
      setIsLoading(false);
      setMessages(messages);
    });

  }, [client]);

  async function saveMessageDeletedState(messageIds: string[], isDeleted: boolean, isBulkAction: boolean) {
    const messageItems = messages?.filter((message) => messageIds.includes(message.id()));
    const updatedMessages: MessageModel[] = [];
    const allowRestoreUserDeletedMessages = !isBulkAction;

    if (messageItems && messages) {
      setIsSaving(true);

      for (const message of messageItems) {
        message.setDeleted(isDeleted, adminUserId, allowRestoreUserDeletedMessages);
        updatedMessages.push(message);
      }
      
      await Parse.Object.saveAll(updatedMessages.map((msg) => msg.rawObject()));

      if (updatedMessages && updatedMessages.length) {
        const newMessages = [...messages];
        updatedMessages.forEach((updatedMessage) => {
          const updateIndex = newMessages.findIndex((msg) => msg.id() === updatedMessage.id());

          if (updateIndex > -1) {
            newMessages[updateIndex] = updatedMessage;
          }
        });

        setMessages(newMessages);
      }

      setIsSaving(false);
    }
  }

  async function handleDeleteMessages(messageIds: string[], isBulkAction = false) {
    if (messageIds.length > 1) {
      if (window.confirm('Are you sure you want to delete all the messages in this conversation?')) {
        await saveMessageDeletedState(messageIds, true, isBulkAction);
      }
    } else {
      await saveMessageDeletedState(messageIds, true, isBulkAction);
    }

    ToastManager.shared().show(`${isBulkAction ? 'Conversation' : 'Message'} deleted.`);
  }
  
  async function handleRestoreMessages(messageIds: string[], isBulkAction = false) {
    if (messageIds.length > 1) {
      if (window.confirm('Are you sure you want to restore all the messages in this conversation? Note that this will only restore messages deleted by admins. Those deleted by the client or worker will remain deleted. You can restore these messages individually.')) {
        await saveMessageDeletedState(messageIds, false, isBulkAction);
      }
    } else {
      await saveMessageDeletedState(messageIds, false, isBulkAction);
    }

    ToastManager.shared().show(`${isBulkAction ? 'Conversation' : 'Message'} restored.`);
  }

  return (
    <div className="container pb-2 pt-4">

      {isLoading && <Loading />}
      {(messages && messages.length > 0) && <>
        <ClientMessagesView
          messages={messages}
          onDeleteMessages={handleDeleteMessages}
          onRestoreMessages={handleRestoreMessages}
          isDisabled={isSaving}
        />
      </>
      }
      {(messages && messages.length === 0) && <>
        <div className="text-info text-center">No messages for this client</div>
      </>
      }
    </div>
  );
};