import React, { useEffect, useState } from 'react';
import { ModalManager } from '../../../Modals/Error/manager';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { Loading } from '../../../components/Loading';
import { AnalyticsEventType } from '../../../enums/AnalyticsEventType';
import useAnalytics, { AnalyticsEvent } from '../../../hooks/useAnalytics';
import ClientManager from '../../../managers/ClientManager';
import { ClientModel } from '../../../models/Client';
import { ContactModel } from '../../../models/Contact';
import { ClientContactView, ClientContactViewState } from '../../../views/Client/EmergencyContacts';
import { ContactState } from '../../Contacts';
import { DEFAULT_DELAY, useCanLoad } from '../util';

export interface ClientContactsContainerProps {
  client?: ClientModel;
}

export const ClientContactsContainer: React.FC<ClientContactsContainerProps> = ({ client }) => {
  const [contacts, setContacts] = useState<ContactModel[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [cancelCount, setCancelCount] = useState<number>(0);
  const [triggerEventReload, setTriggerEventReload] = useState(false);
  const { logEvents, getLastEventInfo, isLoading: isLastEventLoading, lastEvent } = useAnalytics();

  const canLoad = useCanLoad('contacts', DEFAULT_DELAY);

  useEffect(() => {
    if (!canLoad || !client) { return; }
    setIsLoading(true);
    void client.getContacts().then(contacts => {
      setIsLoading(false);
      setContacts(contacts || []);
      setTriggerEventReload(true);
    });
  }, [canLoad, client]);

  useEffect(() => {
    if (triggerEventReload && contacts) {
      setTriggerEventReload(false);
      void getLastEventInfo([
        AnalyticsEventType.clientContactAdded,
        AnalyticsEventType.clientContactDeleted,
        AnalyticsEventType.clientContactEdited,
      ], contacts.map(contact => contact.id()));
    }
  }, [triggerEventReload, contacts]);

  /**
   * Detect changes between goal save state to create events for analytics
   */
  function getChangeEvents(current: ContactModel[], updated: ContactState[]): { events: AnalyticsEvent[]; newItemStates: Array<ContactState | undefined> } {
    const changes: AnalyticsEvent[] = [];
    const newItemStates: Array<ContactState | undefined> = [];

    updated.forEach(newContact => {
      let eventType: AnalyticsEventType | undefined = undefined;

      const prevContact = current.find(item => item.id() === newContact.id);
      if (prevContact) {
        if (newContact.isDeleted && !prevContact.isDeleted()) {
          eventType = AnalyticsEventType.clientContactDeleted;
        } else if (
          newContact.email !== prevContact.email() || 
          newContact.firstName !== prevContact.firstName() ||
          newContact.lastName !== prevContact.lastName() || 
          newContact.phone !== prevContact.phone() || 
          newContact.relationship !== prevContact.relationship())
        {
          eventType = AnalyticsEventType.clientContactEdited;
        }
      } else {
        eventType = AnalyticsEventType.clientContactAdded;
      }

      if (eventType !== undefined) {
        newItemStates.push(prevContact === undefined ? newContact : undefined);
        changes.push({
          eventType,
          objectId: prevContact?.id() ?? 'UNKNOWN',
        });
      }
    });
    
    return { events: changes, newItemStates };
  }

  /**
   * Handle saving changes
   */
  async function handleSave(state: ClientContactViewState) {
    try {
      if (!client) {
        return;
      }

      // Get change events here before saving
      const current: ContactModel[] = contacts?.filter(c => c !== undefined) as ContactModel[];
      const changeEvents = getChangeEvents([...current], [...state.contacts]);

      setIsSaving(true);
      const saved = await ClientManager.saveClientContacts(client, state.contacts);
      setIsSaving(false);
      setContacts(saved);
      ToastManager.shared().show('Client emergency contacts saved.');

      changeEvents.newItemStates.forEach((newState, index) => {
        if (newState) {
          const newModel = saved?.find(contact => {
            return contact.email() === newState.email && contact.phone() === newState.phone && contact.firstName() === newState.firstName;
          });
          if (newModel) {
            changeEvents.events[index].objectId = newModel.id();
          }
        }
      });

      void logEvents(changeEvents.events);
      setTriggerEventReload(true);
    } catch {
      ModalManager.shared().show('Error', 'There was an error saving the client\'s contacts. Please try again.');
    }
  }

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {(client && contacts) &&
        <ClientContactView
          contacts={contacts}
          key={contacts?.length + cancelCount}
          onSave={handleSave}
          disableInteraction={isSaving}
          onReset={() => setCancelCount(cancelCount + 1)}
          lastContactEvent={isLastEventLoading ? undefined : lastEvent}
        />
      }
    </>
  );
};