import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import { SortDown, SortUp } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { SupportSessionRequestTile } from '../../components/SupportSessionRequestTile';
import { SupportSessionTile } from '../../components/SupportSessionTile';
import { ToolBar } from '../../components/Toolbar';
import { FundingManagementType } from '../../enums/SupportNeedFundingManagementType';
import { buildSessionsPath, fromStorage, getFullStatusSet, parseDateParam, parseStatusesParam, toStorage } from '../../helpers/sessionAndBillingUtils';
import { applySessionSearch, applySessionSort } from '../../helpers/sessionFilterAndSort';
import { sessionStatusFilterOptions } from '../../helpers/sessionsAndRequests';
import sessionsManager, { DashboardParams, DashboardSessionFilters } from '../../managers/Sessions';
import { SessionStatus } from '../../models/Sessions';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../models/Sessions/SupportSessionRequest';
import { useSearchParams } from '../../views/Hooks/useSearchParams';

const SESSION_SEARCH_KEY = 'suitsme.search.session';

export type SessionsSortMode = 'start_date' | 'created_date' | 'client_name' | 'worker_name' | 'status';
export type SessionsSortDirection = 'ascending' | 'descending';
type SessionOrRequest = SupportSessionModel | SupportSessionRequestModel;

/**
 * SessionsContainer main component
 */
export const SessionsContainer: React.FC = () => {
  const history = useHistory();
  let initialBefore = useSearchParams('before');
  let initialAfter = useSearchParams('after');
  const initialSortMode = useSearchParams('sortMode');
  const initialSortDirection = useSearchParams('sortDirection');
  const initialStatusParam = useSearchParams('statuses');
  const paramSearch = useSearchParams('search');
  initialBefore = parseDateParam(initialBefore) ?? null;
  initialAfter = parseDateParam(initialAfter) ?? null;

  let initialStatuses = parseStatusesParam(initialStatusParam);
  if (initialStatuses.size === 0) {
    initialStatuses = getFullStatusSet();
  }

  const [sessionRequests, setSessionRequests] = useState<SupportSessionRequestModel[]>([]);
  const [sessions, setSessions] = useState<SupportSessionModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sortMode, setSortMode] = useState<SessionsSortMode>(initialSortMode ?? 'start_date');
  const [sortDirection, setSortDirection] = useState<SessionsSortDirection>(initialSortDirection ?? 'ascending');

  // Client side filtering
  const [searchTerm, setSearchTerm] = useState<string | undefined>(paramSearch || fromStorage(SESSION_SEARCH_KEY) || '');

  // Server side filtering, note the defaults:
  const [beforeDate, setBeforeDate] = useState<Date | undefined>(initialBefore);
  const [afterDate, setAfterDate] = useState<Date | undefined>(initialAfter);
  const [statusFilters, setStatusFilters] = useState<Set<string>>(initialStatuses);

  // Retain copy of last applied filters
  const [lastFilters, setLastFilters] = useState<DashboardSessionFilters>({
    beforeDate,
    afterDate,
    statuses: [],
  });

  const latestParams = useRef<DashboardParams | undefined>();

  // Filtering
  const applyFilters = (): void => {
    const statuses: SessionStatus[] = [];
    statusFilters.forEach((filter) => {
      const item = sessionStatusFilterOptions.find((opt) => opt.value === filter);
      item?.rawValues.forEach(value => statuses.push(value));
    });

    async function fetchData(params: DashboardParams) {
      setIsLoading(true);
      const sessionsAndRequests = await sessionsManager.getDashboardSessionsAndRequests(params);

      const requestResults = sessionsAndRequests.results.requests.map((req: Parse.Object) => new SupportSessionRequestModel(req));
      const sessionResults = sessionsAndRequests.results.sessions.map((session: Parse.Object) => new SupportSessionModel(session));

      return {
        requestResults,
        sessionResults,
        params: sessionsAndRequests.params,
      };
    }

    const managed = [FundingManagementType.Plan, FundingManagementType.Self, FundingManagementType.NDIA];
    const params = { after: afterDate, before: beforeDate, managed, statuses };
    const statusesArray = Array.from(statusFilters);
    latestParams.current = params;

    setLastFilters({
      beforeDate,
      afterDate,
      statuses: statusesArray,
    });

    history.push(buildSessionsPath(afterDate, beforeDate, statusesArray, searchTerm, sortMode, sortDirection));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    void fetchData(params).then(result => {
      // This check prevents results from old filters loading in over top of actually requested data.
      if (result.params === latestParams.current) {
        setSessionRequests(result.requestResults);
        setSessions(result.sessionResults);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (beforeDate && afterDate) {
      applyFilters();
    }
  }, []);

  // Detect when filters are changed and show the Apply button
  const isFiltersChanged = useMemo(() => {
    if (!beforeDate || !afterDate) {
      return false;
    }

    if (afterDate !== lastFilters.afterDate ||
      beforeDate !== lastFilters.beforeDate) {
      return true;
    } else {
      if (lastFilters.statuses) {
        const statusStr = Array.from(statusFilters).join(',');
        const lastStatusStr = Array.from(lastFilters.statuses).join(',');
        if (statusStr !== lastStatusStr) {
          return true;
        }
      }
      return false;
    }
  }, [afterDate, beforeDate, statusFilters, lastFilters]);

  // Reset pending filters back to the current state
  const resetFilters = (): void => {
    setBeforeDate(lastFilters.beforeDate);
    setAfterDate(lastFilters.afterDate);
    if (lastFilters.statuses) {
      const newStatuses = new Set<string>(lastFilters.statuses);
      setStatusFilters(newStatuses);
    }
  };

  // Save to local storage hooks
  useEffect(() => {
    if (searchTerm || searchTerm === '') { toStorage(searchTerm, SESSION_SEARCH_KEY); }
  }, [searchTerm]);

  // Update url hooks
  useEffect(() => {
    history.push(buildSessionsPath(afterDate, beforeDate, [], searchTerm, sortMode, sortDirection));
  }, [searchTerm, history, sortMode, sortDirection]);

  const updateDate = (date: Date | [Date, Date] | null, updateFn: (date: Date) => unknown) => {
    const momentDate = moment(date as Date);
    if (!momentDate.isValid()) { return; }
    updateFn(momentDate.toDate());
  };

  /**
   * Handle changing the filter checkboxes
   */
  const handleFilterSelect = (value: string) => {
    const newSelected = new Set(statusFilters);
    if (newSelected.has(value)) {
      newSelected.delete(value);
    } else {
      newSelected.add(value);
    }
    setStatusFilters(newSelected);
  };

  function toggleSortMode(mode: SessionsSortMode) {
    setSortMode(mode);
    applyFilters();
  }

  function toggleSortOrder() {
    setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
    applyFilters();
  }

  /**
   * Combine all sessionRequests and sessions, after applying the search filter,
   * and finally apply the sorting filter to determine visible sessions
   */
  const visibleSessions = useMemo<SessionOrRequest[]>(() => {
    const filteredSessionRequests = sessionRequests.filter(session => applySessionSearch(session, searchTerm));
    const filteredSessions = sessions.filter(session => applySessionSearch(session, searchTerm));
    const allItems: SessionOrRequest[] = [
      ...filteredSessionRequests,
      ...filteredSessions,
    ];

    const sortedItems = applySessionSort(allItems, sortMode, sortDirection);
    return sortedItems;
  }, [sessionRequests, sessions, searchTerm, sortMode, sortDirection]);

  return (
    <div className="mx-4">
      <ToolBar
        title="Support Sessions"
        top={
          <>
            <button
              type="button"
              className="btn btn-primary mr-2 my-auto"
              style={{
                width: 100,
                paddingRight: 0,
                paddingLeft: 20,
                paddingTop: 8,
                position: 'relative',
              }}
              onClick={() => history.push('/sessions/_create')}
            >
              <span
                className="uil-plus-square"
                style={{
                  fontSize: '1.4rem',
                  position: 'absolute',
                  left: 8,
                  top: 1.5,
                }}
              />
              Create
            </button>
            <div className="form-inline col-4">
              <input
                className="form-control w-100"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </>
        }
        filters={
          <div>
            {/* Checkbox filters */}
            <div className="mx-4 pt-2">
              <Form className="row">
                {
                  sessionStatusFilterOptions.map((option) => {
                    return (
                      <div
                        className="col-3 mb-2"
                        key={option.id}
                      >
                        <input
                          style={{ cursor: 'pointer' }}
                          id={option.id}
                          className="form-check-input"
                          type="checkbox"
                          name={option.id}
                          checked={statusFilters.has(option.value)}
                          value={option.value}
                          onChange={e => handleFilterSelect(e.target.value)}
                        />
                        <label
                          style={{ cursor: 'pointer' }}
                          htmlFor={option.id}
                          className="form-check-label font-weight-normal text-white"
                        >{option.label}</label>
                      </div>
                    );
                  })
                }
              </Form>
            </div>

            <hr />

            {/* Date filters */}
            <div className="row">
              <div className="col-12 mb-1 pl-0">
                <form className="form-inline">
                  <div className="form-group col-6">
                    <label
                      className="col-3 col-form-label text-right text-white d-block"
                    >Scheduled after</label>
                    <div className="col-5 form-control border">
                      <DatePicker
                        showTimeInput
                        placeholderText="Select date"
                        className="w-100 border-0 text-secondary"
                        onChange={date => updateDate(date, setAfterDate)}
                        dateFormat="d/M/yyyy, HH:mm"
                        minDate={moment('2010-01-01').toDate()}
                        maxDate={moment('2050-01-01').toDate()}
                        showYearDropdown
                        selected={afterDate}
                      />
                    </div>
                  </div>
                  <div
                    className="form-group col-6 justify-content-end"
                    style={{
                      transform: 'translateX(-90px)',
                    }}
                  >
                    <div className="col-1 text-white text-right">
                      Sort
                    </div>
                    <div className="col-4 d-flex flex-row">
                      <select
                        id="sortMode"
                        className="form-control"
                        style={{
                          minWidth: 210,
                        }}
                        value={sortMode}
                        onChange={event => toggleSortMode(event.target.value as SessionsSortMode)}
                        disabled={isLoading || visibleSessions.length === 0}
                      >
                        <option
                          key="start_date"
                          value="start_date"
                        >Start date</option>;
                        <option
                          key="created_date"
                          value="created_date"
                        >Creation date</option>;
                        <option
                          key="client_name"
                          value="client_name"
                        >Client name</option>;
                        <option
                          key="worker_name"
                          value="worker_name"
                        >Worker name</option>;
                        <option
                          key="status"
                          value="status"
                        >Status</option>;
                      </select>
                      <button
                        type="button"
                        className="btn btn-primary ml-2 mr-2"
                        style={{ maxWidth: 50 }}
                        onClick={toggleSortOrder}
                        disabled={isLoading || visibleSessions.length === 0}
                      >
                        <span>{sortDirection === 'descending' ? <SortDown size="24" /> : <SortUp size="24" />}</span>
                      </button>
                    </div>
                  </div>
                </form>

                <form className="form-inline mt-2 mb-2">
                  <div className="form-group col-6">
                    <label
                      className="col-3 col-form-label text-right text-white d-block"
                    >Scheduled before</label>
                    <div className="col-5 form-control border">
                      <DatePicker
                        showTimeInput
                        placeholderText="Select date"
                        className="w-100 border-0 text-secondary"
                        onChange={date => updateDate(date, setBeforeDate)}
                        dateFormat="d/M/yyyy, HH:mm"
                        minDate={moment('2010-01-01').toDate()}
                        maxDate={moment('2050-01-01').toDate()}
                        showYearDropdown
                        selected={beforeDate}
                      />
                    </div>
                  </div>
                  <div className="form-group col-6">
                    {
                      isFiltersChanged && (
                        <div style={{ position: 'absolute', left: -190 }}>
                          <button
                            type="button"
                            className="btn btn-primary mr-2 my-auto"
                            onClick={applyFilters}
                            disabled={statusFilters.size === 0}
                          >Apply filters</button>
                          <button
                            type="button"
                            className="btn btn-link m-1"
                            onClick={resetFilters}
                          >Cancel</button>
                        </div>
                      )
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        }
      />


      {isLoading &&
        <Loading />
      }

      {!isLoading &&
        <div
          className="row px-1 mt-2"
          style={{ opacity: isFiltersChanged ? 0.5 : 1 }}
        >
          {
            visibleSessions.map((session) => {
              return (
                <div
                  key={session.id()}
                  className="col-sm-12 col-md-12 col-lg-6 col-xl-4 p-0"
                >
                  {/* Render either SupportSessionTile or SupportSessionRequestTile */}
                  {
                    'proposedSessions' in session ? 
                      <SupportSessionRequestTile
                        session={session}
                      /> :
                      <SupportSessionTile
                        session={session}
                      />
                  }
                </div>
              );
            })
          }
        </div>
      }
      {
        !isLoading && visibleSessions.length === 0 && (!beforeDate || !afterDate) && (
          <div
            className="alert alert-warning mt-2 text-center"
            role="alert"
          >
            Please select date filters to start searching.
          </div>
        )
      }
      {
        !isLoading && visibleSessions.length === 0 && beforeDate && afterDate && (
          <div
            className="alert alert-warning mt-2 text-center"
            role="alert"
          >
            There are no sessions or session requests with the current filters applied.
          </div>
        )
      }
    </div>
  );
};

