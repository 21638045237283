import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ConversationTile, MessageTile } from '../../../components/Messages';
import { MessageUser } from '../../../enums/MessageUser';
import { last } from '../../../helpers';
import { ClientModel } from '../../../models/Client';
import { MessageModel } from '../../../models/Message';
import { WorkerModel } from '../../../models/Worker';

export interface ClientMessagesViewProps  {
  messages: MessageModel[];
  onDeleteMessages(messageIds: string[], isBulkAction?: boolean): void;
  onRestoreMessages(messageIds: string[], isBulkAction?: boolean): void;
  isDisabled?: boolean;
}

interface MessageGroup {
  worker: WorkerModel;
  client: ClientModel;
  messages: MessageModel[];
  isDeleted: boolean;
  deletedDate?: Date;
}

function getMessageGroups(messages: MessageModel[]): MessageGroup[] {
  const workerMessagesMap: {[id: string]: MessageGroup } = {};

  messages.forEach(message => {
    const worker = message.worker();
    const record = workerMessagesMap[worker.id()] || { worker: message.worker(), client: message.client(), messages: [] };

    workerMessagesMap[worker.id()] = { ...record, messages: [...record.messages, message] };
  });

  Object.keys(workerMessagesMap).forEach((key) => {
    const group = workerMessagesMap[key];
    group.isDeleted = group.messages.find((msg) => msg.deletedAt() === undefined) === undefined;    
  });

  return Object.values(workerMessagesMap).sort((worker1, worker2) => last(worker2.messages).createdAt().getTime() - last(worker1.messages).createdAt().getTime());
}

export const ClientMessagesView:React.FC<ClientMessagesViewProps> = ({ messages, onDeleteMessages, onRestoreMessages, isDisabled }) => {
  const groupedMessages = useMemo(() => {
    return getMessageGroups(messages);
  }, [messages]);  
  const [selectedWorker, setSelectedWorker] = useState<string>(groupedMessages[0].worker.id());

  const finalMessage = useRef<HTMLDivElement | null>();

  useEffect(() => {
    finalMessage.current?.scrollIntoView({ block: 'nearest' });
  }, [finalMessage, selectedWorker]);

  function onDeleteMessageGroup(groupIndex: number) {
    const group = groupedMessages[groupIndex];
    void onDeleteMessages(group.messages.map(msg => msg.id()), true);
  }

  function onRestoreMessageGroup(groupIndex: number) {
    const group = groupedMessages[groupIndex];
    void onRestoreMessages(group.messages.map(msg => msg.id()), true);
  }

  return (
    <>
      <div
        className="row mx-0"
        style={isDisabled ? { opacity: 0.5, pointerEvents: 'none' } : {}}
      >
        <div className="col-4">
          {groupedMessages.map((group, index) => <ConversationTile
            key={group.worker.id()}
            them={{
              lastMessage: group.messages[group.messages.length - 1].createdAt(),
              photo: group.worker.photo(),
              name: group.worker.fullName(),
            }}
            onClick={() => setSelectedWorker(group.worker.id())}
            selected={group.worker.id() === selectedWorker}
            isDeleted={group.isDeleted}
            deletedDate={group.messages[group.messages.length - 1].deletedAt()}
            onDelete={() => onDeleteMessageGroup(index)}
            onRestore={() => onRestoreMessageGroup(index)}
          />)}
        </div>
        <div className="col-8 messages-container pr-3">
          {messages.filter(message => message.worker().id() === selectedWorker).map((message) => <MessageTile
            user={message.isClientAuthored() ? MessageUser.You : MessageUser.Them}
            key={message.id()}
            message={message}
            onDelete={onDeleteMessages}
            onRestore={onRestoreMessages}
          />)}
          <div
            style={{ float: 'left', clear: 'both' }}
            ref={(el) => { finalMessage.current = el; }}
          />
        </div>
      </div>
    </>
  );
};
