import classNames from 'classnames';
import moment from 'moment';
import React, { useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { ClientModel } from '../../../models/Client';
import { DatedServiceAgreement } from '../../../types/parseConfig';
import './agreements.css';

export interface ClientAgreementsViewProps  {
  client: ClientModel;
  agreements: DatedServiceAgreement[] | undefined;

  onChangeMessage: (message: string | undefined) => Promise<void>;
  onRequireNewAgreement: (date: Date | undefined) => Promise<void>;

  disableInteraction: boolean;
}

function fd(date: Date): string {
  return moment(date).format('LLLL');
}

function agreementVersion(date: Date, agreements: DatedServiceAgreement[]): string {
  const agreedAt = moment(date);
  let applicableAgreement = agreements[0];
  for (const agreement of agreements) {
    const agreementEffective = moment(agreement.date);

    if (agreementEffective.isBefore(agreedAt)) {
      applicableAgreement = agreement;
    }
  }

  return ` (Version ${applicableAgreement.version})`;
}

export const ClientAgreementsView:React.FC<ClientAgreementsViewProps> = ({ client, agreements, onChangeMessage, onRequireNewAgreement, disableInteraction }) => {
  const [message, setMessage] = useState<string | undefined>(client.requiresServiceAgreementMessage());
  const [requiresAgreement, setRequiresAgreement] = useState<Date | undefined>(client.requiresServiceAgreement());

  return (
    <div className="mt-4">
      <h4>Accepted consent agreement</h4>
      <div>{client.createdAt() ? fd(client.createdAt()!) : 'Not yet agreed'}</div>
      <hr />

      <h4>Accepted service agreement(s)</h4>
      {client.serviceAgreementAcceptedAt()?.map(date => {
        return (
          <div key={date.toString()}>
            {fd(date)}
            {agreements &&
              <span className="font-weight-bold">{agreementVersion(date, agreements)}</span>
            }
          </div>
        );
      },
      )}

      <hr />

      {requiresAgreement &&
        <div className="d-flex">
          <div className="text-danger">
            {`New agreement requested ${fd(requiresAgreement)}`}
            {agreements &&
              <span className="font-weight-bold">{agreementVersion(requiresAgreement, agreements)}</span>
            }
          </div>
          <X
            className={classNames('text-danger', { disabled: disableInteraction })}
            size={24}
            onClick={() => {
              setRequiresAgreement(undefined);
              void onRequireNewAgreement(undefined);
            }}
          />
        </div>
      }

      <form>
        <label
          htmlFor="title"
          className="col-12 col-form-label pl-0"
        >Custom agreement message</label>
        <textarea
          id="title"
          className="col-4 form-control mpb-2"
          rows={7}
          value={message || ''}
          onChange={e => setMessage(e.target.value)}
        />
      </form>

      <div className="row flex-row-reverse mt-1 col-4 mx-0 px-0">
        <button
          className={classNames('btn btn-danger msg-btn', { 'disabled': !message || disableInteraction })}
          onClick={() =>  {
            setMessage(undefined);
            void onChangeMessage(message);
          }}
          disabled={!message || disableInteraction}

        > Remove </button>
        <button
          className={classNames('btn btn-primary mr-2 msg-btn', { 'disabled': !message || disableInteraction })}
          onClick={() => void onChangeMessage(message)}
          disabled={!message || disableInteraction}
        > Save </button>
      </div>

      <button
        className={classNames('mt-4 btn btn-danger', { 'disabled': !!requiresAgreement || disableInteraction })}
        onClick={() => {
          const date = new Date();
          setRequiresAgreement(date);
          void onRequireNewAgreement(date);
        }}
        disabled={!!requiresAgreement || disableInteraction}
      > Require new service agreement </button>
    </div>
  );
};
