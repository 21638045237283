import Parse from 'parse';
import { categoryFromType, IncidentReportCategory, IncidentReportType } from '../enums/WorkerReportType';
import { ClientModel } from './Client';
import { WorkerModel } from './Worker';


export class WorkerReportModel {
  constructor(public object: Parse.Object) { }

  static new(): WorkerReportModel {
    return new WorkerReportModel(new (Parse.Object.extend('IncidentReport'))());
  }

  id(): string { return this.object.id; }
  text(): string | undefined { return this.object.get('text'); }
  createdAt(): Date  { return this.object.get('createdAt'); }

  type(): IncidentReportType { return this.object.get('type') as IncidentReportType; }
  category(): IncidentReportCategory { return categoryFromType(this.type()); }

  /**
   * Assumes that worker is already fetched;
   */
  getWorkerSync(): WorkerModel {
    return new WorkerModel(this.object.get('worker'));
  }

  /**
   * Assumes that client is already fetched;
   */
  getClientSync(): ClientModel | undefined {
    if (this.object.get('client')) {
      return new ClientModel(this.object.get('client'));
    }
    return undefined;
  }
}
