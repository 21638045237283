import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import { UserStatus } from '../../enums/UserStatus';
import ClientManager from '../../managers/ClientManager';
import { CreateSessionState, CreateSupportSessionResponse, checkSessionRequestDates, createSessionRequest } from '../../managers/Sessions';
import WorkerManager from '../../managers/Workers';
import { ClientModel } from '../../models/Client';
import { WorkerModel } from '../../models/Worker';
import { CreateSessionView } from '../../views/CreateSession';

export const CreateSessionContainer:React.FC = () => {

  const [clients, setClients] = useState<ClientModel[] | undefined>();
  const [workers, setWorkers] = useState<WorkerModel[] | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const downloadData = async () => {
      setIsLoading(true);
      let [clients, workers] = await Promise.all([ClientManager.getClientsAsync(), WorkerManager.instance().getWorkers()]);
      clients = clients.sort((client1, client2) => client1.fullName().localeCompare(client2.fullName()));
      workers = workers.sort((worker1, worker2) => worker1.fullName().localeCompare(worker2.fullName()));

      setClients(clients.filter(client => client.status() === UserStatus.Active));
      setWorkers(workers.filter(worker => worker.status() === UserStatus.Active));
      setIsLoading(false);
    };
    void downloadData();
  }, []);

  async function handleCheckSessionDates(params: CreateSessionState): Promise<CreateSupportSessionResponse> {
    return checkSessionRequestDates(params);
  }

  function handleCreateSession(params: CreateSessionState): Promise<void> {
    return createSessionRequest(params);
  }

  return <>
    {isLoading && <Loading />}
    {(clients && workers) &&
      <CreateSessionView
        workers={workers}
        clients={clients}
        onCreate={handleCheckSessionDates}
        onConfirm={handleCreateSession}
      />
    }</>;
};
