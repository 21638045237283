import { SelectorOption } from '../components/Selector';

export enum WorkerLevel {
  One=1,
  Two=2,
  Three=3,
  Four=4
}


const WorkerLevelKeys = Object.keys(WorkerLevel).filter(key => typeof WorkerLevel[key as any] === 'number');
export const WorkerLevelCases = WorkerLevelKeys.map(key => Number(WorkerLevel[key as any]) as WorkerLevel);

export function getWorkerLevelDescription(level: WorkerLevel): string {
  return '2.' + level.toString();
}

export function workerLevelOptions(): SelectorOption[] {
  const options: SelectorOption[] = WorkerLevelCases.map(c => ({ name: getWorkerLevelDescription(c), key: c }));
  return options;
}