import { getStatusDescription, SessionStatus } from '.';
import { getSupportNeedModelTypeDescription, SupportNeedModelType } from '../../enums/SupportNeedModelType';
import { ClientModel } from '../Client';
import { SupportNeedModel } from '../SupportNeed';
import { ParseObject } from '../util/parseTypes';

/**
 * Assumes parse object includes worker and client.
 */
export abstract class BaseSessionModel {
  constructor(public object: Parse.Object) { }

  id(): string | undefined { return this.object.id; }

  workerName(): string {
    const first = this.object.get('worker').get('firstName');
    const last = this.object.get('worker').get('lastName');
    return [first, last].join(' ');
  }

  workerLastName(): string {
    return this.object.get('worker').get('lastName');
  }

  clientId(): string {
    return this.object.get('client').id;
  }

  workerId(): string {
    return this.object.get('worker').id;
  }

  clientSync(): ClientModel {
    return new ClientModel(this.object.get('client'));
  }

  getNeedType(): SupportNeedModelType {
    const type: SupportNeedModelType | undefined = this.object.get('supportNeedType');
    
    if (!type) {
      const needRaw = this.object.get('supportNeed');
      if (needRaw) {
        const need = new SupportNeedModel(needRaw);
        return need.type() ?? SupportNeedModelType.Unknown;
      }
    }

    return type ?? SupportNeedModelType.Unknown;
  }

  clientName(): string {
    const first = this.object.get('client').get('firstName');
    const last = this.object.get('client').get('lastName');
    return [first, last].join(' ');
  }

  clientPhotoUrl(): string {
    const file = this.object.get('client').get('photo') as Parse.File;
    return file ? file.url() : `${window.location.origin}/logo192.png`;
  }

  workerPhotoUrl(): string {
    const file = this.object.get('worker').get('photo') as Parse.File;
    return file ? file.url() : `${window.location.origin}/logo192.png`;
  }

  statusDescription(): string {
    return getStatusDescription(this.status());
  }

  supportNeedDescription(): string {
    return getSupportNeedModelTypeDescription(this.getNeedType());
  }

  suburb(): string | undefined {
    return this.object.get('suburb' || undefined);
  }

  postcode(): string | undefined {
    return this.object.get('postcode' || undefined);
  }

  /**
   * @deprecated To be removed as part of the new plan changes
   */
  supportNeedId(): string { return this.object?.get('supportNeed')?.id; }

  /**
   * Assumes that the query that fetched this object included the `supportNeed`
   * 
   * @deprecated To be removed as part of the new plan changes
   */
  supportNeedSync(): SupportNeedModel | undefined {
    const object = this.object.get('supportNeed');
    if (object) {
      return new SupportNeedModel(object);
    }
  }

  /**
   * @deprecated To be removed as part of the new plan changes
   */
  async supportNeed(): Promise<SupportNeedModel | undefined> {
    const needPointer = this.object.get('supportNeed');
    if (!needPointer) { return; }
    const need = await needPointer.fetch();
    if (!need) { return; }
    return new SupportNeedModel(need);
  }

  /**
   * @deprecated To be removed as part of the new plan changes
   */
  setSupportNeed(need: SupportNeedModel) {
    this.object.set('supportNeed', need.rawObject());
    return this;
  }

  /**
   * Sets the supportNeed pointer to undefined on a session or request
   */
  removeSupportNeed(): void {
    this.object.set('supportNeed', undefined);
  }

  setNeedType(needType: SupportNeedModelType): void {
    this.object.set('supportNeedType', needType);
  }

  setSuburb(suburb: string | undefined): void {
    if (!suburb || suburb === '') {
      this.object.unset('suburb');
    } else {
      this.object.set('suburb', suburb);
    }
  }

  setPostcode(postcode: string | undefined): void {
    if (!postcode || postcode === '') {
      this.object.unset('postcode');
    } else {
      this.object.set('postcode', postcode);
    }
  }

  save(): Promise<this> {
    return this.object.save().then(() => this);
  }

  rawParseObject(): ParseObject { return this.object; }

  abstract status(): SessionStatus; // Left abstract because a classes extending need logic based on instance values.

  billingType(): string | undefined {
    let type = this.getNeedType();
    
    if (!type) {
      type = this.getNeedType();
    }
    
    const types = {
      [SupportNeedModelType.SocialCommunityRecreation]: 'social_recreation',
      [SupportNeedModelType.SocialSkills]: 'social_skills',
      [SupportNeedModelType.LifeSkills]: 'public_transport',
      [SupportNeedModelType.Accommodation]: 'accommodation',
      [SupportNeedModelType.Connecting]: 'mentor',
      [SupportNeedModelType.Transport]: 'worker_transport',

      [SupportNeedModelType.Unknown]: undefined,
    };

    return types[type];
  }
}