import React from 'react';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';

export const SessionWorkerNotes: React.FC<{ session: SupportSessionModel | undefined }> = ({ session }) => {
  const workerNotes = session?.workerNotes() ?? '';

  return (
    <>
      <label
        htmlFor="workerNotes"
        className="col-2 col-form-label text-right"
      >Worker notes</label>
      <div className="col-4 p-0">
        <div
          id="workerNotes"
          className="border rounded bg-light p-2"
        >

          <>
            <div dangerouslySetInnerHTML={{ __html: workerNotes.replace(/\n/g, '<br />') }} />
          </>

        </div>
      </div>
    </>
  );
};