import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { clientsPath } from '../../../Routes';
import { Loading } from '../../../components/Loading';
import { APIVersion } from '../../../enums/APIVersion';
import { BudgetAdjustmentType } from '../../../enums/BudgetAdjustmentType';
import { NDISBookings } from '../../../enums/NDISBookings';
import { sleep } from '../../../helpers';
import { getBudgetAdjustmentsForClient } from '../../../managers/BudgetAdjustments';
import ClientManager from '../../../managers/ClientManager';
import { GetClientNeedBudgetSummaries, NeedSummaryResult, UnallocatedCost } from '../../../managers/ClientNeedBudgets';
import { CreatePlanManager, GetPlanManagers, UpdatePlanManager } from '../../../managers/PlanManagers';
import { SupportNeedState, getSupportNeedState } from '../../../managers/SupportNeeds';
import { GetSupportPlansWithNeeds, findCurrentSupportPlan } from '../../../managers/SupportPlans';
import { BudgetAdjustmentModel } from '../../../models/BudgetAdjustment';
import { ClientModel } from '../../../models/Client';
import { PlanManagerModel } from '../../../models/PlanManager';
import { SupportNeedModel } from '../../../models/SupportNeed';
import { SupportPlanModel } from '../../../models/SupportPlan';
import { ClientSupportPlanView } from '../../../views/Client/SupportPlan';
import { BudgetAdjustmentsView } from '../../../views/Client/SupportPlan/BudgetAdjustments';
import { PlanNeedSessionsView } from '../../../views/Client/SupportPlan/PlanNeedSessions';
import { DEFAULT_DELAY, useCanLoad } from '../util';

export interface ClientSupportPlanState  {
  // From client model
  ndisBooking: NDISBookings;
  ndisNumber: string;

  planStartDate: Date;
  // from active plan model, new plans default to 1 year from now.
  planEndDate: Date;

  // from active plan model
  needs: SupportNeedState[];
}

export function getClientSupportPlanState(client: ClientModel, plan?: SupportPlanModel, needs?: SupportNeedModel[]): ClientSupportPlanState {
  return {
    ndisBooking: client.ndisBookings() ?? NDISBookings.Suitsme,
    ndisNumber: client.ndisNumber() ?? '',
    planStartDate: plan?.startDate() ?? moment().toDate(),
    planEndDate: plan?.endDate() ?? moment().add(1, 'year').toDate(),
    needs: (needs ?? []).map(need => getSupportNeedState(need)),
  };
}

/**
 * Gets an empty state for a new support plan
 */
export function getNewClientSupportPlanState(client: ClientModel): ClientSupportPlanState {
  return {
    ndisBooking: client.ndisBookings() ?? NDISBookings.Suitsme,
    ndisNumber: client.ndisNumber() ?? '',
    planStartDate: new Date(),
    planEndDate: moment().add(1, 'year').toDate(),
    needs: [],
  };
}

export interface ClientSupportPlanContainerProps {
  client: ClientModel;
}

export const ClientSupportPlanContainer: React.FC<ClientSupportPlanContainerProps> = ({ client }) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [supportPlans, setSupportPlans] = useState<SupportPlanModel[] | undefined>(undefined);
  const [supportNeeds, setSupportNeeds] = useState<SupportNeedModel[] | undefined>(undefined);
  const [supportPlan, setSupportPlan] = useState<SupportPlanModel | undefined>(undefined);
  const [isNewPlan, setIsNewPlan] = useState(false);
  const [planManagers, setPlanManagers] = useState<PlanManagerModel[] | undefined>(undefined);
  const [budgetSummaries, setBudgetSummaries] = useState<NeedSummaryResult[] | undefined>(undefined);
  const [budgetAdjustments, setBudgetAdjustments] = useState<BudgetAdjustmentModel[] | undefined>(undefined);
  const [unallocatedCost, setUnallocatedCost] = useState<UnallocatedCost | undefined>(undefined);
  const [isSessionsUpdated, setIsSessionsUpdated] = useState(false);

  const [isLoadingClient, setIsLoadingClient] = useState<boolean>(false);
  const [isLoadingPlan, setIsLoadingPlan] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const canLoad = useCanLoad('supportPlan', DEFAULT_DELAY);

  const [state, setState] = useState<ClientSupportPlanState>(getClientSupportPlanState(client, supportPlan, supportNeeds));

  // Check for required info if budget adjustments are selected and find the associated support need
  const matchAdjustments = useRouteMatch({ path: `${clientsPath}/:id/supportPlan/:planId/needs/:needId/adjustments` });

  // Check for currently selected support plan
  const matchPlan = useRouteMatch({ path: `${clientsPath}/:id/supportPlan/:planId` });
  const matchDefaultPlan = useRouteMatch({ path: `${clientsPath}/:id/supportPlan` });
  const matchPlanSessionsView = useRouteMatch({ path: `${clientsPath}/:id/supportPlan/:planId/sessions` });
  const isLoading = isLoadingClient || isLoadingPlan;

  function getPlanIdParam(): string | undefined {
    return String((matchPlan?.params as Record<string, unknown>)?.planId ?? undefined);
  }

  const adjustmentSupportNeed = useMemo(() => {
    if (!isLoading) {
      const matchNeedId = String((matchAdjustments?.params as Record<string, unknown>)?.needId ?? undefined);
      return matchNeedId ? state.needs.find((need) => need.id && need.id === matchNeedId) : null;
    }

    return null;
  }, [isLoading, matchAdjustments, state.needs]); 

  /**
   * Loads initial data when the selected client changes
   */
  const loadInitialClientData = useCallback(
    async() => {
      if (!client) { return; }
      setIsLoadingClient(true);
      
      const [plans, managers] = await Promise.all([
        GetSupportPlansWithNeeds(client.id()),
        GetPlanManagers(client.id()),
      ]);

      const isInitialNewPlan = getPlanIdParam() === '_new';

      if (plans) {
        setSupportPlans(plans);
      } 

      if (managers) {
        setPlanManagers(managers);
      }

      let currentPlan: SupportPlanModel | undefined = undefined;

      if (getPlanIdParam()) {
        currentPlan = plans.find((plan) => plan.id() === getPlanIdParam());
      }

      if (!currentPlan) {
        currentPlan = findCurrentSupportPlan(plans);
      }

      if (currentPlan && !isNewPlan) {
        setSupportNeeds(currentPlan.supportNeedsSync());
        setSupportPlan(currentPlan);
      }
      
      if (!isInitialNewPlan) {
        setState(getClientSupportPlanState(client, currentPlan, currentPlan?.supportNeedsSync()));
      } else {
        setState(getNewClientSupportPlanState(client));
      }
      setIsLoadingClient(false);

      // Check for new clients with no existing plans
      if ((!plans || plans.length === 0) && !currentPlan) {
        handleAddNewPlan();
      }
    }, [client]);

  /**
   * Loads details for a selected plan, including budget info
   */
  const loadSelectedPlanData = useCallback(
    async(options?: {
      clearBudgetCache: boolean;
    }) => {
      if (!client || !supportPlan) { return; }
      setIsLoadingPlan(true);
      
      if (options?.clearBudgetCache) {
        GetClientNeedBudgetSummaries.clear();
      }

      const [
        budgetSummaries,
        budgetAdjustments,
      ] = await Promise.all([
        GetClientNeedBudgetSummaries(client.id(), APIVersion.three, supportPlan.id()),
        getBudgetAdjustmentsForClient(client.id(), [BudgetAdjustmentType.EstablishmentFee]),
      ]);

      setBudgetSummaries(budgetSummaries.needSummaries);
      setUnallocatedCost(budgetSummaries.unallocatedCost);
      setBudgetAdjustments(budgetAdjustments);
      
      setState(getClientSupportPlanState(client, supportPlan, supportPlan?.supportNeedsSync()));
      setIsLoadingPlan(false);
    }, [client, supportPlan]);
  
  // Trigger initial load when client changes
  useEffect(() => {
    if (!canLoad || !client) { return; }
    
    void loadInitialClientData();
  }, [canLoad, client, loadInitialClientData]);

  // Trigger support plan load when plan changes
  useEffect(() => {
    if (!canLoad || !supportPlan) { return; }
    if (getPlanIdParam() !== '_new') {
      void loadSelectedPlanData();
    }
  }, [canLoad, supportPlan, loadSelectedPlanData]);

  // When adjustments data is changed, we need to reload some data here
  async function reloadSummaryBudgetData(): Promise<void> {
    setIsLoadingPlan(true);
    GetClientNeedBudgetSummaries.clear();
    await sleep(500);

    const [budgetSummaries, budgetAdjustments] = await Promise.all([
      GetClientNeedBudgetSummaries(client.id(), APIVersion.three, supportPlan?.id()),
      getBudgetAdjustmentsForClient(client.id(), [BudgetAdjustmentType.EstablishmentFee]),
    ]);
    
    setBudgetSummaries(budgetSummaries.needSummaries);
    setUnallocatedCost(budgetSummaries.unallocatedCost);
    setBudgetAdjustments(budgetAdjustments);
    setIsLoadingPlan(false);
  }

  function handleSelectPlan(plan: SupportPlanModel): void {
    history.push([clientsPath, client.id(), 'supportPlan', plan.id()].join('/'));
  }

  function handleAddNewPlan(): void {
    history.push([clientsPath, client.id(), 'supportPlan', '_new'].join('/'));
  }

  function handleCancelNewPlan(): void {
    history.push([clientsPath, client.id(), 'supportPlan'].join('/'));
  }

  function handleSessionsUpdated(): void {
    if (!isSessionsUpdated) {
      setIsSessionsUpdated(true);
    }
  }

  async function savePlan(state: ClientSupportPlanState): Promise<void> {
    // Save existing plan and needs
    if (!planManagers) {
      return;
    }

    state.needs.forEach(async need => {
      const planManager = planManagers.find(manager => manager.id() === need.planManagerId);
      if (!planManager || !need.planManagerState) { return; }

      await UpdatePlanManager(planManager, need.planManagerState, client);
    });

    const saveResult = await ClientManager.saveClientSupportNeedsV3(client, {
      ndisNumber: state.ndisNumber,
      ndisBookings: state.ndisBooking,
      planStartDate: state.planStartDate,
      planEndDate: state.planEndDate,
      supportNeeds: state.needs,
      isNewPlan,
    }, supportPlan);

    if (saveResult.plan) {
      const updatedPlans = [...supportPlans ?? []];

      updatedPlans.forEach((plan, index) => {
        if (plan.id() === saveResult.plan.id()) {
          updatedPlans[index] = saveResult.plan;
        }
      });

      setSupportPlans(updatedPlans);
      setSupportPlan(saveResult.plan);
      setSupportNeeds(saveResult.needs);
      setIsNewPlan(false);
      history.replace([clientsPath, client.id(), 'supportPlan', saveResult.plan.id()].join('/'));

      if (isNewPlan) {
        ToastManager.shared().show('Client support plan created.');
      } else {
        ToastManager.shared().show('Client support plan saved.');
      }

      setTimeout(() => {
        void loadSelectedPlanData({ clearBudgetCache: true });
      }, 250);
    }
  }

  async function deletePlan(plan: SupportPlanModel): Promise<void> {
    if (window.confirm('Are you sure you want to delete this support plan?')) {
      plan.setDeletedAt(new Date());
      await plan.save();
      history.replace([clientsPath, client.id(), 'supportPlan'].join('/'));
      void loadInitialClientData();
    }
  }
  
  useEffect(() => {
    const planId = getPlanIdParam();
    let plan = supportPlans?.find(plan => plan.id() === planId);

    if (!plan && supportPlans && supportPlans.length > 0 && planId !== '_new') {
      plan = supportPlans[0];
    }

    if (plan) {
      if (isNewPlan) {
        setIsNewPlan(false);
      }
      
      if (plan.id() !== supportPlan?.id()) {
        setSupportPlan(plan);
      }

      if (isSessionsUpdated && (matchPlan?.isExact || matchDefaultPlan?.isExact)) {
        setIsSessionsUpdated(false);
        void reloadSummaryBudgetData();
      }
    } else if (planId === '_new') {
      if (!isNewPlan) {
        setIsNewPlan(true);
        setSupportPlan(undefined);
        setState(getNewClientSupportPlanState(client));
        setBudgetAdjustments([]);
        setBudgetSummaries([]);
        setUnallocatedCost(undefined);
      }
    }
  }, [matchPlan]);

  function renderMainView(): JSX.Element | null {
    if ((client && planManagers && budgetSummaries && budgetAdjustments && !isLoading)) {
      return (
        <ClientSupportPlanView
          state={state}
          originalState={getClientSupportPlanState(client, supportPlan, supportNeeds)}
          clientId={client.id()}
          budgetSummaries={budgetSummaries}
          budgetAdjustments={budgetAdjustments}
          unallocatedCost={unallocatedCost}
          plans={supportPlans ?? []}
          planManagers={planManagers}
          selectedPlan={supportPlan}
          interactionDisabled={isSaving}
          onStateUpdated={state => setState(state)}
          onPlanManagerCreated={async state => {
            setIsSaving(true);
            const created = await CreatePlanManager(state, client);
            setPlanManagers([...planManagers, created]);
            ToastManager.shared().show('Plan manager created');
            setIsSaving(false);
            return created;
          }}
          onSave={async state => {
            setIsSaving(true);
            await savePlan(state);
            setIsSaving(false);
          }}
          onSelectPlan={async plan => {
            handleSelectPlan(plan);
          }}
          onDeletePlan={async plan => {
            if (!plan) {
              return;
            }
            setIsSaving(true);
            await deletePlan(plan);
            setIsSaving(false);
          }}
          onAddNewPlan={handleAddNewPlan}
          onCancelNewPlan={handleCancelNewPlan}
        />
      );
    }
    
    return null;
  }

  let backButtonUrl = `${clientsPath}/${(client.id())}/supportPlan`;
  if (getPlanIdParam()) {
    backButtonUrl += `/${getPlanIdParam()}`;
  }

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {(client && planManagers && budgetSummaries && budgetAdjustments && !isLoading) &&
        <>
          <Switch>
            <Route
              exact
              path={path}
            >
              {renderMainView()}
            </Route>
            <Route
              exact
              path={`${path}/:planId`}
            >
              {renderMainView()}
            </Route>
            <Route path={`${path}/:planId/needs/:needId/adjustments`}>
              {
                adjustmentSupportNeed && (
                  <BudgetAdjustmentsView
                    supportNeed={adjustmentSupportNeed}
                    clientId={client.id()}
                    backButtonUrl={backButtonUrl}
                    onUpdated={reloadSummaryBudgetData}
                  />
                )
              }
            </Route>
            <Route path={`${path}/:planId/sessions`}>
              <PlanNeedSessionsView
                client={client}
                supportPlan={supportPlan}
                supportNeeds={state.needs}
                unallocatedCost={unallocatedCost}
                backButtonUrl={backButtonUrl}
                onUpdated={handleSessionsUpdated}
              />
            </Route>
          </Switch>
        </>
      }
    </>
  );
};



