export interface PFConfig {
  datedServiceAgreements: DatedServiceAgreement[];
  datedCancellationPolicies: CancellationPolicy;
  publicHolidays: PublicHolidays;
  postcodes: Postcode[];
}

export interface DatedServiceAgreement {
  date: string;
  version: number;
  text: string;
}

export interface ServiceAgreement {
  date: string;
  version: number;
  text: string;
}

export interface PublicHolidays {
  [id: string]: string[];
}

export interface CancellationPolicy {
  date: Date;
  version: number;
  cancellation_minutes_full: number;
  capping_tiers?: {
    minutes: number;
    billable: number;
  }[];
}

export enum PostcodeType {
  NATIONAL = 'national',
  REMOTE = 'remote',
  VERY_REMOTE = 'very_remote',
}

export interface Postcode {
  suburb: string;
  postcode: string;
  classification: PostcodeType;
}