import React, { ReactNode } from 'react';

interface SaveButtonProps {
  onSave: () => Promise<void>;
  onCancel: () => void;
  disableSave: boolean;
  disableCancel: boolean;
  hideCancel?: boolean;

  saveText?: string;
  cancelText?: string;
  savingText?: string;
  leftAction?: ReactNode;

  isSaving?: boolean;
  
  /** Makes the save button red */
  isSaveDestructive?: boolean;
}

export const SaveButton:React.FC<SaveButtonProps> = ({
  disableSave,
  disableCancel,
  hideCancel,
  saveText = 'Save',
  cancelText = 'Cancel',
  savingText = 'Saving...',
  leftAction,
  isSaving,
  isSaveDestructive,
  onSave,
  onCancel,
}) => (
  <div className="row d-flex flex-row-reverse justify-content-between">
    <span className="d-flex flex-row-reverse">
      <button
        type="button"
        disabled={disableSave}
        className={`btn btn-primary m-1 ${isSaveDestructive ? 'btn-danger' : ''}`}
        onClick={() => onSave()}
      >
        <div className="d-flex flex-direction-row">
          {
            isSaving && (
              <div style={{ transform: 'scale(0.45)', height: 0, marginTop: 3 }}>
                <div className="spinner-border text-white mb-0 pb-0" />
              </div>
            )
          }
          <div>{isSaving ? savingText : saveText}</div>
        </div>
      </button>
      {
        !hideCancel && (
          <button
            disabled={disableCancel}
            type="button"
            className="btn btn-link m-1"
            onClick={() => onCancel()}
          >{cancelText}</button>
        )
      }
    </span>
    {leftAction && leftAction}
  </div>
);