import React, { CSSProperties, useEffect, useState } from 'react';
import { NeedByTypeSelector } from '../../../../components/SessionNeed/NeedByTypeSelector';
import { SupportSessionRequestTile } from '../../../../components/SupportSessionRequestTile';
import { SupportSessionTile } from '../../../../components/SupportSessionTile';
import { SupportNeedModelType } from '../../../../enums/SupportNeedModelType';
import { formatMoney } from '../../../../helpers';
import { isNeedTypeAvailableForDate } from '../../../../helpers/plansAndNeeds';
import { getPublicHolidays } from '../../../../helpers/publicHolidays';
import { UnallocatedCost } from '../../../../managers/ClientNeedBudgets';
import { ClientModel } from '../../../../models/Client';
import { SupportSessionModel } from '../../../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../../../models/Sessions/SupportSessionRequest';
import { SupportNeedModel } from '../../../../models/SupportNeed';
import { PublicHolidays } from '../../../../types/parseConfig';
import { SaveButton } from '../../SaveButton';

interface SessionsUnallocatedViewProps {
  client: ClientModel;
  spentItems: SupportSessionModel[];
  bookedItems: Array<SupportSessionModel | SupportSessionRequestModel>;
  unallocatedCost: UnallocatedCost;
  planNeeds: SupportNeedModel[];
  interactionDisabled?: boolean;
  onCancel(): void;
  onSave(needTypes: NeedTypeMap): void;
}

interface ToolbarProps {
  title: string;
  costAmount: number;
  needs: SupportNeedModel[];
  selectedNeed: SupportNeedModelType | undefined;
  onSelectNeed(needType: SupportNeedModelType): void;
}

export type NeedTypeMap = Record<string, SupportNeedModelType>;

const Toolbar = ({
  title,
  costAmount,
  needs,
  selectedNeed,
  onSelectNeed,
}: ToolbarProps): JSX.Element => {
  return <div className="row">
    <div className="col-6 d-flex justify-content-start">
      <h4 className="text-left">{title}</h4>
      <input
        className="form-control ml-2"
        disabled
        value={formatMoney(costAmount)}
        style={{ width: 130 }}
      />
    </div>
    <div className="col-6 d-flex justify-content-end">
      <div className="col-2" />
      <label className="col-4 col-form-label text-right mb-1">Reallocate all to:</label>
      <NeedByTypeSelector
        needs={needs}
        selectedType={selectedNeed}
        onChange={onSelectNeed}
      />
    </div>
  </div>;
};

export const SessionsUnallocatedView:React.FC<SessionsUnallocatedViewProps> = ({
  client,
  spentItems,
  bookedItems,
  unallocatedCost,
  planNeeds,
  onCancel,
  onSave,
  interactionDisabled = false,
}) => {
  const [spentItemsState, setSpentItemsState] = useState(spentItems);
  const [bookedItemsState, setBookedItemsState] = useState(bookedItems);
  const [selectedAllSpentNeed, setSelectedAllSpentNeed] = useState<SupportNeedModelType | undefined>(undefined);
  const [selectedAllBookedNeed, setSelectedAllBookedNeed] = useState<SupportNeedModelType | undefined>(undefined);
  const [needTypeMap, setNeedTypeMap] = useState<NeedTypeMap>({});
  const [spentCost, setSpentCost] = useState(unallocatedCost.spent);
  const [bookedCost, setBookedCost] = useState(unallocatedCost.booked);
  const [itemAlerts, setItemAlerts] = useState<Record<string, string>>({});
  const [isEdited, setIsEdited] = useState(false);
  const [publicHolidays, setPublicHolidays] = useState<PublicHolidays | undefined>(undefined);

  useEffect(() => {
    const typeMap: NeedTypeMap = {};
    
    // Create a key value map for session/request IDs and new need types, defaulting
    // the initial value to Unknown
    [...spentItems, ...bookedItems].forEach((item) => {
      typeMap[item.id() as string] = SupportNeedModelType.Unknown;
    });

    setSpentItemsState(spentItems);
    setBookedItemsState(bookedItems);
  }, [spentItems, bookedItems]);

  useEffect(() => {
    const loadHolidays = async () => {
      setPublicHolidays(await getPublicHolidays());
    };
    void loadHolidays();
  }, []);

  useEffect(() => {
    setSpentCost(unallocatedCost.spent);
    setBookedCost(unallocatedCost.booked);
  }, [unallocatedCost]);

  function updateCosts(type: 'spent' | 'booked', newTypeMap: NeedTypeMap): void {
    let total = type === 'spent' ? unallocatedCost.spent : unallocatedCost.booked;
    const itemIds = Object.keys(newTypeMap);

    itemIds.forEach((itemId) => {
      if (newTypeMap[itemId] !== SupportNeedModelType.Unknown) {
        total -= unallocatedCost.itemAmounts[itemId] ?? 0;
      }
    });

    if (type === 'spent') {
      setSpentCost(total);
    } else if (type === 'booked') {
      setBookedCost(total);
    }
  }

  function handleChangeSpentNeed(itemId: string | 'ALL', needType: SupportNeedModelType): void {
    const newTypeMap = { ...needTypeMap };
    const changedIds: string[] = [];

    if (itemId === 'ALL') {
      console.log('[debug] changing ALL spent needs to: ', needType);
      setSelectedAllSpentNeed(needType);
      spentItemsState.forEach((item) => {
        newTypeMap[item.id() as string] = needType;
        changedIds.push(item.id() as string);
      });
    } else {
      console.log(`[debug] changing ${itemId} spent need to: `, needType);
      newTypeMap[itemId] = needType;
      changedIds.push(itemId);
    }

    setNeedTypeMap(newTypeMap);
    validateChanges(newTypeMap);
    updateCosts('spent', newTypeMap);

    if (!isEdited) {
      setIsEdited(true);
    }
  }

  function handleChangeBookedNeed(itemId: string | 'ALL', needType: SupportNeedModelType): void {
    const newTypeMap = { ...needTypeMap };
    const changedIds: string[] = [];

    if (itemId === 'ALL') {
      console.log('[debug] changing ALL booked needs to: ', needType);
      setSelectedAllBookedNeed(needType);
      bookedItemsState.forEach((item) => {
        newTypeMap[item.id() as string] = needType;
        changedIds.push(item.id() as string);
      });
    } else {
      console.log(`[debug] changing ${itemId} booked need to: `, needType);
      newTypeMap[itemId] = needType;
      changedIds.push(itemId);
    }

    setNeedTypeMap(newTypeMap);
    validateChanges(newTypeMap);
    updateCosts('booked', newTypeMap);
    
    if (!isEdited) {
      setIsEdited(true);
    }
  }

  function validateChanges(changedNeedTypeMap: NeedTypeMap): boolean {
    const alerts: Record<string, string> = {};
    
    if (!publicHolidays) {
      return false;
    }

    [...spentItemsState, ...bookedItemsState].forEach((item) => {
      const itemId = item.id() as string;
      const type = changedNeedTypeMap[itemId];
      let date: Date | undefined;

      if (type !== SupportNeedModelType.Unknown) {
        if (item instanceof SupportSessionModel) {
          date = item.bestKnownStartDate();
        } else if (item instanceof SupportSessionRequestModel) {
          date = new Date(item.firstDateStart());
        }

        if (type !== undefined && !isNeedTypeAvailableForDate(type, date, client.australianState(), publicHolidays)) {
          console.log(`[debug] need type ${type} is not available for ${date}`);
          alerts[itemId] = 'Support type can not be delivered on weekends or holidays';
        }
      }
    });

    setItemAlerts(alerts);
    return Object.keys(alerts).length === 0;
  }

  function handleSave(): void {
    if (Object.keys(itemAlerts).length > 0) {
      if (window.confirm('Some of the selected changes will cause sessions to be delivered on invalid days for the support type. Continue?')) {
        onSave(needTypeMap);
      }
    } else {
      onSave(needTypeMap);
    }

    setIsEdited(false);
  }

  useEffect(() => {
    let edited = false;
    const typeValues = Object.keys(needTypeMap).map((key) => needTypeMap[key]);

    typeValues.forEach((needType) => {
      if (needType !== SupportNeedModelType.Unknown && needType !== undefined) {
        edited = true;
      }
    });

    if (isEdited && !edited) {
      setIsEdited(false);
    }
  }, [needTypeMap]);

  // Disable UI when loading and saving
  const containerStyle: CSSProperties = interactionDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {};

  return (
    <div className="container pb-2 pt-4">
      {spentItemsState.length > 0 &&
        <div style={containerStyle}>
          <Toolbar
            title="Unallocated spent"
            costAmount={spentCost}
            needs={planNeeds}
            selectedNeed={selectedAllSpentNeed}
            onSelectNeed={(needType) => handleChangeSpentNeed('ALL', needType)}
          />
          <div className="row">
            {spentItemsState.map(session => <div
              key={session.id()}
              className="col-sm-12 col-md-12 col-lg-6 p-0"
            >
              <SupportSessionTile
                key={session.id()}
                session={session}
                isNeedEditable={true}
                planNeeds={planNeeds}
                alertText={itemAlerts[session.id() as string]}
                onChangeNeedType={handleChangeBookedNeed}
                selectedNeedType={needTypeMap[session.id() as string]}
              />
            </div>)}
          </div>
        </div>
      }
      <div className="mt-4" />
      {bookedItemsState.length > 0 &&
        <div style={containerStyle}>
          <Toolbar
            title="Unallocated booked"
            costAmount={bookedCost}
            needs={planNeeds}
            selectedNeed={selectedAllBookedNeed}
            onSelectNeed={(needType) => handleChangeBookedNeed('ALL', needType)}
          />
          <div className="row">
            {
              bookedItemsState.map(sessionOrRequest => {
                if (sessionOrRequest instanceof SupportSessionModel) {
                  return (
                    <div
                      key={sessionOrRequest.id()}
                      className="col-sm-12 col-md-12 col-lg-6  p-0"
                    >
                      <SupportSessionTile
                        session={sessionOrRequest}
                        isNeedEditable={true}
                        planNeeds={planNeeds}
                        alertText={itemAlerts[sessionOrRequest.id() as string]}
                        onChangeNeedType={handleChangeBookedNeed}
                        selectedNeedType={needTypeMap[sessionOrRequest.id() as string]}
                      />
                    </div>
                  );
                } else if (sessionOrRequest instanceof SupportSessionRequestModel) {
                  return (<div
                    key={sessionOrRequest.id()}
                    className="col-sm-12 col-md-12 col-lg-6  p-0"
                  >
                    <SupportSessionRequestTile
                      session={sessionOrRequest}
                      isNeedEditable={true}
                      planNeeds={planNeeds}
                      alertText={itemAlerts[sessionOrRequest.id() as string]}
                      onChangeNeedType={handleChangeBookedNeed}
                      selectedNeedType={needTypeMap[sessionOrRequest.id() as string]}
                    />
                  </div>);
                }
              })
            }
          </div>
        </div>
      }
      {(spentItemsState.length === 0 && bookedItemsState.length === 0) &&
        <div className="text-info text-center">This support need has no unallocated support sessions or support session requests</div>
      }
      <SaveButton
        onSave={async () => handleSave()}
        onCancel={onCancel}
        disableCancel={interactionDisabled || !isEdited}
        disableSave={interactionDisabled || !isEdited}
      />
    </div>
  );
};