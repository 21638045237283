import { FundingManagementType } from '../../enums/SupportNeedFundingManagementType';
import { SupportNeedModelType } from '../../enums/SupportNeedModelType';
import { SupportNeedModel } from '../../models/SupportNeed';
import { getPlanManagerState, PlanManagerState } from '../PlanManagers';

export interface SupportNeedState {
  type: SupportNeedModelType;
  fundingManagementType: FundingManagementType | undefined;
  budget: number;
  agreesToUseCoreSupportFunding: boolean | undefined;

  id: string | undefined;
  isDeleted: boolean;
  planManagerId: string | undefined;
  planManagerState: PlanManagerState | undefined;
}

export function getSupportNeedState(need?: SupportNeedModel): SupportNeedState {
  return {
    id: need?.id(),
    type: need?.type() ?? SupportNeedModelType.SocialCommunityRecreation,
    fundingManagementType: need?.fundingManagementType() ?? FundingManagementType.NDIA,
    budget: need?.budget() ?? 0,
    agreesToUseCoreSupportFunding: need?.agreesToUseCoreSupportFunding(),
    planManagerState: need?.planManagerSync() ? getPlanManagerState(need.planManagerSync()) : undefined,
    planManagerId: need?.planManagerId(),
    isDeleted: need?.isDeleted() ?? false,
  };
}
