export enum IncidentReportType {
  other = 0,
  clientDeath = 1,
  clientInjured = 2,
  clientAbusedOrNeglected = 3,
  clientPosesRisk = 4,
  clientAssaultedOther = 5,
  clientArrested = 6,
  clientUncontactable = 7,
  clientHospitalised = 8,
  clientSuicidal = 9,
  clientMentalHealthConcern = 10,
  clientPhysicalHealthConcern = 11,
  clientFell = 12,
  clientHasOpenWound = 13,
  clientSubjectedRestrictivePractice = 14,
  workerInjured = 15,
  workerCarAccident = 16,
  sessionUnsafe = 17,
  clientSexualMisconduct = 18,
  clientSexualPhysicalContact = 19,
  didntFeelRight = 20,
  clientAffectedAlcohol = 21,
  clientAffectedViolence = 22,
  clientSelfInjury = 23,
  childDependentWelfare = 24,
  medicationSafety = 25,
}

export enum IncidentReportCategory {
  adminReportableIncident, //most serious. admins must report
  standardIncident, //somewhat serious
  workerConcern, //least serious. an incident may not have actually happened
  unknown, //we don't know, no matching type
}


export function categoryFromType(type: IncidentReportType): IncidentReportCategory {
  switch (type) {
    case IncidentReportType.clientDeath:
    case IncidentReportType.clientInjured:
    case IncidentReportType.clientAbusedOrNeglected:
    case IncidentReportType.clientSubjectedRestrictivePractice:
    case IncidentReportType.clientSexualMisconduct:
    case IncidentReportType.clientSexualPhysicalContact:
      return IncidentReportCategory.adminReportableIncident;

    case IncidentReportType.clientAssaultedOther:
    case IncidentReportType.clientArrested:
    case IncidentReportType.clientHospitalised:
    case IncidentReportType.clientSuicidal:
    case IncidentReportType.clientFell:
    case IncidentReportType.clientHasOpenWound:
    case IncidentReportType.workerInjured:
    case IncidentReportType.workerCarAccident:
    case IncidentReportType.sessionUnsafe:
    case IncidentReportType.clientAffectedAlcohol:
    case IncidentReportType.clientAffectedViolence:
    case IncidentReportType.clientSelfInjury:
    case IncidentReportType.other:
      return IncidentReportCategory.standardIncident;

    case IncidentReportType.clientPosesRisk:
    case IncidentReportType.clientUncontactable:
    case IncidentReportType.clientMentalHealthConcern:
    case IncidentReportType.clientPhysicalHealthConcern:
    case IncidentReportType.didntFeelRight:
    case IncidentReportType.childDependentWelfare:
    case IncidentReportType.medicationSafety:
      return IncidentReportCategory.workerConcern;

    default:
      return IncidentReportCategory.unknown;
  }
}

export function getIncidentReportTypeDescription(type: IncidentReportType): string {
  switch (type) {
    case IncidentReportType.other:
      return 'Other';
    case IncidentReportType.clientDeath:
      return 'Client has died';
    case IncidentReportType.clientInjured:
      return 'Client was injured (physical or psychological)';
    case IncidentReportType.clientAbusedOrNeglected:
      return 'Client has been abused or neglected';
    case IncidentReportType.clientPosesRisk:
      return 'Client poses a serious risk to themself or others';
    case IncidentReportType.clientAssaultedOther:
      return 'Client has assaulted or caused serious harm to someone';
    case IncidentReportType.clientArrested:
      return 'Client has been arrested';
    case IncidentReportType.clientUncontactable:
      return 'Client could not be found or contacted';
    case IncidentReportType.clientHospitalised:
      return 'Client has been admitted to hospital';
    case IncidentReportType.clientSuicidal:
      return 'Client is suicidal';
    case IncidentReportType.clientMentalHealthConcern:
      return 'Client\'s mental health is concerning';
    case IncidentReportType.clientPhysicalHealthConcern:
      return 'Client\'s physical health is concerning';
    case IncidentReportType.clientFell:
      return 'Client has fallen';
    case IncidentReportType.clientHasOpenWound:
      return 'Client has an open wound';
    case IncidentReportType.clientSubjectedRestrictivePractice:
      return 'Client was subjected to a restrictive practice';
    case IncidentReportType.workerInjured:
      return 'Support worker is injured';
    case IncidentReportType.workerCarAccident:
      return 'Support worker was in a car accident';
    case IncidentReportType.sessionUnsafe:
      return 'Session was unsafe';
    case IncidentReportType.clientSexualMisconduct:
      return 'Client experienced sexual misconduct';
    case IncidentReportType.clientSexualPhysicalContact:
      return 'Client experienced unlawful sexual or physical contact';
    case IncidentReportType.didntFeelRight:
      return 'Something just didn’t feel right';
    case IncidentReportType.clientAffectedAlcohol:
      return 'Client is affected by alcohol or other drug use';
    case IncidentReportType.clientAffectedViolence:
      return 'Client is affected by family or domestic violence';
    case IncidentReportType.clientSelfInjury:
      return 'Client has non suicidal self injury';
    case IncidentReportType.childDependentWelfare:
      return 'Client\'s child or dependent welfare is concerning';
    case IncidentReportType.medicationSafety:
      return 'Client\'s medication safety is concerning';
    default:
      return 'Unknown';
  }
}

export function adminFacingSummary(category: IncidentReportCategory): string {
  switch (category) {
    case IncidentReportCategory.adminReportableIncident:
      return 'Reportable incident';
    case IncidentReportCategory.standardIncident:
      return 'Non-reportable incident';
    case IncidentReportCategory.workerConcern:
      return 'Worker concern';
    case IncidentReportCategory.unknown:
      return 'Unknown';
  }
}
