
export enum NDISBookings {
  Self = 0,
  Suitsme = 1,
  NDIA = 2
}

export const NDISBookingsCases = [NDISBookings.Suitsme, NDISBookings.Self, NDISBookings.NDIA];

export function getNDISBookingsDescription(booking: NDISBookings) {
  switch (booking) {
    case NDISBookings.NDIA: return 'N/A';
    case NDISBookings.Suitsme: return 'Suitsme';
    case NDISBookings.Self: return 'Client';
  }
}