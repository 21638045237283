
export enum CovidVaccinationStatus {
  unknown,
  firstDose,
  notVaccinated,
  secondDose,
}

export const CovidVaccinationStatusCases = [CovidVaccinationStatus.firstDose, CovidVaccinationStatus.secondDose, CovidVaccinationStatus.notVaccinated, CovidVaccinationStatus.unknown];

export function getCovidVaccinationStatusDescription(status: CovidVaccinationStatus): string {
  switch (status) {
    case CovidVaccinationStatus.unknown: return 'Not disclosed';
    case CovidVaccinationStatus.firstDose: return 'First dose';
    case CovidVaccinationStatus.secondDose: return 'Second dose';
    case CovidVaccinationStatus.notVaccinated: return 'Not vaccinated';
    default: return CovidVaccinationStatus[status];
  }
}
