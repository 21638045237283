
export enum ClientCancellationReason {
  clientNotWell = 1,
  clientFamilyIssues = 2,
  clientOtherReason = 3
}

export function getClientCancellationReasonDescription(reason: ClientCancellationReason): string {
  switch (reason) {
    case ClientCancellationReason.clientNotWell:
      return 'Client was not well';
    case ClientCancellationReason.clientFamilyIssues:
      return 'Client had family issues';
    case ClientCancellationReason.clientOtherReason:
      return 'Other client reason / unsure';
  }
}

export function getClientCancellationReasonReportingCode(reason: ClientCancellationReason): string {
  switch (reason) {
    case ClientCancellationReason.clientNotWell:
      return 'NSDH';
    case ClientCancellationReason.clientFamilyIssues:
      return 'NSDF';
    case ClientCancellationReason.clientOtherReason:
      return 'NSDO';
  }
}
