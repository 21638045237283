import React from 'react';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { BrandColors, formatMoney } from '../../../../helpers';
import { UnallocatedCost } from '../../../../managers/ClientNeedBudgets';
import { clientsPath } from '../../../../Routes';
import { SupportNeedFormRow } from './SupportNeedRow';

export interface UnallocatedNeedBudgetViewProps {
  unallocatedCost: UnallocatedCost | undefined;
  planId: string;
  clientId: string;
}


export const UnallocatedNeedBudgetView: React.FC<UnallocatedNeedBudgetViewProps> = ({ unallocatedCost, planId, clientId }) => {
  const history = useHistory();

  function handleClickSpent(): void {
    history.push([clientsPath, clientId, 'supportPlan', planId, 'sessions'].join('/') + '?unallocated=1&view=spent');
  }

  function handleClickBooked(): void {
    history.push([clientsPath, clientId, 'supportPlan', planId, 'sessions'].join('/') + '?unallocated=1&view=booked');
  }

  let showAlert = false;
  if (unallocatedCost?.spent || unallocatedCost?.booked) {
    if (unallocatedCost.spent > 0 || unallocatedCost.booked > 0) {
      showAlert = true;
    }
  }

  return (
    <form className="row">
      <div className="col-2" />
      <h4 className="col-10">
        Unallocated
        {
          showAlert && (<span>
            <ExclamationCircleFill
              className="ml-1"
              color={BrandColors.red}
              size={20}
            />
          </span>)
        }
      </h4>
      <SupportNeedFormRow colsAfter={3}>
        {{
          label: <label className="col-2 col-form-label text-right mb-1">Spent</label>,
          input: <input
            className="col-4 form-control"
            disabled
            value={formatMoney(unallocatedCost?.spent ?? 0)}
          />,
          actionButton: (
            <button
              type="button"
              className="btn btn-primary col-1 form-control ml-2"
              style={{
                maxWidth: '3.4rem',
                width: 'auto',
                padding: '0 0.2rem',
              }}
              onClick={handleClickSpent}
            >
              ●●●
            </button>
          ),
        }}
      </SupportNeedFormRow>
      <SupportNeedFormRow colsAfter={3}>
        {{
          label: <label className="col-2 col-form-label text-right mb-1">Booked</label>,
          input: <input
            className="col-4 form-control"
            disabled
            value={formatMoney(unallocatedCost?.booked ?? 0)}
          />,
          actionButton: (
            <button
              type="button"
              className="btn btn-primary col-1 form-control ml-2"
              style={{
                maxWidth: '3.4rem',
                width: 'auto',
                padding: '0 0.2rem',
              }}
              onClick={handleClickBooked}
            >
              ●●●
            </button>
          ),
        }}
      </SupportNeedFormRow>
    </form>
  );

};