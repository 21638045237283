import Parse from 'parse';
import { ClientModel } from './Client';

export class PlanManagerModel {
  constructor(public object: Parse.Object) { }

  static new(): PlanManagerModel {
    return new PlanManagerModel(new (Parse.Object.extend('PlanManager'))());
  }

  id(): string { return this.object.id; }
  contactName(): string | undefined { return this.object.get('contactName'); }
  setContactName(contactName: string): void { this.object.set('contactName', contactName); }

  organisationName(): string | undefined { return this.object.get('organisationName'); }
  setOrganisationName(organisationName: string): void { this.object.set('organisationName', organisationName); }

  address(): string | undefined { return this.object.get('address'); }
  setAddress(address: string): void { this.object.set('address', address); }

  setClient(client: ClientModel): void {
    const parseObject = client.rawObject();
    this.object.set('client', parseObject);
  }

  rawObject(): Parse.Object {
    return this.object;
  }

  async save(): Promise<this> {
    await this.object.save();
    return this;
  }
}