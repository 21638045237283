import React from 'react';
import { formatDuration, formatMoney } from '../../helpers';
import { SessionCost } from '../../managers/SessionCost';
import { SupportSessionState } from '../../managers/Sessions';
import { Checkbox } from '../../views/Worker/Tabs/Details/Checkbox';
import { CurrencyInput } from '../CurrencyInput';
import { EditableLabelledValue } from './EditableLabelledValue';
import { SimpleLabelledValue } from './SimpleLabelledValue';


interface SessionCostProps {
  cost: SessionCost;
  state: SupportSessionState;
  isLoadingPricing: boolean;
  onStateChanged: (state: SupportSessionState) => void;
}

const roundValue = (value: number): string => {
  let val = String(value.toFixed(1));
  if (val.indexOf('.0') > -1) {
    val = val.replace('.0', '');
  }
  return val;
};

export const SessionCostView: React.FC<SessionCostProps> = ({ cost, state, isLoadingPricing, onStateChanged }) => {
  const hourlyRate = cost.hourlyRate ? formatMoney(cost.hourlyRate) : 'Error with rate';
  const billedHourlyCost = cost.billedHourlyCost ? formatMoney(cost.billedHourlyCost) : 'Error with cost';
  const kmRate = cost.kmRate ? formatMoney(cost.kmRate) : 'Error with KM rate';
  const totalFromNeedBudget = formatMoney(cost.totalFromNeedBudget) ?? '';
  const totalFromTransportBudget = formatMoney(cost.totalFromTransportBudget) ?? '';

  return (
    <div className="row">
      <SimpleLabelledValue
        title={cost.isDayTypeMismatch ? 'Day rate' : 'Day type'}
        value={cost.dayType}
        inputClassName={cost.isDayTypeMismatch ? 'text-danger' : ''}
      />

      <SimpleLabelledValue
        title="Billed time"
        value={formatDuration(cost.billedTime)}
      />

      <SimpleLabelledValue
        title="Rate per hour"
        value={!isLoadingPricing ? hourlyRate : 'Calculating...'}
      />

      <SimpleLabelledValue
        title="Billed income"
        value={!isLoadingPricing ? billedHourlyCost : 'Calculating...'}
      />

      <SimpleLabelledValue
        title="Rate per km"
        value={!isLoadingPricing ? kmRate : 'Calculating...'}
      />

      {/* <SimpleLabelledValue
        title="Logged transport"
        value={cost.loggedTransport ? formatDistance(cost.loggedTransport) : ''}
      /> */}

      <EditableLabelledValue
        title="Logged transport"
        value={state.loggedTransport ? roundValue(state.loggedTransport) : ''}
        onChange={updated => onStateChanged({ ...state, loggedTransport: parseInt(updated) })}
      />

      <SimpleLabelledValue
        title="Billed transport"
        value={cost.billedTransportKm ? formatMoney(cost.billedTransportKm) : ''}
      />

      <CurrencyInput
        id="loggedParking"
        label="Logged parking"
        value={state.loggedParking ?? 0}
        onChange={valueCents => onStateChanged({ ...state, loggedParking: valueCents })}
      />

      <div className="col-12 m-2" />

      <SimpleLabelledValue
        title="Total billed from support need budget"
        value={!isLoadingPricing ? totalFromNeedBudget : 'Calculating...'}
      />

      <SimpleLabelledValue
        title="Total billed from transport budget"
        value={!isLoadingPricing ? totalFromTransportBudget : 'Calculating...'}
      />

      <div className="offset-2 col-6 pl-0">
        <Checkbox
          label="Bill as scheduled"
          isSelected={state.billAsScheduled}
          onChange={e => onStateChanged({ ...state, billAsScheduled: !state.billAsScheduled })}
          value={state.billAsScheduled.toString()}
        />

      </div>

    </div>
  );
};