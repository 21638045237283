import moment from 'moment';

export function saveStringFieldToObject(value: string, fieldName: string, object: Parse.Object, trim: boolean = true, unsetIfEmpty: boolean = true) {
  const str: string = trim ? value.trim() : value;
  if (unsetIfEmpty && !str) { object.unset(fieldName); }
  else { object.set(fieldName, str); }
}

export function saveEnumField(value: number, fieldName: string, object: Parse.Object) {
  object.set(fieldName, value);
}

export function saveEnumsField(values: Set<number>, fieldName: string, object: Parse.Object,  unsetIfEmpty: boolean = true) {
  const arr = Array.from(values);
  if (unsetIfEmpty && arr.length === 0) {
    object.unset(fieldName);
  }
  else {
    object.set(fieldName, arr);
  }
}

export function saveDateField(date: Date, fieldName: string,  object: Parse.Object) {
  const value = moment(date);
  if (value.isValid()) {
    // Date only value (i.e. time components are irrelevant, e.g. date of birth) are stored in UTC timezone with
    // hour, minute, second, and millisecond fields all set to 0.
    const utc = moment.utc().set({
      'year': value.year(),
      'month': value.month(),
      'date': value.date(),
      'hour': 0,
      'minute': 0,
      'second': 0,
      'millisecond': 0,
    });
    object.set(fieldName, utc.toDate());
  } else {
    object.unset(fieldName);
  }
}

export function getFileExtensionFromUrl(url: string): string | undefined {
  // Expecting url in the format 'data:image/png;base64,...' or 'data:image/jpeg;base64,...'
  const regex = /^data:image\/([^;]+);/;
  const matches = url.match(regex);
  if (!matches) { return undefined; }
  return matches.length >= 2 ? matches[1] : undefined;
}