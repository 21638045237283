import moment from 'moment';
import React, { useMemo } from 'react';
import { Selector, SelectorOption } from '../../../components/Selector';
import { findCurrentSupportPlan } from '../../../managers/SupportPlans';
import { SupportPlanModel } from '../../../models/SupportPlan';

export interface SupportPlanSelectorProps {
  plans: SupportPlanModel[];
  selectedPlan?: SupportPlanModel | undefined;
  allowNewPlan?: boolean;
  onChange:(plan: SupportPlanModel) => void;
  onAddNewPlan(): void;
}

export const SupportPlanSelector: React.FC<SupportPlanSelectorProps> = ({ plans, selectedPlan, allowNewPlan, onChange, onAddNewPlan }) => {
  const currentPlan = findCurrentSupportPlan(plans);

  // Generate list of plan options for the select dropdown
  const planOptions = useMemo<SelectorOption[]>(() => {
    const orderedPlans = plans.sort((planA: SupportPlanModel, planB: SupportPlanModel) => {
      return planA.startDate().getTime() > planB.startDate().getTime() ? -1 : 1;
    });

    const options: SelectorOption[] = orderedPlans.map(plan => {
      let name = 'Starting: ' + moment(plan.startDate()).format('DD/MM/YY');
      if (currentPlan.id() === plan.id()) {
        name = `${name} (Current)`;
      }

      return {
        name,
        key: plan.id(),
      };
    });

    if (allowNewPlan) {
      options.push({
        name: '+ New',
        key: '_new',
        disabled: false,
      });
    }

    return options;
  }, [plans, allowNewPlan]);

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    if (event.target.value === '_new') {
      onAddNewPlan();
    } else {
      onChange(plans.find(plan => plan.id() === event.target.value) ?? currentPlan);
    }
  }

  let selectedId = selectedPlan?.id();
  if (allowNewPlan && !selectedId) {
    selectedId = '_new';
  }

  return (
    <>
      <div className="col-3" />
      <div className="col-6">
        <Selector
          className="form-control"
          options={planOptions}
          initial={selectedId}
          onChange={handleChange}
        />
      </div>
      <div className="col-3" />
    </>

  );
};
