export enum FundingManagementType {
  Self = 1,
  Plan = 2,
  NDIA = 3
}

const FundingManagementTypeKeys = Object.keys(FundingManagementType).filter(key => typeof FundingManagementType[key as any] === 'number');
export const FundingManagementTypeCases = FundingManagementTypeKeys.map(key => Number(FundingManagementType[key as any]) as FundingManagementType);

export function getFundingManagementTypeDescription(type: FundingManagementType): string {
  return FundingManagementType[type];
}