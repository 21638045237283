
export enum Hobby {
  Animals = 1,
  Bicycling = 2,
  Beach = 3,
  CardsGames = 4,
  CarsMotorbikes = 5,
  ComputersElectronics = 6,
  Cooking = 7,
  CraftsPainting = 8,
  Dancing = 9,
  FilmTelevision = 10,
  Fishing = 11,
  Gardening = 12,
  Gym = 13,
  Larping = 14,
  ListeningMusic = 15,
  PerformingArts = 16,
  Photography = 17,
  PlayingMusic = 18,
  ReadingWriting = 19,
  Running = 20,
  Sewing = 21,
  Socialising = 22,
  Spirituality = 23,
  Swimming = 24,
  TeamSports = 25,
  Traveling = 26,
  VolunteerWork = 27,
  WalkingHiking = 28,
  WatchSports = 29,
  Yoga = 30,
}

const HobbyKeys = Object.keys(Hobby).filter(key => typeof Hobby[key as any] === 'number');
export const HobbyCases = HobbyKeys.map(key => Number(Hobby[key as any]) as Hobby);

export function getHobbyDescription(hobby: Hobby): string {
  switch (hobby) {
    case Hobby.Beach: return 'Going to the beach';
    case Hobby.CardsGames: return 'Playing cards and games';
    case Hobby.CarsMotorbikes: return 'Cars and motorbikes';
    case Hobby.ComputersElectronics: return 'Computers and electronics';
    case Hobby.CraftsPainting: return 'Crafts and painting';
    case Hobby.FilmTelevision: return 'Film and television';
    case Hobby.Larping: return 'LARPing';
    case Hobby.ListeningMusic: return 'Listening to music';
    case Hobby.PerformingArts: return 'Performing arts';
    case Hobby.PlayingMusic: return 'Playing music';
    case Hobby.ReadingWriting: return 'Reading or writing';
    case Hobby.TeamSports: return 'Team sports';
    case Hobby.VolunteerWork: return 'Volunteer work';
    case Hobby.WalkingHiking: return 'Walking or hiking';
    case Hobby.WatchSports: return 'Watching sports';
    default: return Hobby[hobby];
  }
}
