import Parse from 'parse';
import { ParseObject } from './util/parseTypes';


export class DayAvailabilityModel {
  constructor(public object: Parse.Object) { }

  static new(): DayAvailabilityModel {
    return new DayAvailabilityModel(new (Parse.Object.extend('DayAvailability'))());
  }


  id(): string { return this.object.id; }
  day(): number { return this.object.get('day'); }
  setDay(day: number):void { this.object.set('day', day); }

  startTime(): string { return this.object.get('startTime'); }
  setStartTime(time: string):void { this.object.set('startTime', time); }

  endTime(): string { return this.object.get('endTime'); }
  setEndTime(time: string):void { this.object.set('endTime', time); }


  setWorker(worker: ParseObject): void { this.object.set('worker', worker); }

  async save(): Promise<DayAvailabilityModel> {
    await this.object.save();
    return this;
  }
  async delete():Promise<void> { await this.object.destroy(); }

  rawObject(): ParseObject { return this.object; }
}