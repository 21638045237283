export enum SessionFrequency {
  Single = 0,
  Weekly = 1,
  Fortnightly = 2,
}

const SessionFrequencyKeys = Object.keys(SessionFrequency).filter(key => typeof SessionFrequency[key as any] === 'number');
export const SessionFrequencyCases = SessionFrequencyKeys.map(key => Number(SessionFrequency[key as any]) as SessionFrequency);

export function getSessionFrequencyDescription(frequency: SessionFrequency): string {
  return SessionFrequency[frequency];
}