import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { Loading } from '../../components/Loading';
import { getWorkerReport } from '../../managers/WorkerReports';
import { WorkerReportModel } from '../../models/WorkerReport';
import { workerReportsPath } from '../../Routes';
import { useIsMounted } from '../../views/Hooks/useIsMounted';
import { WorkerReport } from '../../views/WorkerReport';


export const WorkerReportContainer:React.FC = () => {
  const [report, setReport] = useState<WorkerReportModel | undefined>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const match = useRouteMatch({ path: `${workerReportsPath}/:id/` });
  const id = (match?.params as any)?.id as string;

  const isMounted = useIsMounted();

  // Filtering
  useEffect(() => {
    setIsLoading(true);
    void getWorkerReport(id).then(result => {
      if (!isMounted) { return; }
      if (result) {
        setReport(result);
      }
      setIsLoading(false);
    });
  }, [isMounted, id]);


  return (
    <div className="mx-4 mt-4">

      {isLoading &&
        <Loading />
      }

      {!isLoading && !report &&
        <div
          className="alert alert-warning mt-2"
          role="alert"
        >
          There is no report with that ID
        </div>
      }

      {report && !isLoading &&
        <WorkerReport
          key={report.id()}
          report={report}
        />
      }
    </div>
  );
};
