import Parse from 'parse';
import { ContactState, UnsavedContactPrefix } from '../containers/Contacts';


export enum ContactRelationship {
  unknown = 0,
  spousePartner,
  parent,
  otherRelative,
  friend,
  professional,
  guardian,
  supportCoordinatorRecoveryCoach
}

export const ContactRelationshipDescriptions: {[id: number]: string } = {
  1: 'Spouse / partner',
  2: 'Parent',
  3: 'Other relative',
  4: 'Friend',
  5: 'Professional',
  6: 'Guardian',
  7: 'Support Coordinator / Recovery Coach',
};

export class ContactModel {
  constructor(public object: Parse.Object) { }

  static new(): ContactModel {
    return new ContactModel(new (Parse.Object.extend('Contact'))());
  }

  id(): string { return this.object.id; }
  firstName(): string | undefined { return this.object.get('firstName'); }
  lastName(): string | undefined { return this.object.get('lastName'); }
  relationship(): ContactRelationship | undefined { return this.object.get('relationship'); }

  phone(): string | undefined { return this.object.get('phone'); }
  email(): string | undefined { return this.object.get('email'); }
  isDeleted(): boolean { return this.object.get('deletedAt') !== undefined; }

  static fromState(state: ContactState, clientId: string): ContactModel {
    const obj = new Parse.Object('Contact');

    if (state.id && !state.id.startsWith(UnsavedContactPrefix)) {
      obj.id = state.id;
    }

    obj.set('firstName', state.firstName);
    obj.set('lastName', state.lastName);
    obj.set('phone', state.phone);
    obj.set('email', state.email);
    obj.set('relationship', state.relationship);

    const client = new Parse.Object('Client');
    client.id = clientId;
    obj.set('client', client);

    if (state.isDeleted) {
      obj.set('deletedAt', new Date());
    }

    return new ContactModel(obj);
  }

  rawObject() { return this.object; }
}