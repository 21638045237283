import Parse from 'parse';
import { AdminUser } from './AdminUser';
import { ClientModel } from './Client';
import { WorkerModel } from './Worker';
import { ParseObject } from './util/parseTypes';

export class AdminNoteModel {

  constructor(public object: Parse.Object) { }

  static new(): AdminNoteModel {
    return new AdminNoteModel(new (Parse.Object.extend('AdminNote'))());
  }

  id(): string { return this.object.id; }
  text(): string  { return this.object.get('text'); }
  createdAt(): Date { return this.object.get('createdAt'); }
  deletedAt(): Date | undefined { return this.object.get('deletedAt'); }

  author(): AdminUser | undefined {
    if (this.object.get('author')) {
      return new AdminUser(this.object.get('author'));
    }
    return undefined;
  }

  client(): ClientModel | undefined {
    return new ClientModel(this.object.get('client'));
  }

  worker(): WorkerModel | undefined {
    return new WorkerModel(this.object.get('worker'));
  }

  setText(text: string): void { this.object.set('text', text); }

  setAuthor(adminUserId: string): void {
    const adminPointer = {
      __type: 'Pointer',
      className: 'Admin',
      objectId: adminUserId,
    };

    this.object.set('author', adminPointer);
  }

  setClient(client: ClientModel): void {
    const clientPointer = {
      __type: 'Pointer',
      className: 'Client',
      objectId: client.id(),
    };

    this.object.set('client', clientPointer);
  }

  setWorker(worker: WorkerModel): void {
    const workerPointer = {
      __type: 'Pointer',
      className: 'Worker',
      objectId: worker.id(),
    };

    this.object.set('worker', workerPointer);
  }

  async save(): Promise<AdminNoteModel> {
    await this.object.save();
    return this;
  }

  rawObject(): ParseObject {
    return this.object;
  }
}