import { Moment } from 'moment';
import Parse from 'parse';
import { FundingManagementType } from '../enums/SupportNeedFundingManagementType';
import { SupportNeedModelType } from '../enums/SupportNeedModelType';
import { SupportNeedState } from '../managers/SupportNeeds';
import { ClientModel } from './Client';
import { PlanManagerModel } from './PlanManager';
import { SupportPlanModel } from './SupportPlan';
import { momentFromDate } from './util/momentFromDate';
import { ParseObject } from './util/parseTypes';



export class SupportNeedModel {
  constructor(public object: Parse.Object) { }

  static new(): SupportNeedModel {
    return new SupportNeedModel(new (Parse.Object.extend('SupportNeed'))());
  }

  endDate(): Moment | undefined { return momentFromDate(this.object.get('endDate')); }
  budget(): number | undefined { return this.object.get('budget'); }
  id(): string | undefined { return this.object.id; }
  clientId(): string | undefined { return this.object.get('client')?.id; }

  planManagerId(): string | undefined {
    return this.object.get('planManager')?.id;
  }

  planManagerSync(): PlanManagerModel | undefined {
    const parseObject = this.object.get('planManager') as Parse.Object | undefined;
    if (!parseObject) { return undefined; }
    return new PlanManagerModel(parseObject);
  }

  async supportPlan(): Promise<SupportPlanModel | undefined> {
    const plan = this.object.get('supportPlan');
    if (!plan) { return; }
    const object = await plan.fetch();
    if (!object) { return; }
    return new SupportPlanModel(object);
  }

  fundingManagementType(): FundingManagementType | undefined {
    return this.object.get('fundingManagementType') as FundingManagementType | undefined;
  }

  type(): SupportNeedModelType | undefined {
    const val = this.object.get('type') as SupportNeedModelType | undefined;
    return val;
  }

  isTransportType(): boolean {
    return this.object.get('type') === 7;
  }

  agreesToUseCoreSupportFunding(): boolean | undefined {
    if (this.object.get('type') !== 7) { return undefined; }
    return this.object.get('agreesToUseCoreSupportFunding');
  }


  isDeleted(): boolean {
    const deleted = this.object.get('deletedAt');
    return deleted !== undefined;
  }

  loadState(state: SupportNeedState, client: ClientModel): SupportNeedModel {
    this.object.set('objectId', state.id);
    this.object.set('type',  state.type ?? 0);
    this.object.set('fundingManagementType', state.fundingManagementType);

    this.object.set('budget', state.budget);

    if (state.agreesToUseCoreSupportFunding === undefined) {
      this.object.unset('agreesToUseCoreSupportFunding');
    } else {
      this.object.set('agreesToUseCoreSupportFunding', state.agreesToUseCoreSupportFunding);
    }

    if (state.planManagerId) {
      const pointer = new Parse.Object('PlanManager');
      pointer.id = state.planManagerId;
      this.object.set('planManager', pointer);
    }

    this.object.set('client', client.rawObject());
    this.object.set('deletedAt', state.isDeleted ? new Date() : undefined);
    return this;
  }


  updateEndDate(date: Date): void {
    this.object.set('endDate', date);
  }

  setSupportPlan(plan: SupportPlanModel): SupportNeedModel {
    this.object.set('supportPlan', plan.rawObject());
    return this;
  }

  async save(): Promise<SupportNeedModel> {
    await this.object.save();
    return this;
  }

  clone(): SupportNeedModel {
    // WARNING: When adding new fields of note, they need to be added here.
    const clone = new Parse.Object('SupportNeed');
    clone.set('endDate', this.object.get('endDate'));
    clone.set('client', this.object.get('client'));
    clone.set('type', this.object.get('type'));
    clone.set('budget', this.object.get('budget'));
    clone.set('agreesToUseCoreSupportFunding', this.object.get('agreesToUseCoreSupportFunding'));
    clone.set('fundingManagementType', this.object.get('fundingManagementType'));
    clone.set('planManager', this.object.get('planManager'));

    return new SupportNeedModel(clone);
  }

  /**
     * Only to be used when manipulating client...
     */
  rawObject(): ParseObject {
    return this.object;
  }

  supportPlanId(): string | undefined {
    return this.object.get('supportPlan')?.id;
  }

}