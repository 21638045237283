import { pdf } from '@react-pdf/renderer';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { Download } from 'react-bootstrap-icons';
import { Goal } from '../../../components/Goal';
import { downloadFile } from '../../../containers/Client/util';
import { GoalState, goalStateFromGoalModel, UnsavedGoalPrefix } from '../../../containers/Goals';
import { GoalStatus } from '../../../enums/GoalStatus';
import { LastEvent } from '../../../hooks/useAnalytics';
import { ClientModel } from '../../../models/Client';
import { GoalModel } from '../../../models/Goal';
import { ClientGoalsPDF } from '../../../reports/goals';
import { SaveButton } from '../SaveButton';

export interface ClientGoalViewProps {
  client: ClientModel;
  goals?: GoalModel[];
  onSave: (state: ClientGoalViewState) => Promise<void>;
  lastGoalEvent?: LastEvent | undefined;

  onReset: () => void;
  disableInteraction: boolean;
}

export interface ClientGoalViewState {
  goals: GoalState[];
}

export class ClientGoalView extends React.Component<ClientGoalViewProps> {

  state = {
    currentGoals: this.props.goals ? this.props.goals.filter(goal => goal.status() === GoalStatus.current).map(goal => goalStateFromGoalModel(goal)) : [] as GoalState[],
    archivedGoals: this.props.goals ?  this.props.goals.filter(goal => goal.status() === GoalStatus.completed || goal.status() === GoalStatus.inactive).map(goal => goalStateFromGoalModel(goal)) : [] as GoalState[],
    canSave: false,
    canAdd: true,
  };


  updateSaveable() {
    const dirtyGoals = [...this.state.currentGoals, ...this.state.archivedGoals].filter(goal => goal.isDirty);
    const emptyGoals = [...this.state.currentGoals, ...this.state.archivedGoals].filter(goal => (goal.title ?? '').length === 0 || (goal.criteria ?? '').length === 0);

    this.setState({ canSave: (dirtyGoals.length > 0) && (emptyGoals.length === 0) });
    this.setState({ canAdd: emptyGoals.length === 0 });
  }

  onChangeGoal(updated: GoalState, key: string) {
    const [original] = ((this.state as any)[key] as GoalState[]).filter(goal => goal.id === updated.id);
    if (!original) { return; }
    Object.assign(original, { ...updated, isDirty: true });
    this.setState({ [key]: (this.state as any)[key] }, () => {
      this.updateSaveable();
    });
  }

  onDeletedGoal(deleted: GoalState, key: string) {
    // If yet to be created, then just remove from list
    if (deleted.id?.startsWith(UnsavedGoalPrefix)) {
      const cleaned = ((this.state as any)[key] as GoalState[]).filter(goal => goal.id !== deleted.id);
      this.setState({ [key]: cleaned }, () => {
        this.updateSaveable();
      });
    } else {
      Object.assign(deleted, { isDeleted: true, isDirty: true });
      this.setState({ [key]: (this.state as any)[key] }, () => {
        this.updateSaveable();
      });
    }
  }

  renderPDF(): JSX.Element {
    return (
      <ClientGoalsPDF
        client={this.props.client}
        goals={this.props.goals || []}
      />
    );
  }

  async exportPDF(): Promise<void> {
    const blob = await pdf(this.renderPDF()).toBlob();
    downloadFile(blob, [this.props.client.firstName(), this.props.client.lastName(), this.props.client.id(), 'goals'].map(part => part?.toLowerCase()).join('_') + '.pdf');
  }

  render(): JSX.Element | undefined {
    const reportableGoals = (this.props.goals || []).filter(goal => goal.isReportable());
    return (
      <div className="container pb-2 pt-4">
        <form>
          <div className="row ">
            <div className="col-5" />
            <h4 className="col-2 text-center">Current Goals</h4>

          </div>
          {this.state.currentGoals.filter(goal => goal.isDeleted !== true).map(goal => <Goal
            key={goal.id}
            goal={goal}
            onEdited={edited => this.onChangeGoal(edited, 'currentGoals')}
            onDeleted={deleted => this.onDeletedGoal(deleted, 'currentGoals')}
          />)}

          <div className="offset-md-11">
            <button
              type="button"
              className={classNames('btn btn-primary mr-2')}
              onClick={() => {
                this.setState({ 'currentGoals': [...this.state.currentGoals, { id: UnsavedGoalPrefix + _.random(0, 1000, false).toString() }] }, () => {
                  this.updateSaveable();
                });

              }}
              disabled={!this.state.canAdd}
            >Add</button>
          </div>

          {this.state.archivedGoals.length > 0 &&
            <>
              <h4 className="text-center">Archived Goals</h4>
              {this.state.archivedGoals.map(goal => <Goal
                key={goal.id}
                goal={goal}
                onEdited={edited => this.onChangeGoal(edited, 'archivedGoals')}
                onDeleted={deleted => this.onDeletedGoal(deleted, 'archivedGoals')}
              />)}
            </>
          }

          <hr />

          <SaveButton
            onSave={async () => {
              await this.props.onSave({
                goals: [...this.state.archivedGoals, ...this.state.currentGoals],
              });
              this.updateSaveable();
            }}
            onCancel={() => this.props.onReset()}
            disableCancel={this.props.disableInteraction}
            disableSave={this.props.disableInteraction || !this.state.canSave}
            leftAction={
              <div className="col-1">
                { reportableGoals.length >= 1  &&
                  <button
                    type="button"
                    className="btn btn-primary m-1"
                    onClick={async () => this.exportPDF()}
                  >
                    <div className="d-flex">
                      <Download className="mr-1" />
                      <span>Export</span>
                    </div>
                  </button>
                }
              </div>
            }
          />
        </form>
        {
          this.props.lastGoalEvent && (
            <div
              className="alert alert-info mt-2 text-center"
              role="alert"
            >
              Goals last updated {moment(this.props.lastGoalEvent.date).format(`DD MMM YYYY, [at] h:mma`)}, by {this.props.lastGoalEvent.userName}
            </div>
          )
        }
      </div>
    );
  }
}

