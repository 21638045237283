import _isEqual from 'lodash/isEqual';
import { ClientDetailsSave, ClientModel } from '../models/Client';

/**
 * Finds all fields changed between a client model and a client save state and returns the field names
 */
export function getClientDetailChangeFields(client: ClientModel, newState: ClientDetailsSave): string[] {
  const changes: string[] = [];
  
  if (client.phoneNumber() !== newState.phoneNumber) { changes.push('phoneNumber'); }
  if (client.email() !== newState.email) { changes.push('email'); }
  if (client.firstName() !== newState.firstName) { changes.push('firstName'); }
  if (client.lastName() !== newState.lastName) { changes.push('lastName'); }
  if (client.gender() !== newState.gender) { changes.push('gender'); }
  if (client.address() !== newState.address) { changes.push('address'); }
  if (client.photoUrl() !== newState.photoUrl) { changes.push('photoUrl'); }
  if (client.status() !== newState.userStatus) { changes.push('userStatus'); }
  if (client.suburb() !== newState.suburb) { changes.push('suburb'); }
  if (client.postcode() !== newState.postcode) { changes.push('postcode'); }
  if (client.covidVaccinationStatus() !== newState.covidVaccinationStatus) { changes.push('covidVaccinationStatus'); }
  if (client.atsiStatus() !== newState.atsiStatus) { changes.push('atsiStatus'); }
  if (client.dateOfBirth().format('YYYYMMDD') !== newState.dateOfBirth.format('YYYYMMDD')) { changes.push('dateOfBirth'); }  

  
  if (`${client.importantToKnow() ?? ''}`.trim() !== `${newState.importantToKnow ?? ''}`.trim()) {
    changes.push('importantToKnow');
  }
  if (`${client.bio() ?? ''}`.trim() !== `${newState.bio ?? ''}`.trim()) {
    changes.push('bio');
  }

  if (!_isEqual(client.languages(), newState.languages)) { changes.push('languages'); }
  if (!_isEqual(client.hobbies(), newState.hobbies)) { changes.push('hobbies'); }
  if (!_isEqual(client.disallowedWorkerGenders(), newState.disallowedWorkerGenders)) { changes.push('disallowedWorkerGenders'); }
  if (!_isEqual(client.disallowedWorkerVaccinationStatuses(), newState.disallowedWorkerVaccinationStatuses)) { changes.push('disallowedWorkerVaccinationStatuses'); }

  return changes;
}