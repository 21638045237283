import Parse from 'parse';
import { isLocalDev, isProd } from './helpers';
import { AdminUser } from './models/AdminUser';

const PROD_APP_ID = 'yWc64JPcNZHfolGm66bBgl7qhH4PTjWQVa4cLmlg';
const PROD_JS_KEY = 'lREF6TfQhM8PfNCPcYt0nrTR4VNmAlSWahHTTaQj';
const PROD_SERVER_URL = 'https://pg-app-cizdkhwc0ykykqb10er7vef6fecdsb.scalabl.cloud/1/';

const STAGING_APP_ID = 'rk8vFOvX5GWBNKSU6Dk0LQ3NQMVFLoEMPURMoMG8';
const STAGING_JS_KEY = 'TqUV6wkEk0SpnNzr66onCqXLQEqEpVGVNUFMURYM';
const STAGING_SERVER_URL = 'https://pg-app-gmkruox5b8xk38kggy9ceepuhs4zhx.scalabl.cloud/1/';


export interface SessionListener {
  onUserChanged(user: AdminUser | undefined): void;
}

class Session {
  private static _instance: Session;
  private user?: AdminUser;
  private listeners = new Set<SessionListener>();

  private constructor() {
    if (isProd()) {
      Parse.initialize(PROD_APP_ID, PROD_JS_KEY);
      Parse.serverURL = PROD_SERVER_URL;
    } else {
      Parse.initialize(STAGING_APP_ID, STAGING_JS_KEY);
      Parse.serverURL = STAGING_SERVER_URL;

      if (isLocalDev()) {
        // Toggle the comments below to enable PROD or STAGING for local dev
        Parse.initialize(PROD_APP_ID, PROD_JS_KEY);
        // Parse.initialize(STAGING_APP_ID, STAGING_JS_KEY);
        Parse.serverURL = 'http://localhost:1337/parse';
      }
    }

    const parseUser = Parse.User.current();
    if (!parseUser) { return; }

    const user = new AdminUser(parseUser);
    this.user = user;

    const query = new Parse.Query(Parse.Object.extend('Admin'));
    query.equalTo('user', parseUser);
    void query.first().then((admin) => {
      if (admin) { user.admin = admin; }
    });
  }

  static instance(): Session {
    if (!Session._instance) {
      Session._instance = new Session();
    }
    return Session._instance;
  }

  getUser(): AdminUser | undefined { return this.user; }
  isUserLoggedIn(): boolean { return this.getUser() !== undefined; }

  async signIn(email: string, password: string): Promise<AdminUser> {
    if (this.user) { return this.user; }

    const parseUser = await Parse.User.logIn(email, password);
    const type = parseUser.get('type');
    if (type !== 3) {
      await Parse.User.logOut();
      throw new Error('User does not have admin privilege.');
    }

    const query = new Parse.Query(Parse.Object.extend('Admin'));
    query.equalTo('user', parseUser);
    const admin = await query.first();
    if (!admin || admin.get('deletedAt') !== undefined) {
      await Parse.User.logOut();
      throw new Error('Admin details cannot be loaded.');
    }

    const user = new AdminUser(parseUser, admin);
    this.user = user;
    this.listeners.forEach(listener => listener.onUserChanged(this.user));
    return user;
  }

  async signOut() {
    if (!this.user) { return; }
    await Parse.User.logOut();
    this.user = undefined;
    this.listeners.forEach(listener => listener.onUserChanged(this.user));
  }

  registerListener(listener: SessionListener) {
    this.listeners.add(listener);
  }

  unregisterListener(listener: SessionListener) {
    this.listeners.delete(listener);
  }
}

export default Session.instance();