import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { loginPath } from '../../Routes';
import session from '../../Session';
import { BillingContainer } from '../../containers/Billing';

export const Billing: React.FC = () => {
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  const { filter } = useParams() as { filter?: string };
  const pathName = useLocation().pathname;

  // TODO: if no filter, default to the first (NDIA) with history.push?
  if (!filter) { return null; }

  const filters = ['ndia', 'invoices', 'payroll'];

  if (filters.includes(filter)) {
    return <BillingContainer />;
  }

  // TODO: Do we need a separate detail view in this flow or just make sure the back button goes back here?
  return <BillingContainer />;
};
