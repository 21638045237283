import moment from 'moment';
import { SessionStatus } from '../models/Sessions';
import { SupportSessionModel } from '../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../models/Sessions/SupportSessionRequest';

export type SessionOrRequest = SupportSessionRequestModel | SupportSessionModel;

/**
 * Sort a group of support sessions and requests by date
 */
export function sortSessionsAndRequestsByDateDesc(item1: SessionOrRequest, item2: SessionOrRequest): number {
  let date1 = 0;
  let date2 = 0;

  if (item1 instanceof SupportSessionRequestModel) {
    date1 = moment(item1.firstDateStart()).valueOf();
  }  else {
    date1 = item1.bestKnownStartDate().getTime();
  }

  if (item2 instanceof SupportSessionRequestModel) {
    date2 = moment(item2.firstDateStart()).valueOf();
  }  else {
    date2 = item2.bestKnownStartDate().getTime();
  }

  return date2 - date1;
}

// The values here may be more than one actual SessionStatus, but as strings -
// these will be converted to numbers before sending to the API requests.
export type StatusFilterOption = {
  id: string;
  value: string;
  rawValues: SessionStatus[];
  label: string;
};

// These are currently only used on the Sessions screen to define checkbox
// options for filtering session status
export const sessionStatusFilterOptions: StatusFilterOption[] = [
  {
    id: 'pending',
    value: 'pending',
    rawValues: [SessionStatus.pendingWorkerApproval, SessionStatus.pendingClientApproval, SessionStatus.suggested],
    label: 'Pending',
  },
  {
    id: 'confirmed',
    value: 'confirmed',
    rawValues: [SessionStatus.confirmed],
    label: 'Confirmed',
  },
  {
    id: 'cancelled_by_client',
    value: 'cancelled_by_client',
    rawValues: [SessionStatus.cancelledByClient, SessionStatus.noShow],
    label: 'Cancelled by client',
  },
  {
    id: 'cancelled_by_worker',
    value: 'cancelled_by_worker',
    rawValues: [SessionStatus.cancelledByWorker],
    label: 'Cancelled by worker',
  },
  {
    id: 'cancelled_by_admin',
    value: 'cancelled_by_admin',
    rawValues: [SessionStatus.cancelledByAdmin],
    label: 'Cancelled by admin',
  },
  {
    id: 'declined',
    value: 'declined',
    rawValues: [SessionStatus.declined],
    label: 'Declined',
  },
  {
    id: 'completed',
    value: 'completed',
    rawValues: [SessionStatus.completed],
    label: 'Completed',
  },
];