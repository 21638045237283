import { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { clientsPath } from '../../../Routes';

export const useIsCurrentTab = (isTab: string): boolean => {
  const match = useRouteMatch({ path: `${clientsPath}/:id/:tab` });
  const [tab, setTab] = useState<string>();

  useEffect(() => {
    const tab = (match?.params as any)?.tab || 'details';
    setTab(tab);
    return;
  }, [tab, match]);

  return tab === isTab;
};

export function waitFor(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const useCanLoad = (tab: string, delay: number): boolean => {
  const isCurrentTab = useIsCurrentTab(tab);
  const [canLoad, setCanLoad] = useState(false);

  useEffect(() => {
    if (isCurrentTab) {
      setCanLoad(true);
    }
  }, [isCurrentTab]);

  useEffect(() => {
    void waitFor(delay).then(() => setCanLoad(true));
  }, [delay]);

  return canLoad;
};

export const DEFAULT_DELAY = 10_000;

export const downloadFile = (blob: Blob, fileName: string) => {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};
