import classNames from 'classnames';
import React from 'react';

export interface SelectorOption {
  name: string;
  key: string | number | undefined;
  disabled?: boolean;
}

interface SelectorProps {
  onChange:(e: React.ChangeEvent<HTMLSelectElement>) => void;
  options: SelectorOption[];
  initial: string | number | undefined;
  isValid?: boolean;
  disabled?: boolean;
  id?: string;
  className?: string;
}

export const Selector: React.FC<SelectorProps> = ({ options, initial, onChange, isValid, disabled, id = 'select', className }) => {

  const htmlOptions = options.map(option => {
    return (
      <option
        key={option.key}
        value={option.key}
        disabled={option.disabled || false}
      > {option.name}
      </option>
    );
  });


  return (
    <select
      id={id}
      className={classNames(className ?? 'col-4 form-control', { 'is-invalid': isValid === false })}
      value={initial}
      onChange={e => onChange(e)}
      disabled={disabled}
    >
      {htmlOptions}
    </select>
  );

};
