import React from 'react';

interface LoadingProps {
  text?: string;
}

export const Loading:React.FC<LoadingProps> = ({ text }) => (
  <div className="d-flex justify-content-center m-5">
    <div
      className="spinner-grow text-info"
      role="status"
    >
      <span className="sr-only">{text || 'Loading...'}</span>
    </div>
  </div>
);

export const NakedLoader = () => (
  <div
    className="spinner-grow text-info"
    role="status"
  />
);
