import moment from 'moment';
import React from 'react';
import { ContactsContainer, ContactState, contactStateFromContactModel, UnsavedContactPrefix } from '../../../containers/Contacts';
import { LastEvent } from '../../../hooks/useAnalytics';
import { ContactModel } from '../../../models/Contact';
import { SaveButton } from '../SaveButton';


export interface ClientContactViewProps  {
  contacts?: ContactModel[];
  lastContactEvent?: LastEvent | undefined;
  onSave: (state: ClientContactViewState) => Promise<void>;
  onReset: () => void;
  disableInteraction: boolean;
}

export interface ClientContactViewState {
  contacts: ContactState[];
}

export class ClientContactView extends React.Component<ClientContactViewProps> {

  state = {
    contacts: this.props.contacts ? this.props.contacts.map(contact => contactStateFromContactModel(contact)) : [] as ContactState[],
    dirtyKeys: new Set(),
  };

  updateState(key: string, value: any) {
    this.setState({
      isDirty: this.state.dirtyKeys.add(key),
      [key]: value,
    });
  }

  isDirty(): boolean { return this.state.dirtyKeys.size > 0; }

  renderForm(): JSX.Element {
    return (
      <div className="container pb-2 pt-4">
        <form>
          <ContactsContainer
            contacts={this.state.contacts}
            onAdded={(added) => {
              this.updateState('contacts', [...this.state.contacts, added]);
            }}
            onChange={updated => {
              const [original] = this.state.contacts.filter(contact => contact.id === updated.id);
              if (!original) { return; }
              Object.assign(original, updated);
              this.updateState('contacts', this.state.contacts);
            }}
            onSetDelete={deleted => {
            // If yet to be created, then just remove from list
              if (deleted.id?.startsWith(UnsavedContactPrefix)) {
                const cleaned = this.state.contacts.filter(contact => contact.id !== deleted.id);
                this.updateState('contacts', cleaned);
              } else {
                Object.assign(deleted, { isDeleted: true });
                this.updateState('contacts', this.state.contacts);
              }
            }}
          />

          <hr />

          <SaveButton
            onSave={async () => {
              this.setState({ dirtyKeys: new Set() });
              await this.props.onSave(this.state);
            }}
            onCancel={() => this.props.onReset()}
            disableCancel={this.props.disableInteraction}
            disableSave={this.props.disableInteraction || !this.isDirty()}
          />
        </form>
        {
          this.props.lastContactEvent && (
            <div
              className="alert alert-info mt-2 text-center"
              role="alert"
            >
              Contacts last updated {moment(this.props.lastContactEvent.date).format(`DD MMM YYYY, [at] h:mma`)}, by {this.props.lastContactEvent.userName}
            </div>
          )
        }
      </div>
    );
  }

  render(): JSX.Element | undefined {
    return this.renderForm();
  }
}
