import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { getSupportNeedModelTypeDescription, SupportNeedModelType } from '../../../enums/SupportNeedModelType';
import { SupportPlanModel } from '../../../models/SupportPlan';
import { Selector } from '../../Selector';
import { SimpleLabelledValue } from '../../SupportSession/SimpleLabelledValue';

interface SessionNeedEditorProps {
  allPlans: SupportPlanModel[];

  sessionPlan: SupportPlanModel;
  selectedPlan: SupportPlanModel;
  sessionNeedType: SupportNeedModelType | undefined;
  selectedNeedType: SupportNeedModelType | undefined;

  isReadOnlyPlan: boolean;
  isReadOnlyNeed: boolean;

  onChangePlan: (plan: SupportPlanModel) => void;
  onChangeNeedType: (needType: SupportNeedModelType) => void;
}

const UNALLOCATED = 'Unallocated';

export const SessionNeedEditor: React.FC<SessionNeedEditorProps> = ({ sessionNeedType, selectedNeedType, selectedPlan, allPlans, sessionPlan, onChangeNeedType, onChangePlan, isReadOnlyPlan, isReadOnlyNeed }) => {
  const planDateFormat = 'DD/MM/YYYY';
  const [currentNeedType, setCurrentNeedType] = useState<SupportNeedModelType | typeof UNALLOCATED>();

  const availableNeedTypes = () => {
    return selectedPlan.supportNeedsSync()
      .filter(need => !need.isTransportType() && !need.isDeleted() && need.type())
      .map((need) => need.type());
  };

  let needDescription = UNALLOCATED;
  if (currentNeedType !== UNALLOCATED) {
    needDescription = `${getSupportNeedModelTypeDescription(currentNeedType as SupportNeedModelType)}`;
  }

  function getNeedSelectOptionName(needType: SupportNeedModelType | undefined): string {
    if (!needType || !availableNeedTypes().includes(needType)) {
      return UNALLOCATED;
    }

    return `${getSupportNeedModelTypeDescription(needType)}`;
  }

  function handleChangeNeedType(event: React.ChangeEvent<HTMLSelectElement>): void {
    const chosen = availableNeedTypes().find(value => String(value) === event.target.value);
    
    if (chosen) {
      onChangeNeedType(chosen);
    } else {
      onChangeNeedType(SupportNeedModelType.Unknown);
    }
  }

  const needSelectOptions = useMemo(() => {
    const options = availableNeedTypes().map(need => {
      return {
        name: getNeedSelectOptionName(need),
        key: need || '',
      };
    });

    if (!sessionNeedType || !availableNeedTypes().includes(sessionNeedType)) {
      options.push({
        name: UNALLOCATED,
        key: UNALLOCATED,
      });
    }

    return options;
  }, [availableNeedTypes]);

  useEffect(() => {
    const newType = selectedNeedType ?? sessionNeedType ?? SupportNeedModelType.Unknown;
    const hasMatch = availableNeedTypes().includes(newType);

    if (hasMatch) {
      setCurrentNeedType(newType);
      return;
    }

    if (!hasMatch) {
      setCurrentNeedType(UNALLOCATED);
    }
  }, [sessionNeedType, selectedNeedType]);
  
  return (
    <>
      <div className="row">
        {
          isReadOnlyPlan ? (
            <SimpleLabelledValue
              title="Support Plan"
              value={'Starting on: ' + moment(sessionPlan.startDate()).format(planDateFormat)}
            />
          ) : (
            <>
              <label
                className="col-2 col-form-label text-right mb-1"
              >Support Plan</label>
              <Selector
                options={allPlans.map(plan => {
                  return {
                    name: 'Starting on: ' + moment(plan.startDate()).format(planDateFormat),
                    key: plan.id(),
                  };
                })}
                initial={selectedPlan.id()}
                onChange={e => onChangePlan(allPlans.find(plan => plan.id() === e.target.value) ?? sessionPlan)}
              />
            </>
          )
        }
        {
          isReadOnlyNeed ? (
            <SimpleLabelledValue
              title="Support Need"
              value={needDescription}
            />
          ) : (
            <>
              <label
                className="col-2 col-form-label text-right mb-1"
              >Support Need</label>
              <Selector
                key={availableNeedTypes().map(need => need).join()}
                options={
                  [{
                    name: 'Choose a need...',
                    key: 'choose',
                    disabled: true,
                  },
                  ...needSelectOptions]}
                initial={currentNeedType ?? 'choose'}
                isValid={needDescription !== UNALLOCATED}
                onChange={handleChangeNeedType}
              />
            </>
          )
        }
      </div>
    </>
  );
};