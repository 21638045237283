
import React from 'react';
import { useHistory } from 'react-router-dom';
import { workerReportsPath } from '../../Routes';
import { getIncidentReportTypeDescription } from '../../enums/WorkerReportType';
import { formatDate } from '../../helpers';
import { WorkerReportModel } from '../../models/WorkerReport';

interface WorkerReportTileProps {
  report: WorkerReportModel;
}

export const  WorkerReportTile:React.FC<WorkerReportTileProps> = ({ report }) => {
  const photoUrl = report.getWorkerSync().photo();
  const history = useHistory();
  const path = `${workerReportsPath}/${report.id()}`;
  return (
    <a
      className="link-tile col-4 cursor-pointer p-1 text-left"
      id={report.id()}
      href={path}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        history.push(path);
      }}
    >
      <div className="border rounded p-1 d-flex justify-content-between align-items-center hover-item-active">
        <div className="d-flex flex-column ">
          <h5 className="mb-0">{report.getWorkerSync().fullName()}</h5>
          <p className="mb-1">{formatDate(report.createdAt(), 'dddd, Do MMMM, YYYY')}</p>
          <p className="mb-0 font-weight-bold">{getIncidentReportTypeDescription(report.type())}</p>
        </div>
        <img
          src={photoUrl}
          alt="avatar"
          style={{ width: 56, height: 56, borderRadius: '50%', objectFit: 'cover' }}
        />
      </div>
    </a>
  );
};