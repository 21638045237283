import { View } from '@react-pdf/renderer';
import React from 'react';

export const HR = () => (
  <View
    style={{
      borderBottomColor: 'lightgrey',
      borderBottomWidth: 1,
    }}
  />
);
