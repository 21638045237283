import React from 'react';
import { getGoalStatusColor, getGoalStatusDescription, GoalStatus } from '../../enums/GoalStatus';
import { formatDate } from '../../helpers';
import { GoalModel } from '../../models/Goal';

export const GoalSummary: React.FC<{ goal: GoalModel | undefined }> = ({ goal }) => {
  const completedDates = goal?.completedDates();

  if (!goal) {
    return (
      <>
        <label
          htmlFor="goals"
          className="col-2 col-form-label text-right"
        >Client goal</label>
        <div className="col-4 col-form-label">
          No goal
        </div>
      </>
    );
  }

  return (
    <>
      <label
        htmlFor="goals"
        className="col-2 col-form-label text-right"
      >Client goal</label>
      <div className="col-4 p-0">
        <div
          id="goals"
          className="border rounded bg-light p-2"
        >

          <>
            <b>I will:</b>
            <p>{goal?.title() ?? ''}</p>
            <b>To do this I will:</b>
            <p>{goal?.criteria() ?? ''}</p>
          </>

        </div>
        {goal &&
          <>
            <div className="col-2 pl-0 pt-1">
              <span
                className="badge badge-pill badge-warning text-uppercase text-white sm-pill"
                style={{ backgroundColor: getGoalStatusColor(goal.status()), opacity: goal.status() === GoalStatus.inactive ? 0.65 : 1.0 }}
              >{getGoalStatusDescription(goal.status())}</span>
            </div>

            {completedDates && completedDates?.length > 0 &&
              <>
                <div className="col-10 pl-0 mt-1">
                  {`Completed: ${completedDates.map(date => formatDate(date, 'DD/MM/YYYY')).join(', ')}`}
                </div>
              </>
            }
          </>
        }
      </div>
    </>
  );
};