import Parse from 'parse';
import { AdminNoteModel } from '../../models/AdminNote';
import { AdminUser } from '../../models/AdminUser';
import { ClientModel } from '../../models/Client';
import { WorkerModel } from '../../models/Worker';

/**
 * Load all admin notes for a client
 */
export async function getAdminNotes(userId: string, userType: 'client' | 'worker'): Promise<AdminNoteModel[]> {
  const userQuery = new Parse.Query(userType === 'client' ? 'Client' : 'Worker');
  userQuery.equalTo('objectId', userId);

  const query = new Parse.Query('AdminNote');
  query.include('client');
  query.include('worker');
  query.include('author');
  query.doesNotExist('deletedAt');
  query.addDescending('createdAt');
  query.limit(100_000);
  query.matchesQuery(userType, userQuery);

  const parseModels = await query.find();
  const adminNotes = parseModels.map(model => new AdminNoteModel(model));
  return adminNotes;
}

/**
 * Create a new admin note for a client
 */
export async function createAdminNote(
  userModel: ClientModel | WorkerModel | undefined,
  userType: 'client' | 'worker',
  admin: AdminUser | undefined,
  text: string,
): Promise<AdminNoteModel | undefined> {
  if (userModel === undefined) {
    return;
  }

  const model = AdminNoteModel.new();
  const adminUser = admin?.admin;
  if (adminUser) {
    model.setAuthor(adminUser.id);
  }

  if (userType === 'client') {
    model.setClient(userModel as ClientModel);
  } else if (userType === 'worker') {
    model.setWorker(userModel as WorkerModel);
  }

  model.setText(text);

  const res = await model.save();

  if (res) {
    const newNote = await loadAdminNote(res.id());
    return newNote || undefined;
  }

  return undefined;
}

/**
 * Load a single admin note
 */
async function loadAdminNote(id: string): Promise<AdminNoteModel | undefined> {
  const query = new Parse.Query('AdminNote');
  query.equalTo('objectId', id);
  query.include('client');
  query.include('worker');
  query.include('author');
  query.doesNotExist('deletedAt');
  query.limit(1);

  const parseModel = await query.first();
  return parseModel ? new AdminNoteModel(parseModel) : undefined;
}