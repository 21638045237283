import { BrandColors } from '../helpers';

export enum UserStatus {
  Unknown = 0,
  Active = 1,
  PendingDocuments = 2,
  PendingApproval = 3,
  Declined = 4,
  Blocked = 5,
  Removed = 6,
  PendingReapproval = 7,
}

const UserStatusKeys = Object.keys(UserStatus).filter(key => typeof UserStatus[key as any] === 'number');
export const UserStatusCases = UserStatusKeys.map(key => Number(UserStatus[key as any]) as UserStatus);

export function getUserStatusDescription(status: UserStatus): string {
  switch (status) {
    case UserStatus.PendingDocuments: return 'Pending documents';
    case UserStatus.PendingApproval: return 'Pending approval';
    case UserStatus.PendingReapproval: return 'Pending reapproval';
    default: return UserStatus[status];
  }
}

export function getUserStatusColor(status: UserStatus): string {
  switch (status) {
    case UserStatus.PendingApproval:
    case UserStatus.PendingReapproval:
    case UserStatus.PendingDocuments:
      return BrandColors.orange;
    case UserStatus.Removed:
    case UserStatus.Blocked:
    case UserStatus.Declined:
      return BrandColors.red;
    case UserStatus.Active:
      return  BrandColors.green;
  }
  return BrandColors.orange;
}

export const ClientStatuses = [
  UserStatus.Active,
  UserStatus.PendingApproval,
  UserStatus.Declined,
  UserStatus.Blocked,
  UserStatus.Removed,
  UserStatus.PendingReapproval,
];


export const WorkerStatuses = [
  UserStatus.Active,
  UserStatus.PendingApproval,
  UserStatus.PendingDocuments,
  UserStatus.Declined,
  UserStatus.Blocked,
  UserStatus.Removed,
];
