import Parse from 'parse';
import { downloadCSV } from '../../reports/csv/util';

export enum InvoiceType {
  keypayHours = 'keypay-hours',
  keypayTransport = 'keypay-transport',
  payroll = 'payroll',
  ndia = 'ndia',
  selfPlanManagedXero = 'spm-xero'
}

class InvoicingManager {
  private latestData: string[][] | undefined = undefined;
  private latestFilename: string = 'xero_invoice.csv';

  private static _instance: InvoicingManager;

  static instance(): InvoicingManager {
    if (!InvoicingManager._instance) {
      InvoicingManager._instance = new InvoicingManager();
    }
    return InvoicingManager._instance;
  }

  async prepareInvoice(type: InvoiceType, sessionIds: string[]): Promise<void> {
    const result = await Parse.Cloud.run('invoicing', { type, sessionIds });
    this.latestData = result;
    this.latestFilename = type === InvoiceType.ndia ? 'ndia_invoice.csv' : 'xero_invoice.csv';
  }

  downloadLastInvoice(name?: string): void {
    if (!this.latestData) { return; }
    const [headers, ...rows] = this.latestData;

    downloadCSV({ headers, rows }, name ?? this.latestFilename);
  }
}


export default InvoicingManager;