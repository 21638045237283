import React from 'react';
import { InsightHR } from '../../../components/InsightHR';
import { ClientModel } from '../../../models/Client';
import { ClientBasicInsightsContainer } from './Basic';
import { ClientBillableCancellationsInsightsContainer } from './BillableCancellations';
import { ClientBudgetInsightsContainer } from './Budget';
import { ClientLastActiveInsightsContainer } from './LastActive';

export interface ClientInsightsContainerProps {
  client?: ClientModel;
}

export const ClientInsightsContainer: React.FC<ClientInsightsContainerProps> = ({ client }) => {

  return (
    <>
      <div className="container pb-2 pt-4">
        <ClientLastActiveInsightsContainer
          client={client}
        />
        <InsightHR />
        <ClientBasicInsightsContainer
          client={client}
        />

        <InsightHR />
        <ClientBillableCancellationsInsightsContainer
          client={client}
        />

        <InsightHR />
        <ClientBudgetInsightsContainer
          client={client}
        />

      </div>
    </>
  );
};