import Parse from 'parse';
import { AdminUser } from '../../models/AdminUser';

/**
 * Load all admin users
 */
export async function getAdminUsers(): Promise<AdminUser[]> {
  const query = new Parse.Query('Admin');
  query.include('user');
  query.doesNotExist('deletedAt');
  query.limit(100_000);
  const parseModels = await query.find();
  const adminUsers = parseModels.map(model => new AdminUser(model.get('user'), model));
  return adminUsers;
}