import classNames from 'classnames';
import React from 'react';
import { BoxArrowUpRight, ExclamationCircleFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { sessionsPath } from '../../Routes';
import { AustralianState, getAustralianStateAbbreviation } from '../../enums/AustralianState';
import { SupportNeedModelType } from '../../enums/SupportNeedModelType';
import { BrandColors, colorForSupportSession, formatDate, formatDateWithState } from '../../helpers';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SupportNeedModel } from '../../models/SupportNeed';
import { OverlappingAvatars } from '../OverlappingAvatars';
import { NeedByTypeSelector } from '../SessionNeed/NeedByTypeSelector';


type SupportSessionTileProps = |
{
  session: SupportSessionModel;
  isNeedEditable?: false;
  planNeeds?: never;
  alertText?: string;
  selectedNeedType?: never;
  onChangeNeedType?(itemId: string, needType: SupportNeedModelType): never;
} | {
  session: SupportSessionModel;
  isNeedEditable?: true;
  planNeeds: SupportNeedModel[];
  alertText?: string;
  selectedNeedType: SupportNeedModelType | undefined;
  onChangeNeedType(itemId: string, needType: SupportNeedModelType): void;
}

export const SupportSessionTile: React.FC<SupportSessionTileProps> = ({ session, isNeedEditable, planNeeds, alertText, selectedNeedType, onChangeNeedType }) => {
  const history = useHistory();
  const clientState = session.clientSync().australianState() ?? AustralianState.wa;

  function handleChangeNeedType(needType: SupportNeedModelType): void {
    if (session.id() && onChangeNeedType) {
      onChangeNeedType(String(session.id()), needType);
    }
  }

  const isClickable = !isNeedEditable;
  const linkPath = [sessionsPath, session.id()].join('/');

  const renderAlert = () => {
    if (!alertText) {
      return <div
        className="d-flex align-items-center"
        style={{ height: '26px' }}
      />;
    }

    return (
      <div
        className="d-flex align-items-center mb-2"
        style={{
          backgroundColor: BrandColors.red,
          color: 'white',
          fontSize: 12,
          width: 'calc(100% + 24px)',
          height: '26px',
          marginTop: '-12px',
          marginLeft: '-12px',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
      ><ExclamationCircleFill
          className="ml-1 mr-1"
          color="white"
          size={14}
        />{alertText}</div>
    );
  };

  const renderOpenLink = () => {
    if (!isClickable) {
      return null;
    }

    return (
      <div
        style={{
          zIndex: 100,
          position: 'absolute',
          right: 20,
          bottom: 20,
        }}
      >
        <a
          className="link-open-icon"
          onClick={handleClick}
        >
          <BoxArrowUpRight />
        </a>
      </div>
    );
  };

  const handleClick = (event: React.MouseEvent) => {
    const element = event.currentTarget;
    event.stopPropagation();
    event.preventDefault();

    if (!isClickable || element.classList.contains('link-open-icon')) {
      window.open(linkPath, '_blank');
    } else {
      history.push(linkPath);
    }
  };

  return (
    <a
      className={classNames('p-0', {
        'cursor-pointer': isClickable,
        'link-tile': true,
      })}
      id={session.id()}
      href={linkPath}
      rel="noreferrer"
      onClick={handleClick}
    >
      <div className="rounded border m-1 p-2 hover-item-active">
        {renderAlert()}
        <div className="d-flex justify-content-between">
          <div className="d-flex flex-column align-items-start">
            <p className="font-weight-bold mb-0">{`Client: ${session.clientName()}`}</p>
            <p className="font-weight-bold mb-0">{`Worker: ${session.workerName()}`}</p>
            <p className="mb-0">{formatDate(session.bestKnownStartDate(), 'dddd, Do MMMM, YYYY')}</p>
            <p className="mb-0">{[
              formatDateWithState(session.bestKnownStartDate(), 'hh:mm A', clientState),
              formatDateWithState(session.bestKnownEndDate(), 'hh:mm A', clientState),
            ].join(' - ') + `${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} TIME)`}`}</p>

            <div>
              <span
                className="badge badge-pill badge-warning text-uppercase text-white sm-pill"
                style={{ backgroundColor: colorForSupportSession(session) }}
              >{session.statusDescription()}</span>
              {(clientState && clientState !== AustralianState.wa && clientState !== undefined) &&
                <span
                  className="sm-pill badge badge-pill text-white text-uppercase ml-1"
                  style={{ backgroundColor: BrandColors.darkBlue }}
                >{getAustralianStateAbbreviation(clientState ?? AustralianState.wa)}</span>
              }
            </div>
            {isNeedEditable && (
              <div>
                <NeedByTypeSelector
                  className="form-control col-12 mt-2"
                  needs={planNeeds || []}
                  selectedType={selectedNeedType}
                  onChange={handleChangeNeedType}
                />
              </div>
            )}
          </div>
          <div style={{ width: '100px' }}>
            <OverlappingAvatars
              bottomUrl={session.clientPhotoUrl()}
              topUrl={session.workerPhotoUrl()}
              size={56}
            />
          </div>
        </div>
        {renderOpenLink()}
      </div>
    </a>
  );
};