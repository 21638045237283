import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { SupportNeedModelType } from '../../../enums/SupportNeedModelType';
import { sortSessionsAndRequestsByDateDesc } from '../../../helpers/sessionsAndRequests';
import Sessions from '../../../managers/Sessions';
import { ClientModel } from '../../../models/Client';
import { SessionStatus } from '../../../models/Sessions';
import { SupportSessionModel } from '../../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../../models/Sessions/SupportSessionRequest';
import { SupportPlanModel } from '../../../models/SupportPlan';
import { SessionsForNeedView } from '../../../views/Client/SupportPlan/PlanNeedSessions/SessionsForNeed';

export interface SupportPlanNeedSessionsContainerProps {
  client: ClientModel;
  supportPlan: SupportPlanModel;
  needType: SupportNeedModelType;
}

type SessionOrRequest = SupportSessionRequestModel | SupportSessionModel;

export const SupportPlanNeedSessionsContainer: React.FC<SupportPlanNeedSessionsContainerProps> = ({ client, supportPlan, needType }) => {
  // const [requests, setRequests] = useState<SupportSessionRequestModel[] | undefined>(undefined);
  const [billableItems, setBillableItems] = useState<SupportSessionModel[]>([]);
  const [otherItems, setOtherItems] = useState<SessionOrRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const clientId = client?.id();
      if (!clientId) { return; }
      setIsLoading(true);

      const results = await Sessions.getSessionsAndRequestsForClientPlan({
        filter: 'plan_need',
        clientId: client.id(),
        planId: supportPlan.id(),
        needType,
      });

      const billable: SupportSessionModel[] = [];
      const other: SessionOrRequest[] = [];

      if (results) {
        results.sessions.forEach((session) => {
          const sessionModel = new SupportSessionModel(session);

          if ([SessionStatus.completed, SessionStatus.noShow].includes(sessionModel.status())) {
            billable.push(sessionModel);
          } else {
            other.push(sessionModel);
          }
        });

        results.requests.forEach((request) => {
          other.push(new SupportSessionRequestModel(request));
        });
      }

      setBillableItems(billable.sort(sortSessionsAndRequestsByDateDesc));
      setOtherItems(other.sort(sortSessionsAndRequestsByDateDesc));

      setIsLoading(false);
    };

    void fetchData();
  }, [client, supportPlan, needType]);

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {!isLoading &&
        <SessionsForNeedView
          billableItems={billableItems}
          otherItems={otherItems}
        />
      }
    </>
  );
};