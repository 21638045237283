import Parse from 'parse';
import { FundingManagementType } from '../../enums/SupportNeedFundingManagementType';
import sessionsManager, { DashboardParams } from '../../managers/Sessions';
import { SessionStatus } from '../../models/Sessions';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../models/Sessions/SupportSessionRequest';
import { WorkerModel } from '../../models/Worker';
import { downloadCSV } from '../../reports/csv/util';
import { CancellationPolicy } from '../../types/parseConfig';

interface WeekData {
  start: Date;
  end: Date;
  sessions: SupportSessionModel[];
}

interface CancellationLineData {
  cancellationCount: number;
  shortNoticeCount: number;
  notAcceptedCount: number;
}

const reportingWeeks: number = 16;

class WorkerShiftReportManager {
  private latestData: string[][] | undefined = undefined;
  private shortNoticeCancellationPolicies: CancellationPolicy[] | undefined = undefined;

  private static _instance: WorkerShiftReportManager;


  static instance(): WorkerShiftReportManager {
    if (!WorkerShiftReportManager._instance) {
      WorkerShiftReportManager._instance = new WorkerShiftReportManager();
    }
    return WorkerShiftReportManager._instance;
  }

  findLatestCancellationPolicy(beforeDate: Date): CancellationPolicy | null {
    let policy: CancellationPolicy | null = null;

    if (this.shortNoticeCancellationPolicies) {
      this.shortNoticeCancellationPolicies.forEach((cPolicy) => {
        if (new Date(cPolicy.date).getTime() <= beforeDate.getTime()) {
          policy = cPolicy;
        }
      });
    }

    return policy;
  }

  async loadCancellationPolicies(): Promise<void> {
    const config = await Parse.Config.get();
    const policies = config.get('datedCancellationPolicies').map((policy: unknown) => {
      return policy as CancellationPolicy;
    });
    this.shortNoticeCancellationPolicies = policies;
  }

  async prepareReport(workers: WorkerModel[]): Promise<void> {
    const timer = new Date().getTime();
    
    if (!this.shortNoticeCancellationPolicies) {
      await this.loadCancellationPolicies();
    }

    const today = new Date();
    const lastSunday: Date = new Date();
    lastSunday.setDate(today.getDate() - today.getDay());
    lastSunday.setHours(23, 59, 0, 0);

    const firstMonday: Date = new Date();
    firstMonday.setDate(lastSunday.getDate() - (7 * reportingWeeks) + 1);
    firstMonday.setHours(0, 0, 0, 0);

    const params: DashboardParams = {
      filter: 'billable',
      after: firstMonday,
      before: lastSunday,
      managed: [FundingManagementType.Plan, FundingManagementType.Self, FundingManagementType.NDIA],
    };

    const sessionsAndRequests = await sessionsManager.getDashboardSessionsAndRequests(params);
    const unbillableSessionsAndRequests = await sessionsManager.getDashboardSessionsAndRequests({
      ...params,
      filter: 'unbillable',
    });
    
    const sessionResults = sessionsAndRequests.results.sessions.map((session: Parse.Object) => new SupportSessionModel(session));
    const unbillableSessions = unbillableSessionsAndRequests.results.sessions.map((session: Parse.Object) => new SupportSessionModel(session));
    const unbillableRequests = unbillableSessionsAndRequests.results.requests.map((session: Parse.Object) => new SupportSessionRequestModel(session));

    // the start and end dates of the last complete ${reportingWeeks} weeks
    // Also sort all sessions into those start and end dates.
    const weeks: WeekData[] = [...Array(reportingWeeks)].map((_, i) => {
      const start = new Date(firstMonday);
      start.setDate(start.getDate() + i * 7);
      const end = new Date(start);
      end.setDate(end.getDate() + 7);
      end.setHours(0, -1, 0, 0);
      return {
        start,
        end,
        sessions: sessionResults.filter(session => {
          return session.bestKnownStartDate() > start && session.bestKnownEndDate() < end;
        }),
      };
    });

    this.latestData = [[
      'Last name',
      'First name',
      'All cancellations',
      'Short notice cancellations',
      'Sessions not accepted',
      ...weeks.map(week => {
        return week.start.getDate() + '/' + (week.start.getMonth() + 1);
      }), 'Notes']];

    const rows = workers.map(worker => {
      const cancellationLineData = this.getCancellationLineData(worker, unbillableSessions, unbillableRequests);
      const reportLineData = this.getWorkerReportLine(worker, weeks, cancellationLineData);
      return reportLineData;
    });
    const nonEmptyRows = rows.filter(row => row.length > 0);

    this.latestData?.push(...nonEmptyRows);
  }

  getCancellationLineData(
    worker: WorkerModel,
    sessions: SupportSessionModel[],
    requests: SupportSessionRequestModel[],
  ): CancellationLineData {
    let cancellationCount = 0;
    let shortNoticeCount = 0;
    let notAcceptedCount = 0;

    sessions.forEach((session) => {
      if (session.workerId() === worker.id()) {
        if (session.status() === SessionStatus.cancelledByWorker) {
          cancellationCount++;

          // Check short notice cancellation
          const cancelledTime = session.cancelledDate();
          if (cancelledTime) {
            const cancellationPolicy = this.findLatestCancellationPolicy(cancelledTime);

            if (cancellationPolicy) {
              const minutes = cancellationPolicy.cancellation_minutes_full;
              const cancelTimeDiffMs = session.bestKnownStartDate().getTime() - cancelledTime.getTime();
              if ((cancelTimeDiffMs / 60000) <= minutes) {
                shortNoticeCount++;  
              }
            }
          }
        }
      }
    });

    requests.forEach((request) => {
      if (request.workerId() === worker.id()) {
        if (request.realStatus() === SessionStatus.pendingWorkerApproval && request.isNotAccepted()) {          
          notAcceptedCount++;
        }
      }
    });

    return {
      cancellationCount,
      shortNoticeCount,
      notAcceptedCount,
    };
  }

  getWorkerReportLine(worker: WorkerModel, weeks: WeekData[], cancellationData: CancellationLineData): string[] {
    const hoursPerWeek = weeks.map(week => {
      const sessionsForWeek = week.sessions.filter(session => (session.workerId() === worker.id()));
      const hoursForWeek = sessionsForWeek.reduce((sum, current) => sum + current.loggedDuration(), 0);
      return hoursForWeek;
    });

    
    let notes = '';
    let longestSequence = 0;
    let currentSequence = 1;
    let timeAtLongestSequence = 0;
    for (let i = 0; i < reportingWeeks - 1; i++) {
      const current = hoursPerWeek[i];
      if (current === 0) {
        continue;
      }
      const next = hoursPerWeek[i + 1];
      if (current === next) {
        currentSequence += 1;
        if (currentSequence > longestSequence) {
          timeAtLongestSequence = current;
          longestSequence = currentSequence;
        }
      } else {
        if (longestSequence === 0) {
          longestSequence = 1;
        }
        currentSequence = 1;
      }
    }

    if ((longestSequence > 2) && timeAtLongestSequence !== 0) {
      notes = `Worked ${timeAtLongestSequence} hours in ${longestSequence} consecutive weeks`;
      // console.log(notes);
    }

    if (longestSequence === 0) {
      return [];
    }

    return [
      worker.lastName(),
      worker.firstName(),
      String(cancellationData.cancellationCount),
      String(cancellationData.shortNoticeCount),
      String(cancellationData.notAcceptedCount),
      ...hoursPerWeek.map(hpw => {
        const rounded = Math.round(hpw * 100) / 100;
        return rounded.toString();
      }), notes];
  }

  downloadLastReport(name: string): void {
    if (!this.latestData) { return; }
    const [headers, ...rows] = this.latestData;

    downloadCSV({ headers, rows }, name);
  }
}


export default WorkerShiftReportManager;