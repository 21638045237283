export enum SupportNeedModelType {
  Unknown=0,
  // DailyLife=1, // Deprecated - left for historical reasons.
  SocialCommunityRecreation=2,
  SocialSkills,
  LifeSkills,
  Accommodation,
  Connecting,
  Transport,
}

export const SupportNeedModelTypeCases = [SupportNeedModelType.SocialCommunityRecreation,
  SupportNeedModelType.SocialSkills,
  SupportNeedModelType.LifeSkills,
  SupportNeedModelType.Accommodation,
  SupportNeedModelType.Connecting,
  SupportNeedModelType.Transport];


export function getSupportNeedModelTypeDescription(type: SupportNeedModelType): string {
  switch (type) {
    case SupportNeedModelType.Unknown: return 'Unknown';
    // case SupportNeedModelType.DailyLife return 'Assistance with daily life'; // Shouldn't be used.
    case SupportNeedModelType.SocialCommunityRecreation: return 'Social, community and recreation';
    case SupportNeedModelType.SocialSkills: return 'Social skills';
    case SupportNeedModelType.LifeSkills: return 'Life skills';
    case SupportNeedModelType.Accommodation: return 'Accommodation and tenancy';
    case SupportNeedModelType.Connecting: return 'Connecting with a mentor';
    case SupportNeedModelType.Transport: return 'Transport in workers’ cars';
  }
}