import React from 'react';

interface CheckboxProps {
  label: string;
  value: string | number | undefined;
  isSelected: boolean;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

export const Checkbox: React.FC<CheckboxProps>  = ({ label, value, isSelected, onChange }) => {
  return (
    <div className="form-check mb-1">
      <input
        id={label}
        className="form-check-input"
        type="checkbox"
        name={label}
        value={value}
        checked={isSelected}
        onChange={onChange}
      />
      <label
        htmlFor={label}
        className="form-check-label font-weight-normal"
      >{label}</label>
    </div>
  );
};
