import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { BasicInsights, basicInsights } from '../../../../managers/Insights';
import { ClientModel } from '../../../../models/Client';
import { ClientBasicInsightsView } from '../../../../views/Client/Insights/Basic';
import { DEFAULT_DELAY, useCanLoad } from '../../util';


export interface ClientBasicInsightsContainerProps {
  client?: ClientModel;
}

// 5 years either side
const AllTimeStart = moment().subtract(5, 'years').toDate();
const AllTimeEnd = moment().add(5, 'years').toDate();

// Last 30 days
const Last30Start = moment().subtract('30', 'days').toDate();
const Last30End = moment().toDate();

async function getInsights(clientId: string): Promise<BasicInsights[]> {
  return Promise.all([
    basicInsights(clientId, Last30Start, Last30End),
    basicInsights(clientId, AllTimeStart, AllTimeEnd),
  ]);
}

export const ClientBasicInsightsContainer: React.FC<ClientBasicInsightsContainerProps> = ({ client }) => {
  const [allTimeBasicInsights, setAllTimeBasicInsights] = useState<BasicInsights | undefined>(undefined);
  const [last30BasicInsights, setLast30TimeBasicInsights] = useState<BasicInsights | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const canLoad = useCanLoad('insights', DEFAULT_DELAY);

  useEffect(() => {
    if (!canLoad || !client) { return; }
    setIsLoading(true);
    void getInsights(client.id()).then(results => {
      setLast30TimeBasicInsights(results[0]);
      setAllTimeBasicInsights(results[1]);
      setIsLoading(false);
    });
  }, [canLoad, client]);


  return (
    <>
      {isLoading && !(allTimeBasicInsights && last30BasicInsights) &&
        <Loading />
      }

      {(allTimeBasicInsights && last30BasicInsights) &&
        <ClientBasicInsightsView
          allTimeBasicInsights={allTimeBasicInsights}
          last30BasicInsights={last30BasicInsights}
        />
      }
    </>
  );
};