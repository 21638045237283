import _uniq from 'lodash/uniq';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Alert as AlertComponent, Form } from 'react-bootstrap';
import { SortDown, SortUp } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { loginPath } from '../../Routes';
import { default as Session, default as session } from '../../Session';
import { Loading } from '../../components/Loading';
import { ToolBar } from '../../components/Toolbar';
import { MonitoringContainer } from '../../containers/Monitoring';
import { exportMonitoringReport } from '../../containers/Monitoring/exportHelper';
import { AdminType } from '../../enums/AdminType';
import { fromStorage, toStorage } from '../../helpers/sessionAndBillingUtils';
import { CancellationAlertsResponse, EngagementAlertsResponse, PlanExpiryAlertsResponse } from '../../managers/Alerts';
import { AdminUser } from '../../models/AdminUser';
import { ClientModel } from '../../models/Client';
import './Monitoring.css';
import { MonitoringClientTile } from './MonitoringClientTile';

export interface MonitoringLoadingState {
  loading: boolean;
  loadingExpiryAlerts: boolean;
  loadingEngagementAlerts: boolean;
  loadingCancellationAlerts: boolean;
}

interface MonitoringViewProps {
  loadingState: MonitoringLoadingState;
  adminUsers: AdminUser[];
  clients: ClientModel[];
  expiryAlerts: Record<string, PlanExpiryAlertsResponse>;
  engagementAlerts: Record<string, EngagementAlertsResponse>;
  cancellationAlerts: Record<string, CancellationAlertsResponse>;
  loadClients(adminId: string, adminType: AdminType): Promise<void>;
  loadAllClients(): Promise<void>;
  loadUnassignedClients(): Promise<void>;
  loadAlerts(clientIds: string[]): Promise<void>;
}

export enum AlertFilterType {
  planExpiration = 'planExpiration',
  billableCancellations = 'billableCancellations',
  engagement = 'engagement',
}

const MONITORING_SEARCH_KEY = 'suitsme.search.monitoring';

export const Monitoring: React.FC = () => {
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  return <MonitoringContainer />;
};

export const MonitoringView: React.FC<MonitoringViewProps> = ({
  loadingState,
  expiryAlerts,
  engagementAlerts,
  cancellationAlerts,
  adminUsers,
  clients,
  loadClients,
  loadUnassignedClients,
  loadAllClients,
  loadAlerts,
}) => {
  const [filteredItems, setFilteredItems] = useState<ClientModel[]>(clients ?? []);
  const [searchTerm, setSearchTerm] = useState(fromStorage(MONITORING_SEARCH_KEY) || '');
  const [alertTypes, setAlertTypes] = useState<Set<AlertFilterType>>(new Set([
    AlertFilterType.planExpiration,
    AlertFilterType.engagement,
    AlertFilterType.billableCancellations,
  ]));
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState('');
  const [selectedOption, setSelectedOption] = useState('');
  const [sortMode, setSortMode] = useState<'name' | 'expiry' | 'engagement' | 'cancellations'>('name');
  const [sortOrder, setSortOrder] = useState<'ascending' | 'descending'>('ascending');
  const caseManagers = adminUsers.filter(user => {
    return (user.admin?.get('type') === AdminType.caseManager && user.id() !== Session.getUser()?.id());
  });
  const experienceOfficers = adminUsers.filter(user => {
    return (user.admin?.get('type') === AdminType.experienceOfficer && user.id() !== Session.getUser()?.id());
  });
  const sessionUser = session.getUser();

  const currentUser = useMemo(() => {
    return {
      currentUserType: sessionUser?.admin?.get('type') ?? AdminType.unknown,
      currentUserId: sessionUser?.admin?.id,
      isGMUser: sessionUser?.admin?.get('type') === AdminType.manager,
    };
  }, [sessionUser?.admin?.id]);

  /**
   * Effect to filter clients when the search filter input changes
   */
  useEffect(() => {
    if (!adminUsers) { return; }
    toStorage(searchTerm, MONITORING_SEARCH_KEY);

    let filtered = clients.filter(client => {
      const clientName = client.fullName();
      const searchMatch = clientName.toLowerCase().includes(searchTerm.toLowerCase()) || client.id() === searchTerm;
      return searchMatch;
    });

    // Put items in here that should always be at the end regarldess of sort order
    let filteredEndItems: ClientModel[] = [];

    // Sort items here
    if (sortMode === 'name') {
      filtered = filtered.sort((client1, client2) => (client1.lastName() ?? '').localeCompare(client2.lastName() ?? ''));
    } else if (sortMode === 'expiry') {
      // Sort based on expiry alert dates
      const filteredWithExpiry = filtered.filter(item => expiryAlerts[item.id()] && expiryAlerts[item.id()].planExpiryDate);
      filteredEndItems = filtered.filter(item => !expiryAlerts[item.id()] || !expiryAlerts[item.id()].planExpiryDate);

      filtered = filteredWithExpiry.sort((client1, client2) => {
        if (!expiryAlerts[client1.id()] || !expiryAlerts[client2.id()]) {
          return 0;
        }

        const d1 = new Date(expiryAlerts[client1.id()].planExpiryDate).getTime();
        const d2 = new Date(expiryAlerts[client2.id()].planExpiryDate).getTime();
        return d1 < d2 ? -1 : 1;
      });

      // Sort filtered end by client surname
      filteredEndItems = filteredEndItems.sort((client1, client2) => (client1.lastName() ?? '').localeCompare(client2.lastName() ?? ''));
    } else if (sortMode === 'engagement') {
      // Sort based on last engagement dates
      filtered = filtered.sort((client1, client2) => {
        if (!engagementAlerts[client1.id()] || !engagementAlerts[client2.id()]) {
          return 0;
        }

        let client1Date = engagementAlerts[client1.id()].lastEngagementDate;
        let client2Date = engagementAlerts[client2.id()].lastEngagementDate;

        if (!client1Date) {
          client1Date = moment(new Date()).subtract('60', 'days').toISOString();
        }
        if (!client2Date) {
          client2Date = moment(new Date()).subtract('60', 'days').toISOString();
        }

        const d1 = new Date(client1Date).getTime();
        const d2 = new Date(client2Date).getTime();
        return d1 < d2 ? -1 : 1;
      });
    } else if (sortMode === 'cancellations') {
      // Sort based on billable cancellation dates
      filtered = filtered.sort((client1, client2) => {
        if (!cancellationAlerts[client1.id()] || !cancellationAlerts[client2.id()]) {
          return 0;
        }

        const d1 = cancellationAlerts[client1.id()].cancellationsCount;
        const d2 = cancellationAlerts[client2.id()].cancellationsCount;
        return d1 < d2 ? -1 : 1;
      });
    }

    if (sortOrder === 'descending') {
      setFilteredItems(filtered.reverse());
    }
    
    filtered = filtered.concat(filteredEndItems);

    setFilteredItems(filtered);
  }, [searchTerm, clients, selectedUser, expiryAlerts, engagementAlerts, cancellationAlerts, loadingState, sortMode, sortOrder]);

  /**
   * Handler for selecting an admin user from the management dropdown
   */
  function handleSelectUser(event: React.ChangeEvent<HTMLSelectElement> | null, value: string): void {
    if (event) {
      event.preventDefault();
    }
    setSelectedUser(value);

    if (value === 'all') {
      setSelectedOption('All clients');
      void loadAllClients();
      return;
    } else if (value === 'unassigned') {
      setSelectedOption('Unassigned clients');
      void loadUnassignedClients();
      return;
    } else if (value === 'my-clients') {
      setSelectedOption('My clients');
      if (currentUser.currentUserId) {
        void loadClients(currentUser.currentUserId, currentUser.currentUserType);
      }
      return;
    }

    const admin = adminUsers.find(user => user.id() === value);
    setSelectedOption(admin?.fullName() || 'Unknown user');

    // Start loading clients for the admin
    void loadClients(admin?.id() ?? '', admin?.type() as AdminType);
  }

  function handleSetSortMode(mode: string) {
    if (mode === 'expiry' || mode === 'name' || mode === 'engagement' || mode === 'cancellations') {
      setSortMode(mode);
    }
  }

  function toggleSortOrder() {
    setSortOrder(sortOrder === 'ascending' ? 'descending' : 'ascending');
  }

  useEffect(() => {
    if (clients) {
      void loadAlerts(clients.map((client) => client.id()));
    }
  }, [clients]);

  useEffect(() => {
    if (!currentUser.isGMUser && session.getUser() && adminUsers.length > 0 && !loadingState.loading && selectedUser === '' && currentUser.currentUserId) {
      handleSelectUser(null, 'my-clients');
    }
  }, [loadingState, adminUsers, selectedUser, currentUser]);
  
  function getAlertCounts() {
    const clientIdsWithAlerts: string[] = [];
  
    Object.keys(expiryAlerts).forEach(key => {
      if (expiryAlerts[key].planExpiryType !== 'success') {
        clientIdsWithAlerts.push(key);
      }
    });

    Object.keys(engagementAlerts).forEach(key => {
      if (engagementAlerts[key].lastEngagementType !== 'success') {
        clientIdsWithAlerts.push(key);
      }
    });

    Object.keys(cancellationAlerts).forEach(key => {
      if (cancellationAlerts[key].cancellationsType !== 'success') {
        clientIdsWithAlerts.push(key);
      }
    });

    const numClients = _uniq(clientIdsWithAlerts).length;
    return `${clients.length} total clients, ${numClients} with alerts`;
  }

  // Check for all alert types that might be loading
  const isLoadingAlerts = loadingState.loadingExpiryAlerts || loadingState.loadingEngagementAlerts || loadingState.loadingCancellationAlerts;

  function doExport(): void {
    exportMonitoringReport(filteredItems, {
      expiryAlerts,
      engagementAlerts,
      cancellationAlerts,
    });
  }

  function canExport(): boolean {
    if (clients.length === 0 || loadingState.loading || isLoadingAlerts) {
      return false;
    }
    return true;
  }

  return (
    <div className="mx-4">
      <ToolBar
        title="Monitoring"
        top={
          <>
            <div className="form-inline col-1">
              <button
                type="button"
                className="btn btn-primary m-1"
                disabled={!canExport()}
                onClick={async () => doExport()}
              >
                <div className="d-flex">
                  <span>Export</span>
                </div>
              </button>
            </div>
            <div className="form-inline col-4">
              <input
                id="form-search-filter"
                className="form-control w-100"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </>
        }
        filters={
          <div className="mx-2 pb-2">
            <Form className="row d-flex align-items-center">
              <div className="col-1 text-white">
                Management
              </div>
              <div className="col-3">
                <select
                  id="manager"
                  className="form-control"
                  value={selectedUser}
                  onChange={event => handleSelectUser(event, event.target.value)}
                  disabled={loadingState.loading}
                >
                  {currentUser.isGMUser && <option value="">Select...</option>}
                  {!currentUser.isGMUser && <option value="my-clients">My clients</option>}
                  <optgroup label="Case managers">
                    {caseManagers.map(admin => {
                      return <option
                        key={admin.id()}
                        value={admin.id()}
                      >{admin.fullName()}</option>;
                    })}
                  </optgroup>
                  <optgroup label="Experience officers">
                    {experienceOfficers.map(admin => {
                      return <option
                        key={admin.id()}
                        value={admin.id()}
                      >{admin.fullName()}</option>;
                    })}
                  </optgroup>
                  <option value="all">All clients</option>
                  <option value="unassigned">Unassigned clients</option>
                </select>
              </div>
              <div className="col-1 text-white text-right">
                Sort
              </div>
              <div className="col-3 d-flex flex-row">
                <select
                  id="sortMode"
                  className="form-control"
                  value={sortMode}
                  onChange={event => handleSetSortMode(event.target.value)}
                  disabled={loadingState.loading || filteredItems.length === 0}
                >
                  <option
                    key="name"
                    value="name"
                  >Name</option>;
                  <option
                    key="expiry"
                    value="expiry"
                  >Support plan expiry</option>;
                  <option
                    key="engagement"
                    value="engagement"
                  >Last engaged</option>;
                  <option
                    key="cancellations"
                    value="cancellations"
                  >Billable cancellations</option>;
                </select>
                <button
                  type="button"
                  className="btn btn-primary ml-2 mr-2"
                  style={{ maxWidth: 50 }}
                  onClick={toggleSortOrder}
                  disabled={loadingState.loading || filteredItems.length === 0}
                >
                  <span>{sortOrder === 'ascending' ? <SortDown size="24" /> : <SortUp size="24" />}</span>
                </button>
              </div>
            </Form>
          </div>
        }
      />

      {loadingState.loading && <Loading />}

      {searchTerm !== '' &&
        <div
          className="row mt-2"
        >
          <div className="col-12 text-info text-center">{`Showing clients for "${searchTerm}"`}</div>
        </div>
      }

      {clients && clients.length > 0 && !loadingState.loading &&
        <div className="row py-2 mt-2 d-flex flex-row justify-content-center">
          <AlertComponent
            variant="info"
            className="mb-0"
          >
            {isLoadingAlerts && (
              <div className="d-flex flex-direction-row">
                <div style={{ transform: 'scale(0.5)', height: 0, marginTop: 3 }}>
                  <div className="spinner-border text-info mb-0 pb-0" />
                </div>
                <div>Loading alerts...</div>
              </div>
            )}
            {!loadingState.loading && !isLoadingAlerts && getAlertCounts()}
          </AlertComponent>
        </div>
      }

      {filteredItems && !loadingState.loading &&
        <div className="row py-2 px-1">
          <div className="tile-grid">
            {filteredItems.map(item => <div
              key={item.id()}
            >
              <MonitoringClientTile
                client={item}
                alerts={{
                  expiry: expiryAlerts[item.id()],
                  engagement: engagementAlerts[item.id()],
                  cancellations: cancellationAlerts[item.id()],
                }}
                alertTypeFilters={alertTypes}
                loadingState={loadingState}
              />
            </div>)}
          </div>
        </div>
      }

      {!loadingState.loading && clients.length === 0 &&
        <div
          className="alert alert-warning mt-2"
          role="alert"
        >
          There are no clients found with the current filters applied.
        </div>
      }
    </div>
  );
};