import { StyleSheet, View } from '@react-pdf/renderer';
import React from 'react';

const styles = StyleSheet.create({
  table: {
    // @ts-expect-error - Not sure I want to change this
    display: 'table',
    width: 'auto',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: '#E6E6E6',
  },
  tableCol: {
    borderRightStyle: 'solid',
    borderRightColor: 'white',
    borderRightWidth: 2,
  },
  tableCell: {
    marginTop: 4,
    marginBottom: 4,
    marginLeft: 4,
  },
});

export const Table: React.FC = (props) => {
  return <View style={styles.table}>{props.children}</View>;
};

interface TableRowProps {
  even: boolean;
}

export const TableRow: React.FC<TableRowProps> = (props) => {
  return <View
    style={{ ...styles.tableRow, backgroundColor: props.even ? '#F7F7F7' : styles.tableRow.backgroundColor }}
  >{props.children}</View>;
};

interface TableColProps {
  cols: number;
  isHeader?: boolean;
}
export const TableCol: React.FC<TableColProps> = (props) => {
  const additionalStyles = props.isHeader ? styles.tableHeader : {};
  const percentWidth = (((100 / 12) * props.cols) / 100).toLocaleString(undefined, { maximumFractionDigits: 0, style: 'percent' });
  return <View style={{ ...styles.tableCol, ...additionalStyles, width: percentWidth }}>{props.children}</View>;
};

export const TableCell: React.FC = (props) => {
  return <View
    style={styles.tableCell}
  >{props.children}</View>;
};
