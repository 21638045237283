import memoizee from 'memoizee';
import Parse from 'parse';
import { APIVersion } from '../../enums/APIVersion';

export interface NeedBudgetSummaryResult {
  /* Starting budget in cents*/
  starting: number;
  /* Booked budget in cents*/
  booked: number;
  /* Spent budget in cents*/
  spent: number;
  /* Remaining budget in cents*/
  remaining: number;
  /* Total of all budget adjustments in cents */
  adjustments: number;
}


export interface NeedBudgetTransportSummaryResult {
  /* Starting budget in cents*/
  starting: number;
  /* Booked budget in cents*/
  spent: number;
  /* Remaining budget in cents*/
  remaining: number;
  /* Remaining kms in kms*/
  remainingKm: number;
  /* Total of all budget adjustments in cents */
  adjustments: number;
}

export interface UnallocatedCost {
  spent: number;
  booked: number;
  itemAmounts: Record<string, number>;
}

export interface NeedSummaryResult {
  needId: string;
  supportType: number;
  details: null | NeedBudgetTransportSummaryResult | NeedBudgetSummaryResult;
}

export interface ClientBudgetSummaryResult {
  needSummaries: NeedSummaryResult[];
  unallocatedCost: UnallocatedCost;
}

async function _GetClientNeedBudgetSummaries(clientId: string, version: APIVersion, planId?: string):Promise<ClientBudgetSummaryResult> {
  let cloudFn = 'pricing-clientNeedBudgetSummary';

  switch (version) {
    case APIVersion.three:
      cloudFn = 'pricing-clientNeedBudgetSummaryV3';
      break;
    case APIVersion.two:
      cloudFn = 'pricing-clientNeedBudgetSummaryV2';
      break;
    case APIVersion.one:
    default:
      cloudFn = 'pricing-clientNeedBudgetSummary';
      break;
  }

  const result = await Parse.Cloud.run(cloudFn, { clientId, planId });
  let response: ClientBudgetSummaryResult = {
    needSummaries: [],
    unallocatedCost: {
      spent: -1,
      booked: -1,
      itemAmounts: {},
    },
  };

  if (typeof result === 'object' && 'unallocatedCost' in result) {
    response = result;
  } else {
    response.needSummaries = result;
  }

  return response;
}

export const GetClientNeedBudgetSummaries = memoizee(_GetClientNeedBudgetSummaries);
