import React from 'react';
import { CaretLeftFill } from 'react-bootstrap-icons';
import { Link, useHistory } from 'react-router-dom';
import { SupportPlanNeedSessionsContainer } from '../../../../containers/Client/SupportPlanNeedSessions';
import { UnallocatedSessionsContainer } from '../../../../containers/Client/UnallocatedSessions';
import { getSupportNeedModelTypeDescription, SupportNeedModelType } from '../../../../enums/SupportNeedModelType';
import { UnallocatedCost } from '../../../../managers/ClientNeedBudgets';
import { SupportNeedState } from '../../../../managers/SupportNeeds';
import { ClientModel } from '../../../../models/Client';
import { SupportPlanModel } from '../../../../models/SupportPlan';
import { useSearchParams } from '../../../Hooks/useSearchParams';

export interface PlanNeedSessionsViewProps {
  client: ClientModel;
  supportPlan: SupportPlanModel | undefined;
  supportNeeds: SupportNeedState[];
  unallocatedCost?: UnallocatedCost;
  hideBackButton?: boolean;
  backButtonUrl?: string;
  onUpdated(): void;
}

export const PlanNeedSessionsView: React.FC<PlanNeedSessionsViewProps> = ({
  client, supportPlan, supportNeeds, unallocatedCost, hideBackButton, backButtonUrl, onUpdated,
}) =>  {
  const history = useHistory();
  const needIdParam = useSearchParams('needId');
  const unallocatedParam = useSearchParams('unallocated');
  let isUnallocatedView = false;
  let pageTitle = '';
  let needType: SupportNeedModelType = SupportNeedModelType.Unknown;
  let unallocatedCostData: UnallocatedCost = { spent: 0, booked: 0, itemAmounts: {} };

  if (unallocatedParam) {
    pageTitle = 'Unallocated sessions';
    isUnallocatedView = true;
    if (unallocatedCost) {
      unallocatedCostData = unallocatedCost;
    }
  } else if (needIdParam) {
    const need = supportNeeds.find(need => need.id === needIdParam);
    if (need) {
      needType = need.type;
      pageTitle = `${getSupportNeedModelTypeDescription(need.type)} (${need.id})`;
    }
  }

  function handleCancelUnallocated(): void {
    history.goBack();
  }

  return (
    <section className="pb-2 pt-4">
      <h4 className="col-12 text-center">
        {!hideBackButton && backButtonUrl && (
          <div style={{ position: 'absolute' }}>
            <Link
              to={backButtonUrl}
              style={{ verticalAlign: 'middle' }}
            >
              <CaretLeftFill style={{ transform: 'scaleX(1.3) translateY(-1px)' }} />
              Back
            </Link>
          </div>
        )}
        {pageTitle}
      </h4>
      <hr />
      <div className="col-12">
        {
          isUnallocatedView && supportPlan && <UnallocatedSessionsContainer
            client={client}
            supportPlan={supportPlan}
            unallocatedCost={unallocatedCostData}
            onCancel={handleCancelUnallocated}
            onUpdated={onUpdated}
          />
        }
        {
          !isUnallocatedView && supportPlan && <SupportPlanNeedSessionsContainer
            client={client}
            supportPlan={supportPlan}
            needType={needType}
          />
        }
      </div>
    </section>
  );
};
