import React, { Component } from 'react';
import './App.css';
import { isProd } from './helpers';
import Routes from './Routes';

export default class App extends Component {
  componentDidMount() {
    const title = 'Suitsme Admin';
    document.title = isProd() ? title : `${title} (staging)`;
  }

  render(): React.ReactNode {
    return <Routes />;
  }
}
