
import React, { useState } from 'react';
import { X } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { AvailabilityOverrideState } from '../../../../managers/WorkerAvailability';


interface AvailabilityOverrideProps {
  override: AvailabilityOverrideState;
  onEffectiveFromChanged:(date: Date) => void;
  onEffectiveUntilChanged:(date: Date) => void;
  onDelete:() => void;
}

export const AvailabilityOverride: React.FC<AvailabilityOverrideProps>  = ({ override, onEffectiveFromChanged, onEffectiveUntilChanged, onDelete }) => {
  const [effectiveFrom, setEffectiveFrom] = useState(override.effectiveFrom);
  const [effectiveUntil, setEffectiveUntil] = useState(override.effectiveUntil);
  
  return (
    <div className="row mb-1">
      <h5 className="col-2">{override.isAvailable ? 'Available' : 'Unavailable'}</h5>
      <label className="col-1 col-form-label text-right">From</label>
      <div className="col-2 form-control border">
        <DatePicker
          selected={effectiveFrom}
          className="border-0 w-100 text-secondary"
          onChange={(date: Date) => {
            if (date) {
              setEffectiveFrom(date);
            }
          }}
          onCalendarClose={() => {
            if (effectiveFrom) {
              onEffectiveFromChanged(effectiveFrom);
            }
          }}
          showYearDropdown
          dateFormat="d/M/yyyy, HH:mm"
          showTimeInput
        />
      </div>
      <label className="col-1 col-form-label text-right">To</label>
      <div className="col-2 form-control border">
        <DatePicker
          className="border-0 w-100 text-secondary"
          selected={effectiveUntil}
          minDate={effectiveFrom}
          onChange={(date: Date) => {
            if (date) {
              setEffectiveUntil(date);
            }
          }}
          onCalendarClose={() => {
            if (effectiveUntil) {
              onEffectiveUntilChanged(effectiveUntil);
            }
          }}
          dateFormat="d/M/yyyy, HH:mm"
          showTimeInput
        />
      </div>
      <X
        className="col-1 text-danger mt-1"
        size={24}
        onClick={() => onDelete()}
      />
    </div>
  );
};
