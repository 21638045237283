import classNames from 'classnames';
import React, { useState } from 'react';

interface BulkAgreementsViewProps {
  onRequireNewAgreement: (date: Date) => Promise<void>;
  onChangeMessage:(message: string | undefined) => Promise<void>;

  disableInteraction: boolean;
}

export const BulkAgreementsView: React.FC<BulkAgreementsViewProps> = ({ onRequireNewAgreement, onChangeMessage, disableInteraction }) => {
  const [message, setMessage] = useState<string | undefined>();

  return (
    <div className="mt-4">
      <h4>Require new service agreement from all clients</h4>

      <form>
        <label
          htmlFor="title"
          className="col-12 col-form-label pl-0"
        >Custom agreement message</label>
        <textarea
          id="title"
          className="col-4 form-control mpb-2"
          rows={7}
          value={message || ''}
          onChange={e => setMessage(e.target.value)}
        />
      </form>

      <div className="row flex-row-reverse mt-1 col-4 mx-0 px-0">
        <button
          className={classNames('btn btn-danger msg-btn', { 'disabled': !message || disableInteraction })}
          onClick={() =>  {
            if (window.confirm('Warning: This will be applied to all clients, and will override any other custom agreement messages that may have been set for clients. Do you wish to continue?')) {
              setMessage(undefined);
              void onChangeMessage(message);
            }
          }}
          disabled={!message || disableInteraction}
        > Remove </button>
        <button
          className={classNames('btn btn-primary mr-2 msg-btn', { 'disabled': !message || disableInteraction })}
          onClick={() =>   {
            if (window.confirm('Warning: This will be applied to all clients, and will override any other custom agreement messages that may have been set for clients. Do you wish to continue?')) {
              void onChangeMessage(message);
            }
          }}
          disabled={!message || disableInteraction}
        > Save </button>
      </div>

      <button
        className={classNames('mt-4 btn btn-danger', { 'disabled': disableInteraction })}
        onClick={() => {
          if (window.confirm('Warning: This will notify all clients, and will override any other custom agreement requests that may have been set for clients. Do you wish to continue?')) {
            const date = new Date();
            void onRequireNewAgreement(date);
          }
        }}
        disabled={disableInteraction}
      > Require new service agreement </button>
    </div>
  );
};