import React, { useEffect, useState } from 'react';
import { ToastManager } from '../../Modals/Toasts/manager';
import { Loading } from '../../components/Loading';
import { SupportSession } from '../../components/SupportSession';
import { SupportSessionCancel } from '../../components/SupportSessionCancel';
import { getNameOfUser } from '../../helpers/getNameOfUser';
import useParseConfig from '../../hooks/useParseConfig';
import { PredictedSessionCost, SessionCost, getPredictedCost, getSessionCost } from '../../managers/SessionCost';
import sessionsManager, { UpdateSession, UpdateSessionsCancellation } from '../../managers/Sessions';
import { GetPlanForSession, GetPlansForClient, findCurrentSupportPlan } from '../../managers/SupportPlans';
import { GoalModel } from '../../models/Goal';
import { SessionStatus } from '../../models/Sessions';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SupportNeedModel } from '../../models/SupportNeed';
import { SupportPlanModel } from '../../models/SupportPlan';
import { Postcode } from '../../types/parseConfig';
import { useIsMounted } from '../../views/Hooks/useIsMounted';

interface SessionContainerProps {
  id: string;
}
export const SessionContainer:React.FC<SessionContainerProps> = ({ id }) => {
  const [session, setSession] = useState<SupportSessionModel | undefined>();

  const [cost, setCost] = useState<SessionCost>();
  const [predictedCost, setPredictedCost] = useState<PredictedSessionCost>();
  const [cancelledCost, setCancelledCost] = useState<SessionCost>();

  const [plans, setPlans] = useState<SupportPlanModel[] | undefined>();
  const [plan, setPlan] = useState<SupportPlanModel | undefined>();
  const [need, setNeed] = useState<SupportNeedModel | undefined>();
  const [goal, setGoal] = useState<GoalModel | undefined>();
  const [createdByName, setCreatedByName] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isLoadingPricing, setIsLoadingPricing] = useState<boolean>(false);

  const isMounted = useIsMounted();
  const { isConfigLoaded, config, loadConfig } = useParseConfig();

  const [isShowCancelState, setIsShowCancelState] = useState(false);


  const getCosts = async (session: SupportSessionModel, customPostcode?: string | undefined, customSuburb?: string | undefined) => {
    if (session.status() === SessionStatus.completed) {
      const sessionCost = await getSessionCost(id, customPostcode, customSuburb);
      if (isMounted) {
        console.log(`[debug] completed session cost`, sessionCost);
        setCost(sessionCost);
      }
    }

    if (session.status() === SessionStatus.confirmed) {
      const sessionPredictedCost = await getPredictedCost(id, customPostcode, customSuburb);
      if (isMounted) {
        console.log(`[debug] predicted session cost`, sessionPredictedCost);
        setPredictedCost(sessionPredictedCost);
      }
    }

    if (session.isCancelledOrDeclined() || session.isNotAccepted()) {
      const sessionCancelledCost = await getSessionCost(id, customPostcode, customSuburb);
      if (isMounted) {
        console.log(`[debug] cancelled session cost`, sessionCancelledCost);
        setCancelledCost(sessionCancelledCost);
        setCost(sessionCancelledCost);
      }
    }
  };

  useEffect(() => {
    const getData = async () => {
      // Load the PFConfig
      loadConfig();
      
      const session = await sessionsManager.getSession(id);
      if (!session) { return; }

      await getCosts(session);

      const plansResult = await GetPlansForClient(session.clientId());
      const goal = await session.goal();

      console.log('[debug] Loading name of user from session ID: ', session.id());
      console.log(`[debug] msg`, session);
      const createdByNameResult = await getNameOfUser(session.requestSync()?.createdBy());

      const activePlanForSession = findCurrentSupportPlan(plansResult, session.bestKnownStartDate());
      const activePlanNeeds = await activePlanForSession.supportNeeds();
      const needType = session.getNeedType();

      let needModel: SupportNeedModel | undefined = undefined;
      activePlanNeeds.forEach(need => {
        if (need.type() === needType) {
          needModel = need;
        }
      });

      if (isMounted) {
        setSession(session);
        setPlan(activePlanForSession);
        setNeed(needModel);
        setPlans(plansResult);
        setGoal(goal);
        setCreatedByName(createdByNameResult);
      }
    };

    void getData().then(() => {
      if (isMounted) {
        setIsLoading(false);
      }
    });

  }, [id, isMounted]);

  return (
    <>
      {isLoading &&
        <Loading />
      }

      {/* Renders the SupportSession component view, when not in cancel mode */}
      {(!isLoading && session && plans && plan && !isShowCancelState)  &&
        <SupportSession
          session={session}
          costs={{ predicted: predictedCost, actual: cost || cancelledCost }}
          plans={plans}
          plan={plan}
          need={need}
          goal={goal}
          disableInteraction={isSaving || isLoadingPricing}
          createdByUserName={createdByName}
          postcodes={config?.postcodes ?? []}
          isLoadingPricing={isLoadingPricing}
          onSave={async state => {
            setIsSaving(true);
            const updated = await UpdateSession(session, state);
            const [newPlan, need] = await Promise.all([GetPlanForSession(id), session.supportNeed()]);
            setPlan(newPlan);
            setNeed(need);
            await getCosts(session);

            ToastManager.shared().show('Session updated.');
            setIsSaving(false);
            return updated;
          }}
          onSwitchToCancelState={() => setIsShowCancelState(true)}
          onChangePostcode={async (postcode: Postcode) => {
            setIsLoadingPricing(true);
            await getCosts(session, postcode.postcode, postcode.suburb);
            setIsLoadingPricing(false);
          }}
        />
      }

      {/* Renders the SupportSessionCancel component view, when in cancel mode */}
      {(!isLoading && session && plans && plan && isShowCancelState)  &&
        <SupportSessionCancel
          session={session}
          disableInteraction={isSaving}
          createdByUserName={createdByName}
          onSaveCancellation={async (
            session: SupportSessionModel,
            relatedSessionIds: string[],
            newStatus: SessionStatus,
            reason: string,
            cancelledDate: Date | undefined,
          ) => {
            setIsSaving(true);
            const updated = await UpdateSessionsCancellation(session, relatedSessionIds, newStatus, reason, cancelledDate);
            ToastManager.shared().show('Session(s) cancelled.');
            await getCosts(updated);
            setIsSaving(false);
            return updated;
          }}
          onGoBack={() => setIsShowCancelState(false)}
        />
      }
    </>
  );
};
