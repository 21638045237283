import classNames from 'classnames';
import moment from 'moment';
import React, { useContext } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { AlertContainer } from '../../../../containers/Alert';
import { ClientContext } from '../../../../contexts/client';
import { AlertMuteAffectedUserType, AlertTypes } from '../../../../managers/Alerts';
import { InsightTitle } from '../Common';

export interface ClientLastActiveInsightsViewProps  {
  date: Date;
}

function isInactive14(date: Date) {
  const momentDate = moment(date);
  return momentDate.isBefore(moment().subtract(14, 'days'));
}

function isInactive7(date: Date) {
  const momentDate = moment(date);
  return momentDate.isBefore(moment().subtract(7, 'days'));
}

export const ClientLastActiveInsightsView: React.FC<ClientLastActiveInsightsViewProps> = (props) => {
  const clientId = useContext(ClientContext);
  return (
    <>
      <div
        className="d-flex flex-row justify-content-center"
      >
        <InsightTitle title="Last active" />
        <OverlayTrigger
          placement="right"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="tooltip-la">A client is considered active when they request or receive support</Tooltip>}
        >
          <span>
            <QuestionCircleFill
              className="mt-2 ml-1"
              size={15}
            />
          </span>
        </OverlayTrigger>
      </div>
      <div className="d-flex flex-row justify-content-center">
        <div className={classNames('text-center', {
          'text-warning': isInactive7(props.date) && !isInactive14(props.date),
          'text-danger': isInactive14(props.date),
        })}
        >{moment(props.date).format('LLLL')}</div>
        <AlertContainer
          userType={AlertMuteAffectedUserType.client}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userId={clientId!}
          alertTypes={[AlertTypes.clientInactive7, AlertTypes.clientInactive14]}
          subscribe={false}
        />
      </div>
    </>
  );
};
