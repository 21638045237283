import moment from 'moment';
import Parse from 'parse';
import session, { SessionListener } from '../Session';
import { ContactState } from '../containers/Contacts';
import { UserStatus } from '../enums/UserStatus';
import { convertDateToZone } from '../helpers';
import { AdminUser } from '../models/AdminUser';
import { ClientDetailsSave, ClientModel, ClientSupportPlanSaveState, ClientSupportPlanSaveStateV3 } from '../models/Client';
import { SafetyPlanModel, SafetyPlanState } from '../models/SafetyPlan';
import { SupportPlanModel } from '../models/SupportPlan';
import { ClientGoalViewState } from '../views/Client/Goals';
import { ParseResult } from './Sessions/queries';

export interface ClientManagerListener {
  onClientsLoaded(clients: ClientModel[]): void;
}

class ClientManager implements SessionListener {
  private static _instance: ClientManager;
  private clients: ClientModel[] | undefined = undefined;
  private unassignedClients: ClientModel[] | undefined = undefined;
  private listeners = new Set<ClientManagerListener>();
  private clientCache: {[id: string]: ParseResult} = {};

  private constructor() {
    session.registerListener(this);
    if (session.isUserLoggedIn()) {
      // this.fetchAll();
    }
  }

  static instance(): ClientManager {
    if (!ClientManager._instance) {
      ClientManager._instance = new ClientManager();
    }
    return ClientManager._instance;
  }

  // Implementation of SessionListener
  onUserChanged(user: AdminUser | undefined): void {
    if (user) {
      this.fetchAll();
    } else {
      this.clients = [];
    }
  }

  getClients(): ClientModel[] | undefined {
    if (!this.clients) {
      this.fetchAll();
    }
    return this.clients;
  }

  async getClientsAsync(applyActiveFilter = false): Promise<ClientModel[]> {
    if (this.clients) { return this.clients; }
    const query = new Parse.Query(Parse.Object.extend('Client'));
    query.limit(10_000);
    query.include('user');
    query.notEqualTo('isTest', true);

    if (applyActiveFilter) {
      query.containedIn('status', [
        UserStatus.Active,
        UserStatus.PendingReapproval,
        UserStatus.Blocked,
      ]);
    }

    const clientParseObjects = await query.find();
    const clients = clientParseObjects.map((client: Parse.Object) => new ClientModel(client));
    this.clients = clients;
    return clients;
  }

  async getUnassignedClientsAsync(applyActiveFilter = false): Promise<ClientModel[]> {
    if (this.unassignedClients) { return this.unassignedClients; }
    const query = new Parse.Query(Parse.Object.extend('Client'));
    query.limit(100_000);
    query.include('user');
    query.include('caseManager');
    query.include('experienceOfficer');
    query.notEqualTo('isTest', true);

    if (applyActiveFilter) {
      query.containedIn('status', [
        UserStatus.Active,
        UserStatus.PendingReapproval,
        UserStatus.Blocked,
      ]);
    }

    const clientParseObjects = await query.find();
    let clients = clientParseObjects.map((client: Parse.Object) => new ClientModel(client));

    // Filter out any clients which do NOT have a deleted case manager,
    // because deleted case managers should show unassigned for clients.
    clients = clients.filter((client) => {
      if (client.caseManagerDetailsSync()?.id === '' || client.caseManagerDetailsSync().deletedAt !== undefined) {
        return true;
      }
      return false;
    });
    
    this.unassignedClients = clients;
    return clients;
  }

  async getClientWithCaseMgrExpOfficerAsync(clientId: string): Promise<ClientModel | undefined> {
    const query = new Parse.Query(Parse.Object.extend('Client'));
    query.equalTo('objectId', clientId);
    query.limit(1);
    query.include('caseManager');
    query.include('experienceOfficer');
    const result = await query.first();
    if (result) {
      const clientModel = new ClientModel(result);
      return clientModel;
    }
    return undefined;
  }

  async getClientsForCaseManagerId(caseManagerId: string): Promise<ClientModel[]> {
    const query = new Parse.Query(Parse.Object.extend('Client'));
    const cmQuery = new Parse.Query(Parse.Object.extend('Admin'));
    cmQuery.equalTo('objectId', caseManagerId);
    cmQuery.limit(1);
    query.containedIn('status', [
      UserStatus.Active,
      UserStatus.PendingReapproval,
      UserStatus.Blocked,
    ]);
    query.matchesQuery('caseManager', cmQuery);
    query.limit(100_000);
    const result = await query.find();
    return result.map(clientRaw => new ClientModel(clientRaw));
  }

  async getClientsForExperienceOfficerId(experienceOfficerId: string): Promise<ClientModel[]> {
    const query = new Parse.Query(Parse.Object.extend('Client'));
    const eoQuery = new Parse.Query(Parse.Object.extend('Admin'));
    eoQuery.equalTo('objectId', experienceOfficerId);
    eoQuery.limit(1);
    query.containedIn('status', [
      UserStatus.Active,
      UserStatus.PendingReapproval,
      UserStatus.Blocked,
    ]);
    query.matchesQuery('experienceOfficer', eoQuery);
    query.limit(100_000);
    const result = await query.find();
    return result.map(clientRaw => new ClientModel(clientRaw));
  }

  async saveClientDetails(client: ClientModel, saveState: ClientDetailsSave): Promise<ClientModel> {
    const updatedClient = await client.saveDetails(saveState);

    const clients = this.clients;
    if (clients) {
      if (!clients.some(cli => cli.object.id === client.object.id)) {
        clients.push(updatedClient);
      }
    }

    return updatedClient;
  }

  async saveClientSafetyPlan(client: ClientModel,  saveState: SafetyPlanState) {
    const plan = await SafetyPlanModel.fromState(saveState, client.id()).save();
    await client.saveSafetyPlan(plan);

    const clients = this.clients;
    if (clients) {
      if (!clients.some(cli => cli.object.id === client.object.id)) {
        clients.push(client);
      }
    }
  }

  async saveClientGoals(client: ClientModel, saveState: ClientGoalViewState) {
    const goals = await client.saveGoals(saveState.goals);

    const clients = this.clients;
    if (clients) {
      if (!clients.some(cli => cli.object.id === client.object.id)) {
        clients.push(client);
      }
    }

    return goals;
  }

  /** @deprecated To be removed as part of the new plan changes */
  async saveClientSupportNeeds(client: ClientModel, saveState: ClientSupportPlanSaveState) {
    const newNeeds = await client.saveSupportNeeds(saveState);

    const clients = this.clients;
    if (clients) {
      if (!clients.some(cli => cli.object.id === client.object.id)) {
        clients.push(client);
      }
    }

    return newNeeds;
  }

  async saveClientSupportNeedsV3(client: ClientModel, saveState: ClientSupportPlanSaveStateV3, plan: SupportPlanModel | undefined) {
    // Set plan dates to start/end of day in the client's timezone
    if (plan) {
      let startDate = saveState.planStartDate;
      let endDate = saveState.planEndDate;
      startDate = moment(startDate).startOf('day').toDate();
      saveState.planStartDate = convertDateToZone(startDate, client.australianState());

      if (endDate) {
        endDate = moment(endDate).endOf('day').toDate();
        endDate = convertDateToZone(endDate, client.australianState());
        saveState.planEndDate = endDate;
      }
    }
    
    const newNeeds = await client.saveSupportNeedsV3(saveState, plan);

    const clients = this.clients;
    if (clients) {
      if (!clients.some(cli => cli.object.id === client.object.id)) {
        clients.push(client);
      }
    }

    return newNeeds;
  }

  async saveClientContacts(client: ClientModel, contacts: ContactState[]) {
    const saved = await client.saveContacts(contacts);

    const clients = this.clients;
    if (clients) {
      if (!clients.some(cli => cli.object.id === client.object.id)) {
        clients.push(client);
      }
    }
    return saved;
  }

  async saveClientRequiresServiceAgreementMessage(client: ClientModel, message: string | undefined) {
    return await client.saveRequiresServiceAgreementMessage(message);
  }

  async requireNewServiceAgreement(client: ClientModel, date: Date | undefined) {
    await client.saveRequiresServiceAgreement(date);
    if (date) {
      await Parse.Cloud.run('notification-agreementRequired', { clientId: client.id() });
    }
  }

  private fetchAll() {
    const query = new Parse.Query(Parse.Object.extend('Client'));
    query.limit(10_000);
    query.include('user');
    query.include('caseManager');
    query.include('experienceOfficer');
    query.notEqualTo('isTest', true);
    query.find().then((clients: Parse.Object[]) => {
      const clientModels = clients.map(client => new ClientModel(client));
      this.clients = clientModels;
      this.listeners.forEach(listener => listener.onClientsLoaded(clientModels));
    }, () => {
      this.clients = [];
      this.listeners.forEach(listener => listener.onClientsLoaded([]));
    });
  }

  registerListener(listener: ClientManagerListener) {
    this.listeners.add(listener);
  }

  unregisterListener(listener: ClientManagerListener) {
    this.listeners.delete(listener);
  }

  async getClient(id: string) {
    const cached = this.clientCache[id];

    if (!cached) {
      const query = new Parse.Query(Parse.Object.extend('Client'));
      query.include('user');
      // query.notEqualTo('isTest', true);
      query.equalTo('objectId', id);

      const clientParseModel = await query.first();
      if (clientParseModel) {
        this.clientCache[id] = clientParseModel;
        return new ClientModel(clientParseModel);
      }
      return undefined;
    } else {
      return new ClientModel(cached);
    }
  }
}

export default ClientManager.instance();