import moment from 'moment-timezone';

export enum AustralianState {
  unknown = 0,
  nsw = 1,
  nt = 2,
  qld = 3,
  sa = 4,
  tas = 5,
  vic = 6,
  wa = 7,
  act = 8,
}

export const AustralianStateCases = [AustralianState.nsw, AustralianState.nt, AustralianState.qld, AustralianState.sa, AustralianState.tas, AustralianState.vic, AustralianState.wa, AustralianState.act];

export function getAustralianStateDescription(state: AustralianState): string {
  switch (state) {
    case AustralianState.nsw:
      return 'New South Wales';
    case AustralianState.nt:
      return 'Northern Territory';
    case AustralianState.qld:
      return 'Queensland';
    case AustralianState.sa:
      return 'South Australia';
    case AustralianState.tas:
      return 'Tasmania';
    case AustralianState.vic:
      return 'Victoria';
    case AustralianState.wa:
      return 'Western Australia';
    case AustralianState.act:
      return 'Australian Capital Territory';
    case AustralianState.unknown:
      return 'Unknown';
  }
}

export function getAustralianStateAbbreviation(state: AustralianState): string {
  switch (state) {
    case AustralianState.nsw:
      return 'NSW';
    case AustralianState.nt:
      return 'NT';
    case AustralianState.qld:
      return 'QLD';
    case AustralianState.sa:
      return 'SA';
    case AustralianState.tas:
      return 'TAS';
    case AustralianState.vic:
      return 'VIC';
    case AustralianState.wa:
      return 'WA';
    case AustralianState.act:
      return 'ACT';
    case AustralianState.unknown:
      return '';
  }
}

export function ianaTimezoneForState(state: AustralianState): string {
  switch (state) {
    case AustralianState.unknown: return 'Australia/Perth';
    case AustralianState.nsw: return 'Australia/Sydney';
    case AustralianState.nt: return 'Australia/Darwin';
    case AustralianState.wa: return 'Australia/Perth';
    case AustralianState.vic: return 'Australia/Melbourne';
    case AustralianState.tas: return 'Australia/Hobart';
    case AustralianState.qld: return 'Australia/Brisbane';
    case AustralianState.sa: return 'Australia/Adelaide';
    case AustralianState.act: return 'Australia/Canberra';
  }
}

export function offsetTimezoneForState(state: AustralianState): string {
  const iana = ianaTimezoneForState(state);
  return moment().tz(iana).format('Z');
}

export function getStateFromGooglePlaces(placesString: string): AustralianState {
  const map: { [state: string]: AustralianState } = {
    'NSW': AustralianState.nsw,
    'NT': AustralianState.nt,
    'QLD': AustralianState.qld,
    'SA': AustralianState.sa,
    'TAS': AustralianState.tas,
    'VIC': AustralianState.vic,
    'WA': AustralianState.wa,
    'ACT': AustralianState.act,
  };

  const mapped = map[placesString];

  return mapped ?? AustralianState.unknown;
}
