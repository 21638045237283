import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import { useIsMounted } from '../../views/Hooks/useIsMounted';
import { UserOpensView } from '../../views/UserOpens';
export interface UserOpen {
  deviceType: string;
  osVersion: string;
  model: string;
  appVersion: string;
  opens: {
    earliest: string;
    latest: string;
  };
}

export enum UserType{
  client,
  worker
}

export interface UserOpensContainerProps {
  userId: string;
  userType: UserType;
}

export const UserOpensContainer: React.FC<UserOpensContainerProps> = ({ userId, userType }) => {
  const [data, setData] = useState<UserOpen[] | undefined>(undefined);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const mounted = useIsMounted();

  useEffect(() => {
    if (!mounted) { return; }

    const fetchData = async () => {
      const results = await Parse.Cloud.run('analytics-userApps', { userId });
      if (!mounted) { return; }
      setData(results);
      setIsLoading(false);
    };

    setIsLoading(true);

    void fetchData();

  }, [userId, mounted]);

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {(!isLoading && data) &&
        <UserOpensView
          data={data}
          userType={userType}
        />
      }
    </>
  );
};