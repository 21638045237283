import React from 'react';

export interface OverlappingAvatarsProps {
  bottomUrl: string;
  topUrl: string;
  size:number;
}

export const OverlappingAvatars: React.FC<OverlappingAvatarsProps> = ({ bottomUrl, topUrl, size }) => {
  return (
    <div style={{ marginBottom: -(size / 2), display: 'inline' }}>
      <img
        src={bottomUrl}
        alt="avatar"
        style={{ width: size, height: size, borderRadius: '50%', position: 'relative', right: 0, top: 0, bottom: 0, margin: 'auto', objectFit: 'cover' }}
      />
      <img
        src={topUrl}
        alt="avatar"
        style={{ width: size, height: size, borderRadius: '50%', position: 'relative', left: size / 2, top: -(size / 2), bottom: 0, margin: 'auto', objectFit: 'cover' }}
      />
    </div>
  );
};