import classNames from 'classnames';
import React, { ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { QuestionCircleFill } from 'react-bootstrap-icons';
import { BrandColors } from '../../../../helpers';

export const InsightTitle = ({ title, badge, alert }: {title: string; badge?: string; alert?: ReactNode}): JSX.Element => {
  return (<h4 className="text-center">
    {title}
    {badge &&
      <span
        className="ml-2 badge badge-pill badge-secondary text-white"
        style={{ backgroundColor: BrandColors.grey }}
      >{badge}
      </span>
    }
    {alert}
  </h4>);
};

interface InsightRowProps {
  title: string;
  value: number | string;
  errorValue?: boolean;
  explainer?: string;
  onClick?: () => void;
}

export const InsightRow: React.FC<InsightRowProps> = ({ title, value, errorValue, explainer,  onClick }): JSX.Element => {
  return (
    <div
      className={classNames('row', { 'cursor-pointer brand-orange': !!onClick })}
      onClick={onClick ? () =>  onClick() : undefined}
    >
      <div className="col-3 offset-md-3 text-right font-weight-bold">
        <>
          {title}
          {explainer &&
            <OverlayTrigger
              placement="right"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id={title + value + value}>{explainer}</Tooltip>}
            >
              <span>
                <QuestionCircleFill
                  className="ml-1"
                  size={12}
                />
              </span>
            </OverlayTrigger>
          }
        </>
      </div>

      <div className={classNames('col-5', { 'text-danger font-weight-bold': errorValue })}>{value}</div>
    </div>
  );
};