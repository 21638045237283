/* eslint-disable react/jsx-key */
import React, { useState } from 'react';
import PlacesAutocomplete, { geocodeByAddress, getLatLng, Suggestion } from 'react-places-autocomplete';
import { AustralianState, getStateFromGooglePlaces } from '../../../enums/AustralianState';

interface Address {
  id: string;
  address: string;
  suburb: string;
  postcode: string;
  state: AustralianState;
  geo: {
    lat: number;
    lon: number;
  };
}


interface AddressInputProps {
  startingAddress?: string;
  inputClass?: string;
  onChange: (address: Address) => void;
}

export const AddressInput: React.FC<AddressInputProps> = ({ startingAddress, onChange, inputClass }) => {

  const [address, setAddress] = useState<string>(startingAddress ?? '');

  const handleChange = (updated: string) => {
    setAddress(updated);
  };

  const handleSelect = async (suggestion: Suggestion) => {
    const [place] = await geocodeByAddress(suggestion.description);
    const geoPoint = await getLatLng(place);
    const stateString = place.address_components.find(component => component.types.includes('administrative_area_level_1'))?.short_name;
    const state = getStateFromGooglePlaces(stateString ?? '');
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const postcode = place.address_components.find(component => component.types.includes('postal_code'))!.short_name!; // Must have postcode, let it fail if not.
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const suburb = place.address_components.find(component => component.types.includes('locality'))!.short_name!;

    onChange({
      id: place.place_id,
      address: place.formatted_address,
      postcode,
      suburb,
      state,
      geo: {
        lat: geoPoint.lat,
        lon: geoPoint.lng,
      },
    });
  };

  const inputClassName = `form-control location-search-input ${inputClass}`;

  return (
    <PlacesAutocomplete
      value={address}
      searchOptions={{ componentRestrictions: { country: 'au' } }}
      onChange={handleChange}
    //   onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div className="col-4 px-0">
          <input
            {...getInputProps({
              placeholder: 'Start typing an address...',
              className: inputClassName,
            })}
          />
          <div className="autocomplete-dropdown-container">

            {loading && <div>Loading...</div>}
            {suggestions.length > 0 &&
              <ul className="list-group">
                {suggestions.map(suggestion => {
                  return (
                    <li
                      {...getSuggestionItemProps(suggestion, {
                        className: 'list-group-item',
                      })}
                    >
                      <div
                        className="cursor-pointer"
                        onClick={() => void handleSelect(suggestion)}
                      >{suggestion.description}</div>
                    </li>
                  );
                })}
              </ul>
            }
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};