import Parse, { GeoPoint } from 'parse';
import { AustralianState } from '../enums/AustralianState';
import { ClientModel } from './Client';
import { WorkerModel } from './Worker';

/**
 * JobPost model
 */
export class JobPostModel {
  constructor(public object: Parse.Object) {}

  /**
   * Create a new instance of the model
   */
  static new(): JobPostModel {
    return new JobPostModel(new (Parse.Object.extend('JobPost'))());
  }

  id(): string { return this.object.id; }
  clientId(): string | undefined { return this.object.get('client')?.id; }
  workerIds(): string[] { return this.object.get('workers') ?? []; }
  description(): string { return this.object.get('jobDescription') ?? ''; }
  adminNotes(): string { return this.object.get('adminNotes') ?? ''; }
  clientFirstName(): string { return this.object.get('clientFirstName') ?? ''; }
  clientAddress(): string { return this.object.get('clientAddress') ?? ''; }
  clientAddressCoordinates(): GeoPoint | undefined { return this.object.get('clientAddressCoordinates') ?? ''; }
  clientAustralianState(): AustralianState { return this.object.get('clientAustralianState') ?? AustralianState.unknown; }
  isClosed(): boolean { return this.object.get('isClosed') === true; }
  createdAt(): Date { return new Date(this.object.get('createdAt')); }
  isDeleted(): boolean {
    const deleted = this.object.get('deletedAt');
    return deleted !== undefined;
  }

  setClient(value: ClientModel): void {
    const parseObject = value.rawObject();
    this.object.set('client', parseObject);
  }

  unsetClient(): void {
    this.object.set('client', undefined);
  }

  setDescription(value: string): void {
    this.object.set('jobDescription', value);
  }

  setAdminNotes(value: string): void {
    this.object.set('adminNotes', value);
  }

  setClientFirstName(value: string): void {
    this.object.set('clientFirstName', value);
  }

  setClientAddress(value: string): void {
    this.object.set('clientAddress', value);
  }

  setClientAddressCoordinates(value: GeoPoint | undefined): void {
    if (value) {
      this.object.set('clientAddressCoordinates', value ?? undefined);
    } else {
      this.object.unset('clientAddressCoordinates');
    }
  }

  setClientAustralianState(value: AustralianState): void {
    this.object.set('clientAustralianState', value);
  }

  setClosed(value: boolean): void {
    this.object.set('isClosed', value);
  }

  setDeleted(value: boolean): void {
    this.object.set('deletedAt', value === true ? new Date() : undefined);
  }

  /**
   * Gets the client model, assuming a previous query included this pointer
   */
  clientSync(): ClientModel | undefined {
    const parseObject = this.object.get('client') as Parse.Object | undefined;
    if (!parseObject) { return undefined; }
    return new ClientModel(parseObject);
  }

  /**
   * Gets the worker models, assuming a previous query included the pointers
   */
  workersSync(): WorkerModel[] {
    const workerObjects = this.object.get('workers') as Parse.Object[] | undefined;
    
    if (workerObjects) {
      return workerObjects.map(obj => new WorkerModel(obj));
    }

    return [];
  }

  /**
   * Saves the model
   */
  async save(): Promise<JobPostModel> {
    await this.object.save();
    return this;
  }
}