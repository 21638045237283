/**
 * Helper functions for applying search filters and sorting methods
 */

import { SessionsSortDirection, SessionsSortMode } from '../containers/Sessions';
import { getStatusDescription } from '../models/Sessions';
import { SupportSessionModel } from '../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../models/Sessions/SupportSessionRequest';
import { SessionOrRequest } from './sessionsAndRequests';


/** Determine if a session is a SupportSessionRequest or not */
function isRequest(session: SessionOrRequest): boolean {
  return 'proposedSessions' in session;
}

/**
 * Gets the correct start date for either a session or request
 */
function getStartDate(session: SessionOrRequest): Date {
  if (isRequest(session)) {
    return new Date((session as SupportSessionRequestModel).firstDateStart());
  } else {
    return new Date((session as SupportSessionModel).bestKnownStartDate());
  }
}

/**
 * Search session details against a given searchTerm value
 */
export function applySessionSearch(
  session: SupportSessionModel | SupportSessionRequestModel,
  searchTerm: string | undefined,
): boolean {
  if (!searchTerm) {
    return true;
  }

  const idString = session.id();
  const clientId = session.clientId() ?? '';
  const extraStrings = [session.clientName() ?? '', session.workerName() ?? ''];

  if (!searchTerm) { return true; }

  const allText = extraStrings.join(' ').toLowerCase();
  const result = allText.search(searchTerm.toLowerCase());
  const idMatch = idString === searchTerm;
  const clientIdMatch = clientId === searchTerm;

  return result >= 0 || idMatch || clientIdMatch;
}

/**
 * Sorts a list of sessions and requests by the given sort mode and direction
 */
export function applySessionSort(
  sessions: SessionOrRequest[],
  sortMode: SessionsSortMode,
  sortDirection: SessionsSortDirection,
): SessionOrRequest[] {
  let sorted = [...sessions];
  
  switch (sortMode) {
    case 'start_date':
      sorted.sort((inputA, inputB) => {
        const date1 = getStartDate(inputA);
        const date2 = getStartDate(inputB);
        return date1.getTime() > date2.getTime() ? -1 : 1;
      });
      break;

    case 'created_date':
      sorted.sort((inputA, inputB) => {
        const date1 = inputA.createdAt() as Date;
        const date2 = inputB.createdAt() as Date;
        return date1.getTime() > date2.getTime() ? -1 : 1;
      });
      break;

    case 'client_name':
      sorted.sort((inputA, inputB) => {
        const name1 = inputA.clientSync().lastName()?.toLowerCase() ?? '';
        const name2 = inputB.clientSync().lastName()?.toLowerCase() ?? '';
        return name1 < name2 ? -1 : 1;
      });
      break;

    case 'worker_name':
      sorted.sort((inputA, inputB) => {
        const name1 = inputA.workerLastName()?.toLowerCase() ?? '';
        const name2 = inputB.workerLastName()?.toLowerCase() ?? '';
        return name1 < name2 ? -1 : 1;
      });
      break;

    case 'status':
      sorted.sort((inputA, inputB) => {
        const description1 = getStatusDescription(inputA.status());
        const description2 = getStatusDescription(inputB.status());
        return description1 < description2 ? -1 : 1;
      });
      break;
  }

  if (sortDirection === 'descending') {
    sorted = sorted.reverse();
  }

  // Sort name and status groups secondly by ascending start date always
  if (sortMode === 'client_name' || sortMode === 'worker_name' || sortMode === 'status') {
    // Create groups of each type/status/name etc
    const groups: Record<string, SessionOrRequest[]> = {};
    
    sorted.forEach((session) => {
      switch (sortMode) {
        case 'client_name': {
          const key = session.clientSync().lastName() ?? '';
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(session);
          break;
        }

        case 'worker_name': {
          const key = session.workerLastName() ?? '';
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(session);
          break;
        }

        case 'status': {
          const key = getStatusDescription(session.status());
          if (!groups[key]) {
            groups[key] = [];
          }
          groups[key].push(session);
          break;
        }
      }
    });

    // Sort groups by session start date here
    let final: SessionOrRequest[] = [];

    Object.keys(groups).forEach((groupKey) => {
      let group = groups[groupKey];

      group = group.sort((inputA, inputB) => {
        const date1 = getStartDate(inputA);
        const date2 = getStartDate(inputB);
        return date1.getTime() > date2.getTime() ? -1 : 1;
      });
      
      final = final.concat([...group.reverse()]);
    });

    return final;
  }

  return sorted;
}