
import moment from 'moment';
import React, { useState } from 'react';
import { X } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { getDayDescription } from '../../../../enums/DayAvailabilityDay';
import { DayAvailabilityState } from '../../../../managers/WorkerAvailability';

function timeFromString(time: string) {
  const today = moment();
  const [hour, minute] = time.split(':');
  today.set('hour', parseInt(hour));
  today.set('minute', parseInt(minute));
  return today.toDate();
}

interface DayAvailabilityProps {
  day: DayAvailabilityState;
  onStartTimeChanged:(startDate: string) => void;
  onEndTimeChanged:(endDate: string) => void;
  onDelete:() => void;

}

export const DayAvailability: React.FC<DayAvailabilityProps>  = ({ day, onStartTimeChanged, onEndTimeChanged, onDelete }) => {
  const [startTime, setStartTime] = useState(day.startTime);
  const [endTime, setEndTime] = useState(day.endTime);

  return (
    <div className="row mb-1">
      <h5 className="col-2">{getDayDescription(day.day)}</h5>
      <label className="col-1 col-form-label text-right">From</label>
      <div className="col-2 form-control border">
        <DatePicker
          selected={timeFromString(startTime)}
          className="border-0 w-100 text-secondary"
          onChange={date => {
            const string = moment(date as Date).format('HH:mm');
            setStartTime(string);
            onStartTimeChanged(string);
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="HH:mm"
        />
      </div>
      <label className="col-1 col-form-label text-right">To</label>
      <div className="col-2 form-control border">
        <DatePicker
          className="border-0 w-100 text-secondary"
          selected={timeFromString(endTime)}
          onChange={date => {
            const string = moment(date as Date).format('HH:mm');
            setEndTime(string);
            onEndTimeChanged(string);
          }}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={30}
          timeCaption="Time"
          dateFormat="HH:mm"
        />
      </div>
      <X
        className="col-1 text-danger mt-1"
        size={24}
        onClick={() => onDelete()}
      />
    </div>
  );
};
