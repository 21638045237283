import moment from 'moment-timezone';
import Parse from 'parse';
import { AustralianState, getAustralianStateAbbreviation, ianaTimezoneForState } from '../enums/AustralianState';
import { PublicHolidays } from '../types/parseConfig';

const cache: { data: PublicHolidays | undefined } = {
  data: undefined,
};

/**
 * Loads public holiday information from the Parse config
 */
export async function getPublicHolidays(): Promise<PublicHolidays> {
  if (cache && cache.data) {
    return cache.data;
  }

  const config = await Parse.Config.get();
  cache.data = config.get('publicHolidays');
  return config.get('publicHolidays');
}

/**
 * Checks whether the given date is a public holiday in the given state
 */
export function isPublicHoliday(date: Date | undefined, publicHolidays: PublicHolidays, state = AustralianState.wa): boolean {
  if (!date) {
    return false;
  }

  const dateRepresentation = moment(date).tz(ianaTimezoneForState(state)).format('YYYY-MM-DD');
  const holidays = publicHolidays[getAustralianStateAbbreviation(state)] ?? publicHolidays['WA'];
  return holidays.includes(dateRepresentation);
}
