import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { ContactState } from '../../containers/Contacts';
import { ContactRelationshipDescriptions } from '../../models/Contact';

interface ContactProps {
  contact:ContactState;
  onEdited: (state: ContactState) => void;
  onDeleted: (state: ContactState) => void;
  isNew: boolean;
  onAdd?: (state: ContactState) => void;
}

export const Contact:React.FC<ContactProps>  = ({ contact, onEdited, onDeleted, isNew, onAdd }) => {
  const [isValid, setIsValid] = useState<boolean>(false);
  const [contactState, setContactState] = useState<ContactState>(contact);

  // Change Notifications. We are deliberately ignoring changes to onEdited, because it changes a lot when the client rerenders.
  useEffect(() => {
    onEdited(contactState);
  // eslint-disable-next-line
  }, [contactState]);

  // Validation
  useEffect(() => {
    const validContact = !!contactState.firstName && !!contactState.lastName && !!(contactState.email || contactState.phone);
    setIsValid(validContact);
  }, [contactState]);

  return (
    <>
      <label
        htmlFor="firstName"
        className="col-2 col-form-label text-right"
      >First name</label>
      <input
        id="firstName"
        type="text"
        className="col-4 form-control mb-2"
        value={contactState.firstName || ''}
        onChange={e => setContactState({ ...contactState, firstName: e.target.value })}
      />

      <label
        htmlFor="lastName"
        className="col-2 col-form-label text-right"
      >Last name</label>
      <input
        id="lastName"
        type="text"
        className="col-3 form-control mb-2"
        value={contactState.lastName || ''}
        onChange={e => setContactState({ ...contactState, lastName: e.target.value })}
      />

      {!isNew &&
        <X
          className="col-1 text-danger"
          size={24}
          onClick={() => {
            if (window.confirm('Are you sure you want to delete this contact?')) {
              onDeleted(contactState);
            }
          }}
        />
      }
      { isNew && <div className="col-1" />
      }

      <label
        htmlFor="email"
        className="col-2 col-form-label text-right"
      >Email</label>
      <input
        id="email"
        type="text"
        className="col-4 form-control mb-2"
        value={contactState.email || ''}
        onChange={e => setContactState({ ...contactState, email: e.target.value })}
      />

      <label
        htmlFor="phone"
        className="col-2 col-form-label text-right"
      >Phone</label>

      <input
        id="phone"
        type="text"
        className="col-3 form-control mb-2"
        value={contactState.phone || ''}
        onChange={e => setContactState({ ...contactState, phone: e.target.value })}
      />

      <label
        htmlFor="relationship"
        className="col-2 col-form-label text-right"
      >Relationship</label>
      <select
        className="col-4 form-control"
        value={contactState.relationship || 0}
        onChange={e => setContactState({ ...contactState, relationship: parseInt(e.target.value) })}
      >
        {Object.entries(ContactRelationshipDescriptions).map(entry => {
          return (
            <option
              key={entry[0]}
              value={entry[0]}
            > {entry[1]} </option>
          );
        })}
      </select>

      <div className="col-6" />

      {isNew &&
        <div className="offset-md-11">
          <button
            type="button"
            className={classNames('btn btn-primary mr-2', { 'disabled': !isValid })}
            onClick={() => { if (onAdd) { onAdd(contactState); setContactState({}); } }}
          >Add</button>
        </div>
      }
      {!isNew &&
        <hr className="col-10 offset-md-2" />
      }
    </>
  );
};