import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import SessionsManager from '../../../managers/Sessions';
import { ClientModel } from '../../../models/Client';
import { SupportSessionModel } from '../../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../../models/Sessions/SupportSessionRequest';
import { GenericSessionsView } from '../../../views/Client/Sessions';

export interface ClientSessionsContainerProps {
  client?: ClientModel;
}

export const ClientSessionsContainer: React.FC<ClientSessionsContainerProps> = ({ client }) => {
  const [requests, setRequests] = useState<SupportSessionRequestModel[] | undefined>(undefined);

  const [sessions, setSessions] = useState<SupportSessionModel[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const clientId = client?.id();
      if (!clientId) { return; }
      setIsLoading(true);

      const [sessionResults, requestResults] = await Promise.all([
        SessionsManager.getSessionsForClient(clientId),
        SessionsManager.getSessionRequestsForClient(clientId),
      ]);

      if (sessionResults) {
        setSessions(sessionResults.map(result => new SupportSessionModel(result)));
      }
      if (requestResults) {
        setRequests(requestResults.map(result => new SupportSessionRequestModel(result)).sort((session1, session2) => moment(session2.firstDateStart()).valueOf() - moment(session1.firstDateStart()).valueOf()));
      }

      setIsLoading(false);
    };


    void fetchData();

  }, [client]);

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {!isLoading &&
        <GenericSessionsView
          sessions={sessions || []}
          requests={requests || []}
          isClient
          clientId={client?.id()}
        />
      }
    </>
  );
};