import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { ToastManager } from '../../../Modals/Toasts/manager';
import ClientManager from '../../../managers/ClientManager';
import { ClientModel } from '../../../models/Client';
import { DatedServiceAgreement } from '../../../types/parseConfig';
import { ClientAgreementsView } from '../../../views/Client/Agreements';

export interface ClientAgreementsContainerProps {
  client?: ClientModel;
}

export const ClientAgreementsContainer: React.FC<ClientAgreementsContainerProps> = ({ client }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [agreements, setAgreements] = useState<DatedServiceAgreement []| undefined>();

  useEffect(() => {
    void Parse.Config.get().then(config => {
      setAgreements(config.get('datedServiceAgreements'));
    });
  }, []);

  return (
    <>
      {client &&
        <ClientAgreementsView
          client={client}
          agreements={agreements}
          onChangeMessage={async message => {
            setIsSaving(true);
            await  ClientManager.saveClientRequiresServiceAgreementMessage(client, message);
            ToastManager.shared().show('Client agreement message updated');
            setIsSaving(false);
          }}
          onRequireNewAgreement={async date => {
            setIsSaving(true);
            await ClientManager.requireNewServiceAgreement(client, date);
            ToastManager.shared().show(`Client agreement ${date ? 'required' : 'removed'}`);
            setIsSaving(false);
          }}
          disableInteraction={isSaving}
        />
      }
    </>
  );
};