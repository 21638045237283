import Parse from 'parse';
import { DayAvailabilityDay } from '../../enums/DayAvailabilityDay';
import { AvailabilityOverrideModel } from '../../models/AvailabilityOverride';
import { DayAvailabilityModel } from '../../models/DayAvailability';
import { ParseObject } from '../../models/util/parseTypes';
import { WorkerModel } from '../../models/Worker';

export const NEW_PREFIX = 'new:';

class WorkerAvailabilityManager {
  private static _instance: WorkerAvailabilityManager;

  private dayAvailabilityCache: {[id: string]: DayAvailabilityModel[]} = {};
  private overrideCache:  {[id: string]: AvailabilityOverrideModel[]} = {};

  static instance(): WorkerAvailabilityManager {
    if (!WorkerAvailabilityManager._instance) {
      WorkerAvailabilityManager._instance = new WorkerAvailabilityManager();
    }
    return WorkerAvailabilityManager._instance;
  }

  async getAvailabilityOverrides(id: string):  Promise<AvailabilityOverrideModel[] | undefined> {
    const query = new Parse.Query('AvailabilityOverride');
    const workerQuery = new Parse.Query('Worker');
    workerQuery.equalTo('objectId', id);
    query.matchesQuery('worker', workerQuery);
    query.ascending('createdAt');
    query.equalTo('deletedAt', undefined);


    query.limit(10_000);
    const messages = await query.find();
    return messages.map(message => new AvailabilityOverrideModel(message));
  }

  async getWorkerDayAvailability(id: string): Promise<DayAvailabilityModel[] | undefined> {
    const cached = this.dayAvailabilityCache[id];
    if (cached) { return cached; }

    const workerQuery = new Parse.Query('Worker');
    workerQuery.include('availableDays');
    const workerWithAvailableDays = await workerQuery.get(id);

    const days = (workerWithAvailableDays.get('availableDays') ?? []).map((model: ParseObject) => new DayAvailabilityModel(model));
    this.dayAvailabilityCache[id] = days;
    return days;
  }

  private async setWorkerDayAvailabilities(worker: WorkerModel,  dayAvailability: DayAvailabilityModel[], dayAvailabilityState: DayAvailabilityState[]) {
    const workerDays: DayAvailabilityModel[]  = [];

    // Update existing DayAvailability
    dayAvailability.forEach(day => {
      const modified = dayAvailabilityState.find(dayState => dayState.id === day.id());
      if (!modified) { return; }
      //
      day.setStartTime(modified.startTime);
      day.setEndTime(modified.endTime);

      if (!modified.isDeleted) {
        workerDays.push(day);
      }
    });

    // Create any new ones
    const newDayAvailabilities = await Promise.all(dayAvailabilityState.filter(day => day.id.startsWith(NEW_PREFIX) && !day.isDeleted).map(dayAvailability => {
      const newDayAvailability = DayAvailabilityModel.new();
      newDayAvailability.setDay(dayAvailability.day);
      newDayAvailability.setStartTime(dayAvailability.startTime);
      newDayAvailability.setEndTime(dayAvailability.endTime);
      newDayAvailability.setWorker(worker.rawObject());
      return newDayAvailability.save();
    }));

    workerDays.push(...newDayAvailabilities);
    return workerDays;
  }

  private async setWorkerAvailabilityOverrides(worker: WorkerModel, availabilityOverride: AvailabilityOverrideModel[], availabilityOverrideState: AvailabilityOverrideState[]) {
    const overrides: AvailabilityOverrideModel[]  = [];

    // Update existing AvailabilityOverrideModel
    availabilityOverride.forEach(override => {
      const modified = availabilityOverrideState.find(overrideState => overrideState.id === override.id());
      if (!modified) { return; }
      //
      override.setEffectiveFrom(modified.effectiveFrom);
      override.setEffectiveUntil(modified.effectiveUntil);

      if (modified.isDeleted) {
        override.setDeleted();
      }

      overrides.push(override);
    });

    // Create any new ones
    const newOverrides = await Promise.all(availabilityOverrideState.filter(override => override.id.startsWith(NEW_PREFIX) && !override.isDeleted).map(override => {
      const newOverride = AvailabilityOverrideModel.new();
      newOverride.setEffectiveFrom(override.effectiveFrom);
      newOverride.setEffectiveUntil(override.effectiveUntil);
      newOverride.setWorker(worker.rawObject());
      newOverride.setIsAvailable(override.isAvailable);
      return newOverride.save();
    }));

    overrides.push(...newOverrides);
    return overrides;
  }


  async updateWorkerAvailability(worker: WorkerModel, state: WorkerAvailabilityManagerSave): Promise<{dayAvailability:  DayAvailabilityModel[]; availabilityOverrides: AvailabilityOverrideModel[]}> {
    const dayAvailability = await this.setWorkerDayAvailabilities(worker, state.dayAvailability, state.dayAvailabilityState);
    worker.setAvailableDays(dayAvailability.map(day => day.rawObject()));
    worker.setPreferredTravelRadius(state.preferredTravelRadius);

    await worker.save();
    this.dayAvailabilityCache[worker.id()] = dayAvailability;

    const availabilityOverrides = await this.setWorkerAvailabilityOverrides(worker, state.availabilityOverrides, state.availabilityOverrideState);
    worker.setAvailabilityOverrides(availabilityOverrides.map(override => override.rawObject()));

    await worker.save();
    this.overrideCache[worker.id()] = availabilityOverrides;

    return { dayAvailability, availabilityOverrides };
  }
}

export interface DayAvailabilityState {
  id: string;
  day: DayAvailabilityDay;
  startTime: string;
  endTime: string;
  isDeleted: boolean;
}


export interface AvailabilityOverrideState {
  id: string;
  effectiveFrom: Date;
  effectiveUntil?: Date;
  isAvailable: boolean;
  isDeleted: boolean;
}

export interface  WorkerAvailabilityManagerSave {
  dayAvailability: DayAvailabilityModel[];
  dayAvailabilityState: DayAvailabilityState[];
  availabilityOverrides: AvailabilityOverrideModel[];
  availabilityOverrideState: AvailabilityOverrideState[];
  preferredTravelRadius: number;
}
export default WorkerAvailabilityManager;