import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Loading } from '../../components/Loading';
import { ToolBar } from '../../components/Toolbar';
import { IncidentReportCategory, categoryFromType } from '../../enums/WorkerReportType';
import { fromStorage, toStorage } from '../../helpers/sessionAndBillingUtils';
import { getWorkerReports } from '../../managers/WorkerReports';
import { WorkerReportModel } from '../../models/WorkerReport';
import { useIsMounted } from '../../views/Hooks/useIsMounted';
import { useSearchParams } from '../../views/Hooks/useSearchParams';
import { WorkerReportTile } from '../../views/WorkerReportTile';


const WORKER_REPORT_SEARCH_KEY = 'suitsme.search.worker-report';


function getSearchOptionDescription(option: IncidentReportCategory) {
  switch (option) {
    case IncidentReportCategory.workerConcern: return 'Concerns';
    case IncidentReportCategory.adminReportableIncident: return 'Reportable incidents';
    case IncidentReportCategory.standardIncident: return 'Non-reportable incidents';
  }
}

const SearchOptionCases = [IncidentReportCategory.adminReportableIncident, IncidentReportCategory.standardIncident, IncidentReportCategory.workerConcern];

export const WorkerReportsContainer:React.FC = () => {
  const paramSearch = useSearchParams('search');
  const [reports, setReports] = useState<WorkerReportModel[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [searchTerm, setSearchTerm] = useState<string | undefined>(paramSearch || fromStorage(WORKER_REPORT_SEARCH_KEY) || '');
  const [selectedSearchOptions, setSelectedSearchOptions] = useState<Set<IncidentReportCategory>>(new Set(SearchOptionCases));

  const isMounted = useIsMounted();

  useEffect(() => {
    setIsLoading(true);
    void getWorkerReports().then(result => {
      if (!isMounted.current) { return; }
      setReports(result);
      setIsLoading(false);
    });
  }, [isMounted]);

  // Save to local storage hooks
  useEffect(() => {
    if (searchTerm || searchTerm === '') { toStorage(searchTerm, WORKER_REPORT_SEARCH_KEY); }
  }, [searchTerm]);

  const options = SearchOptionCases.map(option => {
    return (
      <div
        className="col-3"
        key={option.toString()}
      >
        <input
          id={option.toString()}
          className="form-check-input"
          type="checkbox"
          name={option.toString()}
          checked={selectedSearchOptions.has(option)}
          value={option}
          onChange={e => {
            const statusChanged = Number(e.target.value);
            if (selectedSearchOptions.has(statusChanged)) {
              selectedSearchOptions.delete(statusChanged);
            } else {
              selectedSearchOptions.add(statusChanged);
            }
            setSelectedSearchOptions(new Set([...selectedSearchOptions.values()]));
          }}
        />
        <label
          htmlFor={option.toString()}
          className="form-check-label font-weight-normal text-white"
        >{getSearchOptionDescription(option)}</label>
      </div>
    );
  });

  const filter = (reports: WorkerReportModel[]) => {
    return reports.filter(report => {
      const category = categoryFromType(report.type());
      const matchesSearch = report.getWorkerSync().fullName().toLowerCase().includes((searchTerm || '').toLowerCase()) || report.getWorkerSync().id() === searchTerm;
      const matchesFilters = selectedSearchOptions.has(category);
      return matchesSearch && (matchesFilters || category === IncidentReportCategory.unknown);
    });
  };

  return (
    <div className="mx-4">
      <ToolBar
        title="Worker reports"
        top={
          <>
            <form
              className="form-inline col-4"
            >
              <input
                className="form-control w-100"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </form>
          </>
        }
        filters={
          <div className="mx-4 pb-2">

            <Form className="row">
              {options}

            </Form>
          </div>
        }
      />

      {isLoading &&
        <Loading />
      }


      {!isLoading && filter(reports).length === 0 &&
        <div
          className="alert alert-warning mt-2"
          role="alert"
        >
          There are reports with the current filters or search applied
        </div>
      }

      {reports && !isLoading &&
        <div className="row px-1 mt-2">
          {filter(reports).map(report => <WorkerReportTile
            key={report.id()}
            report={report}
          />)}
        </div>
      }
    </div>
  );
};
