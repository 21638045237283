import { CancellationAlertsResponse, EngagementAlertsResponse, PlanExpiryAlertsResponse } from '../../managers/Alerts';
import { ClientModel } from '../../models/Client';
import { downloadCSV } from '../../reports/csv/util';

export function exportMonitoringReport(
  clients: ClientModel[],
  alerts: {
    expiryAlerts: Record<string, PlanExpiryAlertsResponse>;
    engagementAlerts: Record<string, EngagementAlertsResponse>;
    cancellationAlerts: Record<string, CancellationAlertsResponse>;
  },
): void {
  const data = [[
    'First Name',
    'Last Name',
    'Case Manager',
    'Support plan expiry days',
    'Last engagement days ago',
    'Billable cancellations last 60 days']];

  clients.forEach((client) => {
    const clientId = client.id();
    const expiry = alerts.expiryAlerts[clientId];
    const engagement = alerts.engagementAlerts[clientId];
    const cancellations = alerts.cancellationAlerts[clientId];
    let caseManagerName = client.caseManagerDetailsSync().name;
    if (!client.caseManagerDetailsSync() || client.caseManagerDetailsSync().deletedAt !== undefined) {
      caseManagerName = '';
    }
    if (caseManagerName.includes('undefined')) {
      caseManagerName = '';
    }
    let engagementVal = `${engagement.dayValue}`;
    if (Math.abs(engagement.dayValue) > 60) {
      engagementVal = '60';
    }
    let expiryVal = '';
    if (expiry?.dayValue) {
      expiryVal = `${expiry.dayValue}`;
    }

    const row = [
      `${client.firstName()}`,
      `${client.lastName()}`,
      `${caseManagerName}`,
      `${expiryVal}`,
      `${engagementVal}`,
      `${cancellations.cancellationsCount}`,
    ];

    data.push(row);
  });

  const [headers, ...rows] = data;
  downloadCSV({ headers, rows }, 'Monitoring Report.csv');
}
