import Parse from 'parse';
import { ParseObject } from './util/parseTypes';


export class AvailabilityOverrideModel {
  constructor(public object: Parse.Object) { }

  static new(): AvailabilityOverrideModel {
    return new AvailabilityOverrideModel(new (Parse.Object.extend('AvailabilityOverride'))());
  }

  id(): string { return this.object.id; }

  effectiveFrom(): Date { return this.object.get('effectiveFrom'); }
  setEffectiveFrom(date: Date): void { this.object.set('effectiveFrom', date); }

  effectiveUntil(): Date | undefined { return this.object.get('effectiveUntil'); }
  setEffectiveUntil(date: Date | undefined): void { this.object.set('effectiveUntil', date); }

  isAvailable():boolean { return this.object.get('isAvailable'); }
  setIsAvailable(isAvailable: boolean):void { this.object.set('isAvailable', isAvailable); }

  setDeleted(): void { this.object.set('deletedAt', new Date()); }

  setWorker(worker: ParseObject): void { this.object.set('worker', worker); }

  async save(): Promise<AvailabilityOverrideModel> {
    await this.object.save();
    return this;
  }

  rawObject(): ParseObject { return this.object; }
}