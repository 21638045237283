import React from 'react';


interface RadioProps {
  id?: string;
  label: string;
  value: string | number | undefined;
  isSelected: boolean;
  onChange: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined;
}

export const Radio: React.FC<RadioProps>  = ({ id, label, value, isSelected, onChange }) => {
  return (
    <div className="form-check">
      <input
        id={id ?? label}
        className="form-check-input"
        type="radio"
        name={label}
        value={value}
        checked={isSelected}
        onChange={onChange}
      />
      <label
        htmlFor={id ?? label}
        className="form-check-label font-weight-normal"
      >{label}</label>
    </div>
  );
};