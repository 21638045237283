import React from 'react';
import { AustralianState, getAustralianStateDescription } from '../../enums/AustralianState';
import { BrandColors } from '../../helpers';

interface UserHeaderProps {
  firstName: string;
  lastName: string;
  photoUrl?: string;
  userState?: AustralianState;
  customBadgeText?: string;
  customBadgeColor?: string;
  secondaryCustomBadgeText?: string;
  secondaryCustomBadgeColor?: string;
}

export const UserHeader: React.FC<UserHeaderProps> = ({ firstName, lastName, photoUrl, userState, customBadgeText, customBadgeColor, secondaryCustomBadgeText, secondaryCustomBadgeColor }) => (
  <>
    <div className="row">
      <div className="col-12 text-center">
        <img
          src={photoUrl || `${window.location.origin}/logo512.png`}
          alt=""
          style={{ width: 72, height: 72, borderRadius: '50%', objectFit: 'cover' }}
        />
        <h3>{`${firstName} ${lastName}`.trim()}</h3>
      </div>

    </div>
    <span>
      {userState !== AustralianState.unknown && userState !== AustralianState.wa && userState !== undefined &&
        <div className="d-flex justify-content-center mb-1">
          <span
            className="sm-pill badge badge-pill text-white text-uppercase ml-1"
            style={{ backgroundColor: BrandColors.darkBlue, fontSize: '1.0rem' }}
          >{getAustralianStateDescription(userState ?? AustralianState.wa)}
          </span>
        </div>
      }
    </span>
    <span>
      {customBadgeText &&
        <div className="d-flex justify-content-center mb-1">
          <span
            className="sm-pill badge badge-pill text-white text-uppercase ml-1"
            style={{ backgroundColor: customBadgeColor ?? BrandColors.green, fontSize: '1.0rem' }}
          >{customBadgeText}
          </span>
          {secondaryCustomBadgeText &&
            <span
              className="sm-pill badge badge-pill text-white text-uppercase ml-1"
              style={{ backgroundColor: secondaryCustomBadgeColor ?? BrandColors.grey, fontSize: '1.0rem' }}
            >{secondaryCustomBadgeText}
            </span>
          }
        </div>
      }
    </span>
  </>
);
