import moment from 'moment';
import React from 'react';

interface NearingDateProps {
  date: Date;
}
export const NearingDate:React.FC<NearingDateProps> = ({ date }) => {
  const mDate = moment(date);
  const monthsDiff = mDate.diff(moment(), 'months', true);
  const className = monthsDiff <= 1 ? 'text-danger' : '';
  const duration = moment.duration(mDate.diff(moment()));
  const inFuture = moment().isBefore(mDate);

  const statement = [
    duration.years() !== 0 ? `${Math.abs(duration.years())} year(s)` : false,
    duration.months() !== 0 ? `${Math.abs(duration.months())} month(s)` : false,
    duration.days() !== 0 ? `${Math.abs(duration.days())} day(s)` : false,
  ].filter(Boolean).join(', ');


  const label = inFuture ? 'Expires in ' + statement : 'Expired ' + statement + ' ago';

  return (
    <span
      className={className}
      style={{ whiteSpace: 'nowrap' }}
    >{label}</span>
  );
};