import { Postcode, PostcodeType } from '../types/parseConfig';

export function getPostcodeClassification(postcode: string | undefined, suburb: string | undefined, postcodes: Postcode[]): PostcodeType {
  let type = PostcodeType.NATIONAL;

  if (postcode && suburb) {
    for (let i = 0; i < postcodes.length; i++) {
      const pc = postcodes[i];

      if (pc.suburb.toLowerCase().trim() === suburb.toLowerCase().trim() && pc.postcode === postcode.trim()) {
        type = pc.classification;
        break;
      }
    }
  }

  return type;
}

export function getPostcodeClassificationName(type: PostcodeType): string {
  switch (type) {
    case PostcodeType.NATIONAL:
      return 'National';
    case PostcodeType.REMOTE:
      return 'Remote';
    case PostcodeType.VERY_REMOTE:
      return 'Very Remote';
  }
}