import React from 'react';
import { Toast } from 'react-bootstrap';
import { ToastManager, ToastManagerListener } from './manager';

export interface ToastProps {
  show: boolean;
  onClose: () => void;
  text: string;
}
export const SuitsMeToast: React.FC<ToastProps> = ({ text, show, onClose }) => {
  return (
    <Toast
      onClose={() => onClose()}
      show={show}
      delay={2000}
      autohide
      style={{
        position: 'fixed',
        top: 24,
        right: 24,
        zIndex: 9999,
      }}

    >
      {/* <Toast.Header closeButton={false}>
        <img
          src="holder.js/20x20?text=%20"
          className="rounded mr-2"
          alt=""
        />
        <strong className="mr-auto">Bootstrap</strong>
        <small>11 mins ago</small>
      </Toast.Header> */}
      <Toast.Body>{text}</Toast.Body>
    </Toast>

  );
};


export class ToastContainer extends React.Component implements ToastManagerListener {
  state = {
    show: false,
    text: '',
  };

  componentDidMount() {
    ToastManager.shared().registerListener(this);
  }

  show(text: string): void {
    this.setState({ show: true, text });
  }

  render(): JSX.Element {
    return <SuitsMeToast
      show={this.state.show}
      text={this.state.text}
      onClose={() => this.setState({ show: false })}
    />;
  }

}

