import { SelectorOption } from '../components/Selector';

export enum Gender {
  Unknown = -1,
  NotSpecified = 0,
  Male = 1,
  Female = 2,
  GenderDiverseOrNonGendered = 3,
  TransMale = 4,
  TransFemale = 5,
  NonBinary = 6,
  GenderFluid = 7,
}


const GenderKeys = Object.keys(Gender).filter(key => typeof Gender[key as any] === 'number');
export const GenderCases = GenderKeys.map(key => Number(Gender[key as any]) as Gender);

export function getGenderDescription(gender: Gender | undefined): string {
  switch (gender) {
    case Gender.Male: return 'Male';
    case Gender.Female: return 'Female';
    case Gender.NotSpecified: return 'Rather not say';
    case Gender.GenderDiverseOrNonGendered: return 'Gender diverse / non-gendered';
    case Gender.TransMale: return 'Transgender male';
    case Gender.TransFemale: return 'Transgender female';
    case Gender.NonBinary: return 'Non-binary';
    case Gender.GenderFluid: return 'Gender fluid';
    default: return 'Unknown';
  }
}

export function getGenderDescriptionForDisallowedWorkers(gender: Gender): string {
  switch (gender) {
    case Gender.NotSpecified: return 'Unspecified';
    default: return getGenderDescription(gender);
  }
}


export function genderOptions(addSelect: boolean): SelectorOption[] {
  const options: SelectorOption[] = GenderCases.map(c => ({ name: getGenderDescription(c), key: c }));
  if (addSelect) {
    // We only add the 'Please select' option if gender is undefined for the ClientModel
    options.unshift({ name: 'Please select', key: -1 });
  }
  return options;
}