import React from 'react';
import { useHistory } from 'react-router-dom';
import { sessionsPath } from '../../../Routes';
import { SupportSessionRequestTile } from '../../../components/SupportSessionRequestTile';
import { SupportSessionTile } from '../../../components/SupportSessionTile';
import { SupportSessionModel } from '../../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../../models/Sessions/SupportSessionRequest';

interface GenericSessionsViewProps {
  sessions: SupportSessionModel[];
  requests: SupportSessionRequestModel[];
  isClient: boolean;
  clientId?: string;
  workerId?: string;
}

export const GenericSessionsView:React.FC<GenericSessionsViewProps> = ({ sessions, requests, isClient, clientId, workerId }) => {
  const history = useHistory();

  const handleClickCreate = () => {
    if (isClient) {
      history.push(`${sessionsPath}/_create?clientId=${clientId}`);
    } else {
      history.push(`${sessionsPath}/_create?workerId=${workerId}`);
    }
  };

  return (
    <div className="container pb-2 pt-4">
      {/* Button to navigate to the Create Session screen for this user */}
      <button
        type="button"
        className="btn btn-primary mr-2 mb-1"
        onClick={handleClickCreate}
      >
        Create
      </button>
      <hr />
      {requests.length > 0 &&
        <>
          <h4 className="text-left">Requests</h4>
          <div className="row">
            {requests.map(session => <div
              key={session.id()}
              className="col-sm-12 col-md-12 col-lg-6  p-0"
            >
              <SupportSessionRequestTile
                session={session}
              />
            </div>)}
          </div>
          <hr />
        </>
      }
      {sessions.length > 0 &&
        <>
          <h4 className="text-left">Sessions</h4>
          <div className="row">
            {sessions.map(session => <div
              key={session.id()}
              className="col-sm-12 col-md-12 col-lg-6 p-0"
            >
              <SupportSessionTile
                key={session.id()}
                session={session}
              />
            </div>)}
          </div>
        </>
      }
      {(sessions.length === 0 && requests.length === 0) &&
        <div className="text-info text-center">{`This ${isClient ? 'client' : 'worker'} has no support sessions or support session requests`}</div>
      }
    </div>
  );
};