export enum AtsiStatus {
  Unknown = 0,
  Aboriginal = 1,
  TorresStraitIslander = 2,
  AboriginalTorresStraitIslander = 3,
  Neither = 4,
}

export const AtsiStatusCases = [
  AtsiStatus.Neither,
  AtsiStatus.Aboriginal,
  AtsiStatus.TorresStraitIslander,
  AtsiStatus.AboriginalTorresStraitIslander,
  AtsiStatus.Unknown,
];

export function getAtsiStatusDescription(status: AtsiStatus): string {
  switch (status) {
    case AtsiStatus.Unknown: return 'Unknown';
    case AtsiStatus.Aboriginal: return 'Aboriginal';
    case AtsiStatus.TorresStraitIslander: return 'Torres Strait Islander';
    case AtsiStatus.AboriginalTorresStraitIslander: return 'Aboriginal Torres Strait Islander';
    case AtsiStatus.Neither: return 'Neither';
    default: return AtsiStatus[status];
  }
}