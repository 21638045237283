import moment from 'moment';
import Parse from 'parse';
import { SessionStatus } from '.';
import { BaseSessionModel } from './BaseSession';

export interface ProposedSession {
  startDateTime: Date;
  endDateTime: Date;
  isAvailable: boolean;
}

export class SupportSessionRequestModel extends BaseSessionModel {

  static new(): SupportSessionRequestModel {
    return new SupportSessionRequestModel(new (Parse.Object.extend('SupportSessionRequest'))());
  }

  // Override methods
  status(): SessionStatus {
    if (this.isNotAccepted()) {
      return SessionStatus.notAccepted;
    }
    return this.object.get('status') || 0;
  }

  realStatus(): SessionStatus {
    return this.object.get('status') || 0;
  }

  hasSpawnedSession(): boolean {
    return this.object.get('status') === SessionStatus.confirmed;
  }

  // Bespoke to SupportSessionRequest
  firstDateStart(): string {
    const [first] = this.object.get('proposedSessions');
    return first.startDateTime;
  }

  firstDateEnd(): string {
    const [first] = this.object.get('proposedSessions');
    return first.endDateTime;
  }

  sessionCount(): string | undefined  {
    const sessionCount =  this.object.get('proposedSessions').length;
    const frequency =  this.object.get('frequency');
    const freqLabels = ['One off', 'weekly', 'monthly'];
    if (sessionCount === 1) { return; }
    return `+ ${sessionCount - 1} ${freqLabels[frequency]} ${sessionCount - 1 > 1 ? 'sessions' : 'session'}`;
  }

  proposedSessions(): ProposedSession [] {
    return this.object.get('proposedSessions').map((proposedSession :any) => {
      return {
        isAvailable: proposedSession.isAvailable,
        startDateTime: new Date(proposedSession.startDateTime),
        endDateTime: new Date(proposedSession.endDateTime),
      };
    });
  }

  isNotAccepted() : boolean {
    const [first] = this.object.get('proposedSessions');
    const { startDateTime } = first;
    if (moment(startDateTime).isBefore(moment()) && [SessionStatus.pendingClientApproval, SessionStatus.pendingWorkerApproval].includes(this.object.get('status'))) {
      return true;
    }
    return false;
  }

  isCancelledOrDeclined(): boolean {
    return [SessionStatus.cancelledByAdmin, SessionStatus.cancelledByClient, SessionStatus.cancelledByWorker, SessionStatus.declined, SessionStatus.declinedByClient, SessionStatus.noShow]
      .includes(this.object.get('status'));
  }

  isPending(): boolean {
    return !this.isNotAccepted() && [SessionStatus.pendingClientApproval, SessionStatus.pendingWorkerApproval].includes(this.status());
  }

  createdBy(): Parse.User | undefined {
    return this.object?.get('createdBy') ?? undefined;
  }

  setStatus(value: SessionStatus): void {
    this.object.set('status', value);
  }
  
  setCreatedBy(user: Parse.User): void {
    this.object.set('createdBy', user);
  }

  createdAt(): Date | undefined {
    return this.object?.get('createdAt') ?? undefined;
  }
}