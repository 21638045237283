import React, { useEffect, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { CaretDownFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { settingsPath } from '../../../Routes';
import Session from '../../../Session';
import { AdminUser } from '../../../models/AdminUser';
import './UserMenu.css';

export interface UserMenuProps {
  adminUser: AdminUser;
}

async function signOut() {
  try {
    await Session.signOut();
  } catch (error) {
    console.error(error);
  }
}

/**
 * UserMenu component in the left hand nav menu
 */
export const UserMenu: React.FC<UserMenuProps> = ({ adminUser }) => {
  const history = useHistory();

  /** Custom component for the menu popup */
  const LogOutPopover = (
    <Popover
      id="user-menu"
    >
      <Popover.Content>
        <div
          className="popover-content text-center"
          style={{ fontSize: 15 }}
        >
          <a
            href="#"
            className="link-text-dark"
            onClick={() => history.push(`${settingsPath}/change-password`)}
          >
            Change password
          </a>
          <br />
          <a
            href="#"
            className="link-text-dark"
            onClick={() => signOut()}
          >
            Sign out
          </a>
        </div>
      </Popover.Content>
    </Popover>
  );

  // Component functionality
  const [user, setUser] = useState<AdminUser | undefined>(undefined);

  useEffect(() => {
    setTimeout(() => {
      if (adminUser) {
        setUser(adminUser);
      }
    }, 2000);
  }, [Session.isUserLoggedIn, adminUser]);

  return (
    <>
      <div className="col-12 text-center">
        <img
          src={user?.photoUrl() || `${window.location.origin}/logo512.png`}
          alt=""
          style={{ width: 72, height: 72, borderRadius: '50%', objectFit: 'cover' }}
        />
      </div>

      <div className="overlay-trigger">
        <OverlayTrigger
          trigger="click"
          placement="bottom"
          rootClose
          overlay={LogOutPopover}
        >
          <p
            style={{ cursor: 'pointer' }}
            className="text-center text-white dropdown-toggle"
          >
            <h3 className="text-white">
              {`${user?.fullName() ?? ''}`}
            </h3>
            {user?.fullName() && <span className="trigger-caret"><CaretDownFill /></span>}
          </p>
        </OverlayTrigger>
      </div>
    </>
  );
};