export enum SessionOption {
  SendRequestToWorker = 1,
  ScheduleSessionDirectly = 2,
}

const SessionOptionKeys = Object.keys(SessionOption).filter(key => typeof SessionOption[key as any] === 'number');
export const SessionOptionCases = SessionOptionKeys.map(key => Number(SessionOption[key as any]) as SessionOption);

export function getSessionOptionDescription(option: SessionOption): string {
  switch (option) {
    case SessionOption.SendRequestToWorker: return 'Send request to worker';
    case SessionOption.ScheduleSessionDirectly: return 'Schedule session directly';
  }
}