import moment from 'moment';
import React from 'react';
import { Radio } from '../../../components/Radio';
import { ClientSafetyPlanAccessType, ClientSafetyPlanAccessTypeCases, getClientSafetyPlanAccessTypeDescription } from '../../../enums/ClientSafetyPlanAccessType';
import { LastEvent } from '../../../hooks/useAnalytics';
import { SafetyPlanModel, SafetyPlanState } from '../../../models/SafetyPlan';
import { SaveButton } from '../SaveButton';

export interface ClientSafetyPlanViewProps {
  safetyPlan?: SafetyPlanModel;
  lastSafetyPlanEvent?: LastEvent;
  onKeyChanged: (key: string, value: unknown) => void;
  onSave: (state: SafetyPlanState) => Promise<void>;
  onReset: () => void;
  disableInteraction: boolean;
}

export class ClientSafetyPlanView extends React.Component<ClientSafetyPlanViewProps> {

  state = {
    becomingUnwellNotes: this.props.safetyPlan?.becomingUnwellNotes() || '',
    unwellActions: this.props.safetyPlan?.unwellActions() || '',
    unwellNotes: this.props.safetyPlan?.unwellNotes() || '',
    emergencyActions: this.props.safetyPlan?.emergencyActions() || '',
    otherNotes: this.props.safetyPlan?.otherNotes() || '',
    id: this.props.safetyPlan?.id(),
    accessType: this.props.safetyPlan?.accessType() || ClientSafetyPlanAccessType.Hidden,

    dirtyKeys: new Set(),
  };

  updateState(key: string, value: any) {
    this.setState({
      isDirty: this.state.dirtyKeys.add(key),
      [key]: value,
    });
  }

  isDirty(): boolean { return this.state.dirtyKeys.size > 0; }

  renderSafetyPlanAccess(): JSX.Element {
    return (
      <div className="row">
        <label
          htmlFor="becomingUnwellNotes"
          className="col-2 col-form-label text-right"
        >Client Access</label>
        <div className="col-3 col-form-label">
          {ClientSafetyPlanAccessTypeCases.map(access => {
            return <Radio
              key={access}
              label={getClientSafetyPlanAccessTypeDescription(access)}
              value={access}
              isSelected={access === this.state.accessType}
              onChange={e => this.updateState('accessType', parseInt(e.target.value))}
            />;
          })}
        </div>
      </div>
    );
  }

  renderForm(): JSX.Element {
    return (
      <div className="container pb-2 pt-4">
        <form>
          {this.renderSafetyPlanAccess()}
          <div className="row form-group">
            <label
              htmlFor="becomingUnwellNotes"
              className="col-2 col-form-label text-right"
            >When I am becoming unwell, others notice</label>
            <textarea
              id="becomingUnwellNotes"
              className="col-10 form-control mb-2"
              rows={4}
              value={this.state.becomingUnwellNotes}
              onChange={e => this.updateState('becomingUnwellNotes', e.target.value)}
            />

            <label
              htmlFor="unwellActions"
              className="col-2 col-form-label text-right"
            >{`If I'm becoming unwell I want my worker to`}</label>
            <textarea
              id="unwellActions"
              className="col-10 form-control mb-2"
              rows={4}
              value={this.state.unwellActions}
              onChange={e => this.updateState('unwellActions', e.target.value)}
            />

            <label
              htmlFor="unwellNotes"
              className="col-2 col-form-label text-right"
            >When I am unwell, others notice</label>
            <textarea
              id="unwellNotes"
              className="col-10 form-control mb-2"
              rows={4}
              value={this.state.unwellNotes}
              onChange={e => this.updateState('unwellNotes', e.target.value)}
            />

            <label
              htmlFor="emergencyActions"
              className="col-2 col-form-label text-right"
            >{`If my worker calls emergency services for me, I'd also like them to`}</label>
            <textarea
              id="emergencyActions"
              className="col-10 form-control mb-2"
              rows={4}
              value={this.state.emergencyActions}
              onChange={e => this.updateState('emergencyActions', e.target.value)}
            />

            <label
              htmlFor="otherNotes"
              className="col-2 col-form-label text-right"
            >Other notes</label>
            <textarea
              id="otherNotes"
              className="col-10 form-control mb-2"
              rows={4}
              value={this.state.otherNotes}
              onChange={e => this.updateState('otherNotes', e.target.value)}
            />
          </div>
          <hr />

          <SaveButton
            onSave={async () => {
              this.setState({ dirtyKeys: new Set() });
              await this.props.onSave(this.state);
            }}
            onCancel={() => this.props.onReset()}
            disableCancel={this.props.disableInteraction}
            disableSave={this.props.disableInteraction || !this.isDirty()}
          />
        </form>

        {
          this.props.lastSafetyPlanEvent && (
            <div
              className="alert alert-info mt-2 text-center"
              role="alert"
            >
              Safety plan last updated {moment(this.props.lastSafetyPlanEvent.date).format(`DD MMM YYYY, [at] h:mma`)}, by {this.props.lastSafetyPlanEvent.userName}
            </div>
          )
        }
      </div>
    );
  }

  render(): JSX.Element | undefined {
    return this.renderForm();
  }
}
