import Parse, { GeoPoint } from 'parse';
import { AtsiStatus } from '../../enums/AtsiStatus';
import { AustralianState } from '../../enums/AustralianState';
import { CovidVaccinationStatus } from '../../enums/CovidVaccinationStatus';
import { Gender } from '../../enums/Gender';
import { Hobby } from '../../enums/Hobby';
import { Language } from '../../enums/Language';
import { UserStatus } from '../../enums/UserStatus';
import { WorkerLevel } from '../../enums/WorkerLevel';
import { WorkerModel } from '../../models/Worker';

class WorkerManager {
  private static _instance: WorkerManager;

  private workerCache: {[id: string]: WorkerModel} = {};
  private workersCache: WorkerModel[] = [];

  static instance(): WorkerManager {
    if (!WorkerManager._instance) {
      WorkerManager._instance = new WorkerManager();
    }
    return WorkerManager._instance;
  }

  async getWorkers(): Promise<WorkerModel[]> {
    if (this.workersCache.length > 0) { return this.workersCache; }

    const query = new Parse.Query('Worker');
    query.limit(100_000);
    query.include('user');
    query.include('availableDays');
    query.ascending('firstName');
    const parseModels = await query.find();
    const models = parseModels.map(parseModel => new WorkerModel(parseModel));

    // Prime caches
    this.workersCache = models;
    models.forEach(worker => this.workerCache[worker.id()] = worker);

    return models;
  }

  async getWorker(id: string): Promise<WorkerModel | undefined> {
    const cached = this.workerCache[id];
    if (cached) { return cached; }

    const query = new Parse.Query('Worker');
    query.include('user');
    query.equalTo('objectId', id);

    const parseModel = await query.first();
    if (parseModel) {
      const result =  new WorkerModel(parseModel);
      this.workerCache[id] = result;
      return result;
    }
    return undefined;
  }

  async updateWorkerDetails(worker: WorkerModel, state: WorkerDetailsSave): Promise<WorkerModel> {
    worker.setFirstName(state.firstName);
    worker.setLastName(state.lastName);
    worker.setGender(state.gender);
    worker.setAddress(state.address);
    worker.setPostCode(state.postcode);
    if (state.addressCoordinates) { worker.setAddressCoordinates(state.addressCoordinates); }
    if (state.addressGoogleId) { worker.setAddressGoogleId(state.addressGoogleId); }
    worker.setAustralianState(state.australianState);
    worker.setPhoneNumber(state.phoneNumber);
    worker.setPhoto(state.photoUrl, state.photoBytes);
    worker.setStatus(state.userStatus);
    worker.setLanguages(state.languages);
    worker.setHobbies(state.hobbies);
    worker.setBio(state.bio);
    worker.setLevel(state.level);
    worker.setUnrestrictedVisibility(state.unrestrictedVisibility);
    worker.setWorkingWithChildrenCheck(state.workingWithChildrenCheck);
    worker.setCovidVaccinationStatus(state.covidVaccinationStatus);
    worker.setAtsiStatus(state.atsiStatus);
    worker.setOtherEmployers(state.otherEmployers);

    if (state.completedInduction) {
      worker.setCompletedInductionAt(state.completedInduction);
    }

    if (state.dateOfBirth) {
      worker.setDateOfBirth(state.dateOfBirth);
    }

    return worker.save();
  }
}

export interface WorkerDetailsSave {
  firstName: string;
  lastName: string;
  dateOfBirth?: Date;
  gender: Gender;
  address: string;
  postcode: string;
  australianState:AustralianState;
  addressCoordinates?: GeoPoint;
  addressGoogleId?: string;
  phoneNumber: string;
  photoUrl?: string;
  photoBytes?: any[];
  userStatus: UserStatus;
  languages: Set<Language>;
  hobbies: Set<Hobby>;
  bio: string;
  level: WorkerLevel;
  completedInduction?: Date;
  otherEmployers?: string;
  unrestrictedVisibility: boolean;
  workingWithChildrenCheck: boolean;
  covidVaccinationStatus: CovidVaccinationStatus;
  atsiStatus: AtsiStatus;
}

export default WorkerManager;