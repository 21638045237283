import React from 'react';
import { useHistory } from 'react-router-dom';
import { WorkerReportsContainer } from '../../containers/WorkerReports';
import { loginPath } from '../../Routes';
import session from '../../Session';

export const WorkerReports = () =>  {
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  return (
    <WorkerReportsContainer />
  );
};
