import Parse from 'parse';
import { ClientModel } from './Client';
import { SupportNeedModel } from './SupportNeed';
import { ParseObject } from './util/parseTypes';

export class SupportPlanModel {
  constructor(public object: Parse.Object) { }

  static new(): SupportPlanModel {
    return new SupportPlanModel(new (Parse.Object.extend('SupportPlan'))());
  }


  id(): string { return this.object.id; }

  startDate(): Date {
    if (!this.object.get('startDate')) {
      return this.object.get('createdAt');
    }
    return this.object.get('startDate');
  }
  endDate(): Date | undefined { return this.object.get('endDate'); }
  /** @deprecated To be removed as part of the new plan changes */
  isActive(): boolean | undefined { return this.object.get('isActive'); }
  clientId():  string | undefined { return this.object.get('client'); }
  supportNeedIds():  string | undefined { return this.object.get('supportNeeds'); }
  hasActualStartDate(): boolean { return this.object.get('startDate') !== undefined; }

  /** @deprecated To be removed as part of the new plan changes */
  setActive(active: boolean): SupportPlanModel {
    this.object.set('isActive', active);
    return this;
  }

  setStartDate(date: Date): SupportPlanModel {
    this.object.set('startDate', date);
    return this;
  }

  setEndDate(date: Date): SupportPlanModel {
    this.object.set('endDate', date);
    return this;
  }

  setSupportNeeds(needs: SupportNeedModel[]): SupportPlanModel {
    this.object.set('supportNeeds', needs.map(need => need.rawObject()));
    return this;
  }

  setClient(clientId: string): SupportPlanModel {
    const client = new Parse.Object('Client');
    client.id = clientId;
    this.object.set('client', client);
    return this;
  }

  setDeletedAt(value: Date | undefined): SupportPlanModel {
    this.object.set('deletedAt', value);
    return this;
  }

  isDeleted(): boolean {
    return this.object.get('deletedAt') !== undefined;
  }

  updateSupportNeeds(needs: SupportNeedModel[]): SupportPlanModel {
    this.object.addAllUnique('supportNeeds', needs.map(need => need.rawObject()));
    return this;
  }

  async client(): Promise<ClientModel> {
    const client = this.object.get('client').fetch();
    return new ClientModel(client);
  }

  async supportNeeds(): Promise<SupportNeedModel[]> {
    const needsPointers: ParseObject[] = this.object.get('supportNeeds');
    const needs = await Promise.all(needsPointers.map(pointer => pointer.fetch()));
    return needs.map((need: Parse.Object<Parse.Attributes>) => new SupportNeedModel(need));
  }

  supportNeedsSync(): SupportNeedModel[] {
    const needsPointers: ParseObject[] | undefined = this.object.get('supportNeeds');
    return (needsPointers ?? []).map((need: Parse.Object<Parse.Attributes>) => new SupportNeedModel(need));
  }

  rawObject(): ParseObject {
    return this.object;
  }

  async save(): Promise<SupportPlanModel> {
    await this.object.save();
    return this;
  }
}