import moment, { Moment } from 'moment';
import Parse, { GeoPoint } from 'parse';
import { ContactState } from '../containers/Contacts';
import { GoalState } from '../containers/Goals';
import { AtsiStatus } from '../enums/AtsiStatus';
import { AustralianState } from '../enums/AustralianState';
import { CovidVaccinationStatus, CovidVaccinationStatusCases } from '../enums/CovidVaccinationStatus';
import { Gender, GenderCases } from '../enums/Gender';
import { Hobby, HobbyCases } from '../enums/Hobby';
import { Language, LanguageCases } from '../enums/Language';
import { NDISBookings } from '../enums/NDISBookings';
import { SupportNeedModelType } from '../enums/SupportNeedModelType';
import { UserStatus, UserStatusCases, getUserStatusDescription } from '../enums/UserStatus';
import { SupportNeedState } from '../managers/SupportNeeds';
import { AdminUser } from './AdminUser';
import { ContactModel } from './Contact';
import { GoalModel } from './Goal';
import { SafetyPlanModel } from './SafetyPlan';
import { SupportNeedModel } from './SupportNeed';
import { SupportPlanModel } from './SupportPlan';
import { getFileExtensionFromUrl, saveStringFieldToObject } from './util/parseSaving';
import { ParseObject } from './util/parseTypes';



export interface ClientDetailsSave {
  password: string;
  email: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Moment;
  gender: Gender;
  address: string;
  suburb: string;
  postcode: string;
  australianState: AustralianState;
  addressCoordinates?: GeoPoint;
  addressGoogleId?: string;
  phoneNumber: string;
  photoUrl?: string;
  photoBytes?: [];
  userStatus: UserStatus;
  languages: Set<Language>;
  hobbies: Set<Hobby>;
  importantToKnow: string;
  bio: string;
  disallowedWorkerGenders: Set<Gender>;
  disallowedWorkerVaccinationStatuses: Set<CovidVaccinationStatus>;
  covidVaccinationStatus: CovidVaccinationStatus;
  atsiStatus: AtsiStatus;
  caseManager?: AdminUser | undefined;
  experienceOfficer?: AdminUser | undefined;
  preferredContactMethod?: string | undefined;
}


/** @deprecated To be removed as part of the new plan changes */
export interface ClientSupportPlanSaveState {
  ndisNumber: string;
  planEndDate: Date;
  ndisBookings: NDISBookings;
  startNewPlan: boolean;
  supportNeeds: SupportNeedState[];
}

export interface ClientSupportPlanSaveStateV3 {
  isNewPlan: boolean;
  ndisNumber: string;
  planStartDate: Date;
  planEndDate: Date;
  ndisBookings: NDISBookings;
  supportNeeds: SupportNeedState[];
}

export interface BasicAdminDetails {
  id: string;
  name: string;
  deletedAt: string | undefined;
}

export class ClientModel {
  constructor(public object: Parse.Object) { }

  static new(): ClientModel {
    return new ClientModel(new (Parse.Object.extend('Client'))());
  }

  id(): string { return this.object.id; }

  firstName(): string | undefined { return this.object.get('firstName'); }
  lastName(): string | undefined { return this.object.get('lastName'); }
  fullName(): string { return `${this.firstName() || ''} ${this.lastName() || ''}`.trim(); }
  initials(): string { return `${(this.firstName() || '').toUpperCase()[0]}${(this.lastName() || '').toUpperCase()[0]}`; }

  adminNotes(): string | undefined { return this.object.get('adminNotes'); }

  // Support Plan
  ndisNumber(): string | undefined { return this.object.get('ndisNumber'); }

  // Agreements
  serviceAgreementAcceptedAt(): Date[] {
    return this.object.get('serviceAgreementAcceptedAt');
  }

  requiresServiceAgreementMessage(): string | undefined {
    return this.object.get('requiresServiceAgreementMessage');
  }

  async saveRequiresServiceAgreementMessage(message: string | undefined): Promise<ClientModel> {
    this.object.set('requiresServiceAgreementMessage', message);
    await this.object.save();
    return this;
  }

  requiresServiceAgreement(): Date {
    return this.object.get('requiresServiceAgreement');
  }

  async saveRequiresServiceAgreement(date: Date | undefined): Promise<ClientModel> {
    if (!date) {
      this.object.unset('requiresServiceAgreement');
    } else {
      this.object.set('requiresServiceAgreement', date);
    }

    await this.object.save();
    return this;
  }

  ndisBookings(): NDISBookings | undefined { return this.object.get('ndisBookingResponsibleParty'); }

  dateOfBirth(): Moment {
    const dob = this.object.get('dateOfBirth');
    if (!dob) { return moment(''); } // passing empty string will create invalid moment

    // The date value stored in the DB is in UTC timezone, we first need to represent that
    // in the right timezone using Moment.
    const utc = moment.utc(dob);

    // We convert the date value to device's local timezone, at midnight start of day
    return moment().set({
      'year': utc.year(),
      'month': utc.month(),
      'date': utc.date(),
      'hour': 0,
      'minute': 0,
      'second': 0,
      'millisecond': 0,
    });
  }

  gender(): Gender | undefined {
    const gender = this.object.get('gender') as Gender;
    if (gender === undefined) { return undefined; }
    return GenderCases.includes(gender) ? gender : undefined;
  }

  disallowedWorkerGenders(): Set<Gender> {
    const genders = new Set<Gender>();
    const arr: [number] = this.object.get('disallowedWorkerGenders');
    if (!arr) { return genders; }
    for (const gender of arr) {
      if (GenderCases.includes(gender)) { genders.add(gender); }
    }
    return genders;
  }

  disallowedWorkerVaccinationStatuses(): Set<CovidVaccinationStatus> {
    const statuses = new Set<CovidVaccinationStatus>();
    const arr: [number] = this.object.get('disallowedWorkerVaccinationStatuses');
    if (!arr) { return statuses; }
    for (const status of arr) {
      if (CovidVaccinationStatusCases.includes(status)) { statuses.add(status); }
    }
    return statuses;
  }

  email(): string | undefined { return this.object.get('user')?.get('username'); }
  createdAt(): Date | undefined { return this.object.get('user')?.get('createdAt'); }
  deletedAt(): Date | undefined { return this.object.get('deletedAt'); }
  userId(): string { return this.object.get('user').id; }

  address(): string | undefined { return this.object.get('address'); }
  postcode(): string | undefined { return this.object.get('postcode'); }
  suburb(): string | undefined { return this.object.get('suburb'); }
  phoneNumber(): string | undefined { return this.object.get('phoneNumber'); }
  australianState(): AustralianState | undefined { return this.object.get('australianState'); }
  addressCoordinates(): GeoPoint | undefined { return this.object.get('addressCoordinates'); }
  addressGoogleId(): string | undefined { return this.object.get('addressGoogleId'); }

  status(): UserStatus | undefined {
    const status = this.object.get('status') as UserStatus;
    if (status === undefined) { return undefined; }
    return UserStatusCases.includes(status) ? status : undefined;
  }

  covidVaccinationStatus(): CovidVaccinationStatus {
    const status = this.object.get('covidVaccinationStatus') as CovidVaccinationStatus | undefined;
    if (status === undefined) { return CovidVaccinationStatus.unknown; }
    return status;
  }

  atsiStatus(): AtsiStatus {
    const status = this.object.get('atsiStatus') as AtsiStatus | undefined;
    if (status === undefined) { return AtsiStatus.Unknown; }
    return status;
  }

  statusDescription(): string {
    const status = this.status();
    return status ? getUserStatusDescription(status) : '';
  }

  languages(): Set<Language> {
    const languages = new Set<Language>();
    const arr: [number] = this.object.get('languages');
    if (!arr) { return languages; }
    for (const language of arr) {
      if (LanguageCases.includes(language)) { languages.add(language); }
    }
    return languages;
  }

  hobbies(): Set<Hobby> {
    const hobbies = new Set<Hobby>();
    const arr: [number] = this.object.get('hobbies');
    if (!arr) { return hobbies; }
    for (const hobby of arr) {
      if (HobbyCases.includes(hobby)) { hobbies.add(hobby); }
    }
    return hobbies;
  }

  importantToKnow(): string | undefined { return this.object.get('importantToKnow'); }
  bio(): string | undefined { return this.object.get('bio'); }

  photoUrl(): string | undefined {
    const file = this.object.get('photo') as Parse.File;
    return file ? file.url() : undefined;
  }

  photo(): string {
    const file = this.object.get('photo') as Parse.File;
    return file ? file.url() : `${window.location.origin}/logo192.png`;
  }

  preferredContactMethod(): string | undefined {
    return this.object.get('preferredContactMethod');
  }

  async getSafetyPlan(): Promise<SafetyPlanModel | undefined> {
    const planObject = this.object.get('safetyPlan');
    if (!planObject) { return; }

    const plan: ParseObject = await planObject.fetch();

    if (plan) {
      return new SafetyPlanModel(plan);
    }
  }

  async getCaseManager(): Promise<AdminUser | undefined> {
    const adminObject = this.object.get('caseManager');
    if (!adminObject) { return; }
    const admin: ParseObject = await adminObject.fetch();

    if (admin) {
      return new AdminUser(admin.get('user'), admin);
    }
    return undefined;
  }

  /**
   * Just returns the basic id and full name of the case manager admin
   */
  caseManagerDetailsSync(): BasicAdminDetails {
    const adminObject: ParseObject = this.object.get('caseManager');

    if (adminObject) {
      return {
        id: adminObject.id,
        name: `${adminObject.get('firstName')} ${adminObject.get('lastName')}`,
        deletedAt: adminObject.get('deletedAt'),
      };
    }

    return { id: '', name: '', deletedAt: undefined };
  }

  async getExperienceOfficer(): Promise<AdminUser | undefined> {
    const adminObject = this.object.get('experienceOfficer');
    if (!adminObject) { return; }
    const admin: ParseObject = await adminObject.fetch();

    if (admin) {
      return new AdminUser(admin.get('user'), admin);
    }
    return undefined;
  }

  /**
   * Just returns the basic id and full name of the experience officer admin
   */
  experienceOfficerDetailsSync(): BasicAdminDetails {
    const adminObject: ParseObject = this.object.get('experienceOfficer');

    if (adminObject) {
      return {
        id: adminObject.id,
        name: `${adminObject.get('firstName')} ${adminObject.get('lastName')}`,
        deletedAt: adminObject.get('deletedAt'),
      };
    }

    return { id: '', name: '', deletedAt: undefined };
  }

  async saveAdminNotes(notes: string): Promise<ClientModel> {
    this.saveStringField(notes, 'adminNotes');

    await this.object.save();
    return this;
  }

  async saveAdminUsers(caseManager: AdminUser, experienceOfficer: AdminUser | undefined): Promise<ClientModel> {
    this.object.set('caseManager', caseManager.admin);

    if (experienceOfficer) {
      this.object.set('experienceOfficer', experienceOfficer?.admin);
    } else {
      this.object.unset('experienceOfficer');
    }

    await this.object.save();
    return this;
  }

  async saveDetails(state: ClientDetailsSave): Promise<ClientModel> {
    const obj = this.object;

    if (!obj.id) {
      // We're creating a new Client
      const user = new Parse.User();
      user.setUsername(state.email);
      user.setPassword(state.password);
      user.set('type', 1);
      await user.save();
      obj.set('user', user);
    } else {
      // const email = state.email.trim();
      // if (email !== this.email() && email.length > 0) {
      //   const user = obj.get('user') as Parse.User;
      //   if (user) {
      //     user.setUsername(email);
      //     await user.save();
      //   }
      // }
    }

    this.saveStringField(state.firstName, 'firstName');
    this.saveStringField(state.lastName, 'lastName');
    this.saveDateField(state.dateOfBirth, 'dateOfBirth');
    this.saveEnumField(state.gender, 'gender');
    this.saveStringField(state.address, 'address');
    this.saveStringField(state.suburb, 'suburb');
    this.saveStringField(state.postcode, 'postcode');
    this.saveEnumField(state.australianState, 'australianState');
    if (state.addressGoogleId) { this.saveStringField(state.addressGoogleId, 'addressGoogleId'); }
    if (state.addressCoordinates) { this.object.set('addressCoordinates', state.addressCoordinates); }

    this.saveStringField(state.phoneNumber, 'phoneNumber');
    this.saveStringField(state.importantToKnow, 'importantToKnow');
    this.saveStringField(state.bio, 'bio');

    this.saveEnumField(state.userStatus, 'status');
    this.saveEnumsField(state.languages, 'languages');
    this.saveEnumsField(state.hobbies, 'hobbies');
    this.saveEnumField(state.covidVaccinationStatus, 'covidVaccinationStatus');
    this.saveEnumField(state.atsiStatus, 'atsiStatus');

    this.saveEnumsField(state.disallowedWorkerGenders, 'disallowedWorkerGenders');
    this.saveEnumsField(state.disallowedWorkerVaccinationStatuses, 'disallowedWorkerVaccinationStatuses');

    if (state.photoUrl !== this.photoUrl()) {
      if (state.photoUrl && state.photoBytes) {
        const fileExt = getFileExtensionFromUrl(state.photoUrl) || 'jpeg';
        const file = new Parse.File(`photo.${fileExt}`, state.photoBytes);
        this.object.set('photo', file);
      } else {
        this.object.unset('photo');
      }
    }

    await this.object.save();
    return this;
  }

  async savePreferredContactMethod(value: string): Promise<ClientModel> {
    this.object.set('preferredContactMethod', value);
    await this.object.save();
    return this;
  }

  async getGoals(): Promise<GoalModel[] | undefined> {
    const relationArray = this.object.get('goals');
    if (!relationArray) { return; }
    const goals: ParseObject[] = await Promise.all(relationArray.map((object: ParseObject) => object.fetch()));
    if (goals) {
      return goals.map((contact: ParseObject) => new GoalModel(contact));
    }
  }

  async saveGoals(goals: GoalState[]): Promise<GoalModel[]> {
    const models = goals.map(goal => GoalModel.fromState(goal, this.id()));

    models.forEach(goal => {
      if (!goal.id()) {
        goal.setActivatedDate(new Date()); // New goals get activated
      }
    });

    this.object.set('goals', models.map(model => model.rawObject()));

    await this.object.save();
    return models;
  }

  async getContacts(): Promise<ContactModel[] | undefined> {
    const relationArray = this.object.get('contacts');
    if (!relationArray) { return; }
    const contacts: ParseObject[] = await Promise.all(relationArray.map((object: ParseObject) => object.fetch()));
    if (contacts) {
      return contacts.filter(contact => !contact.get('deletedAt')).map((contact: ParseObject) => new ContactModel(contact));
    }
  }

  async saveContacts(contacts: ContactState[]): Promise<ContactModel[] | undefined> {
    const models = contacts.map(contact => ContactModel.fromState(contact, this.id()));

    this.object.set('contacts', models.map(model => model.rawObject()));
    await this.object.save();
    return models;
  }

  async saveSafetyPlan(plan: Parse.Object) {
    this.object.set('safetyPlan', plan);
    await this.object.save();
    return this;
  }


  filterSearchNeeds(need: Parse.Object<Parse.Attributes>) {
    // The client needs maintains a list of needs the client ever had.
    // Therefore, when displaying we need to filter out some in two cases:
    // - Deleted needs. We soft delete these, so filter any with a deletedAt date set
    // - Needs with a different end date to the current end date. This means the plan end date has changed.
    //   So, filter out any without a matching end date to the clients plan end date.
    const deleted = need.get('deletedAt') !== undefined;

    const needEndDate = need.get('endDate') as Date | undefined;
    const planEndDate = this.object.get('planEndDate') as Date | undefined;
    const forOldPlan = needEndDate?.valueOf() !== planEndDate?.valueOf();

    return !(deleted || forOldPlan);
  }

  /** @deprecated To be removed as part of the new plan changes */
  async activeSupportPlan(): Promise<SupportPlanModel | undefined> {
    const planQuery = new Parse.Query('SupportPlan');
    const clientQuery = new Parse.Query('Client');
    clientQuery.equalTo('objectId', this.object.id);
    planQuery.equalTo('isActive', true);
    planQuery.matchesQuery('client', clientQuery);
    planQuery.descending('createdAt');
    const plans = await planQuery.find();
    if (!plans || plans.length === 0) { return; }
    return new SupportPlanModel(plans[0]);
  }

  async supportPlans(): Promise<SupportPlanModel[] | undefined> {
    const planRefs = this.object.get('supportPlans');

    if (!planRefs) {
      return undefined;
    }

    const planParseObjects: ParseObject[] = await Promise.all(planRefs.map((plan: ParseObject) => plan.fetch()));
    return planParseObjects.map(plan => new SupportPlanModel(plan as ParseObject));
  }

  /** @deprecated To be removed as part of the new plan changes */
  async getActiveSupportNeeds(): Promise<SupportNeedModel[] | undefined> {
    const plan = await this.activeSupportPlan();
    if (!plan) { return; }

    const needs = await plan.supportNeeds();
    return needs.filter(need => !need.isDeleted() && need.type() !== 1 as SupportNeedModelType); // Hide "Daily life" needs
  }

  /** @deprecated To be removed as part of the new plan changes */
  async saveSupportNeeds(state: ClientSupportPlanSaveState): Promise<{ needs: SupportNeedModel[]; plan: SupportPlanModel }> {
    const updatedNeeds: SupportNeedModel[] = [];

    // Generally speaking, we save as we go/update, and save the client last once we're sure all models are in the correct state.
    // This prevents our after save migration function from reading yet to be persisted data and making false assumptions about the state of the client's related objects (needs and plans)

    if (state.startNewPlan) {
      // Create a new plan
      const newPlan = SupportPlanModel.new();
      newPlan.setActive(true);
      newPlan.setEndDate(state.planEndDate);
      newPlan.setClient(this.object.id);

      // Save it
      await newPlan.save();

      // Create and set needs
      const needs = state.supportNeeds.map(needState => {
        const need = SupportNeedModel.new().loadState(needState, this).clone();
        need.updateEndDate(state.planEndDate);
        need.setSupportPlan(newPlan);
        return need;
      });

      // Save needs
      await Parse.Object.saveAll(needs.map(need => need.rawObject()));

      // Set needs on the plan
      newPlan.setSupportNeeds(needs);

      // Save the plan again
      await newPlan.save();

      // Make sure all old plans are inactive
      const existingPlans = await this.supportPlans();
      if (existingPlans) {
        existingPlans.forEach(plan => plan.setActive(false));
      }

      // Set new plan on client
      this.object.set('supportPlans', [...(existingPlans || []).map(plan => plan.rawObject()), newPlan.rawObject()]);
      updatedNeeds.push(...needs);
    } else {
      const activePlan = await this.activeSupportPlan();

      // Shouldn't happen. But if there are no plans, then it should create a new one on save.
      if (!activePlan) {
        return this.saveSupportNeeds({ ...state, startNewPlan: true });
      }

      // Update end date on needs
      const needs = state.supportNeeds.map(needState => {
        const need = SupportNeedModel.new().loadState(needState, this);
        need.updateEndDate(state.planEndDate);
        return need;
      });

      needs.map(need => need.setSupportPlan(activePlan));

      // Save needs
      await Parse.Object.saveAll(needs.map(need => need.rawObject()));

      // Update plan
      activePlan.updateSupportNeeds(needs);
      activePlan.setEndDate(state.planEndDate);

      // Save plan
      await activePlan.save();

      updatedNeeds.push(...needs);
    }


    // LEGACY: Set plan end date on client directly
    this.object.set('planEndDate', state.planEndDate);

    // LEGACY: Set supportNeeds on client directly
    this.object.addAllUnique('supportNeeds', updatedNeeds.map(need => need.rawObject()));

    this.saveStringField(state.ndisNumber, 'ndisNumber');
    this.saveEnumField(state.ndisBookings, 'ndisBookingResponsibleParty');

    await this.object.save();
    const supportPlan = await this.activeSupportPlan();
    const needs = await this.getActiveSupportNeeds();
    return { needs: needs!, plan: supportPlan! };
  }

  /**
   * API V3
   * 
   * Save support needs on a support plan
   */
  async saveSupportNeedsV3(state: ClientSupportPlanSaveStateV3, plan?: SupportPlanModel | undefined): Promise<{ needs: SupportNeedModel[]; plan: SupportPlanModel }> {
    let planResult: SupportPlanModel | undefined = undefined;
    let needsResult: SupportNeedModel[] | undefined = undefined;

    if (state.isNewPlan) {
      // Create a new plan
      const newPlan = SupportPlanModel.new();
      newPlan.setStartDate(state.planStartDate);
      newPlan.setEndDate(state.planEndDate);
      newPlan.setClient(this.object.id);

      // Save it
      await newPlan.save();

      // Create and set needs
      const needs = state.supportNeeds.map(needState => {
        const need = SupportNeedModel.new().loadState(needState, this).clone();
        need.updateEndDate(state.planEndDate);
        need.setSupportPlan(newPlan);
        return need;
      });

      // Save needs
      const savedNeeds = await Parse.Object.saveAll(needs.map(need => need.rawObject()));
      needsResult = savedNeeds.map((needObj) => new SupportNeedModel(needObj));

      // Set needs on the plan
      newPlan.setSupportNeeds(needs);

      // Save the plan again
      planResult = await newPlan.save();

      // Set updated plans on client
      const existingPlans = await this.supportPlans();
      this.object.set('supportPlans', [...(existingPlans || []).map(plan => plan.rawObject()), planResult.rawObject()]);
    } else {
      if (!plan) {
        throw new Error('Plan model object must be provided to save an existing plan.');
      }

      // Update end date on needs
      const needs = state.supportNeeds.map(needState => {
        const need = SupportNeedModel.new().loadState(needState, this);
        need.setSupportPlan(plan);
        need.updateEndDate(state.planEndDate);
        return need;
      });

      // Save needs
      const savedNeeds = await Parse.Object.saveAll(needs.map(need => need.rawObject()));
      needsResult = savedNeeds.map((needObj) => new SupportNeedModel(needObj));

      // Update plan
      plan.updateSupportNeeds(needs);
      plan.setStartDate(state.planStartDate);
      plan.setEndDate(state.planEndDate);

      // Save plan
      planResult = await plan.save();
    }

    this.saveStringField(state.ndisNumber, 'ndisNumber');
    this.saveEnumField(state.ndisBookings, 'ndisBookingResponsibleParty');

    await this.object.save();

    return {
      needs: needsResult,
      plan: planResult as SupportPlanModel,
    };
  }


  rawObject(): Parse.Object { return this.object; }

  private saveStringField(value: string, fieldName: string, trim: boolean = true, unsetIfEmpty: boolean = true) {
    saveStringFieldToObject(value, fieldName, this.object, trim, unsetIfEmpty);
  }

  private saveEnumField(value: number, fieldName: string) {
    this.object.set(fieldName, value);
  }

  private saveEnumsField(values: Set<number>, fieldName: string, unsetIfEmpty: boolean = true) {
    const arr = Array.from(values);
    if (unsetIfEmpty && arr.length === 0) { this.object.unset(fieldName); }
    else { this.object.set(fieldName, arr); }
  }

  private saveDateField(value: Moment, fieldName: string) {
    if (value.isValid()) {
      // Date only value (i.e. time components are irrelevant, e.g. date of birth) are stored in UTC timezone with
      // hour, minute, second, and millisecond fields all set to 0.
      const utc = moment.utc().set({
        'year': value.year(),
        'month': value.month(),
        'date': value.date(),
        'hour': 0,
        'minute': 0,
        'second': 0,
        'millisecond': 0,
      });
      this.object.set(fieldName, utc.toDate());
    } else {
      this.object.unset(fieldName);
    }
  }

}

