export enum BudgetAdjustmentType {
  Other = 0,
  EstablishmentFee = 1,
  NonFaceToFace = 2,
  ERLSession = 3,
  SafetyRecoveringPlanning = 4,
}

export const BudgetAdjustmentTypeCases = [
  BudgetAdjustmentType.Other,
  BudgetAdjustmentType.NonFaceToFace,
  BudgetAdjustmentType.ERLSession,
  BudgetAdjustmentType.SafetyRecoveringPlanning,
  BudgetAdjustmentType.EstablishmentFee,
];

export function getBudgetAdjustmentDescription(type: BudgetAdjustmentType): string {
  switch (type) {
    case BudgetAdjustmentType.EstablishmentFee:
      return 'Establishment fee';
    case BudgetAdjustmentType.NonFaceToFace:
      return 'Non face-to-face time';
    case BudgetAdjustmentType.ERLSession:
      return 'ERL session';
    case BudgetAdjustmentType.SafetyRecoveringPlanning:
      return 'Safety and recovery planning';
    case BudgetAdjustmentType.Other:
    default:
      return 'Other';
  }
}
