import Parse from 'parse';
import { AlertMuteAffectedUserType, AlertTypes } from '../managers/Alerts';

export class AlertMuteModel {

  constructor(public object: Parse.Object) { }

  static new(): AlertMuteModel {
    return new AlertMuteModel(new (Parse.Object.extend('AlertMute'))());
  }

  id(): string { return this.object.id; }
  adminId():string { return this.object.get('adminId'); }
  affectedUserId():string { return this.object.get('affectedUserId'); }
  affectedUserType():AlertMuteAffectedUserType { return this.object.get('affectedUserType'); }

  effectiveUntil():Date| undefined { return this.object.get('effectiveUntil'); }
  type():AlertTypes { return this.object.get('type'); }

  create(type: AlertTypes, adminId: string, affectedUserId: string, affectedUserType: AlertMuteAffectedUserType, effectiveUntil?: Date): AlertMuteModel {
    this.object.set('type', type);
    this.object.set('adminId', adminId);
    this.object.set('affectedUserId', affectedUserId);
    this.object.set('effectiveUntil', effectiveUntil);
    this.object.set('affectedUserType', affectedUserType);
    return this;
  }

  save(): Promise<AlertMuteModel> {
    return this.object.save().then(() =>  this);
  }

  async destroy(): Promise<void> {
    await this.object.destroy();
  }
}