import moment from 'moment';
import React from 'react';
import { UserOpen, UserType } from '../../containers/UserOpens';
interface UserOpensViewProps {
  data: UserOpen[];
  userType: UserType;
}

const BulletDivider = () => {
  return <span className="mx-2">•</span>;
};

export const UserOpenRow:React.FC<{data: UserOpen}> = ({ data }) => {
  return (
    <div className="mb-2">
      <div className="d-flex ">
        <img
          width={50}
          height={50}
          src={`/icons/${data.deviceType === 'ios' ? 'ios' : 'android'}.svg`}
          className="mr-2"
          alt="The icon for the platform, either ios or android"
        />
        <div className="d-flex flex-column justify-content-center">
          <div className="font-weight-bold">{`Suitsme v ${data.appVersion}`}</div>
          <div>
            {data.model}
            <BulletDivider />
            {`${data.deviceType === 'ios' ? 'iOS' : 'Android'} ${data.osVersion}`}
            <BulletDivider />
            {`First used ${moment(data.opens.earliest).format('DD/MM/YYYY')}`}
            <BulletDivider />
            {`Most recently used ${moment(data.opens.latest).format('DD/MM/YYYY')}`}</div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export const UserOpensView:React.FC<UserOpensViewProps> = ({ data, userType }) => {
  return (
    <div className="container pb-2 pt-4">
      {data.length > 0 &&
        <>
          {data.map((open, index) => <UserOpenRow
            key={index}
            data={open}
          />)}
        </>
      }

      {data.length === 0 &&
        <div className="text-info text-center">{`This ${userType === UserType.client ? 'client' : 'worker'} has no recorded apps. Note: Data only available from 15 March 2021.`}</div>
      }
    </div>
  );
};