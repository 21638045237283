import React, { useState } from 'react';
import { formatMoney } from '../../helpers';

export interface CurrencyInputProps {
  id: string;
  value: number;
  onChange: (valueCents: number) => void;
  label?: string;
  labelClassName?: string;
  inputClassName?: string;
}

export const CurrencyInput: React.FC<CurrencyInputProps> = ({
  id,
  value,
  label,
  onChange,
  labelClassName = 'col-2 col-form-label text-right mb-1',
  inputClassName = 'col-4 form-control mb-1',
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(() => {
    return String(value / 100);
  });

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const numStrValue = String(event.target.value).replace(/[^0-9.]+/g, '').replace(/[..]+/g, '.');
    let numValue = Number(numStrValue);
    
    if (!isNaN(numValue)) {
      setInputValue(numStrValue);
    } else {
      setInputValue(inputValue);
      numValue = Number(inputValue);
    }

    const updatedValue = Number(formatMoney(numValue * 100).replace(/[$,.]+/g, ''));
    onChange(updatedValue);
  }

  return (
    <>
      {label && <label
        htmlFor={id}
        className={labelClassName}
      >{label}</label>}
      <input
        className={inputClassName}
        type="text"
        inputMode="decimal"
        value={isEditing ? inputValue : formatMoney(value as number)}
        id={id}
        onChange={handleChange}
        onFocus={() => setIsEditing(true)}
        onBlur={() => setIsEditing(false)}
      />
    </>
  );
};