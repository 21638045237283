import React, { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { GoalState, UnsavedGoalPrefix } from '../../containers/Goals';

interface GoalProps {
  goal: GoalState;
  onEdited: (state: GoalState) => void;
  onDeleted: (state: GoalState) => void;
}

export const Goal:React.FC<GoalProps>  = ({ goal, onEdited, onDeleted }) => {
  const [goalState, setGoalState] = useState<GoalState>(goal);

  const isNew = goal.id?.startsWith(UnsavedGoalPrefix);
  //  Change Notifications. We are deliberately ignoring changes to onEdited, because it changes a lot when the client rerenders.
  useEffect(() => {
    onEdited(goalState);
  // eslint-disable-next-line
  }, [goalState]);


  return (
    <div className="row form-group">
      <label
        htmlFor="title"
        className="col-2 col-form-label text-right"
      >I want to</label>
      <textarea
        id="title"
        className="col-8 form-control mb-2"
        rows={3}
        value={goalState.title || ''}
        onChange={e => setGoalState({ ...goalState, title: e.target.value })}
      />


      <X
        className="col-1 text-danger"
        size={24}
        onClick={() => {
          if (!isNew) {
            if (window.confirm('Delete this goal? This action will delete the goal, rather than archiving it.')) {
              onDeleted(goalState);
            }
          } else {
            onDeleted(goalState);
          }
        }}
      />


      <label
        htmlFor="criteria"
        className="col-2 col-form-label text-right"
      >To do this I will</label>
      <textarea
        id="criteria"
        className="col-8 form-control mb-2"
        rows={3}
        value={goalState.criteria || ''}
        onChange={e => setGoalState({ ...goalState, criteria: e.target.value })}
      />
      <div className="col-2" />

      {!isNew &&
        <>
          <div className="col-2 offset-md-2 pl-0">
            <span
              className="badge badge-pill badge-warning text-uppercase text-white sm-pill"
              style={{ backgroundColor: goalState.color, opacity: goal.isInactive ? 0.65 : 1.0 }}
            >{goalState.status}</span>
          </div>
          <div className="col-8" />

          {goal.completedDates && goal.completedDates.length > 0 &&
            <>
              <div className="col-10 offset-md-2 pl-0 mt-1">
                {`Completed: ${goalState.completedDates?.join(', ')}`}
              </div>
            </>
          }
        </>
      }
      <hr className="col-8 offset-md-2" />

    </div>
  );
};