import React, { useState } from 'react';
import { Selector } from '../../../components/Selector';
import { getPlanManagerState, PlanManagerState } from '../../../managers/PlanManagers';
import { PlanManagerModel } from '../../../models/PlanManager';


interface PlanManagerProps {
  managers: PlanManagerModel[];
  currentManager?: PlanManagerModel;

  currentManagerState: PlanManagerState;
  onCurrentManagerStateChanged: (details: PlanManagerState) => void;

  onManagerChanged: (manager: PlanManagerModel) => void;

  onPlanManagerCreated:(details: PlanManagerState) => Promise<void>;
}

export const PlanManager: React.FC<PlanManagerProps> = ({ managers, currentManager, currentManagerState, onCurrentManagerStateChanged, onManagerChanged, onPlanManagerCreated }) => {
  const [isAdding, setIsAdding] = useState(currentManager === undefined);
  const [newState, setNewState] = useState<PlanManagerState> (getPlanManagerState());

  return (
    <>
      <label className="offset-2 col-2 col-form-label text-right mb-1">Organisation</label>
      <Selector
        initial={isAdding ? '-1' : (currentManager?.id() ?? '-1')}
        options={[...managers.map(manager => ({ key: manager.id(), name: manager.organisationName() ?? '' })), { key: '-1', name: '+ Add' }]}

        onChange={option => {
          if (option.target.value === '-1') {
            setIsAdding(true);
          } else {
            setIsAdding(false);
            onManagerChanged(managers.find(manager => manager.id() === option.target.value)!);
          }
        }
        }
      />
      <div className="col-4" />


      {isAdding &&
        <>
          <label className="offset-2 col-2 col-form-label text-right mb-1">Organisation</label>
          <input
            className="col-4 form-control"
            value={newState.organisation}
            onChange={e => setNewState({ ...newState, organisation: e.target.value })}
          />
          <div className="col-4" />
        </>
      }

      <label className="offset-2 col-2 col-form-label text-right mb-1">Contact person</label>
      <input
        className="col-4 form-control"
        value={isAdding ? newState.contact : currentManagerState.contact}
        onChange={e => isAdding ? setNewState({ ...newState, contact: e.target.value }) : onCurrentManagerStateChanged({ ...currentManagerState, contact: e.target.value })}
      />
      <div className="col-4" />


      <label className="offset-2 col-2 col-form-label text-right mb-1">Invoice address</label>
      <input
        className="col-4 form-control"
        value={isAdding ? newState.address : currentManagerState.address}
        onChange={e => isAdding ? setNewState({ ...newState, address: e.target.value }) : onCurrentManagerStateChanged({ ...currentManagerState, address: e.target.value })}
      />
      <div className="col-4" />


      {isAdding &&
        <button
          type="button"
          className="btn btn-primary offset-4 col-4"
          onClick={() => onPlanManagerCreated(newState).then(() => setIsAdding(false))}
        >Add</button>
      }
    </>
  );
};