import moment from 'moment';
import React from 'react';
import { getSupportNeedModelTypeDescription } from '../../../enums/SupportNeedModelType';
import { SupportNeedModel } from '../../../models/SupportNeed';
import { SupportPlanModel } from '../../../models/SupportPlan';

interface SessionNeedViewProps {
  need: SupportNeedModel | undefined;
  plan: SupportPlanModel;
}

export const SessionNeedView: React.FC<SessionNeedViewProps> = ({ need, plan }) => {
  const planDateFormat = 'DD/MM/YYYY';
  let description = 'Unallocated';

  if (need) {
    description = `${getSupportNeedModelTypeDescription(need.type()!)} (${need.id()})`;
  }

  return (
    <div className="row">
      <label
        className="col-2 col-form-label text-right mb-1"
      >Support Plan</label>
      <input
        className="col-4 form-control"
        type="text"
        value={'Starting on: ' + moment(plan.startDate()).format(planDateFormat)}
        readOnly
      />

      <label
        className="col-2 col-form-label text-right mb-1"
      >Support Need</label>
      <input
        className="col-4 form-control"
        type="text"
        value={description}
        readOnly
      />

    </div>
  );
};
