import React, { ReactNode } from 'react';

interface SupportNeedFormRowProps {
  colsAfter: number;
  children: {
    /** Form row label text */
    label: ReactNode;
    /** Form row input component */
    input: ReactNode;
    /** Optional action button after the input  */
    actionButton?: ReactNode;
  };
}

export const SupportNeedFormRow = (props: SupportNeedFormRowProps): JSX.Element => {
  return (
    <>
      <div className="col-2" />
      {props.children.label}
      {props.children.input}
      {props.children.actionButton || null}
      <div className={`col-${props.colsAfter}`} />
    </>
  );
};