import { random } from 'lodash';
import React, { useContext } from 'react';
import { Contact } from '../../components/Contact';
import { ClientContext } from '../../contexts/client';
import { ContactModel, ContactRelationship } from '../../models/Contact';

interface ContactsContainerProps {
  contacts: ContactState[];
  onAdded: (need: ContactState) => void;
  onChange: (need: ContactState) => void;
  onSetDelete: (need: ContactState) => void;
}

export interface ContactState  {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  relationship?: ContactRelationship;

  isDeleted?: boolean;
}

export const UnsavedContactPrefix = 'new:';

export function contactStateFromContactModel(contact: ContactModel) : ContactState {
  return {
    id: contact.id(),
    firstName: contact.firstName(),
    lastName: contact.lastName(),
    email: contact.email(),
    phone: contact.phone(),
    relationship: contact.relationship(),
    isDeleted: false,
  };
}

export const ContactsContainer:React.FC<ContactsContainerProps> = (props: ContactsContainerProps) => {
  const clientId = useContext(ClientContext);

  return (
    <div className="row form-group">
      {clientId &&
        <>
          {props.contacts.filter(contact => !contact.isDeleted).map(contact => <Contact
            key={contact.id}
            isNew={false}
            contact={contact}
            onEdited={edited => props.onChange(edited)}
            onDeleted={stateDeleted => { props.onSetDelete(stateDeleted); }}
          />)}
          <Contact
            key="add"
            isNew={true}
            contact={{ id: UnsavedContactPrefix + random(), relationship: ContactRelationship.parent }}
            onAdd={added => props.onAdded(added)}
            onEdited={edited => props.onChange(edited)}
            onDeleted={stateDeleted => { props.onSetDelete(stateDeleted); }}
          />
        </>
      }
      {!clientId &&
        <>
          <div className="col-2" />
          <div className="col-10 pl-0">
            <h5>You are able to add contacts after you have finished creating the client</h5>
          </div>
        </>
      }
    </div>

  );

};