import moment from 'moment';
import Parse, { GeoPoint } from 'parse';
import React, { useEffect, useMemo, useState } from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Selector } from '../../components/Selector';
import { UserHeader } from '../../components/UserHeader';
import { JobPostContainer } from '../../containers/JobPost';
import { AustralianState, getAustralianStateDescription } from '../../enums/AustralianState';
import { BrandColors } from '../../helpers';
import ClientManager from '../../managers/ClientManager';
import JobPostManager, { JobPostState } from '../../managers/JobPosts';
import { ModalManager } from '../../Modals/Error/manager';
import { ClientModel } from '../../models/Client';
import { JobPostModel } from '../../models/JobPost';
import { jobPostsPath, loginPath } from '../../Routes';
import Session from '../../Session';
import { AddressInput } from '../Client/Details/addressInput';
import { SaveButton } from '../Client/SaveButton';
import { FormItem } from '../Worker/Tabs/Details/FormItem';
import { JobPostWorkerTile } from './JobPostWorkerTile';

interface JobPostViewProps {
  isLoading: boolean;
  jobPost: JobPostModel | undefined;
  clients: ClientModel[];
  disableInteraction?: boolean;
  onSave(state: JobPostState): void;
}

type ClientType = 'client' | 'prospective';

export const JobPost: React.FC = () => {
  const history = useHistory();

  if (!Session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  const match = useRouteMatch({ path: `${jobPostsPath}/:id` });
  const id = (match?.params as any)?.id;

  return <JobPostContainer jobPostId={(id as string)} />;
};


export const JobPostView: React.FC<JobPostViewProps> = ({ jobPost, clients, isLoading, disableInteraction = false, onSave }) => {
  const [selectedClient, setSelectedClient] = useState<ClientModel | undefined>(() => {
    return jobPost?.clientSync() ?? undefined;
  });
  const isRealClient = jobPost?.clientId() !== undefined;
  let initialClientType: ClientType = 'client';
  if (!jobPost?.clientId() && jobPost?.clientFirstName()) {
    initialClientType = 'prospective';
  }

  const [description, setDescription] = useState(jobPost?.description() ?? '');
  const [adminNotes, setAdminNotes] = useState(jobPost?.adminNotes() ?? '');
  const [wasValidated, setWasValidated] = useState(false);
  const firstName = jobPost?.clientSync()?.firstName() ?? 'New job post';
  const lastName = jobPost?.clientSync()?.lastName() ?? '';
  const photoUrl = selectedClient?.photoUrl() ?? '';
  const workers = jobPost?.workersSync() ?? [];
  const history = useHistory();

  // State for client vs prospective
  const [clientType, setClientType] = useState<ClientType>(initialClientType);
  const [pFirstName, setPFirstName] = useState(jobPost?.clientFirstName() ?? '');
  const [pAddress, setPAddress] = useState(jobPost?.clientAddress() ?? '');
  const [pAddressCoords, setPAddressCoords] = useState<GeoPoint | undefined>(undefined);
  const [pState, setPState] = useState<AustralianState>(jobPost?.clientAustralianState() ?? AustralianState.unknown);
  const [clientCaseManagerName, setClientCaseManagerName] = useState('');
  const [clientExperienceOfficerName, setClientExperienceOfficerName] = useState('');
  const [isLoadingExtraDetails, setIsLoadingExtraDetails] = useState(false);

  /**
   * This effect runs when the selected client is changed, in order to load the details
   * of the Case Manager and Experience Officer associated with the client (if they exist).
   * Because these are pointers to other records, it's quicker to load them in this way rather
   * than including them in the original query that provides the client list data.
   */
  useEffect(() => {
    const getClientDetails = async () => {
      if (selectedClient) {
        setIsLoadingExtraDetails(true);
        const clientDetails = await ClientManager.getClientWithCaseMgrExpOfficerAsync(selectedClient.id());
        if (clientDetails) {
          if (clientDetails.caseManagerDetailsSync()) {
            setClientCaseManagerName(clientDetails.caseManagerDetailsSync().name);
          } else {
            setClientCaseManagerName('');
          }
          if (clientDetails.experienceOfficerDetailsSync()) {
            setClientExperienceOfficerName(clientDetails.experienceOfficerDetailsSync().name);
          } else { 
            setClientExperienceOfficerName('');
          }
        }
        setIsLoadingExtraDetails(false);
      }
    };
    
    void getClientDetails();
  }, [selectedClient]);

  const hasEdited = useMemo(() => {
    let edited = false;

    if (clientType === 'client') {
      if (!jobPost) {
        if (description !== '' || adminNotes !== '' && selectedClient) {
          edited = true;
        }
      } else if (description !== jobPost.description() || adminNotes !== jobPost.adminNotes() || selectedClient?.id() !== jobPost.clientId()) {
        edited = true;
      }
    } else if (clientType === 'prospective') {
      if (jobPost) {
        if (
          pFirstName !== jobPost?.clientFirstName() ||
          pAddress !== jobPost?.clientAddress() ||
          description !== jobPost.description() ||
          adminNotes !== jobPost.adminNotes()
        ) {
          edited = true;
        }
      } else {
        if (
          pFirstName !== '' ||
          pAddress !== '' ||
          description !== '' ||
          adminNotes !== ''
        ) {
          edited = true;
        }
      }
    }
    
    return edited;
  }, [jobPost, description, adminNotes, selectedClient, pFirstName, pAddress]);

  function validateForm(): boolean {
    let valid = true;

    if (clientType === 'client') {
      if (!jobPost) {
        if (!selectedClient || description === '') {
          valid = false;
        }
      }
    } else if (clientType === 'prospective') {
      if (
        pFirstName === '' ||
        pAddress === ''
      ) {
        valid = false;
      }
    }

    return valid;
  }
  
  async function handleSave(): Promise<void> {
    setWasValidated(true);
    if (!validateForm()) {
      ModalManager.shared().show('You have invalid fields', 'Please fix the highlighted fields before proceeding.');
      return;
    }

    const saveState: JobPostState = {
      description,
      adminNotes,
      isClosed: jobPost?.isClosed() ?? false,
      isDeleted: jobPost?.isDeleted() ?? false,
    };

    if (clientType === 'client') {
      saveState.client = selectedClient;
    } else if (clientType === 'prospective') {
      saveState.clientFirstName = pFirstName;
      saveState.clientAddress = pAddress;
      saveState.clientAddressCoordinates = pAddressCoords;
      saveState.clientAustralianState = pState;
    }

    await onSave(saveState);
    setWasValidated(false);
    history.replace(jobPostsPath);
  }

  async function handleReopen(): Promise<void> {
    await onSave({
      description,
      adminNotes,
      isClosed: false,
      isDeleted: jobPost?.isDeleted() ?? false,
    });
  }

  async function handleClose(): Promise<void> {
    await onSave({
      description,
      adminNotes,
      isClosed: true,
      isDeleted: jobPost?.isDeleted() ?? false,
    });
  }

  async function handleDelete(): Promise<void> {
    await onSave({
      description,
      adminNotes,
      isClosed: jobPost?.isClosed() ?? false,
      isDeleted: true,
    });

    JobPostManager.instance().deleteJobPost(jobPost?.id() ?? '');
    history.replace(jobPostsPath);
  }

  function handleCancel(): void {
    history.goBack();
  }

  function getValidClassName(fieldId: string): string {
    if (!wasValidated) {
      return '';
    }
    
    switch (fieldId) {
      case 'pFirstName':
        return pFirstName.trim().length > 0 ? '' : 'is-invalid';
      case 'pAddress':
        return pAddress.trim().length > 0 ? '' : 'is-invalid';
      default:
        return '';
    }
  }

  function renderLeftButtons() {
    if (!jobPost) {
      return null;
    }

    if (jobPost.isClosed()) {
      return (
        <span className="d-flex flex-row-reverse">
          <button
            type="button"
            disabled={disableInteraction}
            className="btn btn-primary m-1"
            onClick={handleReopen}
          >Re-open</button>
          <button
            type="button"
            disabled={disableInteraction}
            className="btn btn-danger m-1"
            onClick={handleDelete}
          >Delete</button>
        </span>
      );
    }

    return (
      <span className="d-flex flex-row-reverse">
        <button
          type="button"
          disabled={disableInteraction}
          className="btn btn-danger m-1"
          onClick={handleClose}
        >Close Job Post</button>
      </span>
    );
  }

  function isClientSelectDisabled(): boolean {
    if (disableInteraction) { return true; }
    return false;
  }

  /**
   * Renders the form for when the job post has a real client
   */
  function renderClientForm(): JSX.Element {
    return (
      <>
        <div className="row form-group">
          <FormItem
            title="Client"
            readOnly={!clients}
            valueNode={
              <Selector
                options={[{ key: '-1', name: 'Select a client', disabled: true }, ...(clients ?? []).map(client => ({ name: client.fullName(), key: client.id() }))]}
                initial={selectedClient?.id() ?? '-1'}
                onChange={e => setSelectedClient(clients?.find(client => client.id() === e.target.value))}
                disabled={isClientSelectDisabled()}
              />
            }
          />
          {
            jobPost && (
              <FormItem
                title="Posted"
                value={moment(jobPost?.createdAt()).format('D MMMM YYYY')}
                readOnly
              />
            )
          }
        </div>
        <div className="row form-group">
          <FormItem
            title="Case manager"
            value={isLoadingExtraDetails ? 'Loading...' : clientCaseManagerName ?? ''}
            readOnly
          />
          <FormItem
            title="Experience officer"
            value={isLoadingExtraDetails ? 'Loading...' : clientExperienceOfficerName ?? ''}
            readOnly
          />
        </div>
      </>
    );
  }

  /**
   * Renders the form for when the job post only has a prospective client
   */
  function renderProspectiveForm(): JSX.Element {
    return (
      <>
        <div className="row form-group">
          <label
            htmlFor="pFirstName"
            className="col-2 col-form-label text-right"
          >
            First name
          </label>
          <input
            id="pFirstName"
            type="text"
            className={`col-4 form-control ${getValidClassName('pFirstName')}`}
            value={pFirstName}
            onChange={e => setPFirstName(e.target.value)}
          />
          {
            jobPost && (
              <FormItem
                title="Posted"
                value={moment(jobPost?.createdAt()).format('D MMMM YYYY')}
                readOnly
              />
            )
          }
        </div>
        <div className="row form-group">
          <label
            htmlFor="address"
            className="col-2 col-form-label text-right"
          >Address</label>
          <AddressInput
            startingAddress={pAddress}
            inputClass={getValidClassName('pAddress')}
            onChange={address => {
              setPAddress(address.address);
              setPState(address.state);
              setPAddressCoords(new Parse.GeoPoint(address.geo.lat, address.geo.lon));
            }}
          />
        </div>
        <div className="row form-group">
          <label
            htmlFor="pState"
            className="col-2 col-form-label text-right"
          >
            State
          </label>
          <input
            id="pState"
            type="text"
            className="col-4 form-control"
            value={getAustralianStateDescription(pState)}
            disabled
          />
        </div>
      </>
    );
  }

  return (
    <div className="container mt-2">
      <UserHeader
        firstName={firstName}
        lastName={lastName}
        photoUrl={photoUrl}
        customBadgeText={jobPost ? jobPost?.isClosed() ? 'CLOSED' : 'OPEN' : ''}
        customBadgeColor={jobPost && jobPost?.isClosed() ? BrandColors.red : BrandColors.green }
        secondaryCustomBadgeText={jobPost && !jobPost?.clientSync() ? 'PROSPECTIVE' : undefined }
        secondaryCustomBadgeColor={BrandColors.grey}
      />
      <hr />
      <form
        className="m-4"
      >
        <div className="row form-group">
          <label className="col-2 col-form-label text-right">Type</label>
          <div className="col-2 col-form-label">
            <div className="form-check mb-1">
              <input
                id="client-type-client"
                className="form-check-input"
                type="radio"
                name="client-type"
                value="client"
                checked={clientType === 'client'}
                onChange={() => setClientType('client')}
              />
              <label
                htmlFor="client-type-client"
                className="form-check-label font-weight-normal"
              >Current client</label>
            </div>
          </div>
          <div className="col-2 col-form-label">
            <div className="form-check mb-1">
              <input
                id="client-type-prospective"
                className="form-check-input"
                type="radio"
                name="client-type"
                value="prospective"
                checked={clientType === 'prospective'}
                onChange={() => setClientType('prospective')}
              />
              <label
                htmlFor="client-type-prospective"
                className="form-check-label font-weight-normal"
              >Prospective client</label>
            </div>
          </div>
        </div>
        {clientType === 'client' && renderClientForm()}
        {clientType === 'prospective' && renderProspectiveForm()}
        <div className="row form-group">
          <label
            htmlFor="description"
            className="col-2 col-form-label text-right"
          >Description</label>
          <textarea
            id="description"
            className="col-10 form-control mb-2"
            rows={8}
            value={description}
            onChange={e => setDescription(e.target.value)}
            disabled={disableInteraction}
          />
        </div>
        <div className="row form-group">
          <label
            htmlFor="adminnotes"
            className="col-2 col-form-label text-right"
          >Admin notes</label>
          <textarea
            id="adminnotes"
            className="col-10 form-control mb-2"
            rows={4}
            value={adminNotes}
            onChange={e => setAdminNotes(e.target.value)}
            disabled={disableInteraction}
          />
          <div className="col-12 text-right text-warning">
            <span className="mr-2"><ExclamationTriangleFill /></span>
            Admin notes are not displayed to workers
          </div>
        </div>
      
        <hr />

        <SaveButton
          onSave={handleSave}
          onCancel={handleCancel}
          disableCancel={disableInteraction}
          disableSave={disableInteraction || !hasEdited}
          cancelText={jobPost ? 'Back' : 'Cancel'}
          leftAction={renderLeftButtons()}
        />
      </form>

      <hr />

      {jobPost && (
        <>
          <div className="text-center">
            <h3>Expressions of interest</h3>
          </div>
          <div className="row py-2 px-1">
            {workers.map(worker => <div
              key={worker.id()}
              className="col-sm-12 col-md-12 col-lg-6 col-xl-4 p-0"
            >
              <JobPostWorkerTile
                worker={worker}
              />
            </div>)}
          </div>
          {
            workers.length === 0 && (
              <div className="d-flex justify-content-center">
                <div
                  className="alert alert-info mt-2 text-center"
                  role="alert"
                  style={{ maxWidth: 505 }}
                >
                  There are no expressions of interest from workers for this job post yet.
                </div>
              </div>
            )
          }
        </>
      )}
    </div>
  );
};