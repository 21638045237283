import moment from 'moment';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Sticky } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';
import { jobPostsPath } from '../../Routes';
import { BrandColors } from '../../helpers';
import { JobPostModel } from '../../models/JobPost';

interface JobPostTileProps {
  jobPost: JobPostModel;
}

export const  JobPostTile = (props: JobPostTileProps): JSX.Element => {
  const jobPost = props.jobPost;
  const client = jobPost.clientSync();
  const history = useHistory();
  const path = `${jobPostsPath}/${jobPost.object.id}`;
  const photoUrl = client?.photo() ?? `${window.location.origin}/logo192.png`;

  return (
    <a
      className="link-tile cursor-pointer p-1 text-left"
      href={path}
      rel="noreferrer"
      onClick={(event) => {
        event.preventDefault();
        history.push(path);
      }}
    >
      <div
        className="d-flex justify-content-between border rounded p-1 hover-item-active"
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            <h5 className="pr-0 mr-1 mb-0">{jobPost.clientSync()?.fullName() ?? jobPost.clientFirstName()}</h5>
          </div>
          <p className="pr-0 mr-1">Posted {moment(jobPost.createdAt()).format('D MMMM YYYY')}</p>
          <div>
            <span
              className="sm-pill badge badge-pill text-white text-uppercase"
              style={{ backgroundColor: jobPost.isClosed() ? BrandColors.red : BrandColors.green }}
            >{jobPost.isClosed() ? 'CLOSED' : 'OPEN'}</span>
            {
              !jobPost.clientSync() && jobPost.clientFirstName() && (
                <span
                  className="sm-pill badge badge-pill text-white text-uppercase ml-1"
                  style={{ backgroundColor: BrandColors.grey }}
                >Prospective</span>
              )
            }
            {
              !jobPost.isClosed() && jobPost.workerIds().length !== 0 && (
                <span
                  className="sm-pill badge badge-pill text-white text-uppercase ml-1"
                  style={{ backgroundColor: BrandColors.green }}
                >{jobPost.workerIds().length}</span>
              )
            }
            {
              jobPost.adminNotes()?.length > 0 && (
                <span
                  className="sm-pill badge badge-pill text-white text-uppercase ml-1"
                  style={{ backgroundColor: BrandColors.orange }}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip_${jobPost.id()}`}>
                        {jobPost.adminNotes()}
                      </Tooltip>
                    }
                  >
                    <div style={{ transform: 'translateY(-1px)' }}>
                      <Sticky />
                    </div>
                  </OverlayTrigger>
                </span>
              )
            }
          </div>
        </div>
        <div>

          <img
            src={photoUrl}
            alt="avatar"
            style={{ width: 56, height: 56, borderRadius: '50%', margin: 'auto', objectFit: 'cover' }}
          />
        </div>
      </div>
    </a>
  );
};