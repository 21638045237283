import Parse from 'parse';
import React, { useState } from 'react';
import { ChangePasswordView } from '../../views/ChangePassword';

export const ChangePasswordContainer: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [error, setError] = useState('');

  const handleSave = async (currentPassword: string, newPassword: string) => {
    const currentUser = Parse.User.current();
    
    if (!currentUser) {
      setError('You are not signed in');
      return;
    }

    const username = currentUser.get('username');
    
    if (username) {
      setIsSaving(true);
      setIsSaved(false);
      setError('');

      try {
        // Attempt login with the old password
        const parseUser = await Parse.User.logIn(username, currentPassword);
        if (!parseUser) {
          throw new Error('Invalid login');
        }

        // Change their password
        currentUser.set('password', newPassword);
        const changed = await currentUser.save();
        setIsSaved(true);

        // Reset the saved trigger
        setTimeout(() => {
          setIsSaved(false);
        }, 500);
      } catch (error) {
        // Failed login
        setError('Your current password was invalid');
      }
    }
    
    setIsSaving(false);
  };

  return (
    <div className="mt-4">
      <ChangePasswordView
        isSaving={isSaving}
        isUpdated={isSaved}
        error={error}
        onSave={handleSave}
      />
    </div>
  );
};