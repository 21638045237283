import React, { useEffect, useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { jobPostsPath, loginPath } from '../../Routes';
import session from '../../Session';
import { Loading } from '../../components/Loading';
import { ToolBar } from '../../components/Toolbar';
import { JobPostsContainer } from '../../containers/JobPosts';
import { fromStorage, toStorage } from '../../helpers/sessionAndBillingUtils';
import { AdminUser } from '../../models/AdminUser';
import { JobPostModel } from '../../models/JobPost';
import { JobPostTile } from './JobPostTile';

interface JobPostsViewProps {
  isLoading: boolean;
  jobPosts?: JobPostModel[];
  adminUsers?: AdminUser[];
}

interface AdminUserDetails {
  id: string;
  name: string;
}

type JobStatus = 'open' | 'closed';

const JOBPOST_SEARCH_KEY = 'suitsme.search.jobpost';

export const JobPosts: React.FC = () => {
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  return <JobPostsContainer />;
};

export const JobPostsView: React.FC<JobPostsViewProps> = ({ isLoading, jobPosts, adminUsers }) => {
  const [filteredItems, setFilteredItems] = useState<JobPostModel[]>(jobPosts ?? []);
  const [searchTerm, setSearchTerm] = useState(fromStorage(JOBPOST_SEARCH_KEY) || '');
  const [statuses, setStatuses] = useState<Set<JobStatus>>(new Set(['open', 'closed']));
  const [selectedManager, setSelectedManager] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (!jobPosts) { return; }
    toStorage(searchTerm, JOBPOST_SEARCH_KEY);
    let filtered = jobPosts.filter(job => {
      const clientName = job.clientSync()?.fullName() || job.clientFirstName() || '';
      const searchMatch = clientName.toLowerCase().includes(searchTerm.toLowerCase()) || job.id() === searchTerm;
      const statusMatch = job.isClosed() && statuses.has('closed') || !job.isClosed() && statuses.has('open');
      return searchMatch && statusMatch;
    });

    if (selectedManager) {
      let mgrType: 'caseManager' | 'experienceOfficer' | null = null;
      let mgrId = '';

      if (selectedManager.includes('CASEMANAGER')) {
        mgrType = 'caseManager';
        mgrId = selectedManager.replace('CASEMANAGER_', '');
      } else if (selectedManager.includes('EXPERIENCEOFFICER')) {
        mgrType = 'experienceOfficer';
        mgrId = selectedManager.replace('EXPERIENCEOFFICER_', '');
      }

      if (mgrType === 'caseManager') {
        filtered = filtered.filter((job) => {
          return job.clientSync()?.caseManagerDetailsSync().id === mgrId;
        });
      } else if (mgrType === 'experienceOfficer') {
        filtered = filtered.filter((job) => {
          return job.clientSync()?.experienceOfficerDetailsSync().id === mgrId;
        });
      }
    }

    setFilteredItems(filtered);
  }, [searchTerm, jobPosts, statuses, selectedManager]);

  /**
   * Gets unique list of all case managers for job posts
   */
  const allCaseManagers = useMemo<AdminUserDetails[]>(() => {
    const items: AdminUserDetails[] = [];
    if (!jobPosts || !adminUsers) {
      return items;
    }
    
    jobPosts.forEach((post) => {
      if (post.clientSync()?.caseManagerDetailsSync()) {
        const entry: AdminUserDetails = {
          name: post.clientSync()?.caseManagerDetailsSync().name ?? '',
          id: post.clientSync()?.caseManagerDetailsSync().id ?? '',
        };
  
        if (!items.find(item => item.id == entry.id) && entry.id) {
          items.push(entry);
        }
      }
    });

    return items;
  }, [jobPosts, adminUsers]);

  /**
   * Gets unique list of all experience officers for job posts
   */
  const allExperienceOfficers = useMemo<AdminUserDetails[]>(() => {
    const items: AdminUserDetails[] = [];
    if (!jobPosts || !adminUsers) {
      return items;
    }
    
    jobPosts.forEach((post) => {
      if (post.clientSync()?.experienceOfficerDetailsSync()) {
        const entry: AdminUserDetails = {
          name: post.clientSync()?.experienceOfficerDetailsSync().name ?? '',
          id: post.clientSync()?.experienceOfficerDetailsSync().id ?? '',
        };
  
        if (!items.find(item => item.id == entry.id) && entry.id) {
          items.push(entry);
        }
      }
    });

    return items;
  }, [jobPosts, adminUsers]);
  

  /**
   * Toggle the open or closed checkbox filters
   */
  function handleToggleStatus(value: 'open' | 'closed'): void {
    const newStatuses: Set<JobStatus> = new Set(['open', 'closed']);
    
    if (value === 'open') {
      if (statuses.has('open')) {
        newStatuses.delete('open');
      }
    } else if (value === 'closed') {
      if (statuses.has('closed')) {
        newStatuses.delete('closed');
      }
    }

    setStatuses(newStatuses);
  }

  /**
   * Handler for selecting a case manager or experience officer
   */
  function handleSelectManager(value: string): void {
    setSelectedManager(value);
  }

  return (
    <div className="mx-4">
      <ToolBar
        title="Jobs board"
        top={
          <>
            <button
              type="button"
              className="btn btn-primary mr-2 my-auto"
              onClick={() => history.push(`${jobPostsPath}/_create`)}
            >Create</button>
            <div className="form-inline col-4">
              <input
                className="form-control w-100"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </>
        }
        filters={
          <div className="mx-4 pb-2">
            <Form className="row d-flex align-items-center">
              <div className="col-1">
                <input
                  id="open"
                  className="form-check-input"
                  type="checkbox"
                  name="open"
                  checked={statuses.has('open')}
                  value="open"
                  disabled={isLoading}
                  onChange={e => handleToggleStatus('open')}
                />
                <label
                  htmlFor="open"
                  className="form-check-label font-weight-normal text-white"
                >Open</label>
              </div>
              <div
                className="col-1"
              >
                <input
                  id="closed"
                  className="form-check-input"
                  type="checkbox"
                  name="closed"
                  checked={statuses.has('closed')}
                  value="closed"
                  disabled={isLoading}
                  onChange={e => handleToggleStatus('closed')}
                />
                <label
                  htmlFor="closed"
                  className="form-check-label font-weight-normal text-white"
                >Closed</label>
              </div>
              <div className="col-3">
                <select
                  id="manager"
                  className="form-control"
                  value={selectedManager}
                  onChange={e => handleSelectManager(e.target.value)}
                  disabled={isLoading}
                >
                  <option value="">All managers</option>
                  <optgroup label="Case managers">
                    {allCaseManagers.map(admin => {
                      return <option
                        key={admin.id}
                        value={`CASEMANAGER_${admin.id}`}
                      >{admin.name}</option>;
                    })}
                  </optgroup>
                  <optgroup label="Experience officers">
                    {allExperienceOfficers.map(admin => {
                      return <option
                        key={admin.id}
                        value={`EXPERIENCEOFFICER_${admin.id}`}
                      >{admin.name}</option>;
                    })}
                  </optgroup>
                </select>
              </div>
            </Form>
          </div>
        }
      />

      {isLoading && <Loading />}

      {searchTerm !== '' &&
        <div
          className="row mt-2"
        >
          <div className="col-12 text-info text-center">{`Showing job posts for "${searchTerm}"`}</div>
        </div>
      }

      {filteredItems &&
        <div className="row py-2 px-1">
          {filteredItems.map(item => <div
            key={item.id()}
            className="col-sm-12 col-md-12 col-lg-6 col-xl-4 p-0"
          >
            <JobPostTile
              jobPost={item}
            />
          </div>)}
        </div>
      }
    </div>
  );
};