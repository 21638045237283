import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalManager, ModalManagerListener } from './manager';

export interface ToastProps {
  show: boolean;
  onClose: () => void;
  title: string;
  message: string;
}

export const ErrorModal: React.FC<ToastProps> = ({ title, message, show, onClose }) => {
  return (
    <Modal
      show={show}
      centered
      onHide={() => onClose()}
    >
      <Modal.Header><Modal.Title>{title}</Modal.Title></Modal.Header>
      <Modal.Body>{message}</Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-primary"
          onClick={() => onClose()}
        >Ok</button>
      </Modal.Footer>
    </Modal>
  );
};


export class ModalContainer extends React.Component implements ModalManagerListener {
  state = {
    show: false,
    title: '',
    message: '',

    onClose: undefined as (() => void) | undefined,
  };

  componentDidMount() {
    ModalManager.shared().registerListener(this);
  }

  show(title: string, message: string, onClose?: () => void): void {
    this.setState({ show: true, title, message, onClose });
  }

  render(): JSX.Element {
    return <ErrorModal
      show={this.state.show}
      title={this.state.title}
      message={this.state.message}
      onClose={() => {
        this.setState({ show: false });
        if (this.state.onClose) { this.state.onClose(); }
      }}
    />;
  }

}

