import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import { UserStatus } from '../../enums/UserStatus';
import ClientManager from '../../managers/ClientManager';
import JobPostManager, { JobPostState } from '../../managers/JobPosts';
import { ClientModel } from '../../models/Client';
import { JobPostModel } from '../../models/JobPost';
import { JobPostView } from '../../views/JobPost';

interface JobPostContainerProps {
  jobPostId: string;
}

export const JobPostContainer: React.FC<JobPostContainerProps> = ({ jobPostId }) => {
  const isNewPost = jobPostId === '_create';
  const [jobPost, setJobPost] = useState<JobPostModel | undefined>(undefined);
  const [clients, setClients] = useState<ClientModel[] | undefined>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const downloadData = async () => {
      if (!isNewPost) {
        const job = await JobPostManager.instance().getJobPost(jobPostId, true);
        setJobPost(job);
      }
      
      let clients = await ClientManager.getClientsAsync();
      clients = clients.sort((client1, client2) => client1.fullName().localeCompare(client2.fullName()));
      setClients(clients.filter(client => client.status() === UserStatus.Active));
    };

    setIsLoading(true);
    void downloadData().then(() => setIsLoading(false));

  }, [jobPostId]);

  async function handleSave(state: JobPostState): Promise<void> {
    setIsSaving(true);
    if (jobPost) {
      const savedModel = await JobPostManager.instance().updateJobPostDetails(jobPost, state);
      setJobPost(savedModel);
    } else {
      const newModel = await JobPostManager.instance().createJobPost(state);
      setJobPost(newModel);
    }
    setIsSaving(false);
  }

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && <JobPostView
        jobPost={jobPost}
        isLoading={isLoading}
        clients={clients ?? []}
        onSave={handleSave}
        disableInteraction={isLoading || isSaving}
      />}
    </>
  );

};
