import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { loginPath } from '../../Routes';
import session from '../../Session';
import { CreateSessionContainer } from '../../containers/CreateSession';
import { SessionContainer } from '../../containers/Session';
import { SessionRequestsContainer } from '../../containers/SessionRequest';
import { SessionsContainer } from '../../containers/Sessions';

export const Sessions: React.FC = () => {
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  const { filter } = useParams() as { filter?: string };
  const pathName = useLocation().pathname;

  if (filter === '_create') {
    return <CreateSessionContainer />;
  } else if (!filter) {
    return <SessionsContainer />;
  }

  if (pathName.startsWith('/session-requests')) {
    return <SessionRequestsContainer id={filter} />;
  }
  return <SessionContainer id={filter} />;
};
