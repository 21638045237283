import _isEqual from 'lodash/isEqual';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { v4 as uuidV4 } from 'uuid';
import { BudgetAdjustmentEditor } from '../../../components/BudgetAdjustment/Editor';
import { Loading } from '../../../components/Loading';
import { BudgetAdjustmentState, getBudgetAdjustmentsForSupportNeed, getBudgetAdjustmentState, getBudgetAdjustmentStateForCompare, saveBudgetAdjustment } from '../../../managers/BudgetAdjustments';
import { SupportNeedState } from '../../../managers/SupportNeeds';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { BudgetAdjustmentModel } from '../../../models/BudgetAdjustment';
import { SaveButton } from '../../../views/Client/SaveButton';

export interface BudgetAdjustmentsContainerProps {
  supportNeed: SupportNeedState;
  clientId: string;
  onUpdated(): void;
  onStateModified?(hasModifiedItems: boolean): void;
}

export const BudgetAdjustmentsContainer: React.FC<BudgetAdjustmentsContainerProps> = ({
  supportNeed,
  clientId,
  onUpdated,
  onStateModified,
}) =>  {
  const [adjustments, setAdjustments] = useState<BudgetAdjustmentModel[]>([]);
  const [adjustmentsState, setAdjustmentsState] = useState<BudgetAdjustmentState[]>([]);
  const [invalidStateIds, setInvalidStateIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!supportNeed || !supportNeed.id) { return; }
    setIsLoading(true);
    void getBudgetAdjustmentsForSupportNeed(supportNeed.id).then(results => {
      setIsLoading(false);
      if (results?.length > 0) {
        setAdjustments(results);
        setAdjustmentsState(results.map((result) => getBudgetAdjustmentState(result)));
      }
    });
  }, [supportNeed]);

  // Report modified changes to the parent component view
  useEffect(() => {
    const hasChanged = getModifiedItems().length > 0;
    if (onStateModified) {
      onStateModified(hasChanged);
    }
  }, [adjustmentsState, getModifiedItems]);

  function createEmptyItem() {
    const newItem = getBudgetAdjustmentState();
    newItem.id = `temp_${uuidV4()}`;
    setAdjustmentsState([...adjustmentsState].concat(newItem));
  }

  function getModelIndex(stateItem: BudgetAdjustmentState): number {
    return adjustments.findIndex((adj) => adj.id() === stateItem.id);
  }

  function getModifiedItems(): BudgetAdjustmentState[] {
    const changedItems: BudgetAdjustmentState[] = [];

    adjustmentsState.forEach((item) => {
      const modelIndex = getModelIndex(item);
      
      if (modelIndex > -1) {
        const compareModel = getBudgetAdjustmentStateForCompare(adjustments[modelIndex]);
        const compareState = getBudgetAdjustmentStateForCompare(item);
        if (!_isEqual(compareModel, compareState)) {
          changedItems.push(item);
        }
      } else {
        changedItems.push(item);
      }
    });

    return changedItems;
  }

  function handleStateUpdate(updated: BudgetAdjustmentState) {
    const index = adjustmentsState.findIndex((adj) => adj.id === updated.id);
    const newState = [...adjustmentsState];
    newState[index] = updated;
    setAdjustmentsState(newState);
  }

  function handleAdd() {
    createEmptyItem();
  }

  function handleDelete(state: BudgetAdjustmentState) {
    if (state.createdAt) {
      if (window.confirm('Are you sure you want to delete this adjustment?')) {
        handleStateUpdate({ ...state, deletedAt: new Date() });
      }
    } else {
      // If it was never saved, just clear it
      setAdjustmentsState(adjustmentsState.filter((adj) => adj.id !== state.id));
    }
  }

  async function handleSave() {
    // Check validation state before proceeding with save
    if (invalidStateIds.length > 0) {
      return;
    }

    setIsSaving(true);
    const modified = getModifiedItems();
    const models = [...adjustments];
    const states = [...adjustmentsState];

    for (const item of modified) {
      const modelIndex = getModelIndex(item);
      if (modelIndex > -1) {
        const savedModel = await saveBudgetAdjustment(models[modelIndex], item);
        models[modelIndex] = savedModel;
      } else {
        const newModel = BudgetAdjustmentModel.new();
        newModel.loadState({
          ...item,
          supportNeedId: supportNeed.id,
          clientId,
        });
        const savedModel = await saveBudgetAdjustment(newModel, item);
        models.push(savedModel);

        // Update the state object ID now
        const stateIndex = states.findIndex((adj) => adj.id === item.id);
        if (stateIndex > -1) {
          states[stateIndex] = getBudgetAdjustmentState(savedModel);
        }
      }
    }

    setAdjustments(models);
    setAdjustmentsState(states);
    setIsSaving(false);
    ToastManager.shared().show('Budget adjustments saved.');
    onUpdated();
  }

  async function handleCancel() {
    if (getModifiedItems().length > 0) {
      if (window.confirm('You have unsaved changes, do you wish to continue?')) {
        history.push(`/clients/${clientId}/supportPlan`);
      }
    } else {
      history.push(`/clients/${clientId}/supportPlan`);
    }
  }

  function handleValidStateChange(itemId: string, isValid: boolean) {
    let newInvalidStateIds = [...invalidStateIds];
    let isChanged = false;

    if (isValid && newInvalidStateIds.includes(itemId)) {
      newInvalidStateIds = newInvalidStateIds.filter((item) => item !== itemId);
      isChanged = true;
    } else if (!isValid && !newInvalidStateIds.includes(itemId)) {
      newInvalidStateIds.push(itemId);
      isChanged = true;
    }

    if (isChanged) {
      setInvalidStateIds(newInvalidStateIds);
    }
  }

  const isSaveEnabled = useMemo(() => {
    return getModifiedItems().length > 0 && invalidStateIds.length === 0 && !isSaving && !isLoading;
  }, [adjustmentsState, invalidStateIds, isSaving, isLoading]);

  return (
    <div>
      {isLoading && <Loading />}
      {
        !isLoading && adjustmentsState.length > 0 && (
          <>
            {adjustmentsState.filter((adj) => !adj.deletedAt).map((adjustment, index) => {
              return (
                <div key={`${adjustment.id}-${index}`}>
                  <BudgetAdjustmentEditor
                    state={adjustment}
                    supportNeedType={supportNeed.type}
                    onStateUpdate={handleStateUpdate}
                    onPromptDelete={handleDelete}
                    onValidStateChange={handleValidStateChange}
                    disableInteraction={isLoading || isSaving}
                  />
                  <hr />
                </div>);
            })}
            <SaveButton
              onSave={handleSave}
              onCancel={handleCancel}
              disableSave={!isSaveEnabled}
              disableCancel={isSaving}
            />
          </>
        )
      }
      {
        !isLoading && adjustmentsState.length === 0 && (
          <div className="col-12 text-center">
            <p>No adjustments</p>
          </div>
        )
      }
      <button
        type="button"
        disabled={isLoading || isSaving}
        className="btn btn-primary m-1"
        onClick={handleAdd}
      >Add</button>
    </div>
  );
};