import moment, { Moment } from 'moment';
import Session from '../Session';
import { AustralianState, ianaTimezoneForState } from '../enums/AustralianState';
import { SessionStatus } from '../models/Sessions';
import { BaseSessionModel } from '../models/Sessions/BaseSession';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const Compress = require('compress.js');

export function isProd(): boolean {
  return window.location.hostname === 'pg-app-cizdkhwc0ykykqb10er7vef6fecdsb.scalabl.cloud';
}

export function isLocalDev(): boolean {
  return window.location.hostname.includes('localhost');
}

export function isBetaAdmin(): boolean {
  if (!isProd()) {
    return true;
  }

  const userId = Session.getUser()?.user.id ?? '';
  // Shane & James, "hello@suitsme.app", and "trixie@suitsme.app"  usernames for beta feature access in prod
  return ['ISZ3j54bfP', '6eWWdEyI1g', '5DFQPStX6O', 'nSVdn9Aphe'].includes(userId);
}

export function displayString(str: string | undefined): string {
  return str ? str : '';
}

export const BusinessLogic = {
  Company: {
    minimumCancellationNoticeMinutes: 120,
    sessionStartTime: '07:00',
    sessionEndTime: '19:00',
    defaultWorkerTravelRadius: 100,
  },
};

export const BrandColors = {
  darkBlue: '#313A46',
  red: '#DC584F',
  pink: '#E37972',
  lightGrey: '#dee2e6',
  orange: '#FF9856',
  green: '#93C81C',
  grey: '#6E757C',
};

export function formatDate(date: Date | string, formatString: string): string {
  return moment(date).format(formatString);
}

export function formatDateWithState(date: Date | string, formatString: string, state: AustralianState = AustralianState.wa): string {
  try {
    return moment(date).tz(ianaTimezoneForState(state)).format(formatString);
  } catch {
    return '';
  }
}

export function formatDuration(ms: number): string {
  if (ms === 0) { return '0'; }
  const duration = moment.duration(ms, 'milliseconds');
  const statement = [
    duration.hours() > 0 ? `${duration.hours()} ${duration.hours() === 1 ? 'hour' : 'hours'}` : false,
    duration.minutes() > 0 ? `${duration.minutes()} ${duration.minutes() === 1 ? 'minute' : 'minutes'}` : false,
  ].filter(Boolean).join(', ');

  return statement;
}

export function formatDurationRounded(ms: number): string {
  if (ms === 0) { return '0'; }
  const isNeg = ms < 0;

  const duration = moment.duration(Math.abs(ms), 'milliseconds');
  const num = duration.hours() + (duration.minutes() / 60);
  const rounded  = Math.round(num * 2) / 2;

  return `${isNeg ? '-' : '+'}${rounded} ${rounded === 1 ? 'hour' : 'hours'}`;
}


export function formatPercentage(decimal: number): string {
  return decimal.toLocaleString(undefined, { maximumFractionDigits: 0, style: 'percent'  });
}


export function formatMoney(cents: number): string {
  return (cents / 100).toLocaleString('en-AU', { style: 'currency', currency: 'AUD' });
}

/**
 * Formats money but replaces a negative amount with a string like "$100 CR"
 */
export function formatMoneyWithNegativeCheck(cents: number, suffix = 'CR'): string {
  if (cents < 0) {
    return `${formatMoney(Math.abs(cents))} ${suffix}`;
  }

  return formatMoney(cents);
}

export function formatDistance(kms: number): string {
  return `${kms.toLocaleString(undefined, { maximumFractionDigits: 2 })} KM`;
}

export function convertDateToZone(date: Date, state: AustralianState = AustralianState.wa): Date {
  const newDate = new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
  ));

  const utcDate = new Date(newDate.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(newDate.toLocaleString('en-US', { timeZone: ianaTimezoneForState(state) }));
  const offset = utcDate.getTime() - tzDate.getTime();
  newDate.setTime(newDate.getTime() + offset);
  
  return newDate;
}

export function combineDateAndTime(datePortion: Date | undefined, timePortion: Date | undefined): Date {
  if (datePortion && timePortion) {
    const date = new Date(datePortion ?? timePortion);
    date.setHours(timePortion.getHours());
    date.setMinutes(timePortion.getMinutes());
    date.setSeconds(timePortion.getSeconds());
    return date;
  }

  if (datePortion && !timePortion) { return datePortion; }
  if (timePortion && !datePortion) { return timePortion; }
  return new Date();
}

export function colorForSupportSession(session: BaseSessionModel): string {
  switch (session.status()) {
    case SessionStatus.notAccepted:
      return BrandColors.red;
    case SessionStatus.declined:
    case SessionStatus.cancelledByAdmin:
    case SessionStatus.cancelledByClient:
    case SessionStatus.cancelledByWorker:
    case SessionStatus.noShow:
      return BrandColors.red;
    case SessionStatus.pendingClientApproval:
    case SessionStatus.pendingWorkerApproval:
    case SessionStatus.confirmed:
      return BrandColors.orange;
    case SessionStatus.completed:
    case SessionStatus.suggested:
      return BrandColors.green;
    case SessionStatus.needsFinalisation:
      return BrandColors.pink;
    default:
      return BrandColors.grey;
  }
}

export async function resizeFile(file: File): Promise<File> {
  const compress = new Compress();
  const compressed = await compress.compress([file], {
    quality: .5,
    maxWidth: 400,
    maxHeight: 400,
  });
  const base64str = compressed[0].data;
  const imgExt = compressed[0].ext;
  return Compress.convertBase64ToFile(base64str, imgExt);
}

export function last<T>(array: T[]): T {
  return array[array.length - 1];
}

export function downloadDataUrlFromJavascript(filename: string, dataUrl:string): void {
  // Construct the 'a' element
  const link = document.createElement('a');
  link.download = filename;
  link.target = '_blank';

  // Construct the URI
  link.href = dataUrl;
  document.body.appendChild(link);
  link.click();

  // Cleanup the DOM
  document.body.removeChild(link);
  // delete link;
}

/**
 * Simple time delay helper
 */
export function sleep(ms: number): Promise<void> {
  return new Promise<void>((res) => setTimeout(res, ms));
}

/**
 * Get a string age range from a DOB date value
 * 
 * Possible ranges are as follows:
 * 18-24
 * 25-34
 * 35-44
 * 45-54
 * 55-64
 * 65+
 * Unknown 
 */
export function getAgeRangeFromDOB(dob: Date | Moment | undefined): string {
  if (!dob) {
    return 'Unknown';
  }
  
  const years = moment().diff(dob, 'years');
  let res: string = 'Unknown';
  res = years < 18 ? 'Under 18' : res;
  res = years >= 18 ? '18-24' : res;
  res = years >= 25 ? '25-34' : res;
  res = years >= 35 ? '35-44' : res;
  res = years >= 45 ? '45-54' : res;
  res = years >= 55 ? '55-64' : res;
  res = years >= 65 ? '65 or over' : res;
  return res;
}