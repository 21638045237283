import React, { useEffect, useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { clientBudget, ClientBudgetInsights } from '../../../../managers/Insights';
import { ClientModel } from '../../../../models/Client';
import { ClientBudgetInsightsView } from '../../../../views/Client/Insights/Budget';
import { DEFAULT_DELAY, useCanLoad } from '../../util';


export interface ClientBudgetInsightsContainerProps {
  client?: ClientModel;
}

export const ClientBudgetInsightsContainer: React.FC<ClientBudgetInsightsContainerProps> = ({ client }) => {
  const [insights, setInsights] = useState<ClientBudgetInsights[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const canLoad = useCanLoad('insights', DEFAULT_DELAY);

  useEffect(() => {
    if (!canLoad || !client) { return; }
    setIsLoading(true);
    void clientBudget(client.id()).then(results => {
      setInsights(results);
      setIsLoading(false);
    });
  }, [canLoad, client]);



  return (
    <>
      {isLoading &&
        <Loading />
      }

      {insights &&
        <ClientBudgetInsightsView
          insights={insights}
        />
      }
    </>
  );
};