import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import SessionsManager from '../../../managers/Sessions';
import { SupportSessionModel } from '../../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../../models/Sessions/SupportSessionRequest';
import { WorkerModel } from '../../../models/Worker';
import { GenericSessionsView } from '../../../views/Client/Sessions';

export interface WorkerSessionsContainerProps {
  worker: WorkerModel;
}

export const WorkerSessionsContainer: React.FC<WorkerSessionsContainerProps> = ({ worker }) => {
  const [requests, setRequests] = useState<SupportSessionRequestModel[] | undefined>(undefined);

  const [sessions, setSessions] = useState<SupportSessionModel[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const workerId = worker.id();
      if (!workerId) { return; }
      setIsLoading(true);

      const [sessionResults, requestResults] = await Promise.all([
        SessionsManager.getSessionsForWorker(workerId),
        SessionsManager.getSessionRequestsForWorker(workerId),
      ]);

      if (sessionResults) {
        setSessions(sessionResults.map(result => new SupportSessionModel(result)));
      }
      if (requestResults) {
        setRequests(requestResults.map(result => new SupportSessionRequestModel(result)).sort((session1, session2) => moment(session2.firstDateStart()).valueOf() - moment(session1.firstDateStart()).valueOf()));
      }

      setIsLoading(false);
    };


    void fetchData();

  }, [worker]);

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {!isLoading &&
        <GenericSessionsView
          sessions={sessions || []}
          requests={requests || []}
          isClient={false}
          workerId={worker.id()}
        />
      }
    </>
  );
};