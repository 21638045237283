import moment from 'moment';
import Parse from 'parse';
import { CancellationAlertsResponse } from '../Alerts';

export interface BasicInsights {
  sessions: {
    booked: number;
    completed: number;
    declined: number;
    cancelledByClient: number;
    cancelledByWorker: number;
  };
}

export interface BillableCancellationInsights {
  windows: {
    sessions: number;
    start: string;
    end: string;
  }[];
  sessions: number;
  latest: number;
}

export async function basicInsights(clientId: string, startDate: Date, endDate: Date): Promise<BasicInsights> {
  const insights = await Parse.Cloud.run('insights-basic', {
    clientId,
    startDate,
    endDate,
  });
  return insights;
}

export async function lastActiveInsight(clientId: string): Promise<Date> {
  const insights = await Parse.Cloud.run('insights-lastActive', {
    clientId,
  });

  const date = insights.lastActive;
  return moment(date).toDate();
}


export async function billableCancellationInsight(clientId: string): Promise<CancellationAlertsResponse> {
  const insights = await Parse.Cloud.run('insights-billableCancellations', {
    clientId,
  }) as CancellationAlertsResponse;

  return insights;
}


export interface ClientBudgetInsights {
  need: {
    needId: string;
    type: number;
  };
  starting: number;
  expectedSpendPerWeek: number;
  actualSpendPerWeek: number;
  expectedSpendToDate: number;
  actualSpendToDate: number;
  variance: number;
}

export async function clientBudget(clientId: string): Promise<ClientBudgetInsights[]> {
  const insights = await Parse.Cloud.run('insights-clientBudget', {
    clientId,
  }) as ClientBudgetInsights[];

  return insights;
}