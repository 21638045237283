import React from 'react';

export enum SimpleLabelledValueAction {
  EDIT = 'Edit',
}

interface SimpleLabelledValueProps {
  title: string;
  value: string | number | undefined;
  inputClassName?: string;
  action?: SimpleLabelledValueAction | undefined;
  onClickAction?(value: SimpleLabelledValueAction | undefined): void;
}

export const SimpleLabelledValue: React.FC<SimpleLabelledValueProps> = ({ title, value, inputClassName = '', action, onClickAction }) => (
  <>
    <label
      htmlFor={title}
      className="col-2 col-form-label text-right mb-1"
    >{title}</label>
    <input
      className={`col-4 form-control mb-1 ${inputClassName}`}
      type="text"
      value={value}
      readOnly
      id={title}
    />
    {
      action && onClickAction && <span
        style={{ position: 'absolute', right: 40, marginTop: 2 }}
      >
        <button
          className="btn btn-link font-weight-bold"
          onClick={() => onClickAction(action)}
        >{action}
        </button>
      </span>
    }
  </>
);