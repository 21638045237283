import { AdminType, getAdminTypeDescription } from '../enums/AdminType';

export class AdminUser {
  readonly user: Parse.User;
  admin?: Parse.Object;

  constructor(user: Parse.User, admin?: Parse.Object) {
    this.user = user;
    this.admin = admin;
  }

  id(): string { return this.admin?.id ?? ''; }
  innerUserId(): string { return this.user.id; }
  username(): string { return this.user.get('username') ?? ''; }
  fullName(): string { return `${this.admin?.get('firstName') ?? ''} ${this.admin?.get('lastName') ?? ''}`; }
  type(): AdminType { return this.admin?.get('type') ?? AdminType.unknown; }
  typeDescription(): string { return getAdminTypeDescription(this.admin?.get('type')); }
  deletedAt(): Date | undefined { return this.admin?.get('deletedAt') ?? undefined; }

  photoUrl(): string | undefined {
    const file = this.admin?.get('photo') as Parse.File;
    return file ? file.url() : undefined;
  }

  photo(): string {
    const file = this.admin?.get('photo') as Parse.File;
    return file ? file.url() : `${window.location.origin}/logo192.png`;
  }
}