import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment';
import React, { CSSProperties, useMemo } from 'react';
import { ExclamationTriangleFill } from 'react-bootstrap-icons';
import DatePicker from 'react-datepicker';
import { NearingDate } from '../../../components/NearingDate';
import { AlertContainer } from '../../../containers/Alert';
import { ClientSupportPlanState } from '../../../containers/Client/SupportPlan';
import { BudgetAdjustmentType } from '../../../enums/BudgetAdjustmentType';
import { SupportNeedModelTypeCases } from '../../../enums/SupportNeedModelType';
import { AlertMuteAffectedUserType, AlertTypes } from '../../../managers/Alerts';
import { NeedSummaryResult, UnallocatedCost } from '../../../managers/ClientNeedBudgets';
import { PlanManagerState } from '../../../managers/PlanManagers';
import { getSupportNeedState } from '../../../managers/SupportNeeds';
import { BudgetAdjustmentModel } from '../../../models/BudgetAdjustment';
import { PlanManagerModel } from '../../../models/PlanManager';
import { SupportPlanModel } from '../../../models/SupportPlan';
import { AddSupportNeed } from '../addSupportNeed';
import { SaveButton } from '../SaveButton';
import { BudgetAdjustmentStatusList } from './BudgetAdjustments/BudgetAdjustmentStatusList';
import { NDISBookingsSelector } from './NDISBookingsSelector';
import { SupportNeedView } from './SupportNeeds/SupportNeedView';
import { UnallocatedNeedBudgetView } from './SupportNeeds/UnallocatedNeedBudgetView';
import { SupportPlanSelector } from './SupportPlanSelector';


interface ClientSupportPlanViewProps {
  state: ClientSupportPlanState;
  onStateUpdated:(state: ClientSupportPlanState) => void;
  originalState: ClientSupportPlanState;
  clientId: string;

  plans: SupportPlanModel[];
  selectedPlan: SupportPlanModel | undefined;
  planManagers: PlanManagerModel[];
  budgetSummaries: NeedSummaryResult[];
  budgetAdjustments: BudgetAdjustmentModel[];
  unallocatedCost: UnallocatedCost | undefined;

  onPlanManagerCreated:(details: PlanManagerState) => Promise<PlanManagerModel>;

  onSave:(state: ClientSupportPlanState) => Promise<void>;
  onSelectPlan(plan: SupportPlanModel): void;
  onAddNewPlan(): void;
  onCancelNewPlan(): void;
  onDeletePlan(plan: SupportPlanModel | undefined): void;

  interactionDisabled: boolean;
}

export const ClientSupportPlanView: React.FC<ClientSupportPlanViewProps> = ({ state, onStateUpdated, originalState, clientId, plans, selectedPlan, planManagers, budgetSummaries, unallocatedCost, budgetAdjustments, onPlanManagerCreated, onSave, onSelectPlan, onAddNewPlan, onCancelNewPlan, onDeletePlan, interactionDisabled }) => {
  // Find which support need types the client does not yet use
  const availableAddNeedTypes = useMemo(() => {
    return SupportNeedModelTypeCases.filter(type => !state.needs.find((need) => need.type === type && !need.isDeleted));
  }, [state.needs]);

  function handleClickCancel(): void {
    if (selectedPlan) {
      onStateUpdated(originalState);
    } else {
      onCancelNewPlan();
    }
  }

  // Disable UI when loading and saving
  const containerStyle: CSSProperties = interactionDisabled ? { pointerEvents: 'none', opacity: 0.5 } : {};
  
  return (
    <div
      className="container pb-2 pt-4"
      style={containerStyle}
    >
      <form>
        <div className="row form-group">
          <label
            htmlFor="ndisNumber"
            className="col-2 col-form-label text-right mb-2"
          >NDIS Number</label>
          <input
            id="ndisNumber"
            type="text"
            className={classNames('col-2 form-control', { 'is-invalid': state.ndisNumber === '' || !state.ndisNumber })}
            value={state.ndisNumber}
            onChange={e => onStateUpdated({ ...state, ndisNumber: e.target.value })}
          />
          <div className="col-8" />

          <NDISBookingsSelector
            booking={state.ndisBooking}
            onChange={ndisBooking => onStateUpdated({ ...state, ndisBooking })}
          />

          <BudgetAdjustmentStatusList
            budgetAdjustments={budgetAdjustments}
            notPaidBudgetType={BudgetAdjustmentType.EstablishmentFee}
          />
        </div>

        <hr />
        
        <div className="row form-group">
          <SupportPlanSelector
            plans={plans}
            selectedPlan={selectedPlan}
            onChange={onSelectPlan}
            onAddNewPlan={onAddNewPlan}
            allowNewPlan
          />

          <div className="col-1 mt-2" />
          <div className="col-10 mt-2 d-flex justify-content-around">
            <div className="mt-1 d-flex">
              <label
                htmlFor="planStartDate"
                className="col-form-label mb-1 mr-1"
              >Start date</label>
              <div
                id="planStartDate"
                className="w-auto form-control border"
              >
                <DatePicker
                  className="w-auto border-0 text-secondary"
                  onChange={(planStartDate: Date) => onStateUpdated({ ...state, planStartDate })}
                  selected={state.planStartDate}
                  dateFormat="d/M/yyyy"
                  minDate={moment().subtract(10, 'years').toDate()}
                  maxDate={moment().add(10, 'years').toDate()}
                  showYearDropdown
                />
              </div>
            </div>
            <div className="mt-1 d-flex">
              <label
                htmlFor="planEndDate"
                className="col-form-label mb-1 mr-1"
              >End date</label>
              <div
                id="planEndDate"
                className="w-auto form-control border"
              >
                <DatePicker
                  className="w-auto border-0 text-secondary"
                  onChange={(planEndDate: Date) => onStateUpdated({ ...state, planEndDate })}
                  selected={state.planEndDate}
                  dateFormat="d/M/yyyy"
                  minDate={moment().toDate()}
                  maxDate={moment().add(10, 'years').toDate()}
                  showYearDropdown
                />
              </div>
              <div className="col-4 mt-1">
                {state.planEndDate &&
                  <>
                    <span className="mr-1">
                      <AlertContainer
                        userType={AlertMuteAffectedUserType.client}
                        userId={clientId}
                        alertTypes={[AlertTypes.planExpired]}
                        subscribe={false}
                      />
                    </span>
                    <NearingDate date={state.planEndDate} />
                  </>
                }
              </div>
            </div>
          </div>
          <div className="col-1 mt-2" />

          {!selectedPlan?.hasActualStartDate() && selectedPlan?.id() && (
            <>
              <div className="col-1 mt-2" />
              <div className="col-10 mt-2 d-flex justify-content-start text-warning">
                <span className="mr-2"><ExclamationTriangleFill /></span>No explicit start date set. Presumed based on creation date.
              </div>
              <div className="col-1 mt-2" />
            </>
          )}
        </div>

        <hr />

        {state.needs.filter(need => !need.isDeleted).map(need => {
          return (
            <SupportNeedView
              key={need.id ?? need.type ?? need.type}
              state={need}
              onStateUpdate={updated => {
                const index = state.needs.findIndex(need => need.type === updated.type && !need.isDeleted);
                const newNeeds = [...state.needs];
                newNeeds[index] = updated;
                onStateUpdated({ ...state, needs: newNeeds });
              }}
              budgetSummary={budgetSummaries.find(summary => summary.needId === need.id)}
              planManagers={planManagers}
              onPlanManagerCreated={onPlanManagerCreated}
              planId={selectedPlan?.id() ?? ''}
              clientId={clientId}
              supportPlanPageUrl="supportPlan"
              needUrlSuffix="needs"
              showSessionsLink
            />);
        })}

        {selectedPlan && (
          <UnallocatedNeedBudgetView
            unallocatedCost={unallocatedCost}
            planId={selectedPlan?.id() ?? ''}
            clientId={clientId}
          />
        )}

        <hr />

        {availableAddNeedTypes.length > 0 &&
          <AddSupportNeed
            key={availableAddNeedTypes.join()}
            options={availableAddNeedTypes}
            onAdded={type => onStateUpdated({ ...state, needs: [...state.needs, { ...getSupportNeedState(), type }] })}
          />
        }

        <hr />

        <div className="row d-flex justify-content-between">
          <div>
            <button
              disabled={interactionDisabled || !selectedPlan}
              type="button"
              className="btn btn-danger m-1"
              onClick={() => onDeletePlan(selectedPlan)}
            >
              Delete plan
            </button>
          </div>
          <div>
            <SaveButton
              onSave={async () => onSave(state) }
              onCancel={handleClickCancel}
              disableCancel={interactionDisabled}
              disableSave={interactionDisabled || _.isEqual(originalState, state) }
            />
          </div>
        </div>

      </form>

    </div>
  );
};




