export enum TransportFunding {
  CORE=1,
  SPECIFIC=2
}

export const TransportFundingCases = [TransportFunding.SPECIFIC, TransportFunding.CORE];

export function getTransportFundingDescription(funding: TransportFunding): string {
  switch (funding) {
    case TransportFunding.CORE: return 'Core';
    case TransportFunding.SPECIFIC: return 'Specific';
  }
}