
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { ModalManager } from '../../Modals/Error/manager';
import { validatePassword, validateRequired } from '../../helpers/password';
import { SaveButton } from '../Client/SaveButton';

interface ChangePasswordViewProps {
  isUpdated: boolean;
  error: string | undefined;
  isSaving: boolean;
  onSave(currentPassword: string, newPassword: string): void;
}

export const ChangePasswordView: React.FC<ChangePasswordViewProps> = ({
  isUpdated,
  error,
  isSaving,
  onSave,
}) => {

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [errors, setErrors] = useState<Record<string, string>>({
    currentPassword: '',
    newPassword1: '',
    newPassword2: '',
  });

  const handleValidate = (): boolean => {
    const newErrors = { ...errors };

    newErrors['currentPassword'] = validateRequired(currentPassword);
    newErrors['newPassword1'] = validateRequired(newPassword1);
    newErrors['newPassword2'] = validateRequired(newPassword2);

    if (newPassword1) {
      newErrors['newPassword1'] = '';
      if (newPassword1 === currentPassword) {
        newErrors['newPassword1'] = 'Same as current password';
      } else if (newPassword1.length < 12) {
        newErrors['newPassword1'] = 'Too short';
      } else if (!validatePassword(newPassword1)) {
        newErrors['newPassword1'] = 'Not strong enough';
      }
    }

    if (newPassword2) {
      newErrors['newPassword2'] = '';
      if (newPassword1 !== newPassword2) {
        newErrors['newPassword2'] = 'Doesn\'t match';
      }
    }

    setErrors(newErrors);
    return Object.values(newErrors).filter((err) => err !== '').length > 0;
  };

  const handleSave = async () => {
    if (!handleValidate()) {
      onSave(currentPassword, newPassword1);
    }
  };

  useEffect(() => {
    if (isUpdated) {
      setCurrentPassword('');
      setNewPassword1('');
      setNewPassword2('');
      setErrors({
        currentPassword: '',
        newPassword1: '',
        newPassword2: '',
      });
      ModalManager.shared().show('Success', 'Your password was changed');
    }
  }, [isUpdated]);

  return (
    <div className="mx-0">
      <div className="bg-dark rounded p-1 pl-2">
        <h2 className="text-white" style={{ fontSize: '1.5rem' }}>Change password</h2>
      </div>
      <div className="mt-2">
        <p><strong>Your password must have the following:</strong></p>
      </div>
      <div className="mt-1">
        <ul>
          <li>An uppercase character</li>
          <li>A lowercase character</li>
          <li>A number</li>
          <li>A special character (e.g. punctuation)</li>
          <li>At least 12 characters</li>
        </ul>
      </div>
      <hr />
      <form>
        <div>
          <div className="row form-group mb-0">
            <label
              htmlFor="currentPassword"
              className="col-2 col-form-label text-right"
              style={{ maxWidth: 190 }}
            >Current password</label>
            <input
              type="password"
              id="currentPassword"
              className={cx('col-3 form-control mb-2', {
                'is-invalid': errors['currentPassword'],
              })}
              value={currentPassword}
              disabled={isSaving}
              onChange={(event) => setCurrentPassword(event.target.value)}
            />
            <div className="mt-2 ml-2 text-danger">{errors['currentPassword']}</div>
          </div>
          <div className="row form-group mb-0">
            <label
              htmlFor="newPassword1"
              className="col-2 col-form-label text-right"
              style={{ maxWidth: 190 }}
            >New password</label>
            <input
              type="password"
              id="newPassword1"
              className={cx('col-3 form-control mb-2', {
                'is-invalid': errors['newPassword1'],
              })}
              value={newPassword1}
              disabled={isSaving}
              onChange={(event) => setNewPassword1(event.target.value)}
            />
            <div className="mt-2 ml-2 text-danger">{errors['newPassword1']}</div>
          </div>
          <div className="row form-group mb-0">
            <label
              htmlFor="newPassword2"
              className="col-2 col-form-label text-right"
              style={{ maxWidth: 190 }}
            >New password again</label>
            <input
              type="password"
              id="newPassword2"
              className={cx('col-3 form-control mb-2', {
                'is-invalid': errors['newPassword2histry'],
              })}
              value={newPassword2}
              disabled={isSaving}
              onChange={(event) => setNewPassword2(event.target.value)}
            />
            <div className="mt-2 ml-2 text-danger">{errors['newPassword2']}</div>
          </div>
          
          <div className="row form-group mt-1">
            <div
              className="col-5 text-right"
              style={{ maxWidth: 567 }}
            >
              {
                error && (
                  <p className="text-danger mb-1">{error}</p>
                )
              }
              <SaveButton
                isSaving={isSaving}
                disableSave={isSaving}
                disableCancel
                hideCancel
                onSave={handleSave}
                onCancel={() => {
                  //
                }}
              />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
