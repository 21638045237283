import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { SupportNeedModelType } from '../../../enums/SupportNeedModelType';
import { sortSessionsAndRequestsByDateDesc } from '../../../helpers/sessionsAndRequests';
import { UnallocatedCost } from '../../../managers/ClientNeedBudgets';
import Sessions from '../../../managers/Sessions';
import { ClientModel } from '../../../models/Client';
import { SessionStatus } from '../../../models/Sessions';
import { SupportSessionModel } from '../../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../../models/Sessions/SupportSessionRequest';
import { SupportPlanModel } from '../../../models/SupportPlan';
import { NeedTypeMap, SessionsUnallocatedView } from '../../../views/Client/SupportPlan/PlanNeedSessions/SessionsUnallocated';

export interface UnallocatedSessionsContainerProps {
  client: ClientModel;
  supportPlan: SupportPlanModel;
  unallocatedCost: UnallocatedCost;
  onCancel(): void;
  onUpdated(): void;
}

export type SessionOrRequest = SupportSessionRequestModel | SupportSessionModel;

/**
 * Takes input of sessions or requests for original state and new state, and filters
 * them based on which items have an updated need type. Ignores "Unknown" need types.
 */
function filterSessionsWithChangedNeedType(items: SessionOrRequest[], needTypes: NeedTypeMap): SessionOrRequest[] {
  const results: SessionOrRequest[] = [];

  items.forEach((item) => {
    const id = String(item.id());
    const type = item.getNeedType();
    if (needTypes[id] && type !== needTypes[id] && needTypes[id] !== SupportNeedModelType.Unknown) {
      item.setNeedType(needTypes[id]);
      results.push(item);
    }
  });

  return results;
}

export const UnallocatedSessionsContainer: React.FC<UnallocatedSessionsContainerProps> = ({
  client,
  supportPlan,
  unallocatedCost,
  onCancel,
  onUpdated,
}) => {
  const [spentItems, setSpentItems] = useState<SupportSessionModel[]>([]);
  const [bookedItems, setBookedItems] = useState<SessionOrRequest[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  async function handleSave(needTypes: NeedTypeMap): Promise<void> {
    setIsSaving(true);
    const changedSpent = filterSessionsWithChangedNeedType(spentItems, needTypes);
    const changedBooked = filterSessionsWithChangedNeedType(bookedItems, needTypes);
    const itemsToSave = [...changedSpent, ...changedBooked];
    const itemIdsUpdated: string[] = [];

    for (const item of itemsToSave) {
      console.log(`[debug] Saving item: ${item.id()} with new type: ${item.getNeedType()}`);
      await item.save();
      itemIdsUpdated.push(item.id() as string);
    }

    // Remove updated items from the current sets of data
    setSpentItems(spentItems.filter(item => !itemIdsUpdated.includes(item.id() as string)));
    setBookedItems(bookedItems.filter(item => !itemIdsUpdated.includes(item.id() as string)));
    setIsSaving(false);
    onUpdated();
  }

  useEffect(() => {
    const fetchData = async () => {
      const clientId = client?.id();
      if (!clientId) { return; }
      setIsLoading(true);

      const results = await Sessions.getSessionsAndRequestsForClientPlan({
        filter: 'plan_unallocated',
        clientId: client.id(),
        planId: supportPlan.id(),
      });

      const spent: SupportSessionModel[] = [];
      const booked: SessionOrRequest[] = [];

      if (results) {
        results.sessions.forEach((session) => {
          const sessionModel = new SupportSessionModel(session);

          if ([SessionStatus.completed, SessionStatus.noShow].includes(sessionModel.status())) {
            if (sessionModel.loggedDuration() > 0) {
              spent.push(sessionModel);
            } else if (sessionModel.loggedDurationScheduled() > 0) {
              spent.push(sessionModel);
            }
          } else if ([SessionStatus.confirmed, SessionStatus.needsFinalisation].includes(sessionModel.status())) {
            booked.push(sessionModel);
          }
        });

        results.requests.forEach((request) => {
          const requestModel = new SupportSessionRequestModel(request);

          if ([SessionStatus.pendingWorkerApproval].includes(requestModel.realStatus())) {
            booked.push(requestModel);
          }
        });
      }

      setSpentItems(spent.sort(sortSessionsAndRequestsByDateDesc));
      setBookedItems(booked.sort(sortSessionsAndRequestsByDateDesc));

      setIsLoading(false);
    };

    void fetchData();
  }, [client]);

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {!isLoading &&
        <SessionsUnallocatedView
          client={client}
          spentItems={spentItems || []}
          bookedItems={bookedItems || []}
          unallocatedCost={unallocatedCost}
          planNeeds={supportPlan.supportNeedsSync()}
          onCancel={onCancel}
          onSave={handleSave}
          interactionDisabled={isSaving || isLoading}
        />
      }
    </>
  );
};