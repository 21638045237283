import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import { AnalyticsEventType } from '../../../enums/AnalyticsEventType';
import useAnalytics, { AnalyticsEvent } from '../../../hooks/useAnalytics';
import ClientManager from '../../../managers/ClientManager';
import { ModalManager } from '../../../Modals/Error/manager';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { ClientModel } from '../../../models/Client';
import { SafetyPlanModel, SafetyPlanState } from '../../../models/SafetyPlan';
import { ClientSafetyPlanView } from '../../../views/Client/SafetyPlan';
import { DEFAULT_DELAY, useCanLoad } from '../util';

export interface ClientSafetyPlanContainerProps {
  client?: ClientModel;
  onKeyChanged: (key:string, value: unknown) => void;
}

export const ClientSafetyPlanContainer: React.FC<ClientSafetyPlanContainerProps> = ({ client, onKeyChanged }) => {
  const [safetyPlan, setSafetyPlan] = useState<SafetyPlanModel | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [cancelCount, setCancelCount] = useState<number>(0);
  const [triggerEventReload, setTriggerEventReload] = useState(false);
  const { logEvents, getLastEventInfo, isLoading: isLastEventLoading, lastEvent } = useAnalytics();

  const canLoad = useCanLoad('safetyPlan', DEFAULT_DELAY);

  useEffect(() => {
    if (!canLoad || !client) { return; }
    setIsLoading(true);
    void client.getSafetyPlan().then(plan => {
      setIsLoading(false);
      setSafetyPlan(plan);
      setTriggerEventReload(true);
    });
  }, [canLoad, client]);

  useEffect(() => {
    if (triggerEventReload && safetyPlan) {
      setTriggerEventReload(false);
      void getLastEventInfo([
        AnalyticsEventType.clientSafetyPlanEdited,
      ], [safetyPlan.id()]);
    }
  }, [triggerEventReload, safetyPlan]);

  /**
   * Detect any changes to safety plan to create events for analytics
   */
  function getChangeEvents(current: SafetyPlanModel, updated: SafetyPlanState): { events: AnalyticsEvent[] } {
    const changes: AnalyticsEvent[] = [];

    if (
      current.accessType() !== updated.accessType ||
      current.becomingUnwellNotes() !== updated.becomingUnwellNotes ||
      current.emergencyActions() !== updated.emergencyActions ||
      current.otherNotes() !== updated.otherNotes ||
      current.unwellActions() !== updated.unwellActions ||
      current.unwellNotes() !== updated.unwellNotes
    ) {
      changes.push({
        eventType: AnalyticsEventType.clientSafetyPlanEdited,
        objectId: safetyPlan?.id() ?? 'UNKNOWN',
      });
    }

    return { events: changes };
  }

  /**
   * Handle saving changes
   */
  async function handleSave(state: SafetyPlanState) {
    if (!client) {
      return;
    }

    try {
      // Get change events here before saving
      let changeEvents: { events: AnalyticsEvent[] } | undefined = undefined;
      if (safetyPlan) {
        changeEvents = getChangeEvents(safetyPlan, state);
      }

      setIsSaving(true);
      await ClientManager.saveClientSafetyPlan(client, state);
      setIsSaving(false);
      ToastManager.shared().show('Client safety plan saved.');

      if (changeEvents && changeEvents.events) {
        void logEvents(changeEvents.events);
        setTriggerEventReload(true);
      }
    } catch {
      ModalManager.shared().show('Error', 'There was an error saving the client\'s safety plan. Please try again.');
    }
  }

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {(client && !isLoading) &&
        <ClientSafetyPlanView
          key={(safetyPlan?.id() ?? '') + cancelCount}
          safetyPlan={safetyPlan}
          onKeyChanged={onKeyChanged}
          disableInteraction={isSaving}
          onReset={() => setCancelCount(cancelCount + 1)}
          onSave={handleSave}
          lastSafetyPlanEvent={isLastEventLoading ? undefined : lastEvent}
        />
      }
    </>
  );
};