import React, { useMemo } from 'react';
import { getSupportNeedModelTypeDescription, SupportNeedModelType } from '../../../enums/SupportNeedModelType';
import { SupportNeedModel } from '../../../models/SupportNeed';
import { Selector } from '../../Selector';

interface NeedByTypeSelectorProps {
  selectedType: SupportNeedModelType | undefined;
  needs: SupportNeedModel[];
  onChange: (need: SupportNeedModelType) => void;
  includeTransport?: boolean;
  className?: string;
  allowEmptyState?: boolean;
}

function formatNeed(need: SupportNeedModel) {
  const type = need.type();
  return type ? getSupportNeedModelTypeDescription(type) : 'Warning: Unknown type?';
}

export const NeedByTypeSelector: React.FC<NeedByTypeSelectorProps> = ({ selectedType, needs, onChange, includeTransport = false, className = 'form-control col-6 ml-2', allowEmptyState = true }) => {
  const needOptions = useMemo(() => {
    return needs.filter(need => !need.isTransportType() && !includeTransport && !need.isDeleted());
  }, [needs]);

  const currentType = selectedType || SupportNeedModelType.Unknown;

  function handleChange(event: React.ChangeEvent<HTMLSelectElement>): void {
    const value: string = String(event.target.value);
    const need = needs.find(need => String(need.type()) === value);
    if (need) {
      onChange(need.type() as SupportNeedModelType);
    } else if (value === String(SupportNeedModelType.Unknown) && allowEmptyState) {
      onChange(SupportNeedModelType.Unknown);
    }
  }

  return (
    <Selector
      className={className}
      options={[{ key: String(SupportNeedModelType.Unknown), name: 'Select a support need', disabled: !allowEmptyState }, ...(needOptions ?? []).map(need => ({ name: formatNeed(need), key: String(need.type()) }))]}
      initial={currentType}
      onChange={handleChange}
    />
  );
};