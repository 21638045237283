import Parse, { GeoPoint } from 'parse';
import { AtsiStatus } from '../enums/AtsiStatus';
import { AustralianState } from '../enums/AustralianState';
import { CovidVaccinationStatus } from '../enums/CovidVaccinationStatus';
import { Gender } from '../enums/Gender';
import { Hobby, HobbyCases } from '../enums/Hobby';
import { Language, LanguageCases } from '../enums/Language';
import { UserStatus } from '../enums/UserStatus';
import { WorkerLevel } from '../enums/WorkerLevel';
import { BusinessLogic } from '../helpers';
import { DayAvailabilityModel } from './DayAvailability';
import { getFileExtensionFromUrl, saveDateField, saveEnumField, saveEnumsField, saveStringFieldToObject } from './util/parseSaving';
import { ParseObject } from './util/parseTypes';

export class WorkerModel {
  constructor(public object: Parse.Object) { }

  static new(): WorkerModel {
    return new WorkerModel(new (Parse.Object.extend('Worker'))());
  }

  id(): string { return this.object.id; }

  firstName(): string { return this.object.get('firstName'); }
  setFirstName(name: string): void { saveStringFieldToObject(name, 'firstName', this.object, true, true); }

  lastName(): string { return this.object.get('lastName'); }
  setLastName(name: string): void { saveStringFieldToObject(name, 'lastName', this.object, true, true); }

  fullName(): string {
    return [this.firstName(), this.lastName()].join(' ');
  }

  adminNotes(): string | undefined { return this.object.get('adminNotes'); }

  createdAt(): Date { return this.object.get('createdAt'); }
  deletedAt(): Date | undefined { return this.object.get('deletedAt'); }

  photo(): string {
    const file = this.object.get('photo') as Parse.File;
    return file ? file.url() : `${window.location.origin}/logo192.png`;
  }

  status(): UserStatus { return this.object.get('status') as UserStatus; }
  setStatus(status: UserStatus): void { saveEnumField(status, 'status', this.object); }

  covidVaccinationStatus(): CovidVaccinationStatus { return (this.object.get('covidVaccinationStatus') as CovidVaccinationStatus | undefined) ?? CovidVaccinationStatus.unknown; }
  setCovidVaccinationStatus(covidVaccinationStatus: CovidVaccinationStatus): void { saveEnumField(covidVaccinationStatus, 'covidVaccinationStatus', this.object); }

  atsiStatus(): AtsiStatus { return (this.object.get('atsiStatus') as AtsiStatus | undefined) ?? AtsiStatus.Unknown; }
  setAtsiStatus(atsiStatus: AtsiStatus): void { saveEnumField(atsiStatus, 'atsiStatus', this.object); }

  email(): string | undefined { return this.object.get('user')?.get('username'); }
  userId(): string { return this.object.get('user').id; }

  dateOfBirth(): Date | undefined { return this.object.get('dateOfBirth'); }
  setDateOfBirth(date: Date): void { saveDateField(date, 'dateOfBirth', this.object); }

  completedInductionAt(): Date | undefined { return this.object.get('completedInductionAt'); }
  setCompletedInductionAt(completedInduction: Date): void { saveDateField(completedInduction, 'completedInductionAt', this.object); }

  otherEmployers(): string | undefined { return this.object.get('otherEmployers') ?? ''; }
  setOtherEmployers(value: string | undefined): void { this.object.set('otherEmployers', value ?? ''); }

  gender(): Gender | undefined { return this.object.get('gender'); }
  setGender(gender: Gender): void { saveEnumField(gender, 'gender', this.object); }

  level(): WorkerLevel | undefined { return this.object.get('level'); }
  setLevel(level: WorkerLevel): void { saveEnumField(level, 'level', this.object); }

  unrestrictedVisibility(): boolean { return this.object.get('unrestrictedVisibility') === true; }
  setUnrestrictedVisibility(unrestrictedVisibility: boolean): void { this.object.set('unrestrictedVisibility', unrestrictedVisibility); }

  workingWithChildrenCheck(): boolean { return this.object.get('workingWithChildrenCheck') === true; }
  setWorkingWithChildrenCheck(workingWithChildrenCheck: boolean): void { this.object.set('workingWithChildrenCheck', workingWithChildrenCheck); }

  address(): string | undefined { return this.object.get('address'); }
  setAddress(address: string): void { saveStringFieldToObject(address, 'address', this.object, true, false); }

  postCode(): string | undefined { return this.object.get('postcode'); }
  setPostCode(postcode: string): void { saveStringFieldToObject(postcode, 'postcode', this.object, true, false); }

  phoneNumber(): string | undefined { return this.object.get('phoneNumber'); }
  setPhoneNumber(phoneNumber: string): void { saveStringFieldToObject(phoneNumber, 'phoneNumber', this.object, true, false); }

  addressCoordinates(): GeoPoint { return this.object.get('addressCoordinates'); }
  setAddressCoordinates(coords: GeoPoint): void { this.object.set('addressCoordinates', coords); }

  australianState(): AustralianState | undefined { return this.object.get('australianState'); }
  setAustralianState(australianState: AustralianState): void { saveEnumField(australianState, 'australianState', this.object); }

  preferredTravelRadius(): number { return this.object.get('preferredTravelRadius') as number | undefined ?? BusinessLogic.Company.defaultWorkerTravelRadius; }
  setPreferredTravelRadius(preferredTravelRadius: number): void { this.object.set('preferredTravelRadius', preferredTravelRadius); }

  preferredContactMethod(): string | undefined { return this.object.get('preferredContactMethod'); }
  setPreferredContactMethod(preferredContactMethod: string): void { this.object.set('preferredContactMethod', preferredContactMethod); }

  addressGoogleId(): string | undefined { return this.object.get('addressGoogleId'); }
  setAddressGoogleId(addressGoogleId: string): void { saveStringFieldToObject(addressGoogleId, 'addressGoogleId', this.object, true, false); }

  languages(): Set<Language> {
    const languages = new Set<Language>();
    const arr: [number] = this.object.get('languages');
    if (!arr) { return languages; }
    for (const language of arr) {
      if (LanguageCases.includes(language)) { languages.add(language); }
    }
    return languages;
  }

  setLanguages(languages: Set<Language>): void { saveEnumsField(languages, 'languages', this.object); }

  hobbies(): Set<Hobby> {
    const hobbies = new Set<Hobby>();
    const arr: [number] = this.object.get('hobbies');
    if (!arr) { return hobbies; }
    for (const hobby of arr) {
      if (HobbyCases.includes(hobby)) { hobbies.add(hobby); }
    }
    return hobbies;
  }
  setHobbies(hobbies: Set<Hobby>): void { saveEnumsField(hobbies, 'hobbies', this.object); }

  bio(): string | undefined { return this.object.get('bio'); }
  setBio(bio: string): void { saveStringFieldToObject(bio, 'bio', this.object, true, false); }


  setPhoto(url: string | undefined, bytes: any[] | undefined): void {
    if (url !== this.photo()) {
      if (url && bytes) {
        const fileExt = getFileExtensionFromUrl(url) || 'jpeg';
        const file = new Parse.File(`photo.${fileExt}`, bytes);
        this.object.set('photo', file);
      } else {
        this.object.unset('photo');
      }
    }
  }

  availableDaysSync(): DayAvailabilityModel[] | undefined {
    const days = this.object.get('availableDays') as Parse.Object[] | undefined;
    if (!days) { return; }
    return days.map(day => new DayAvailabilityModel(day));
  }

  setAvailableDays(days: ParseObject[]): void { days.length > 0 ? this.object.set('availableDays', days) : this.object.unset('availableDays'); }
  setAvailabilityOverrides(days: ParseObject[]): void { days.length > 0 ? this.object.set('availabilityOverrides', days) : this.object.unset('availabilityOverrides'); }

  async save(): Promise<WorkerModel> {
    await this.object.save();
    return this;
  }

  async saveAdminNotes(notes: string): Promise<WorkerModel> {
    saveStringFieldToObject(notes, 'adminNotes', this.object);

    await this.object.save();
    return this;
  }

  async savePreferredContactMethod(value: string): Promise<WorkerModel> {
    this.object.set('preferredContactMethod', value);
    await this.object.save();
    return this;
  }

  rawObject(): ParseObject { return this.object; }
}