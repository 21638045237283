import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { loginPath } from '../../Routes';
import session from '../../Session';
import { AsyncExportButton, AsyncExportButtonOption } from '../../components/AsyncExportButton';
import { Loading } from '../../components/Loading';
import { ToolBar } from '../../components/Toolbar';
import { WorkersContainer } from '../../containers/Workers';
import { UserStatus, WorkerStatuses, getUserStatusDescription } from '../../enums/UserStatus';
import { fromStorage, toStorage } from '../../helpers/sessionAndBillingUtils';
import WorkerShiftReportManager from '../../managers/WorkerShiftReport';
import { WorkerModel } from '../../models/Worker';
import { exportWorkers } from '../../reports/csv';
import { WorkerTile } from './WorkerTile';


export const Workers: React.FC = () => {
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  return <WorkersContainer />;
};



interface WorkersViewProps {
  isLoading: boolean;
  workers?: WorkerModel[];
}

const WORKER_SEARCH_KEY = 'suitsme.search.worker';


export const WorkersView: React.FC<WorkersViewProps> = ({ isLoading, workers }) => {
  const [filteredWorkers, setFilteredWorkers] = useState<WorkerModel[] | undefined>(workers);
  const [searchTerm, setSearchTerm] = useState(fromStorage(WORKER_SEARCH_KEY) || '');
  const [statuses, setStatuses] = useState<Set<UserStatus>>(new Set(WorkerStatuses));

  useEffect(() => {
    if (!workers) { return; }
    toStorage(searchTerm, WORKER_SEARCH_KEY);
    const filtered = workers.filter(worker => {
      const searchMatch = worker.fullName().toLowerCase().includes(searchTerm.toLowerCase()) || worker.id() === searchTerm;
      const statusMatch = statuses.has(worker.status());
      return searchMatch && statusMatch;
    });
    setFilteredWorkers(filtered);
  }, [searchTerm, workers, statuses]);

  const options = WorkerStatuses.map(status => {
    return (
      <div
        className="col-3"
        key={status.toString()}
      >
        <input
          id={status.toString()}
          className="form-check-input"
          type="checkbox"
          name={status.toString()}
          checked={statuses.has(status)}
          value={status}
          onChange={e => {
            const status = Number(e.target.value);
            const newStatuses = new Set(statuses);
            if (statuses.has(status)) {
              newStatuses.delete(status);
              setStatuses(newStatuses);
            } else {
              newStatuses.add(status);
              setStatuses(newStatuses);
            }
          }}
        />
        <label
          htmlFor={status.toString()}
          className="form-check-label font-weight-normal text-white"
        >{getUserStatusDescription(status)}</label>
      </div>
    );
  });

  const exportOptions = (): AsyncExportButtonOption[] => {
    return [{
      title: 'Worker list',
      enabled: true,
      // eslint-disable-next-line
      onExport: async () => { },
      onDownload: () => exportWorkers(filteredWorkers ?? []),
    },
    {
      title: 'Worker shifts report',
      enabled: true,
      onExport: async () => WorkerShiftReportManager.instance().prepareReport(filteredWorkers ?? []),
      onDownload: () => WorkerShiftReportManager.instance().downloadLastReport('worker-shifts.csv'),
    }];
  };

  return (
    <div className="mx-4">
      <ToolBar
        title="Workers"
        top={
          <>
            {(filteredWorkers !== undefined && filteredWorkers.length > 0) &&
              <AsyncExportButton
                options={exportOptions()}
              />
            }
            <form
              className="form-inline col-4"
            >
              <input
                className="form-control w-100"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </form>
          </>
        }
        filters={
          <div className="mx-4 pb-2">
            <Form className="row">
              {options}
            </Form>
          </div>
        }
      />
      {isLoading && <Loading />}

      {searchTerm !== '' &&
        <div
          className="row mt-2"
        >
          <div className="col-12 text-info text-center">{`Showing workers for "${searchTerm}"`}</div>
        </div>
      }

      {filteredWorkers &&
        <div className="row py-2 px-1">
          {filteredWorkers.map(worker => <div
            key={worker.id()}
            className="col-sm-12 col-md-12 col-lg-6 col-xl-4 p-0"
          >
            <WorkerTile
              worker={worker}
            />
          </div>)}
        </div>
      }
    </div>
  );

};