export function validateRequired(value: string): string {
  return value === '' || value === undefined ? 'Required' : '';
}

/**
 * Validate password complexity based on:
 * - An uppercase character
 * - A lowercase character
 * - A number
 * - A special character (e.g. punctuation)
 * - At least 12 characters
 */
export function validatePassword(value: string): boolean {
  const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[\W_]).{12,}$/;
  return passwordRegex.test(value);
}