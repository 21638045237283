import React from 'react';
import { isProd } from '../../helpers';


export const LogoImageSizeable = ({ width, height }: { width?: number | string; height?: number | string }): JSX.Element => {
  const imageFilename = isProd() ? 'suitsme-semi-reverse.svg' : 'suitsme-staging-semi-reverse.svg';
  return <img
    src={`${window.location.origin}/${imageFilename}`}
    height={height}
    width={width}
    alt="logo"
  />;
};

export function LogoImage(): JSX.Element {
  const imageFilename = isProd() ? 'suitsme.svg' : 'suitsme-staging.svg';
  return <img
    src={`${window.location.origin}/${imageFilename}`}
    height="40"
    alt="logo"
  />;
}

export function LogoImageDiv(): JSX.Element {
  return <div className="w-100 pt-1 text-center"><LogoImage /></div>;
}
