import React, { ReactNode } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { AlertContainer } from '../../../containers/Alert';
import { AlertMuteAffectedUserType, AlertTypes } from '../../../managers/Alerts';
import { workersPath } from '../../../Routes';

export interface WorkerTabsProps {
  details: ReactNode;
  sessions: ReactNode;
  messages: ReactNode;
  availability: ReactNode;
  userOpens: ReactNode;
  adminManagement: ReactNode;
}

export const WorkerTabs: React.FC<WorkerTabsProps> = (props) => {
  const tabbedMatch = useRouteMatch({ path: `${workersPath}/:id/:tab` });
  const noTabbedMatch = useRouteMatch({ path: `${workersPath}/:id/` });

  const match = noTabbedMatch || tabbedMatch;

  const tab = (tabbedMatch?.params as any)?.tab || 'details';
  const id = (match?.params as any)?.id;
  const history = useHistory();

  return (
    <Tabs
      defaultActiveKey={tab}
      id="uncontrolled-tab-example"
      onSelect={tab => history.push(`${workersPath}/${id}/${tab}`)}
    >
      <Tab
        eventKey="details"
        title={
          <>
            Details
            {
              tab !== 'details' && (
                <AlertContainer
                  userType={AlertMuteAffectedUserType.worker}
                  userId={id}
                  alertTypes={[AlertTypes.workerPendingDocumentsLate]}
                  subscribe={true}
                />
              )
            }
          </>
        }
      >
        {props.details}
      </Tab>
      <Tab
        eventKey="availability"
        title="Availability"
      >
        {props.availability}
      </Tab>
      <Tab
        eventKey="sessions"
        title="Sessions"
      >
        {props.sessions}
      </Tab>
      <Tab
        eventKey="messages"
        title="Messages"
      >
        {props.messages}
      </Tab>
      <Tab
        eventKey="apps"
        title="Apps"
      >
        {props.userOpens}
      </Tab>

      <Tab
        eventKey="adminManagement"
        title="Management"
      >
        {props.adminManagement}
      </Tab>
    </Tabs>
  );
};