import React from 'react';
import { formatDuration, formatMoney } from '../../helpers';
import { PredictedSessionCost } from '../../managers/SessionCost';
import { SimpleLabelledValue } from './SimpleLabelledValue';


interface PredictedSessionCostProps {
  cost: PredictedSessionCost;
  isLoadingPricing: boolean;
}

export const PredictedSessionCostView: React.FC<PredictedSessionCostProps> = ({ cost, isLoadingPricing }) => {
  const expectedHourlyRate = cost.expectedHourlyRate ? formatMoney(cost.expectedHourlyRate) : '';
  const expectedDuration = formatDuration(cost.expectedDuration);
  const expectedCost = cost.expectedCost ? formatMoney(cost.expectedCost) : '';


  return (
    <div className="row">
      <SimpleLabelledValue
        title="Day type"
        value={cost.dayType}
      />

      <SimpleLabelledValue
        title="Expected duration"
        value={!isLoadingPricing ? expectedDuration : 'Calculating...'}
      />

      <SimpleLabelledValue
        title="Rate per hour"
        value={!isLoadingPricing ? expectedHourlyRate : 'Calculating...'}
      />

      <SimpleLabelledValue
        title="Expected income"
        value={!isLoadingPricing ? expectedCost : 'Calculating...'}
      />
    </div>
  );
};