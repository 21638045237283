export interface ModalManagerListener {
  show(title: string, message: string, onClose?: () => void): void;
}

export class ModalManager {
  private static instance:ModalManager;
  private listener?: ModalManagerListener;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {
  }


  public static shared(): ModalManager {
    if (!ModalManager.instance) {
      ModalManager.instance = new ModalManager();
    }

    return ModalManager.instance;
  }

  registerListener(listener: ModalManagerListener): void {
    this.listener = listener;
  }

  show(title: string, message: string, onClose?: () => void): void {
    this.listener?.show(title, message, onClose);
  }
}