import React from 'react';
import { useHistory } from 'react-router-dom';
import { workersPath } from '../../Routes';
import { AlertContainer } from '../../containers/Alert';
import { AlertMuteAffectedUserType } from '../../managers/Alerts';
import { WorkerModel } from '../../models/Worker';

interface JobPostWorkerTileProps {
  worker: WorkerModel;
}

export const JobPostWorkerTile = (props: JobPostWorkerTileProps): JSX.Element => {
  const worker = props.worker;
  const history = useHistory();
  const path = `${workersPath}/${worker.object.id}`;
  const photoUrl = worker.photo();

  return (
    <div
      className="cursor-pointer p-1 text-left"
      onClick={() => history.push(path)}
    >
      <div
        className="d-flex justify-content-between border rounded p-1 hover-item-active"
      >
        <div className="d-flex flex-column">
          <div className="d-flex">
            <h5 className="pr-0 mr-1">{worker.fullName()}</h5>
            <div className="ml-0 pl-0 mt-1">
              <AlertContainer
                userType={AlertMuteAffectedUserType.worker}
                userId={worker.id()}
                subscribe={false}
              />
            </div>
          </div>
        </div>
        <div>

          <img
            src={photoUrl}
            alt="avatar"
            style={{ width: 56, height: 56, borderRadius: '50%', margin: 'auto', objectFit: 'cover' }}
          />
        </div>
      </div>
    </div>
  );
};