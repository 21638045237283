
import classNames from 'classnames';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { clientsPath } from '../../../Routes';
import { MenuItem, SUITS_ME_DASHBOARD_MENU_ITEMS } from '../../../views';
import './Menu.css';


function isActive(currentPath: string, item: MenuItem, previousPath: string | undefined): boolean {
  const activeChildren = item.children.filter(child => isActive(currentPath, child, previousPath));
  const activePath = currentPath === item.path;

  // Special case - the default item is clients, so we highlight that
  const isDefaultPath = currentPath === '/' && (item.path === clientsPath || item.title === 'Users');
  // Special case - the detail screen is active
  const isDetailScreen = currentPath.includes(item.path);

  // Special case - a session or request detail, highlight the previous path - which could be sessions/something or worker/sessions or client/sessions
  const detailRegex = /(sessions|session-requests)\/.+/gm;
  const sessionRegex = /(sessions)\/(accepted|billable|unbillable|all|requested)+/gm;
  const isSessionOrRequestDetail = currentPath.match(detailRegex) !== null && currentPath.match(sessionRegex) === null; // eg sessions/1234 but not sessions/all
  const onDetail = (previousPath?.includes(item.path) ?? false) && isSessionOrRequestDetail;

  return (activeChildren.length > 0) || activePath || isDetailScreen || isDefaultPath || onDetail;// Default item is clients
}

const MenuItemChild = ({ item, open }: { item: MenuItem; open: boolean }) => {
  const currentPath = useLocation().pathname;
  const prevPath = useLastLocation()?.pathname;

  return (
    <li className="side-nav-item">
      <Link
        className={
          classNames(
            'side-nav-link-ref side-sub-nav-link',
            { 'text-white': isActive(currentPath, item, prevPath) },
            { 'mm-active': isActive(currentPath, item, prevPath) },
          )
        }
        aria-expanded={open}
        to={item.path}
        onClick={() => localStorage.setItem('suitsme.app._internal.last_location', currentPath)}
      >
        {item.icon &&
          <i className={item.icon} />
        }
        <span> {item.title} </span>
      </Link>
    </li>
  );
};

const MenuItemWithChildren = ({ item }: { item: MenuItem }) => {
  const [open, setOpen] = useState(true);
  const currentPath = useLocation().pathname;
  const prevPath = useLastLocation()?.pathname;

  return (
    <li className={classNames('side-nav-item',
      { 'text-white': isActive(currentPath, item, prevPath) },
      { 'mm-active': open },
    )}
    >

      {item.children.length === 0 &&
        <Link
          className="side-sub-nav-link side-nav-link"
          to={item.path}
        >
          {item.icon &&
            <i className={classNames(item.icon, { 'icon-inactive': !isActive(currentPath, item, prevPath) })} />
          }
          <span> {item.title} </span>
        </Link>
      }
      {item.children.length > 0 &&
        <>
          <Link
            className="has-arrow side-sub-nav-link side-nav-link"
            to="#"
            onClick={() => setOpen(!open)}
          >
            {item.icon &&
              <i className={classNames(item.icon, { 'icon-inactive': !isActive(currentPath, item, prevPath) })} />
            }
            <span> {item.title} </span>
          </Link>
          <ul
            className={classNames('side-nav-second-level', 'mm-collapse', { 'mm-collapsed mm-show': open })}
            aria-expanded={open}
          >
            {
              item.children.map(child => {
                if (child.children.length) {
                  return <MenuItemWithChildren
                    key={child.path}
                    item={child}
                  />;
                }
                return <MenuItemChild
                  key={child.path}
                  item={child}
                  open={open}
                />;
              })
            }
          </ul>
        </>
      }
    </li>
  );
};


export const Menu = () => {
  return (
    <ul
      className="metismenu side-nav"
      id="menu-bar"
    >
      {SUITS_ME_DASHBOARD_MENU_ITEMS.map(item => <MenuItemWithChildren
        key={item.path}
        item={item}
      />)}
    </ul>
  );
};
