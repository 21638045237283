import React, { useEffect, useState } from 'react';
import { Loading } from '../../../components/Loading';
import WorkerAvailabilityManager from '../../../managers/WorkerAvailability';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { AvailabilityOverrideModel } from '../../../models/AvailabilityOverride';
import { DayAvailabilityModel } from '../../../models/DayAvailability';
import { WorkerModel } from '../../../models/Worker';
import { WorkerAvailabilityView } from '../../../views/Worker/Tabs/Availability';

export interface WorkerAvailabilityContainerProps {
  worker: WorkerModel;
}


export const WorkerAvailabilityContainer: React.FC<WorkerAvailabilityContainerProps> = ({ worker }) => {
  const [dayAvailability, setDayAvailability] = useState<DayAvailabilityModel[] | undefined>();
  const [availabilityOverrides, setAvailabilityOverrides] = useState<AvailabilityOverrideModel[] | undefined>();

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    const loadData = async () => {
      const overrides = await WorkerAvailabilityManager.instance().getAvailabilityOverrides(worker.id());
      setAvailabilityOverrides(overrides);
      const days = await WorkerAvailabilityManager.instance().getWorkerDayAvailability(worker.id());
      setDayAvailability(days);
      setIsLoading(false);
    };
    setIsLoading(true);
    void loadData();

  }, [worker]);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading &&
        <WorkerAvailabilityView
          worker={worker}
          dayAvailability={dayAvailability || []}
          availabilityOverrides={availabilityOverrides || []}
          disableInteraction={isSaving}
          onSave={async (dayAvailabilityState, availabilityOverrideState, preferredTravelRadius) => {
            setIsSaving(true);

            const results = await WorkerAvailabilityManager.instance().updateWorkerAvailability(worker, {
              dayAvailability: dayAvailability || [],
              dayAvailabilityState,
              availabilityOverrideState,
              availabilityOverrides: availabilityOverrides || [],
              preferredTravelRadius });

            setIsSaving(false);
            setDayAvailability(results.dayAvailability);
            ToastManager.shared().show('Worker availability saved');
          }}
        />
      }
    </>
  );
};