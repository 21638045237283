import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Selector } from '../../../../components/Selector';
import { DayAvailabilityDay, getDayDescription, remainingDays } from '../../../../enums/DayAvailabilityDay';
import { BusinessLogic } from '../../../../helpers';
import { AvailabilityOverrideState, DayAvailabilityState, NEW_PREFIX } from '../../../../managers/WorkerAvailability';
import { AvailabilityOverrideModel } from '../../../../models/AvailabilityOverride';
import { DayAvailabilityModel } from '../../../../models/DayAvailability';
import { WorkerModel } from '../../../../models/Worker';
import { SaveButton } from '../../../Client/SaveButton';
import { AvailabilityOverride } from './AvailabilityOverride';
import { DayAvailability } from './DayAvailability';

interface WorkerAvailabilityViewProps {
  worker: WorkerModel;
  dayAvailability: DayAvailabilityModel[];
  availabilityOverrides: AvailabilityOverrideModel[];

  onSave(draftDayAvailability: DayAvailabilityState[], draftAvailabilityOverrides: AvailabilityOverrideState[], preferredTravelRadius: number): Promise<void>;
  disableInteraction: boolean;
}

function dayAvailabilityStateFromModel(dayAvailability: DayAvailabilityModel): DayAvailabilityState {
  return {
    id: dayAvailability.id(),
    day: dayAvailability.day(),
    startTime: dayAvailability.startTime(),
    endTime: dayAvailability.endTime(),
    isDeleted: false,
  };
}

function getDraftDayAvailability(dayAvailability: DayAvailabilityModel[]) {
  return dayAvailability.map(day => dayAvailabilityStateFromModel(day));
}

function getNewDayAvailabilityDraft(day: DayAvailabilityDay): DayAvailabilityState {
  return {
    id: NEW_PREFIX + day,
    startTime: BusinessLogic.Company.sessionStartTime,
    endTime: BusinessLogic.Company.sessionEndTime,
    day,
    isDeleted: false,
  };
}

function availabilityOverrideStateFromModel(override: AvailabilityOverrideModel): AvailabilityOverrideState {
  return {
    id: override.id(),
    effectiveFrom: override.effectiveFrom(),
    effectiveUntil: override.effectiveUntil(),
    isDeleted: false,
    isAvailable: override.isAvailable(),
  };
}

function getNewAvailabilityOverrideDraft(): AvailabilityOverrideState {
  return {
    id: NEW_PREFIX,
    effectiveFrom: new Date(),
    effectiveUntil: undefined,
    isDeleted: false,
    isAvailable: false,
  };
}

function getDraftOverrides(overrides: AvailabilityOverrideModel[]) {
  return overrides.map(override => availabilityOverrideStateFromModel(override));
}


function daysToBeAdded(draftDayAvailability: DayAvailabilityState[]) {
  return remainingDays(draftDayAvailability.filter(day => !day.isDeleted).map(day => day.day));
}

export const WorkerAvailabilityView: React.FC<WorkerAvailabilityViewProps> = ({ worker, dayAvailability, availabilityOverrides, onSave, disableInteraction }) => {
  const [draftDayAvailability, setDraftDayAvailability] = useState<DayAvailabilityState[]>(getDraftDayAvailability(dayAvailability));
  const [draftAvailabilityOverrides, setDraftAvailabilityOverrides] = useState<AvailabilityOverrideState[]>(getDraftOverrides(availabilityOverrides));

  const [canSave, setCanSave] = useState(false);
  const [canCancel, setCanCancel] = useState(false);

  const [selectedNewDayAvailability, setSelectedNewDayAvailability] = useState<DayAvailabilityDay>(daysToBeAdded(draftDayAvailability)[0]);

  const [preferredTravelRadius, setPreferredTravelRadius] = useState<number | undefined>(worker.preferredTravelRadius());


  useEffect(() => {
    const startingDayAvailability = getDraftDayAvailability(dayAvailability);
    const changedAvailability = !_.isEqual(draftDayAvailability, startingDayAvailability);

    const startingOverrides = getDraftOverrides(availabilityOverrides);
    const changedOverrides = !_.isEqual(draftAvailabilityOverrides, startingOverrides);

    const validTravelRadius = preferredTravelRadius !== undefined && preferredTravelRadius <= BusinessLogic.Company.defaultWorkerTravelRadius;
    const changedTravelRadius = preferredTravelRadius !== worker.preferredTravelRadius();

    setCanSave((changedAvailability || changedOverrides || changedTravelRadius) && validTravelRadius);
    setCanCancel(changedAvailability || changedOverrides || changedTravelRadius);
  }, [draftDayAvailability, draftAvailabilityOverrides, dayAvailability, availabilityOverrides, preferredTravelRadius, worker]);

  return (
    <div className="container pb-2 pt-4">
      <h4>Travel</h4>
      <div className="row">
        <label
          htmlFor="lastName"
          className="col-2 col-form-label text-left"
        >Preferred travel radius</label>

        <input
          id="lastName"
          type="text"
          className="col-1 form-control"
          pattern="[0-9]"
          value={preferredTravelRadius ?? ''}
          onChange={e => {
            const number = parseInt(e.target.value);
            if (e.target.value === '') {
              setPreferredTravelRadius(undefined);
            }
            else if (isNaN(number)) {
              e.target.value = preferredTravelRadius?.toString() ?? '';
            } else {
              setPreferredTravelRadius(number);
            }
          }}
        />
        <div className="col-1 text-left d-flex align-items-center">
          KM
        </div>
      </div>
      <hr />

      <h4>Regular Days</h4>
      {draftDayAvailability.filter(day => !day.isDeleted)
        .sort((day1, day2) => day1.day - day2.day)
        .map(day => <DayAvailability
          key={day.id + day.endTime + day.startTime}
          day={day}
          onStartTimeChanged={time => {
            const copy = [...draftDayAvailability];
            const index = copy.indexOf(day);
            copy[index].startTime = time;
            setDraftDayAvailability(copy);
          }}
          onEndTimeChanged={time => {
            const copy = [...draftDayAvailability];
            const index = copy.indexOf(day);
            copy[index].endTime = time;
            setDraftDayAvailability(copy);
          }}
          onDelete={() => {
            const copy = [...draftDayAvailability];
            const index = copy.indexOf(day);
            copy[index].isDeleted = true;
            setDraftDayAvailability(copy);
            setSelectedNewDayAvailability(daysToBeAdded(copy)[0]);
          }}
        />)}
      {draftDayAvailability.filter(day => !day.isDeleted).length === 0 &&
        <div className="text-info">This worker has no availability</div>
      }

      <div className="row mt-3 pl-2">
        <Selector
          options={daysToBeAdded(draftDayAvailability).map(day => ({ name: getDayDescription(day), key: day }))}
          onChange={e => setSelectedNewDayAvailability(parseInt(e.target.value) as DayAvailabilityDay)}
          initial={selectedNewDayAvailability}
        />
        <button
          type="button"
          className="col-1 btn btn-primary ml-1"
          disabled={selectedNewDayAvailability === undefined}
          onClick={() => {
            const newDay = getNewDayAvailabilityDraft(selectedNewDayAvailability);
            setDraftDayAvailability([...draftDayAvailability, newDay].sort((day1, day2) => day1.day - day2.day));
            setSelectedNewDayAvailability(daysToBeAdded([...draftDayAvailability, newDay])[0]);
          }}
        >Add</button>

      </div>

      <hr />

      <h4>Exceptions</h4>
      {draftAvailabilityOverrides.filter(override => !override.isDeleted).map(override => <AvailabilityOverride
        key={override.id + override.effectiveFrom + override.effectiveUntil}
        override={override}
        onEffectiveFromChanged={time => {
          const copy = [...draftAvailabilityOverrides];
          const index = copy.indexOf(override);
          copy[index].effectiveFrom = time;
          setDraftAvailabilityOverrides(copy);
        }}
        onEffectiveUntilChanged={time => {
          const copy = [...draftAvailabilityOverrides];
          const index = copy.indexOf(override);
          copy[index].effectiveUntil = time;
          setDraftAvailabilityOverrides(copy);
        }}
        onDelete={() => {
          const copy = [...draftAvailabilityOverrides];
          const index = copy.indexOf(override);
          copy[index].isDeleted = true;
          setDraftAvailabilityOverrides(copy);
        }}
      />)}

      {draftAvailabilityOverrides.filter(override => !override.isDeleted).length === 0 &&
        <div className="text-info">This worker has no exceptions</div>
      }
      <div className="row mt-3 pl-2">
        <button
          type="button"
          className="col-1 btn btn-primary"
          onClick={() => {
            const newDraft = getNewAvailabilityOverrideDraft();
            setDraftAvailabilityOverrides([...draftAvailabilityOverrides, newDraft]);
          }}
        >Add</button>

      </div>
      <hr />

      <SaveButton
        onSave={async () => {
          if (preferredTravelRadius === undefined) { return; }
          return onSave(draftDayAvailability, draftAvailabilityOverrides, preferredTravelRadius);
        }
        }
        onCancel={() => {
          setPreferredTravelRadius(worker.preferredTravelRadius());
          setDraftDayAvailability(getDraftDayAvailability(dayAvailability));
          setDraftAvailabilityOverrides(getDraftOverrides(availabilityOverrides));
        }}
        disableCancel={disableInteraction || !canCancel}
        disableSave={disableInteraction || !canSave}
        saveText="Save"
      />
    </div>
  );
};
