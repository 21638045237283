import memoize from 'memoizee';
import Parse from 'parse';
import { SessionFrequency } from '../../enums/SessionFrequency';
import { SupportNeedModelType } from '../../enums/SupportNeedModelType';
import { SessionStatus } from '../../models/Sessions';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../models/Sessions/SupportSessionRequest';
import { SupportNeedModel } from '../../models/SupportNeed';
import { SupportPlanModel } from '../../models/SupportPlan';
import { GetSupportSession, GetSupportSessionRequest, GetSupportSessionRequestsForClient, GetSupportSessionRequestsForWorker, GetSupportSessionsForClient, GetSupportSessionsForWorker, ParseResult } from './queries';
export type SessionFilter = 'accepted' | 'unbillable' | 'billable' | 'requests'| 'all';
export type BillingFilter = 'ndia' | 'invoices' | 'payroll';

interface DashboardSessions {
  requests: Parse.Object[];
  sessions: Parse.Object[];
}

class SessionsManager {
  private static _instance: SessionsManager;

  private sessionCache: {[id: string] : SupportSessionModel} = {};
  private sessionRequestCache: {[id: string] : SupportSessionRequestModel} = {};

  private clientSessionCache: {[id: string] : ParseResult[]} = {};
  private clientSessionRequestCache: {[id: string] : ParseResult[]} = {};

  private workerSessionCache: {[id: string] : ParseResult[]} = {};
  private workerSessionRequestCache: {[id: string] : ParseResult[]} = {};

  private getDashboardSessionsAndRequestsMemo = memoize(this._getDashboardSessionsAndRequests, {
    promise: true,
    normalizer: args => JSON.stringify(args),
    maxAge: 300_000, // cache for 5 minutes
  });

  private constructor() {
    // Consider some cache prewarming
  }

  static instance(): SessionsManager {
    if (!SessionsManager._instance) {
      SessionsManager._instance = new SessionsManager();
    }
    return SessionsManager._instance;

  }

  public async getSessionsForClient(clientId: string): Promise<ParseResult[] | undefined> {
    const cached = this.clientSessionCache[clientId];
    if (cached) { return cached; }

    const results = await GetSupportSessionsForClient(clientId);
    for (const result of results) {
      this.sessionCache[result.id] = new SupportSessionModel(result);
    }

    this.clientSessionCache[clientId] = results;

    return results;
  }

  public async getSessionRequestsForClient(clientId: string): Promise<ParseResult[] | undefined> {
    const cached = this.clientSessionRequestCache[clientId];
    if (cached) { return cached; }

    const results = await GetSupportSessionRequestsForClient(clientId);

    for (const result of results) {
      this.sessionRequestCache[result.id] = new SupportSessionRequestModel(result);
    }

    this.clientSessionRequestCache[clientId] = results;
    return results;
  }

  public async getSessionsForWorker(workerId: string): Promise<ParseResult[] | undefined> {
    const cached = this.workerSessionCache[workerId];
    if (cached) { return cached; }

    const results = await GetSupportSessionsForWorker(workerId);
    for (const result of results) {
      this.sessionCache[result.id] = new SupportSessionModel(result);
    }

    this.workerSessionCache[workerId] = results;

    return results;
  }

  public async getSessionRequestsForWorker(workerId: string): Promise<ParseResult[] | undefined> {
    const cached = this.workerSessionRequestCache[workerId];
    if (cached) { return cached; }

    const results = await GetSupportSessionRequestsForWorker(workerId);

    for (const result of results) {
      this.sessionRequestCache[result.id] = new SupportSessionRequestModel(result);
    }

    this.workerSessionRequestCache[workerId] = results;
    return results;
  }

  private async _getDashboardSessionsAndRequests(params: DashboardParams): Promise<DashboardSessions> {
    const sessions = await Parse.Cloud.run('dashboard-sessions', params);
    return sessions;
  }

  public async getDashboardSessionsAndRequests(params: DashboardParams): Promise<{results: DashboardSessions; params: DashboardParams}> {
    const results = await this.getDashboardSessionsAndRequestsMemo(params);
    return { results, params };
  }

  public async getSessionsAndRequestsForClientPlan(params: ClientSessionsParams): Promise<DashboardSessions> {
    const results = await Parse.Cloud.run('client-sessions', params);
    return results;
  }
  
  public clearDashboardSessionsCache(): void {
    this.getDashboardSessionsAndRequestsMemo.clear();
  }

  public async getSession(id: string): Promise<SupportSessionModel | undefined> {
    const cached = this.sessionCache[id];
    if (cached) { return cached; }

    const result = await GetSupportSession(id);
    this.sessionCache[id] = new SupportSessionModel(result);
    return new SupportSessionModel(result);
  }

  public async getSessionRequest(id: string): Promise<SupportSessionRequestModel | undefined> {
    const cached = this.sessionRequestCache[id];
    if (cached) { return cached; }
    let result: SupportSessionRequestModel | undefined = undefined;

    try {
      const queryResult = await GetSupportSessionRequest(id);
      if (queryResult) {
        result = new SupportSessionRequestModel(queryResult);
      }
    } catch (err) {
      return undefined;
    }
    
    if (result) {
      this.sessionRequestCache[id] = result;
      return result;
    }
  }
}

export default SessionsManager.instance();

export interface DashboardParams{
  after?: Date;
  before?: Date;
  managed: number[];
  /** Filter is used currently only for 'billable' in the billing section */
  filter?: string;
  /** Statuses is used currently only for the new sessions screen */
  statuses?: SessionStatus[];
}
export interface DashboardSessionFilters {
  beforeDate: Date | undefined;
  afterDate: Date | undefined;
  statuses?: string[];
}

export interface ClientSessionsParams {
  filter: 'plan_need' | 'plan_unallocated';
  clientId: string;
  planId: string;
  needType?: SupportNeedModelType;
}

export interface SupportSessionState {
  loggedParking: number;
  loggedTransport: number;
  loggedStartDate: Date | undefined;
  loggedEndDate: Date | undefined;
  billAsScheduled: boolean;

  needType: SupportNeedModelType;
  suburb: string | undefined;
  postcode: string | undefined;
}
export function sessionStateFromSession(session: SupportSessionModel): SupportSessionState {
  let suburb = session.suburb();
  let postcode = session.postcode();

  if (!suburb) {
    suburb = session.clientSync().suburb();
  }
  if (!postcode) {
    postcode = session.clientSync().postcode();
  }

  return {
    loggedTransport: session.loggedTransport(),
    loggedParking: session.loggedParking(),
    loggedEndDate: session.loggedEndDate(),
    loggedStartDate: session.loggedStartDate(),
    billAsScheduled: session.billAsScheduled(),

    needType: session.getNeedType(),
    suburb,
    postcode,
  };
}

export interface SupportSessionRequestState {
  needType: SupportNeedModelType;
  suburb: string | undefined;
  postcode: string | undefined;
}

export function sessionStateFromSessionRequest(session: SupportSessionRequestModel, planNeeds: SupportNeedModel[]): SupportSessionRequestState {
  let needType = session.getNeedType();
  let suburb = session.suburb();
  let postcode = session.postcode();

  if (planNeeds && needType) {
    const need = planNeeds.find((need) => need.type() === needType);
    if (need && need.type()) {
      needType = need.type() ?? SupportNeedModelType.Unknown;
    }
  }

  if (!suburb) {
    suburb = session.clientSync().suburb();
  }
  if (!postcode) {
    postcode = session.clientSync().postcode();
  }
  
  return {
    needType,
    suburb,
    postcode,
  };
}

export function getNeedFromPlans(needType: SupportNeedModelType, plans: SupportPlanModel[]): SupportNeedModel | undefined {
  for (const plan of plans) {
    for (const need of plan.supportNeedsSync()) {
      if (need.type() === needType) {
        return need;
      }
    }
  }
}

export async function UpdateSession(session: SupportSessionModel, state: SupportSessionState): Promise<SupportSessionModel> {
  session.setLoggedParking(state.loggedParking);
  session.setLoggedTransport(state.loggedTransport);

  session.setBillAsScheduled(state.billAsScheduled);

  if (state.loggedStartDate) {
    session.setLoggedStartDate(state.loggedStartDate);
  }

  if (state.loggedEndDate) {
    session.setLoggedEndDate(state.loggedEndDate);
  }

  if (state.suburb !== session.suburb()) {
    session.setSuburb(state.suburb);
  }

  if (state.postcode !== session.postcode()) {
    session.setPostcode(state.postcode);
  }

  if (state.needType !== session.getNeedType()) {
    session.setNeedType(state.needType);
    session.removeSupportNeed();
    await session.save();
  }

  return session.save();
}


export async function UpdateSessionRequest(session: SupportSessionRequestModel, state: SupportSessionRequestState): Promise<SupportSessionRequestModel> {

  if (state.needType !== session.getNeedType()) {
    await changeSupportSessionRequestNeed(state.needType, session);
  }

  if (state.suburb !== session.suburb()) {
    session.setSuburb(state.suburb);
  }

  if (state.postcode !== session.postcode()) {
    session.setPostcode(state.postcode);
  }

  return session.save();
}

/**
 * Set a cancelled status for one or more sessions. This updates the session status, cancellationReason, and cancelledAt fields.
 * Returns the saved model of the main session.
 */
export async function UpdateSessionsCancellation(
  session: SupportSessionModel,
  relatedSessionIds: string[],
  newStatus: SessionStatus,
  reason: string,
  cancelledDate: Date | undefined,
): Promise<SupportSessionModel> {
  // Save the main session
  session.setStatus(newStatus);
  session.setCancelledDate(cancelledDate);
  session.setCancellationReason(Number(reason));
  const savedSession = await session.save();

  // Save all of the related sessions with the same state
  const relQuery = new Parse.Query('SupportSession');
  relQuery.containedIn('objectId', relatedSessionIds);

  try {
    const results = await relQuery.find();

    for (let i = 0; i < results.length; i++) {
      results[i].set('status', newStatus);
      results[i].set('cancelledAt', cancelledDate);
      results[i].set('cancellationReason', Number(reason));
    }

    await Parse.Object.saveAll(results);
  } catch (error) {
    console.error('Error updating rows: ', error);
  }

  SessionsManager.instance().clearDashboardSessionsCache();
  return savedSession;
}

/**
 * Set a cancelled status for a session request. This updates the session status only.
 */
export async function UpdateSessionRequestCancellation(
  request: SupportSessionRequestModel,
  newStatus: SessionStatus,
): Promise<SupportSessionRequestModel> {
  // Save the session request
  request.setStatus(newStatus);
  const savedRequest = await request.save();

  SessionsManager.instance().clearDashboardSessionsCache();
  return savedRequest;
}

export async function changeSupportSessionRequestNeed(newNeedType: SupportNeedModelType, request: SupportSessionRequestModel): Promise<void>  {
  if (newNeedType) {
    request.setNeedType(newNeedType);
    request.removeSupportNeed();
  }
  
  await request.save();
}

export interface CreateSessionState {
  clientId: string;
  workerId: string;
  request: {
    date: string;
    startTime: string;
    endTime: string;
    frequency: SessionFrequency;
    totalSessions: number;
    createdByUserId: string;
  };
  scheduleDirectly: boolean;
  supportNeedType: SupportNeedModelType;
  /** @deprecated To be removed as part of the new plan changes */
  supportNeedId: string;
}
interface CreateSupportSessionResponseWindow{
  startDate: string;
  endDate: string;
}
export interface CreateSupportSessionResponse {
  workerSessions: {
    impossible: CreateSupportSessionResponseWindow[];
    possible: CreateSupportSessionResponseWindow[];
  };
  clientSessions: {
    impossible: CreateSupportSessionResponseWindow[];
    possible: CreateSupportSessionResponseWindow[];
  };
}

export interface SessionRequestBudgetValidation {
  needBudgetAmountExcess: number;
}

export async function validateBudgetsForSessionRequest(state: CreateSessionState): Promise<SessionRequestBudgetValidation> {
  const response = await Parse.Cloud.run('pricing-validateNeedBudgetOverbooking', state);
  return response;
}

export async function checkSessionRequestDates(state: CreateSessionState): Promise<CreateSupportSessionResponse> {
  const response = await Parse.Cloud.run('checkSupportSessionRequestDates', state);
  return response;
}

export function createSessionRequest(state: CreateSessionState): Promise<void> {
  return Parse.Cloud.run('createSupportSessionRequest', state);
}