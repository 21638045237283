import Parse from 'parse';
import React, { useEffect, useState } from 'react';
import { ToastManager } from '../../Modals/Toasts/manager';
import { Loading } from '../../components/Loading';
import { ServiceAgreement } from '../../types/parseConfig';
import { ServiceAgreementView } from '../../views/ServiceAgreements';

export const ServiceAgreementsContainer: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [agreements, setAgreements] = useState<ServiceAgreement []| undefined>();

  useEffect(() => {
    void Parse.Config.get().then(config => {
      setAgreements(config.get('datedServiceAgreements'));
      setIsLoading(false);
    });
  }, []);

  return (
    <div className="mt-4">
      {isLoading && <Loading />}
      {agreements &&
        <ServiceAgreementView
          agreements={[...agreements]}
          disableInteraction={isSaving}
          onAdded={async(agreement) => {
            setIsSaving(true);
            const newAgreements = [...agreements, agreement];
            await Parse.Cloud.run('agreements-setDatedAgreements', { 'datedServiceAgreements': newAgreements });
            setAgreements(newAgreements);
            setIsSaving(false);
            ToastManager.shared().show('Agreement added');
          }}
        />
      }
    </div>
  );
};