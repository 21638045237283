export enum ClientSafetyPlanAccessType {
  Hidden=0,
  Visible=1,
  Editable=2
}

export function getClientSafetyPlanAccessTypeDescription(type: ClientSafetyPlanAccessType): string {
  return ClientSafetyPlanAccessType[type];
}

export const ClientSafetyPlanAccessTypeCases = [ClientSafetyPlanAccessType.Hidden, ClientSafetyPlanAccessType.Visible, ClientSafetyPlanAccessType.Editable];
