import { BrandColors } from '../helpers';

export enum GoalStatus {
  current=0,
  completed,
  inactive,
  deleted,
}

export function getGoalStatusDescription(goal:GoalStatus): string {
  switch (goal) {
    case GoalStatus.current: return 'Active';
    case GoalStatus.completed: return 'Complete';
    case GoalStatus.inactive: return 'Inactive';
    case GoalStatus.deleted: return 'Deleted';
  }
}

export function getGoalStatusColor(goal:GoalStatus): string {
  switch (goal) {
    case GoalStatus.current: return BrandColors.orange;
    case GoalStatus.completed: return BrandColors.green;
    case GoalStatus.inactive: return BrandColors.orange;
    case GoalStatus.deleted: return BrandColors.orange;
  }
}
