import classNames from 'classnames';
import moment from 'moment';
import React from 'react';
import { ArrowCounterclockwise, X } from 'react-bootstrap-icons';
import { MessageUser } from '../../enums/MessageUser';
import { MessageModel } from '../../models/Message';
import './messages.css';

export function formatMessageDate(date: Date): string {
  return moment(date).format('D MMMM YYYY, h.mm a');
}

export function relativeMessageDate(date: Date): string {
  return moment(date).fromNow();
}

const DeleteButton = ({ onClick }: { onClick(): void }) => (
  <div
    className="message-btn message-btn-delete d-flex"
    onClick={onClick}
  >
    <span className="message-btn-icon">
      <X />
    </span>
  </div>
);

const RestoreButton = ({ onClick }: { onClick(): void }) => (
  <div
    className="message-btn message-btn-restore d-flex"
    onClick={onClick}
  >
    <span>Restore</span>
    <span className="message-btn-icon ml-1">
      <ArrowCounterclockwise />
    </span>
  </div>
);

interface DeleteStateTileOverlayProps {
  isDeleted: boolean;
  message?: MessageModel;
  onDelete(messageIds?: string[]): void;
  onRestore(messageIds?: string[]): void;
}


const DeleteStateTileOverlay: React.FC<DeleteStateTileOverlayProps> = ({ isDeleted, message, onDelete, onRestore }) => {
  function handleDelete() {
    if (message) {
      onDelete([message.id()]);
    } else {
      onDelete();
    }
  }

  function handleRestore() {
    if (message) {
      onRestore([message.id()]);
    } else {
      onRestore();
    }
  }

  return (
    <div className="message-overlay">
      {!isDeleted && <DeleteButton onClick={handleDelete} />}
      {isDeleted && <RestoreButton onClick={handleRestore} />}
    </div>
  );
};

interface ConversationTileTileProps {
  them: {
    photo: string;
    name: string;
    lastMessage: Date;
  };

  onClick: ()=> void;
  selected: boolean;
  isDeleted: boolean;
  deletedDate: Date | undefined;
  onDelete(): void;
  onRestore(): void;
}

export const ConversationTile: React.FC<ConversationTileTileProps> = ({ them, onClick, selected, isDeleted, deletedDate, onDelete, onRestore }) => {
  return (
    <div
      className={classNames('row mb-2 mr-2 p-1', { 'conversation-tab-selected': selected }, { 'conversation-tab': !selected })}
      onClick={() => onClick()}
    >
      <div className={classNames('col-4 d-flex justify-content-center flex-column', { 'content-deleted': isDeleted })}>
        <img
          src={them.photo}
          alt=""
          style={{ width: 72, height: 72, borderRadius: '50%', objectFit: 'cover' }}
        />
      </div>
      <div className={classNames('col-8 justify-content-center flex-column', { 'content-deleted': isDeleted })}>
        <p className="py-0 my-0">Conversation with</p>
        <p className="font-weight-bold py-0 my-0">{them.name}</p>
        {!isDeleted && <p className="py-0 my-0">{`Last message: ${relativeMessageDate(them.lastMessage)}`}</p>}
        {isDeleted && deletedDate &&
          <p className="deleted-date mb-1 mt-2">{'Deleted ' + formatMessageDate(deletedDate)}</p>
        }
      </div>
      <DeleteStateTileOverlay
        isDeleted={isDeleted}
        onDelete={onDelete}
        onRestore={onRestore}
      />
    </div>
  );
};


interface MessageTileProps {
  message: MessageModel;
  user: MessageUser;
  onDelete(messageIds: string[], isBulkAction?: boolean): void;
  onRestore(messageIds: string[], isBulkAction?: boolean): void;
}

export const MessageTile: React.FC<MessageTileProps> = ({ message, ...props }) => {
  return props.user === MessageUser.You ? <YouMessageTile
    message={message}
    {...props}
  /> : <ThemMessageTile
    message={message}
    {...props}
  />;
};

const YouMessageTile: React.FC<MessageTileProps> = ({ message, ...props }) => {
  return (
    <div className="row my-2">
      <div className="col-4" />
      <div className={classNames('col-8', { 'you-message': !message.deletedAt() }, { 'you-message-deleted': !!message.deletedAt() })}>
        <InnerMessage message={message} />
        <DeleteStateTileOverlay
          message={message}
          isDeleted={message.deletedAt() !== undefined}
          {...props}
        />
      </div>
    </div>
  );
};

const ThemMessageTile: React.FC<MessageTileProps> = ({ message, ...props }) => {
  return (
    <div className="row my-2">
      <div className={classNames('col-8', { 'them-message': !message.deletedAt() }, { 'them-message-deleted': !!message.deletedAt() })}>
        <InnerMessage message={message} />
        <DeleteStateTileOverlay
          message={message}
          isDeleted={message.deletedAt() !== undefined}
          {...props}
        />
      </div>
      <div className="col-4" />
    </div>
  );
};

const InnerMessage: React.FC<{message: MessageModel}> = ({ message }) => {
  const isDeleted = message.deletedAt() !== undefined;

  return (
    <div className={isDeleted ? 'content-deleted' : ''}>
      <p className="message my-1">{`${message.text()}`}</p>
      <p className="date mb-1 mt-2">{formatMessageDate(message.createdAt())}</p>
      {!!isDeleted &&
        <p className="deleted-date mb-1 mt-2">{'Deleted ' + formatMessageDate(message.deletedAt()!)}</p>
      }
    </div>
  );
};