import React, { useState } from 'react';
import { CaretLeftFill } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { BudgetAdjustmentsContainer } from '../../../../containers/Client/BudgetAdjustments';
import { getSupportNeedModelTypeDescription } from '../../../../enums/SupportNeedModelType';
import { SupportNeedState } from '../../../../managers/SupportNeeds';

export interface BudgetAdjustmentsViewProps {
  supportNeed: SupportNeedState;
  clientId: string;
  hideBackButton?: boolean;
  backButtonUrl?: string;
  onUpdated(): void;
}

export const BudgetAdjustmentsView: React.FC<BudgetAdjustmentsViewProps> = ({
  supportNeed, clientId, hideBackButton, backButtonUrl, onUpdated,
}) =>  {
  const [isStateModified, setIsStateModified] = useState(false);

  function handleStateModified(isModified: boolean) {
    setIsStateModified(isModified);
  }

  function handleClickBack(event: React.MouseEvent<HTMLAnchorElement>) {
    if (!isStateModified) {
      return;
    } else {
      if (!window.confirm('You have unsaved changes, do you wish to continue?')) {
        event.stopPropagation();
        event.preventDefault(); 
      }
    }
  }

  return (
    <section className="pb-2 pt-4">
      <h4 className="col-12 text-center">
        {!hideBackButton && backButtonUrl && (
          <div style={{ position: 'absolute' }}>
            <Link
              to={backButtonUrl}
              style={{ verticalAlign: 'middle' }}
              onClick={handleClickBack}
            >
              <CaretLeftFill style={{ transform: 'scaleX(1.3) translateY(-1px)' }} />
              Back
            </Link>
          </div>
        )}
        Budget adjustments: ({getSupportNeedModelTypeDescription(supportNeed.type)})
      </h4>
      <hr />
      <div className="col-12">
        <BudgetAdjustmentsContainer
          supportNeed={supportNeed}
          clientId={clientId}
          onUpdated={onUpdated}
          onStateModified={handleStateModified}
        />
      </div>
    </section>
  );
};
