import { View } from '@react-pdf/renderer';
import React from 'react';

interface SpacerProps {
  width?: number;
  height?: number;
}

export const Spacer: React.FC<SpacerProps> = ({ width, height }) => {
  return <View style={{ width: width || 0, height: height || 0 }} />;
};
