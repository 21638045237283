import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ClientManager from '../../managers/ClientManager';
import { ClientModel } from '../../models/Client';
import { clientsPath } from '../../Routes';
import { ClientView } from '../../views/Client';


export const ClientContainer:React.FC = () =>  {
  const [client, setClient] = useState<ClientModel | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const match = useRouteMatch({ path: `${clientsPath}/:id` });
  let id = (match?.params as any)?.id;
  if (id === '_create') { id = undefined; }

  useEffect(() => {
    if (!id) { return; }
    setIsLoading(true);
    void ClientManager.getClient(id).then(client => {
      setClient(client);
      setIsLoading(false);
    });
  }, [id]);

  return (
    <>
      <ClientView
        client={client}
        isNew={!id}
        key={client?.id()}
        isLoading={isLoading}
      />
    </>
  );
};