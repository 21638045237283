import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { loginPath, settingsPath } from '../../Routes';
import session from '../../Session';
import { BulkAgreementsContainer } from '../../containers/BulkAgreements';
import { ChangePasswordContainer } from '../../containers/ChangePassword';
import { MutesContainer } from '../../containers/Mutes';
import { ServiceAgreementsContainer } from '../../containers/ServiceAgreements';

export const Settings = () =>  {
  const tabbedMatch = useRouteMatch({ path: `${settingsPath}/:tab` });
  const tab = (tabbedMatch?.params as any)?.tab || 'mutes';
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  return (
    <div className="m-4">
      <Tabs
        defaultActiveKey={tab}
        id="uncontrolled-tab-example"
        onSelect={tab => history.push(`${settingsPath}/${tab}`)}
      >
        <Tab
          eventKey="mutes"
          title="Mutes"
        >
          <MutesContainer />
        </Tab>
        <Tab
          eventKey="agreements"
          title="Agreements"
        >
          <BulkAgreementsContainer />
          <hr />
          <ServiceAgreementsContainer />
        </Tab>
        <Tab
          eventKey="change-password"
          title="Change Password"
        >
          <ChangePasswordContainer />
        </Tab>
      </Tabs>
    </div>
  );
};
