import Parse from 'parse';
import { WorkerReportModel } from '../../models/WorkerReport';

const WORKER_REPORT_CACHE: {[id: string]: WorkerReportModel} = {};
let WORKER_REPORTS_CACHE: WorkerReportModel[] = [];

export async function getWorkerReports(): Promise<WorkerReportModel[]> {
  if (WORKER_REPORTS_CACHE.length > 0) {
    return WORKER_REPORTS_CACHE;
  }

  const query = new Parse.Query('IncidentReport');
  query.include('worker');
  query.include('client.caseManager');
  query.limit(1_000_000);
  query.descending('createdAt');
  const parseModels = await query.find();
  const models = parseModels.map(parseModel => new WorkerReportModel(parseModel));

  models.forEach(model => WORKER_REPORT_CACHE[model.id()] = model);
  WORKER_REPORTS_CACHE = models;
  return models;
}


export async function getWorkerReport(id: string): Promise<WorkerReportModel | undefined> {
  if (WORKER_REPORT_CACHE[id] !== undefined) {
    return WORKER_REPORT_CACHE[id];
  }

  const query = new Parse.Query('IncidentReport');
  query.include('worker');
  query.include('client.caseManager');
  const parseModel = await query.get(id);
  if (!parseModel) { return; }

  const model = new WorkerReportModel(parseModel);
  WORKER_REPORT_CACHE[model.id()] = model;

  return model;
}