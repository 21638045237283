import Parse from 'parse';
import { useState } from 'react';
import { PFConfig } from '../../types/parseConfig';

export interface UseParseConfig {
  loadConfig(): void;
  isConfigLoading: boolean;
  isConfigLoaded: boolean;
  config: PFConfig | undefined;
}

const configStore: Record<'config', PFConfig | undefined> = { config: undefined };

/**
 * UseParseConfig hook
 * 
 * Provides functionality for loading and retrieving the Parse PFConfig data
 */
const useParseConfig = (): UseParseConfig => {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  async function loadConfig() {
    if (configStore.config) {
      setIsLoaded(true);
    } else {
      setIsLoading(true);
      const config: any = await Parse.Config.get();
      configStore.config = {
        datedServiceAgreements: config.get('datedServiceAgreements'),
        datedCancellationPolicies: config.get('datedCancellationPolicies'),
        publicHolidays: config.get('publicHolidays'),
        postcodes: config.get('postcodes'),
      };
      setIsLoaded(true);
    }
  }

  return {
    isConfigLoading: isLoading,
    isConfigLoaded: isLoaded,
    config: configStore.config,
    loadConfig,
  };
};

export default useParseConfig;