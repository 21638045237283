import React from 'react';
import Session from '../../Session';
import { LeftSideMenu } from '../../components/LeftSideMenu';

export const VerticalLayout: React.FC = (props) => {
  const isLoggedIn = Session.isUserLoggedIn();
  const adminUser = Session.getUser();
  const contentPageClass = isLoggedIn ? 'content-page' : 'content-page-unauth';

  return (
    <div className="app">
      <div className="wrapper">
        {
          isLoggedIn && adminUser &&  <LeftSideMenu adminUser={adminUser} />
        }
        <div className={`${contentPageClass} pt-0`}>
          <div className="content">
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
};