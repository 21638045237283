export enum DayAvailabilityDay {
  Monday=1,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday,
  Sunday
}

export function getDayDescription(day: DayAvailabilityDay): string {
  return DayAvailabilityDay[day] + 's'; // eg "Mondays"
}

const DayAvailabilityDayKeys = Object.keys(DayAvailabilityDay).filter(key => typeof DayAvailabilityDay[key as any] === 'number');
export const DayAvailabilityDayCases = DayAvailabilityDayKeys.map(key => Number(DayAvailabilityDay[key as any]) as DayAvailabilityDay);

export function remainingDays(days: DayAvailabilityDay[]): DayAvailabilityDay[] {
  return DayAvailabilityDayCases.filter(day => !days.includes(day));
}