import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ModalManager } from '../../../Modals/Error/manager';
import { ToastManager } from '../../../Modals/Toasts/manager';
import { clientsPath } from '../../../Routes';
import { Loading } from '../../../components/Loading';
import { AnalyticsEventType } from '../../../enums/AnalyticsEventType';
import { sleep } from '../../../helpers';
import { getClientDetailChangeFields } from '../../../helpers/clientDetailChanges';
import useAnalytics, { AnalyticsEvent } from '../../../hooks/useAnalytics';
import ClientManager from '../../../managers/ClientManager';
import { ClientDetailsSave, ClientModel } from '../../../models/Client';
import { ClientDetailsView } from '../../../views/Client/Details';

export interface ClientDetailsContainerProps {
  client?: ClientModel;
  onKeyChanged: (key: string, value: unknown) => void;

  isLoading: boolean;
}

export const ClientDetailsContainer: React.FC<ClientDetailsContainerProps> = ({ client, onKeyChanged, isLoading }) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [cancelCount, setCancelCount] = useState<number>(0);
  const [stateClient, setStateClient] = useState<ClientModel | undefined>(client);
  const [triggerEventReload, setTriggerEventReload] = useState(false);
  const { logEvents, getLastEventInfo, isLoading: isLastEventLoading, lastEvent } = useAnalytics();
  const history = useHistory();

  useEffect(() => {
    if (!client) { return; }
    setTriggerEventReload(true);
  }, [client]);

  useEffect(() => {
    if (triggerEventReload && client) {
      setTriggerEventReload(false);
      void getLastEventInfo([
        AnalyticsEventType.clientProfileCreated,
        AnalyticsEventType.clientProfileEdited,
      ], [client.id()]);
    }
  }, [triggerEventReload, client]);

  /**
   * Handle saving changes
   */
  async function handleSave(cli: ClientModel, state: ClientDetailsSave) {
    const events: AnalyticsEvent[] = [];
    if (stateClient) {
      const changedFields = getClientDetailChangeFields(stateClient, state);

      if (changedFields.length > 0) {
        if (stateClient.id()) {
          events.push({
            eventType: AnalyticsEventType.clientProfileEdited,
            objectId: stateClient.id(),
          });
        } else {
          events.push({
            eventType: AnalyticsEventType.clientProfileCreated,
            objectId: 'UNKNOWN',
          });
        }
      }
    }

    try {
      setIsSaving(true);
      await sleep(500);
      const updated = await ClientManager.saveClientDetails(cli, state);
      setStateClient(updated);
      ToastManager.shared().show('Client details saved.');

      events.forEach(event => {
        if (event.eventType === AnalyticsEventType.clientProfileCreated) {
          event.objectId = updated.id();
        }
      });

      void logEvents(events);
      setTriggerEventReload(true);

      setTimeout(() => {
        setIsSaving(false);
        history.push(`${clientsPath}/${updated.id()}`);
      }, 500);
    } catch {
      setIsSaving(false);
      ModalManager.shared().show('Error', 'There was an error saving the client\'s details. Please try again.');
    }

  }

  return (
    <>
      {isLoading &&
        <Loading />
      }
      {stateClient &&
        <ClientDetailsView
          client={stateClient}
          disableInteraction={isSaving}
          onKeyChanged={onKeyChanged}
          key={cancelCount + (client?.id() || '')}
          lastProfileEvent={isLastEventLoading ? undefined : lastEvent}
          onReset={() => setCancelCount(cancelCount + 1)}
          onSave={handleSave}
          isSaving={isSaving}
        />
      }
      {(!client && !isLoading) &&
        <div className="text-center mt-2">No client with that ID</div>
      }
    </>
  );
};