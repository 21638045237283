import classNames from 'classnames';
import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import './AsyncExportButton.css';

enum AsyncExportButtonState {
  start = 'Export',
  exporting = 'Exporting...',
  readyToDownload = 'Download',
}

export interface AsyncExportButtonOption {
  title: string;
  enabled: boolean;
  onExport: () => Promise<void>;
  onDownload: () => void;
}

export interface AsyncExportButtonProps {
  options: AsyncExportButtonOption[];
  enableSingleExportClick?: boolean;
  disabled?: boolean;
}

export const AsyncExportButton: React.FC<AsyncExportButtonProps> = ({
  options,
  disabled,
  enableSingleExportClick,
}) => {
  const [state, setState] = useState<AsyncExportButtonState>(AsyncExportButtonState.start);
  const [option, setOption] = useState<AsyncExportButtonOption | undefined> (undefined);// use it for the button click...

  // If there is only one export option, just allow clicking the button
  // and not require the dropdown
  if (enableSingleExportClick && options.length === 1 && options[0]) {
    return (
      <button
        type="button"
        className="btn btn-primary mr-2 my-auto"
        disabled={state === AsyncExportButtonState.exporting || disabled}
        onClick={(e) => {
          if (state !== AsyncExportButtonState.readyToDownload) {
            e.stopPropagation();
            setState(AsyncExportButtonState.exporting);
            setOption(option);
            document.body.click();
            void options[0].onExport().then(() => setState(AsyncExportButtonState.readyToDownload));
          } else if (state === AsyncExportButtonState.readyToDownload) {
            if (options[0]) { options[0].onDownload(); }
            setState(AsyncExportButtonState.start);
            return;
          }
        }
        }
      >
        <Download style={{ marginBottom: '4px', marginRight: '4px' }} />
        {state.toString()}
      </button>
    );
  }

  return (
    <OverlayTrigger
      rootClose
      placement="bottom"
      trigger={(state === AsyncExportButtonState.start) ? 'click' : []}
      overlay={<Tooltip
        className="white-tooltip"
        id="export"
      >
        {options.map(option => <div
          key={option.title}
          className={classNames('mb-1', { 'cursor-pointer': option.enabled, 'text-muted': !option.enabled })}
          onClick={e => {
            if (!option.enabled) { return; }
            e.stopPropagation();
            setState(AsyncExportButtonState.exporting);
            setOption(option);
            document.body.click();
            void option.onExport().then(() => setState(AsyncExportButtonState.readyToDownload));
          }}
        >{option.title}</div>)}
      </Tooltip>
      }
    >
      <button
        type="button"
        className="btn btn-primary mr-2 my-auto"
        disabled={state === AsyncExportButtonState.exporting || disabled}
        onClick={() => {
          if (state === AsyncExportButtonState.readyToDownload) {
            if (option) { option.onDownload(); }
            setState(AsyncExportButtonState.start);
            return;
          }
        }
        }
      >
        <Download style={{ marginBottom: '4px', marginRight: '4px' }} />
        {state.toString()}
      </button>
    </OverlayTrigger>
  );
};