import React, { useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import { ErrorModal } from '../../Modals/Error/ErrorModal';
import { AdminNoteTile } from '../../components/AdminNoteTile';
import { AdminType } from '../../enums/AdminType';
import { AdminNoteModel } from '../../models/AdminNote';
import { AdminUser } from '../../models/AdminUser';
import { SaveButton } from '../Client/SaveButton';

export interface AdminManagementViewProps {
  isClient?: boolean;
  adminUsers?: AdminUser[];
  clientCaseManager?: AdminUser | undefined;
  clientExperienceOfficer?: AdminUser | undefined;
  initialContactMethod?: string;
  adminNotes?: AdminNoteModel[];
  onSaveClient: (caseManager?: AdminUser, experienceOfficer?: AdminUser) => Promise<void>;
  onSaveAdminNote(text: string): Promise<void>;
  onSaveContactMethod(value: string): Promise<void>;
  onReset: () => void;
  disableInteraction: boolean;
  isSaving?: boolean;
  isSavingNotes?: boolean;
}

const AdminManagementView: React.FC<AdminManagementViewProps> = ({
  isClient,
  adminUsers = [],
  clientCaseManager,
  clientExperienceOfficer,
  initialContactMethod,
  adminNotes = [],
  onSaveClient,
  onSaveAdminNote,
  onSaveContactMethod,
  onReset,
  disableInteraction,
  isSaving,
  isSavingNotes,
}): JSX.Element => {
  const [newNote, setNewNote] = useState('');
  const [caseManagerId, setCaseManagerId] = useState('');
  const [experienceOfficerId, setExperienceOfficerId] = useState('');
  const [isDirty, setIsDirty] = useState(false);
  const [isAdminNoteDirty, setIsAdminNoteDirty] = useState(false);
  const [isAdminUserError, setIsAdminUserError] = useState(false);
  const [contactMethod, setContactMethod] = useState(initialContactMethod);

  useEffect(() => {
    if (clientCaseManager?.admin?.id !== caseManagerId) {
      if (clientCaseManager?.deletedAt() !== undefined) {
        setCaseManagerId('');
        return;
      }
      setCaseManagerId(clientCaseManager?.admin?.id ?? '');
    }
    if (clientExperienceOfficer?.admin?.id !== experienceOfficerId) {
      if (clientExperienceOfficer?.deletedAt() !== undefined) {
        setExperienceOfficerId('');
        return;
      }
      setExperienceOfficerId(clientExperienceOfficer?.admin?.id ?? '');
    }
    setContactMethod(initialContactMethod);
  }, [clientCaseManager, clientExperienceOfficer]);

  function findAdminById(id: string): AdminUser | undefined {
    return adminUsers?.find((admin) => admin.id() === id);
  }

  function isCaseManagerValid(): boolean {
    if (!isDirty) {
      return true;
    }
    
    const caseManager = findAdminById(caseManagerId);
    if (caseManager?.deletedAt() !== undefined) { 
      return false;
    }
    return caseManagerId !== '';
  }
  
  function isExperienceOfficerValid(): boolean {
    const experienceOfficer = findAdminById(experienceOfficerId);
    if (experienceOfficer?.deletedAt() !== undefined) { 
      return false;
    }
    return true;
  }

  function handleChangeCaseManager(id: string): void {
    setCaseManagerId(id);
    setIsDirty(true);
  }

  function handleChangeExperienceOfficer(id: string): void {
    setExperienceOfficerId(id);
    setIsDirty(true);
  }

  function handleChangeContactMethod(value: string): void {
    setContactMethod(value);
    setIsDirty(true);
  }

  function handleChangeNewNote(value: string): void {
    setNewNote(value);
    setIsAdminNoteDirty(true);
  }

  function renderCaseManagerSelector(): JSX.Element | null {
    if (!adminUsers) {
      return null;
    }
  
    const adminOptions = adminUsers
      .filter((admin) => admin.type() === AdminType.caseManager)
      .filter((admin) => {
        return admin.id() === caseManagerId || !admin.deletedAt();
      });

    return (
      <select
        id="caseManager"
        className={`form-control col-4 ${!isCaseManagerValid() ? 'is-invalid' : ''}`}
        value={caseManagerId}
        onChange={e => handleChangeCaseManager(e.target.value)}
        disabled={disableInteraction}
      >
        {
          caseManagerId === '' && (
            <option value="">Select user...</option>
          )
        }
        {
          adminOptions.map((admin) => (
            <option
              key={admin.id()}
              value={admin.id()}
              disabled={admin.deletedAt() !== undefined}
            >{admin.fullName()}</option>))
        }
      </select>
    );
  }

  function renderExperienceOfficerSelector(): JSX.Element | null {
    if (!adminUsers) {
      return null;
    }
  
    const adminOptions = adminUsers
      .filter((admin) => admin.type() === AdminType.experienceOfficer)
      .filter((admin) => {
        return admin.id() === experienceOfficerId || !admin.deletedAt();
      });

    return (
      <>
        <select
          id="experienceOfficer"
          className={`form-control ${experienceOfficerId === '' ? 'col-4' : 'col-3'} ${!isExperienceOfficerValid() ? 'is-invalid' : ''}`}
          value={experienceOfficerId}
          onChange={e => handleChangeExperienceOfficer(e.target.value)}
          disabled={disableInteraction}
        >
          {
            experienceOfficerId === '' && (
              <option value="">Select user...</option>
            )
          }
          {
            adminOptions.map((admin) => (
              <option
                key={admin.id()}
                value={admin.id()}
                disabled={admin.deletedAt() !== undefined}
              >{admin.fullName()}</option>))
          }
        </select>
        {
          experienceOfficerId !== '' && (
            <X
              className="col-1 text-danger"
              size={24}
              style={{ cursor: 'pointer', marginTop: 7, marginLeft: -20 }}
              onClick={() => handleChangeExperienceOfficer('')}
            />
          )
        }
      </>
    );
  }

  /**
   * Renders the text area for admin note entry
   */
  function renderAdminNoteEntry(): JSX.Element {
    return (
      <>
        <div className="row form-group">
          <textarea
            id="notes_client"
            className="col-12 form-control mb-2"
            rows={4}
            value={newNote}
            placeholder="Enter new note..."
            onChange={e => handleChangeNewNote(e.target.value) }
            disabled={disableInteraction}
          />
        </div>
      </>
    );
  }

  /**
   * Renders the list of admin note items for a Client
   */
  function renderAdminNotes(): JSX.Element {
    return (
      <div className="row mt-4 flex-column">
        {
          adminNotes.map((note) => (
            <div
              key={`admin_note_${note.id()}`}
              className="mb-2"
            > 
              <AdminNoteTile adminNote={note} />
            </div>
          ))
        }
      </div>
    );
  }

  function renderErrorModal(): JSX.Element {
    return <ErrorModal
      show={isAdminUserError}
      title="Admin selection error"
      message="The case manager or experience officer selected is not valid. Please double check your selection."
      onClose={() => setIsAdminUserError(false)}
    />;
  }

  /**
   * Save changes handler for case manager, experience officer and worker admin notes
   */
  async function handleSave(): Promise<void> {    
    if (isClient) {
      if (!isCaseManagerValid() || !isExperienceOfficerValid()) {
        setIsAdminUserError(true);
        return;
      }

      const caseManager = findAdminById(caseManagerId);
      const experienceOfficer = findAdminById(experienceOfficerId);
      await onSaveClient(caseManager, experienceOfficer);
      await onSaveContactMethod(contactMethod ?? '');
    } else {
      await onSaveContactMethod(contactMethod ?? '');
    }

    setIsDirty(false);
  }

  /**
   * Save changes to a new admin note for a client or worker
   */
  async function handleSaveAdminNote(): Promise<void> {
    setIsAdminNoteDirty(false);
    await onSaveAdminNote(newNote);
    setNewNote('');
  }

  return (
    <div className="container pb-2 pt-4">
      <form>
        {isClient && (
          <div className="row form-group">
            <label
              htmlFor="caseManager"
              className="col-2 col-form-label text-right"
            >Case Manager</label>
            {renderCaseManagerSelector()}
            <label
              htmlFor="experienceOfficer"
              className="col-2 col-form-label text-right"
            >Experience Officer</label>
            {renderExperienceOfficerSelector()}
          </div>
        )}

        <div className="row form-group">
          <label
            htmlFor="preferredContactMethod"
            className="col-2 col-form-label text-right"
            style={{ lineHeight: '1.3' }}
          >Preferred Contact Method</label>
          <input
            id="preferredContactMethod"
            type="text"
            className="form-control col-10"
            value={contactMethod}
            onChange={e => handleChangeContactMethod(e.target.value)}
            disabled={disableInteraction}
          />
        </div>

        <SaveButton
          onSave={handleSave}
          onCancel={() => onReset()}
          disableCancel={disableInteraction}
          disableSave={disableInteraction || !isDirty}
          isSaving={isSaving}
        />

        <hr />

        <div className="text-center mt-3 mb-3">
          <h3>Admin notes</h3>
        </div>

        {renderAdminNoteEntry()}

        <SaveButton
          onSave={handleSaveAdminNote}
          onCancel={() => { setNewNote(''); }}
          disableCancel={disableInteraction}
          disableSave={disableInteraction || !isAdminNoteDirty || newNote === ''}
          saveText="Save note"
          cancelText="Clear"
          isSaving={isSavingNotes}
        />
      </form>

      {renderAdminNotes()}

      {isAdminUserError && renderErrorModal()}
    </div>
  );
};

export default AdminManagementView;