import moment from 'moment';
import { getGoalStatusColor, getGoalStatusDescription, GoalStatus } from '../../enums/GoalStatus';
import { GoalModel } from '../../models/Goal';


export interface GoalState  {
  id?: string;

  title?: string;
  criteria?: string;

  status?: string;
  color?: string;
  isInactive?: boolean;

  completedDates?: string[];

  isDeleted?: boolean;

  isDirty: boolean;
}

export const UnsavedGoalPrefix = 'new:';

const CompletedAtFormat = 'D/M/YYYY';

export function goalStateFromGoalModel(goal: GoalModel) : GoalState {
  return {
    id: goal.id(),
    criteria: goal.criteria(),
    title: goal.title(),
    status: getGoalStatusDescription(goal.status()),
    color: getGoalStatusColor(goal.status()),
    isInactive: goal.status() === GoalStatus.inactive,
    completedDates: goal.completedDates()?.map(date =>  moment(date).format(CompletedAtFormat)),

    isDeleted: false,
    isDirty: false,
  };
}
