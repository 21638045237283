import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { billingPath } from '../../Routes';
import { AsyncExportButton, AsyncExportButtonOption } from '../../components/AsyncExportButton';
import { Loading } from '../../components/Loading';
import { SupportSessionRequestTile } from '../../components/SupportSessionRequestTile';
import { SupportSessionTile } from '../../components/SupportSessionTile';
import { ToolBar } from '../../components/Toolbar';
import { FundingManagementType } from '../../enums/SupportNeedFundingManagementType';
import { buildBillingPath, fromStorage, parseDateParam, toStorage } from '../../helpers/sessionAndBillingUtils';
import { applySessionSearch } from '../../helpers/sessionFilterAndSort';
import InvoicingManager, { InvoiceType } from '../../managers/Invoicing';
import sessionsManager, { BillingFilter, DashboardParams, DashboardSessionFilters } from '../../managers/Sessions';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SupportSessionRequestModel } from '../../models/Sessions/SupportSessionRequest';
import { useSearchParams } from '../../views/Hooks/useSearchParams';

const FilterNames: { [id: string]: string } = {
  custom: 'Unknown filter',
  ndia: 'NDIA sessions',
  invoices: 'Invoice sessions',
  payroll: 'Payroll sessions',  
};

const BILLING_SEARCH_KEY = 'suitsme.search.billing';

export const BillingContainer: React.FC = () => {
  const history = useHistory();
  const filter = useRouteMatch<{ filter: BillingFilter }>({ path: `${billingPath}/:filter` })?.params.filter;

  let initialBefore = useSearchParams('before');
  let initialAfter = useSearchParams('after');
  const paramSearch = useSearchParams('search');
  initialBefore = parseDateParam(initialBefore) ?? null;
  initialAfter = parseDateParam(initialAfter) ?? null;

  const [sessionRequests, setSessionRequests] = useState<SupportSessionRequestModel[]>([]);
  const [sessions, setSessions] = useState<SupportSessionModel[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Client side filtering
  const [searchTerm, setSearchTerm] = useState<string | undefined>(paramSearch || fromStorage(BILLING_SEARCH_KEY) || '');

  // Server side filtering, note the defaults:
  const [beforeDate, setBeforeDate] = useState<Date | undefined>(initialBefore || undefined);
  const [afterDate, setAfterDate] = useState<Date | undefined>(initialAfter || undefined);

  // Retain copy of last applied filters
  const [lastFilters, setLastFilters] = useState<DashboardSessionFilters>({
    beforeDate,
    afterDate,
  });

  const latestParams = useRef<DashboardParams | undefined>();

  // Filtering
  const applyFilters = (): void => {
    if (!filter) { return; }

    async function fetchData(params: DashboardParams) {
      setIsLoading(true);
      const sessionsAndRequests = await sessionsManager.getDashboardSessionsAndRequests(params);

      let requestResults = sessionsAndRequests.results.requests.map((req: Parse.Object) => new SupportSessionRequestModel(req));
      let sessionResults = sessionsAndRequests.results.sessions.map((session: Parse.Object) => new SupportSessionModel(session));

      requestResults = requestResults.sort((request1, request2) => new Date(request1.firstDateStart()).valueOf() - new Date(request2.firstDateStart()).valueOf());
      sessionResults = sessionResults.sort((session1, session2) => session1.bestKnownStartDate().valueOf() - session2.bestKnownStartDate().valueOf());

      return {
        requestResults,
        sessionResults,
        params: sessionsAndRequests.params,
      };
    }

    // Set managed here based on filter
    let managed: FundingManagementType[] = [];

    switch (filter) {
      case 'ndia':
        managed = [FundingManagementType.NDIA];
        break;
      case 'invoices':
        managed = [FundingManagementType.Plan, FundingManagementType.Self];
        break;
      case 'payroll':
        managed = [FundingManagementType.Plan, FundingManagementType.Self, FundingManagementType.NDIA];
        break;
      default:
        managed = [FundingManagementType.Plan, FundingManagementType.Self, FundingManagementType.NDIA];
        break;
    }

    const params = { filter: 'billable', after: afterDate, before: beforeDate, managed };
    latestParams.current = params;
    setLastFilters({
      beforeDate,
      afterDate,
    });

    history.push(buildBillingPath(filter, afterDate, beforeDate, searchTerm));

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    void fetchData(params).then(result => {
      // This check prevents results from old filters loading in over top of actually requested data.
      if (result.params === latestParams.current) {
        setSessionRequests(result.requestResults);
        setSessions(result.sessionResults);

        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (beforeDate && afterDate) {
      applyFilters();
    }
  }, [filter]);

  // Detect when filters are changed and show the Apply button
  const isFiltersChanged = useMemo(() => {
    if (!beforeDate || !afterDate) {
      return false;
    }
    
    if (afterDate !== lastFilters.afterDate ||
      beforeDate !== lastFilters.beforeDate) {
      return true;
    } else {
      return false;
    }
  }, [filter, afterDate, beforeDate, lastFilters]);

  // Reset pending filters back to the current state
  const resetFilters = (): void => {
    setBeforeDate(lastFilters.beforeDate);
    setAfterDate(lastFilters.afterDate);
  };

  // Save to local storage hooks
  useEffect(() => {
    if (searchTerm || searchTerm === '') { toStorage(searchTerm, BILLING_SEARCH_KEY); }
  }, [searchTerm]);

  // Update url hooks
  useEffect(() => {
    if (!filter) { return; }
    history.push(buildBillingPath(filter, afterDate, beforeDate, searchTerm));
  }, [filter, searchTerm, history]);

  const updateDate = (date: Date | [Date, Date] | null, updateFn: (date: Date) => unknown) => {
    const momentDate = moment(date as Date);
    if (!momentDate.isValid()) { return; }
    updateFn(momentDate.toDate());
  };


  const getInvoiceFileName = (invoiceType: InvoiceType) => {
    const from = afterDate ? moment(afterDate).format('yyy-MM-DD') : 'all_time';
    const to = beforeDate ? moment(beforeDate).format('yyy-MM-DD') : 'future';

    switch (invoiceType) {
      case InvoiceType.ndia:
        return `ndia_invoice_${from}_to_${to}.csv`;
        break;
      case InvoiceType.selfPlanManagedXero:
        return `xero_invoice_${from}_to_${to}.csv`;
        break;
      case InvoiceType.keypayHours:
        return `keypay_hours_${from}_to_${to}.csv`;
      case InvoiceType.keypayTransport:
        return `keypay_transport_${from}_to_${to}.csv`;
      case InvoiceType.payroll:
      default:
        return `payroll_${from}_to_${to}.csv`;
    }
  };

  // Invoice export options for billable sessions
  const billableInvoicingOptions = (): AsyncExportButtonOption[] => {
    switch (filter) {
      case 'ndia':
        return [{
          title: 'NDIA report',
          enabled: !isLoading,
          onExport: async () => InvoicingManager.instance().prepareInvoice(
            InvoiceType.ndia,
            sessions.filter(session => applySessionSearch(session, searchTerm)).map(session => session.id()).filter(Boolean) as string[]),
          onDownload: () => InvoicingManager.instance().downloadLastInvoice(getInvoiceFileName(InvoiceType.ndia)),
        }];

      case 'invoices':
        return [{
          title: 'Invoice report',
          enabled: !isLoading,
          onExport: async () => InvoicingManager.instance().prepareInvoice(
            InvoiceType.selfPlanManagedXero,
            sessions.filter(session => applySessionSearch(session, searchTerm)).map(session => session.id()).filter(Boolean) as string[]),
          onDownload: () => InvoicingManager.instance().downloadLastInvoice(getInvoiceFileName(InvoiceType.selfPlanManagedXero)),
        }];

      case 'payroll':
      default:
        return [{
          title: 'KeyPay hours report',
          enabled: !isLoading,
          onExport: async () => InvoicingManager.instance().prepareInvoice(
            InvoiceType.keypayHours,
            sessions.filter(session => applySessionSearch(session, searchTerm)).map(session => session.id()).filter(Boolean) as string[]),
          onDownload: () => InvoicingManager.instance().downloadLastInvoice(getInvoiceFileName(InvoiceType.keypayHours)),
        }, {
          title: 'KeyPay transport report',
          enabled: true,
          onExport: async () => InvoicingManager.instance().prepareInvoice(
            InvoiceType.keypayTransport,
            sessions.filter(session => applySessionSearch(session, searchTerm)).map(session => session.id()).filter(Boolean) as string[]),
          onDownload: () => InvoicingManager.instance().downloadLastInvoice(getInvoiceFileName(InvoiceType.keypayTransport)),
        }, {
          title: 'Integrated payroll report',
          enabled: true,
          onExport: async () => InvoicingManager.instance().prepareInvoice(
            InvoiceType.payroll,
            sessions.filter(session => applySessionSearch(session, searchTerm)).map(session => session.id()).filter(Boolean) as string[]),
          onDownload: () => InvoicingManager.instance().downloadLastInvoice(getInvoiceFileName(InvoiceType.payroll)),
        }];
    }
  };

  const filteredSessionRequests = sessionRequests.filter(session => applySessionSearch(session, searchTerm));
  const filteredSessions = sessions.filter(session => applySessionSearch(session, searchTerm));
  const numSessions = filteredSessionRequests.length + filteredSessions.length;

  return (
    <div className="mx-4">
      <ToolBar
        title={FilterNames[filter || 'custom']}
        top={
          <>
            {!isLoading && !isFiltersChanged &&
              <AsyncExportButton
                options={billableInvoicingOptions()}
                disabled={numSessions === 0}
                enableSingleExportClick
              />
            }
            <div className="form-inline col-4">
              <input
                className="form-control w-100"
                type="search"
                placeholder="Search"
                aria-label="Search"
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
              />
            </div>
          </>
        }
        filters={
          <div className="row">
            <div className="col-9 mb-1 pl-0">
              <form className="form-inline">
                <div className="form-group col-6">
                  <label
                    className="col-4 col-form-label text-right text-white d-block"
                  >Finalised after</label>
                  <div className="col-7 form-control border">
                    <DatePicker
                      showTimeInput
                      placeholderText="Select date"
                      className="w-100 border-0 text-secondary"
                      onChange={date => updateDate(date, setAfterDate)}
                      dateFormat="d/M/yyyy, HH:mm"
                      minDate={moment('2010-01-01').toDate()}
                      maxDate={moment('2050-01-01').toDate()}
                      showYearDropdown
                      selected={afterDate}
                    />
                  </div>
                </div>
              </form>

              <form className="form-inline mt-2 mb-2">
                <div className="form-group col-6">
                  <label
                    className="col-4 col-form-label text-right text-white d-block"
                  >Finalised before</label>
                  <div className="col-7 form-control border">
                    <DatePicker
                      showTimeInput
                      placeholderText="Select date"
                      className="w-100 border-0 text-secondary"
                      onChange={date => updateDate(date, setBeforeDate)}
                      dateFormat="d/M/yyyy, HH:mm"
                      minDate={moment('2010-01-01').toDate()}
                      maxDate={moment('2050-01-01').toDate()}
                      showYearDropdown
                      selected={beforeDate}
                    />
                  </div>
                </div>
                <div
                  className="form-group col-6"
                  style={{ marginTop: -6 }}
                >
                  {
                    isFiltersChanged && (
                      <div style={{ position: 'absolute', left: -30, top: -22 }}>
                        <button
                          type="button"
                          className="btn btn-primary mr-2 my-auto"
                          onClick={applyFilters}
                        >Apply filters</button>
                        <button
                          type="button"
                          className="btn btn-link m-1"
                          onClick={resetFilters}
                        >Cancel</button>
                      </div>
                    )
                  }
                </div>
              </form>
            </div>
          </div>
        }
      />


      {isLoading &&
        <Loading />
      }

      {!isLoading &&
        <div
          className="row px-1 mt-2"
          style={{ opacity: isFiltersChanged ? 0.5 : 1 }}
        >
          {filteredSessionRequests.map(session => <div
            key={session.id()}
            className="col-sm-12 col-md-12 col-lg-6 col-xl-4 p-0"
          >
            <SupportSessionRequestTile
              session={session}
            />
          </div>)}
          {filteredSessions.map(session => <div
            key={session.id()}
            className="col-sm-12 col-md-12 col-lg-6 col-xl-4 p-0"
          ><SupportSessionTile
              key={session.id()}
              session={session}
            /></div>)}
        </div>
      }
      {
        !isLoading && filteredSessions.length === 0 && filteredSessionRequests.length === 0 && (!beforeDate || !afterDate) && (
          <div
            className="alert alert-warning mt-2 text-center"
            role="alert"
          >
            Please select date filters to start searching.
          </div>
        )
      }
      {
        !isLoading && filteredSessions.length === 0 && filteredSessionRequests.length === 0 && beforeDate && afterDate && (
          <div
            className="alert alert-warning mt-2 text-center"
            role="alert"
          >
            There are no sessions or session requests with the current filters applied.
          </div>
        )
      }
    </div>
  );
};
