import Parse from 'parse';

/**
 * Given a _User ID, find the type of user and then look up their
 * first and last name depending on the actual user type
 */
export async function getNameOfUser(user: Parse.User | undefined): Promise<string> {
  let name = 'Unknown';
  
  if (!user) {
    console.log('[debug] ERROR: getNameOfUser: User object does not exist: ', user);
    return name;
  }

  const type = user.get('type');
  let query: Parse.Query | undefined;

  switch (type) {
    case 3:
      query = new Parse.Query('Admin');
      break;
    case 2:
      query = new Parse.Query('Worker');
      break;
    case 1:
      query = new Parse.Query('Client');
      break;
  }

  if (query) {
    query.equalTo('user', user);
    const result = await query.find();

    if (result) {
      const user = result[0];
      const firstName = user?.get('firstName') ?? '';
      const lastName = user?.get('lastName') ?? '';
      name = `${firstName} ${lastName}`;
    }
  }

  return name;
}