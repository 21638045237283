import _ from 'lodash';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ExclamationCircleFill, ExclamationTriangleFill, InfoCircleFill } from 'react-bootstrap-icons';
import { BrandColors } from '../../helpers';
import { Alert, AlertSeverity, AlertTypes } from '../../managers/Alerts';

type AlertIndicatorPopoverType = 'counts' | 'mutes';
type MuteFunction = (type: AlertTypes, duration?: number) => void

interface AlertIndicatorProps {
  alerts: Alert[];
  popover? : AlertIndicatorPopoverType;
  didMute: MuteFunction;
}

interface MuteTooltipProps {
  alert: Alert;
  didMute: MuteFunction;
}

const MuteTooltip: React.FC<MuteTooltipProps> = ({ alert, didMute }) => {
  return <div>
    <div>{alert.description}</div>
    <hr className="my-1" />
    <div
      className="text-danger cursor-pointer mb-1"
      onClick={e => { e.stopPropagation(); didMute(alert.type, 7); }}
    >Mute for 7 days</div>
    <div
      className="text-danger cursor-pointer mb-1"
      onClick={e => { e.stopPropagation(); didMute(alert.type, 14); }}
    >Mute for 14 days</div>
    <div
      className="text-danger cursor-pointer mb-1"
      onClick={e => { e.stopPropagation(); didMute(alert.type, 30); }}
    >Mute for 30 days</div>
    <div
      className="text-danger cursor-pointer mb-1"
      onClick={e => { e.stopPropagation(); didMute(alert.type); }}
    >Mute forever</div>
  </div>;
};

const CountTooltip:React.FC<{count: number}> = (props) => {
  return <div className="text-nowrap">{`${props.count} ${props.count === 1 ? 'alert' : 'alerts'}`}</div>;
};


const InnerIndicator:React.FC<Omit<AlertIndicatorProps, 'didMute'>> = ({ alerts }) => {
  const levels = new Set(alerts.map(alert => alert.severity));

  return (
    <>
      {levels.has(AlertSeverity.error) &&
        <ExclamationCircleFill
          className="ml-1"
          color={BrandColors.red}
          size={20}
        />
      }
      {(!levels.has(AlertSeverity.error) && levels.has(AlertSeverity.warning)) &&
        <ExclamationTriangleFill
          className="ml-1"
          color="#EAC645"
          size={20}
        />
      }
      {(!levels.has(AlertSeverity.error) && !levels.has(AlertSeverity.warning) && levels.has(AlertSeverity.info)) &&
        <InfoCircleFill
          className="ml-1"
          color="blue"
          size={20}
        />
      }
    </>
  );
};

export const AlertIndicator: React.FC<AlertIndicatorProps> = (props) => {
  return (
    <>
      {props.popover &&
        <OverlayTrigger
          rootClose
          placement="right"
          trigger="click"
          overlay={<Tooltip id={_.random(true).toPrecision(5)}>
            { props.popover === 'counts' &&
              <CountTooltip
                count={props.alerts.length}
                {...props}
              />}
            {props.popover === 'mutes' && <MuteTooltip
              alert={props.alerts[0]}
              didMute={(type, duration) => {
                props.didMute(type, duration);
                document.body.click();
              }}
            />}
          </Tooltip>}
        >
          <span onClick={e => e.stopPropagation()}>
            <InnerIndicator
              alerts={props.alerts}
              popover={props.popover}
            />
          </span>

        </OverlayTrigger>
      }
      {!props.popover &&
        <InnerIndicator
          alerts={props.alerts}
          popover={props.popover}
        />
      }
    </>
  );

};



