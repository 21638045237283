import React from 'react';
import { UserHeader } from '../../components/UserHeader';
import { AdminManagementContainer } from '../../containers/AdminManagement';
import { ClientAgreementsContainer } from '../../containers/Client/Agreements';
import { ClientDetailsContainer } from '../../containers/Client/Details';
import { ClientContactsContainer } from '../../containers/Client/EmergencyContacts';
import { ClientGoalsContainer } from '../../containers/Client/Goals';
import { ClientInsightsContainer } from '../../containers/Client/Insights';
import { ClientMessagesContainer } from '../../containers/Client/Messages';
import { ClientSafetyPlanContainer } from '../../containers/Client/SafetyPlan';
import { ClientSessionsContainer } from '../../containers/Client/Sessions';
import { ClientSupportPlanContainer } from '../../containers/Client/SupportPlan';
import { ClientTabs } from '../../containers/ClientTabs';
import { UserOpensContainer, UserType } from '../../containers/UserOpens';
import { ClientContext } from '../../contexts/client';
import { ClientModel } from '../../models/Client';

export interface ClientViewProps {
  client?: ClientModel;
  isNew: boolean;
  isLoading: boolean;
}

export interface ClientTabViewProps {
  onKeyChanged: (key: string, value: unknown) => void;
  onSave: <T>(state: T) => Promise<void>;
  onReset: () => void;
}

export class ClientView extends React.Component<ClientViewProps> {
  state = {
    firstName: this.props.client?.firstName() || '',
    lastName: this.props.client?.lastName() || '',
    photoUrl: this.props.client?.photoUrl(),
    clientState: this.props.client?.australianState(),
  };

  handleChildKeyChange(key: string, value: unknown): void {
    switch (key) {
      case 'firstName': {
        this.setState({ firstName: value });
        break;
      }
      case 'lastName': {
        this.setState({ lastName: value });
        break;
      }
      case 'photoUrl': {
        this.setState({ photoUrl: value });
        break;
      }
    }
  }

  render(): JSX.Element {
    return (
      <div className="container mt-2">
        <UserHeader
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          photoUrl={this.state.photoUrl}
          userState={this.state.clientState}
        />
        <ClientContext.Provider value={this.props.client?.id()}>
          <ClientTabs
            disableSecondaryTabs={!this.props.client}
            details={
              <ClientDetailsContainer
                client={this.props.isNew ? ClientModel.new() : this.props.client}
                onKeyChanged={(key, value) => this.handleChildKeyChange(key, value)}
                isLoading={this.props.isLoading}
              />
            }
            safetyPlan={
              <ClientSafetyPlanContainer
                client={this.props.isNew ? undefined : this.props.client}
                onKeyChanged={(key, value) => this.handleChildKeyChange(key, value)}
              />
            }
            supportPlan={
              <>
                {this.props.client &&
                  <ClientSupportPlanContainer
                    client={this.props.client}
                  />
                }
              </>
            }
            contacts={
              <ClientContactsContainer
                client={this.props.isNew ? undefined : this.props.client}
              />
            }
            insights={
              <ClientInsightsContainer
                client={this.props.isNew ? undefined : this.props.client}
              />
            }
            goals={
              <ClientGoalsContainer
                client={this.props.isNew ? undefined : this.props.client}
              />
            }
            sessions={
              <ClientSessionsContainer
                client={this.props.isNew ? undefined : this.props.client}
              />
            }
            messages={
              <ClientMessagesContainer
                client={this.props.isNew ? undefined : this.props.client}
              />
            }
            agreements={
              <ClientAgreementsContainer
                client={this.props.isNew ? undefined : this.props.client}
              />
            }
            userOpens={
              <>
                {this.props.client &&
                  <UserOpensContainer
                    userId={this.props.client.userId()}
                    userType={UserType.client}
                  />
                }
              </>
            }
            adminManagement={
              <>
                <AdminManagementContainer
                  person={this.props.client}
                />
              </>
            }
          />
        </ClientContext.Provider>
      </div>
    );
  }
}