import React from 'react';
import { X } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { CurrencyInput } from '../../../../components/CurrencyInput';
import { Radio } from '../../../../components/Radio';
import { FundingManagementType, FundingManagementTypeCases, getFundingManagementTypeDescription } from '../../../../enums/SupportNeedFundingManagementType';
import { getSupportNeedModelTypeDescription, SupportNeedModelType } from '../../../../enums/SupportNeedModelType';
import { getTransportFundingDescription, TransportFunding, TransportFundingCases } from '../../../../enums/TransportFunding';
import { NeedBudgetSummaryResult, NeedBudgetTransportSummaryResult, NeedSummaryResult } from '../../../../managers/ClientNeedBudgets';
import { getPlanManagerState, PlanManagerState } from '../../../../managers/PlanManagers';
import { SupportNeedState } from '../../../../managers/SupportNeeds';
import { PlanManagerModel } from '../../../../models/PlanManager';
import { clientsPath } from '../../../../Routes';
import { PlanManager } from '../PlanManager';
import { NeedBudgetSummary } from './NeedBudgetSummaryView';
import { SupportNeedFormRow } from './SupportNeedRow';

export interface SupportNeedViewProps {
  state: SupportNeedState;
  onStateUpdate: (state: SupportNeedState) => void;
  planManagers: PlanManagerModel[];
  onPlanManagerCreated:(details: PlanManagerState) => Promise<PlanManagerModel>;
  budgetSummary: NeedSummaryResult | undefined; // Not defined when a need is being created

  /** Currently selected plan ID */
  planId?: string;

  /** Currently selected client ID */
  clientId?: string;

  /** Whether to display a link to sessions for this plan need */
  showSessionsLink?: boolean;

  /** URL portion of support plan link */
  supportPlanPageUrl: string;

  /** V3 changes require this property to include "/needs" in the URL */
  needUrlSuffix?: string;
}


export const SupportNeedView: React.FC<SupportNeedViewProps> = ({
  state,
  onStateUpdate,
  budgetSummary,
  planManagers,
  onPlanManagerCreated,
  planId,
  clientId,
  showSessionsLink,
  supportPlanPageUrl,
  needUrlSuffix,
}) => {
  const showSessionLink = showSessionsLink && state.id && state.type !== SupportNeedModelType.Transport;
  const viewSessionsPath = [clientsPath, clientId, 'supportPlan', planId, 'sessions'].join('/') + `?needId=${state.id}`;

  return (
    <form
      id={state.id}
      className="row"
    >
      <div className="col-2" />
      <h4 className="col-9">
        {(state.type ? getSupportNeedModelTypeDescription(state.type) : '') + ` (${state.id ?? 'pending'})`}
        {showSessionLink && <Link
          className="ml-2"
          to={viewSessionsPath}
        >View sessions</Link>}
      </h4>
      <X
        className="col-1 text-danger"
        size={24}
        onClick={() => {
          if (window.confirm('Are you sure you want to delete this support need?')) {
            onStateUpdate({ ...state, isDeleted: true });
          }
        }}
      />

      {state.type === SupportNeedModelType.Transport &&
        <>
          <SupportNeedFormRow colsAfter={5}>
            {{
              label: <label className="col-2 col-form-label text-right mb-1">Funding</label>,
              input: <div className="col-3 col-form-label d-flex">
                {TransportFundingCases.map(option => {

                  const isSelected = () => {
                    if (option === TransportFunding.CORE && state.agreesToUseCoreSupportFunding === true) {
                      return true;
                    }
                    if (option === TransportFunding.SPECIFIC && state.agreesToUseCoreSupportFunding !== true) {
                      return true;
                    }
                    return false;

                  };

                  return (
                    <div
                      className="mr-2"
                      key={option}
                    >
                      <Radio
                        id={`${state.id}-${getTransportFundingDescription(option)}`}
                        value={option}
                        label={getTransportFundingDescription(option)}
                        isSelected={isSelected()}

                        onChange={transportFunding => {
                          if (parseInt(transportFunding.target.value) === TransportFunding.CORE) {
                            onStateUpdate({ ...state, agreesToUseCoreSupportFunding: true, fundingManagementType: undefined });
                          }
                          if (parseInt(transportFunding.target.value) === TransportFunding.SPECIFIC) {
                            onStateUpdate({ ...state, agreesToUseCoreSupportFunding: false, fundingManagementType: FundingManagementType.Self });
                          }
                        }}
                      /></div>);
                })
                }
              </div>,
            }}
          </SupportNeedFormRow>
        </>
      }
      {!state.agreesToUseCoreSupportFunding &&
        <>
          <SupportNeedFormRow colsAfter={4}>
            {{
              label: <label className="col-2 col-form-label text-right mb-1">Management</label>,
              input: <div className="col-3 col-form-label d-flex">
                {FundingManagementTypeCases.map(type =>
                  <div
                    className="mr-2"
                    key={type}
                  >
                    <Radio
                      id={`${state.id}-${getFundingManagementTypeDescription(type)}`}
                      value={type}
                      label={getFundingManagementTypeDescription(type)}
                      isSelected={type === state.fundingManagementType}
                      onChange={fundingManagementType => {
                        if (parseInt(fundingManagementType.target.value) === FundingManagementType.Plan && planManagers.length > 0) {
                          onStateUpdate({ ...state, fundingManagementType: parseInt(fundingManagementType.target.value), planManagerId: planManagers[0].id(), planManagerState: getPlanManagerState(planManagers[0]) });
                        } else {
                          onStateUpdate({ ...state, fundingManagementType: parseInt(fundingManagementType.target.value),  planManagerId: undefined, planManagerState: undefined });
                        }
                      }
                      }
                    /></div>,
                )}
              </div>,
            }}
          </SupportNeedFormRow>

          <SupportNeedFormRow colsAfter={4}>
            {{
              label: <label className="col-2 col-form-label text-right mb-1">Budget</label>,
              input: <CurrencyInput
                id="budgetAmount"
                inputClassName="col-4 form-control"
                value={Math.abs(state.budget ?? 0)}
                onChange={valueCents => onStateUpdate({ ...state, budget: valueCents })}
              />,
            }}
          </SupportNeedFormRow>

          {(budgetSummary && !!budgetSummary.details) &&
            <NeedBudgetSummary
              summary={{ ...budgetSummary, details: budgetSummary.details as NeedBudgetTransportSummaryResult | NeedBudgetSummaryResult }}
              hideAdjustments={budgetSummary.supportType === SupportNeedModelType.Transport && state.agreesToUseCoreSupportFunding === undefined}
              supportPlanPageUrl={`${supportPlanPageUrl}${planId ? `/${planId}` : ''}`}
              needUrlSuffix={needUrlSuffix}
              clientId={clientId ?? ''}
            />
          }

          {(state.fundingManagementType === FundingManagementType.Plan) &&
            <PlanManager
              managers={planManagers}
              currentManager={planManagers.find(manager => manager.id() === state.planManagerId)}
              currentManagerState={state.planManagerState ?? getPlanManagerState()}
              onCurrentManagerStateChanged={planManagerState => onStateUpdate({ ...state, planManagerState })}
              onManagerChanged={manager => onStateUpdate({ ...state, planManagerId: manager.id(), planManagerState: getPlanManagerState(manager) })}
              onPlanManagerCreated={async details => {
                const newManager = await onPlanManagerCreated(details);
                onStateUpdate({ ...state, planManagerId: newManager.id(), planManagerState: getPlanManagerState(newManager) });
              }
              }
            />
          }
        </>
      }
      <hr className="col-10 offset-md-2" />

    </form>
  );
};