import React from 'react';
import { formatDuration, formatMoney } from '../../helpers';
import { SessionCost } from '../../managers/SessionCost';
import { SessionStatus } from '../../models/Sessions';
import { BillabilityReason } from './BillabilityReason';
import { SimpleLabelledValue } from './SimpleLabelledValue';


interface CancelledSessionCostProps {
  cost: SessionCost;
  sessionStatus: SessionStatus;
  isLoadingPricing: boolean;
}

export const CancelledSessionCostView: React.FC<CancelledSessionCostProps> = ({ cost, sessionStatus, isLoadingPricing }) => {
  let showCosts = [SessionStatus.cancelledByClient, SessionStatus.noShow].includes(sessionStatus);
  if (sessionStatus === SessionStatus.cancelledByClient && cost.billedTime <= 0) {
    showCosts = false;
  }

  const hourlyRate = cost.hourlyRate ? formatMoney(cost.hourlyRate) : 'Error with rate';
  const billedHourlyCost = cost.billedHourlyCost ? formatMoney(cost.billedHourlyCost) : 'Error with cost';

  return (
    <div className="row">
      <BillabilityReason
        sessionStatus={sessionStatus}
        billedCost={cost.billedTime}
        billedProportion={cost.billedProportion}
        billedRebookedSessions={cost.billedRebookedSessions}
      />

      {
        showCosts && (
          <>
            <SimpleLabelledValue
              title="Day type"
              value={cost.dayType}
            />

            <SimpleLabelledValue
              title="Billed time"
              value={formatDuration(cost.billedTime)}
            />

            <SimpleLabelledValue
              title="Rate per hour"
              value={!isLoadingPricing ? hourlyRate : 'Calculating...'}
            />

            <SimpleLabelledValue
              title="Billed income"
              value={!isLoadingPricing ? billedHourlyCost : 'Calculating...'}
            />
          </>
        )
      }
    </div>
  );
};