
export enum SessionStatus {
  unknown = 0,
  pendingWorkerApproval = 1,
  confirmed = 2,
  cancelledByClient = 3,
  cancelledByWorker = 4,
  cancelledByAdmin = 5,
  reservedByWorker = 6,
  reservedByAdmin = 7,
  completed = 8,
  noShow = 9,
  declined = 10,
  suggested = 11,
  declinedByClient = 12,
  pendingClientApproval = 13,
  notAccepted = 100, // Special for SupportSessionRequests (will be pendingWorkerApproval)
  needsFinalisation = 101 //Special for unfinished confirmed SupportSessionRequests
}

export function getStatusDescription(status: SessionStatus): string {
  switch (status) {
    case SessionStatus.pendingWorkerApproval: return 'Pending worker approval';
    case SessionStatus.confirmed: return 'Confirmed';
    case SessionStatus.cancelledByClient: return 'Cancelled by client';
    case SessionStatus.cancelledByWorker: return 'Cancelled by worker';
    case SessionStatus.cancelledByAdmin: return 'Cancelled by admin';
    case SessionStatus.reservedByWorker: return 'Reserved by worker';
    case SessionStatus.reservedByAdmin: return 'Reserved by admin';
    case SessionStatus.completed: return 'Completed';
    case SessionStatus.noShow: return 'No show';
    case SessionStatus.declined: return 'Declined';
    case SessionStatus.suggested: return 'Suggested';
    case SessionStatus.pendingClientApproval: return 'Pending client approval';
    case SessionStatus.notAccepted: return 'Not accepted';
    case SessionStatus.needsFinalisation: return 'Needs finalisation';
    case SessionStatus.declinedByClient: return 'Declined by client';
    default: return 'Unknown';
  }
}

export function getSimpleCancelledByDescription(status: SessionStatus): string {
  switch (status) {
    case SessionStatus.cancelledByClient: return 'Client';
    case SessionStatus.cancelledByWorker: return 'Worker';
    case SessionStatus.cancelledByAdmin: return 'Admin';
    default: return 'Unknown';
  }
}

export function getStatusDescriptionWithStartDate(status: SessionStatus, startDate: Date | string | undefined): string {
  let description = getStatusDescription(status);

  try {
    if (startDate) {
      const isInPast = new Date(startDate).getTime() < new Date().getTime();
      if (isInPast && status === SessionStatus.pendingWorkerApproval) {
        description = 'Not accepted by worker';
      } else if (isInPast && status === SessionStatus.pendingClientApproval) {
        description = 'Not accepted by client';
      }
    }
  } catch (err) {
    return description;
  }

  return description;
}