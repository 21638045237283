import React, { useEffect, useState } from 'react';
import { Loading } from '../../components/Loading';
import { AlertMuteGroup, getActiveMuteGroups } from '../../managers/Alerts';
import { AlertMuteModel } from '../../models/AlertMute';
import Session from '../../Session';
import { MutesView } from '../../views/Mutes';


export const MutesContainer = () => {
  const [mutes, setMutes] = useState<AlertMuteGroup[] | undefined>();
  const [filteredMutes, setFilteredMutes] = useState<AlertMuteGroup[] | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const loggedInAdmin = Session.getUser()?.user.id;
    if (! loggedInAdmin) { return; }
    setIsLoading(true);
    void getActiveMuteGroups(loggedInAdmin).then(mutes => {
      setMutes(mutes);
      setFilteredMutes(mutes);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!mutes) { return; }

    const filtered = mutes.filter(group => {
      return group.user.toLowerCase().includes(searchTerm) || group.userId === searchTerm;
    });

    setFilteredMutes(filtered);

  }, [mutes, searchTerm]);

  const deleteMute = (muteGroup: AlertMuteGroup, deletedMute: AlertMuteModel) => {
    if (!mutes) { return; }
    mutes.forEach(group => {
      if (group.userId === muteGroup.userId) {
        group.mutes = group.mutes.filter(mute => mute !== deletedMute);
      }
    });
    const newMutes = mutes.filter(mute => mute.mutes.length > 0);
    setMutes(newMutes);
  };

  return (
    <div className="mt-4">
      {isLoading && <Loading />}
      {(mutes && mutes.length === 0) &&  <div className="text-info text-center">No mutes are in place</div>}
      {(filteredMutes && mutes && mutes.length > 0) &&
        <>
          <div className="d-flex flex-row-reverse">
            <input
              className="col-2 form-control"
              type="search"
              value={searchTerm}
              placeholder="Search"
              id="searchTerm"
              onChange={e => setSearchTerm(e.target.value)}
            />
          </div>
          <MutesView
            key={filteredMutes.length}
            mutes={filteredMutes}
            onDeleted={(group, deleted) => deleteMute(group, deleted)}
          />
        </>}
    </div>
  );

};
