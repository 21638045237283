/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AustralianState, getAustralianStateAbbreviation } from '../../enums/AustralianState';
import { formatDateWithState } from '../../helpers';
import { SessionStatus, getSimpleCancelledByDescription } from '../../models/Sessions';
import { SupportSessionRequestModel } from '../../models/Sessions/SupportSessionRequest';
import { SaveButton } from '../../views/Client/SaveButton';
import { OverlappingAvatars } from '../OverlappingAvatars';
import { Selector } from '../Selector';
import { SimpleLabelledValue } from '../SupportSession/SimpleLabelledValue';

interface SupportSessionRequestCancelProps {
  request: SupportSessionRequestModel;
  disableInteraction: boolean;
  createdByUserName?: string;
  onSaveRequestCancellation: (
    request: SupportSessionRequestModel,
    newStatus: SessionStatus,
  ) => Promise<SupportSessionRequestModel>;
  onGoBack(): void;
}

export const SupportSessionRequestCancel: React.FC<SupportSessionRequestCancelProps> = ({
  request,
  disableInteraction,
  createdByUserName,
  onSaveRequestCancellation,
  onGoBack,
}) => {
  const history = useHistory();
  const [cancelledBy, setCancelledBy] = useState(SessionStatus.unknown);
  const [isCancelledByError, setIsCancelledByError] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const clientState = request.clientSync().australianState() ?? AustralianState.wa;

  useEffect(() => {
    if ([SessionStatus.cancelledByAdmin, SessionStatus.cancelledByClient, SessionStatus.cancelledByWorker, SessionStatus.noShow].includes(request.status())) {
      setCancelledBy(request.status());
    }
  }, []);

  /**
   * Handle change to the cancelled by field
   */
  const handleChangeCancelledBy = (value: number) => {
    setCancelledBy(value);
  };

  /**
   * Validate cancelled by selection
   */
  const validateFields = () => {
    const isCancelledByError = cancelledBy === SessionStatus.unknown;
    setIsCancelledByError(isCancelledByError);
    return !isCancelledByError;
  };

  return (
    <div className="m-4">
      <div className="row">
        <div
          className="col-1 mx-auto"
          style={{ marginBottom: -28 }}
        >
          <OverlappingAvatars
            bottomUrl={request.clientPhotoUrl()}
            topUrl={request.workerPhotoUrl()}
            size={80}
          />
        </div>
        <div className="col-12">
          <h2 className="font-weight-bold mb-0 text-center">{`${request.clientName()} with ${request.workerName()}`}</h2>
        </div>
      </div>

      <hr />

      <div className="row">
        <SimpleLabelledValue
          title="ID"
          value={request.id()}
        />

        <SimpleLabelledValue
          title="Status"
          value={request.statusDescription()}
        />

        <SimpleLabelledValue
          title="Date"
          value={formatDateWithState(request.firstDateStart(), 'dddd, Do MMMM, YYYY', request.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title="Created by"
          value={createdByUserName ?? 'Unknown'}
        />

        <SimpleLabelledValue
          title="Requested"
          value={formatDateWithState(request.createdAt() ?? '', 'dddd, Do MMMM, YYYY', request.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title="Accepted"
          value={formatDateWithState(request.createdAt() ?? '', 'dddd, Do MMMM, YYYY', request.clientSync().australianState())}
        />
      </div>

      <hr />

      <div className="row">
        <SimpleLabelledValue
          title={`Scheduled start ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
          value={formatDateWithState(request.firstDateStart(), 'hh:mm A', request.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title={`Scheduled end ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
          value={formatDateWithState(request.firstDateEnd(), 'hh:mm A', request.clientSync().australianState())}
        />
      </div>

      <hr />

      <div className="row">
        <div className="col-12 text-center text-danger">
          <h3>Cancel session</h3>
        </div>
      </div>

      <div className="row">
        <>
          <label
            className="col-2 col-form-label text-right mb-1"
          >Cancelled by</label>
          <div className="col-8">
            <Selector
              options={[
                {
                  name: 'Please select...',
                  key: '',
                },
                {
                  name: getSimpleCancelledByDescription(SessionStatus.cancelledByClient),
                  key: SessionStatus.cancelledByClient,
                },
                {
                  name: getSimpleCancelledByDescription(SessionStatus.cancelledByWorker),
                  key: SessionStatus.cancelledByWorker,
                },
                {
                  name: getSimpleCancelledByDescription(SessionStatus.cancelledByAdmin),
                  key: SessionStatus.cancelledByAdmin,
                },
              ]}
              initial={cancelledBy}
              isValid={!isCancelledByError}
              disabled={disableInteraction}
              onChange={e => { handleChangeCancelledBy(Number(e.target.value)); }}
            />
          </div>
        </>
      </div>

      <div>
        <SaveButton
          onSave={async () => {
            if (!validateFields()) {
              return;
            }

            setIsSaving(true);
            await onSaveRequestCancellation(
              request,
              cancelledBy,
            );
            setIsSaving(false);
            onGoBack();
          }}
          onCancel={onGoBack}
          cancelText="Back"
          disableCancel={disableInteraction}
          disableSave={disableInteraction}
          isSaving={isSaving}
          isSaveDestructive
        />
      </div>
    </div>
  );
};