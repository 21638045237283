import Parse from 'parse';
import React, { useState } from 'react';
import { ToastManager } from '../../Modals/Toasts/manager';
import { BulkAgreementsView } from '../../views/BulkAgreements';

export const BulkAgreementsContainer: React.FC = () => {
  const [isSaving, setIsSaving] = useState(false);



  return (
    <div className="mt-4">
      <BulkAgreementsView

        onRequireNewAgreement={async () => {
          setIsSaving(true);
          await Parse.Cloud.run('notification-agreementRequiredBulk');
          setIsSaving(false);
          ToastManager.shared().show('All clients messages have been notified');
        }}

        onChangeMessage={async (message) => {
          setIsSaving(true);
          await Parse.Cloud.run('agreements-setMessage', { message });
          setIsSaving(false);
          ToastManager.shared().show('The custom agreement message have been set on all clients');
        }}
        disableInteraction={isSaving}
      />
    </div>
  );

};
