import Parse from 'parse';
import { SessionStatus } from '../../models/Sessions';

export type ParseResult = Parse.Object<Parse.Attributes>
export type ParseQuery = Parse.Query<Parse.Object<Parse.Attributes>>

function addIncludes(query: ParseQuery): ParseQuery {
  query.include('worker');
  query.include('client');
  query.limit(1_000_000);
  return query;
}

export function GetSupportSession(id: string): Promise<ParseResult> {
  let query = new Parse.Query('SupportSession');
  query = addIncludes(query);

  // Detail screen needs more data
  query.include('supportNeed');
  query.include('request');
  query.include('request.createdBy');
  query.include('request.createdAt');

  return query.get(id);
}

export function GetSupportSessionRequest(id: string): Promise<ParseResult> {
  let query = new Parse.Query('SupportSessionRequest');
  query = addIncludes(query);
  query.include('createdBy');
  query.include('createdAt');

  // Detail screen needs more data
  query.include('supportNeed');

  return query.get(id);
}

export function GetSupportSessionsForClient(clientId: string) {
  let query = new Parse.Query('SupportSession');
  const clientQuery = new Parse.Query('Client');
  clientQuery.equalTo('objectId', clientId);

  query.matchesQuery('client', clientQuery);
  query.include('request');
  query.include('request.createdBy');
  query.include('request.createdAt');
  query.descending('scheduledStartDate');
  query = addIncludes(query);
  query.limit(10_000);
  return query.find();
}


export function GetSupportSessionRequestsForClient(clientId: string) {
  let query = new Parse.Query('SupportSessionRequest');
  const clientQuery = new Parse.Query('Client');
  clientQuery.equalTo('objectId', clientId);
  query.containedIn('status', [
    SessionStatus.pendingClientApproval,
    SessionStatus.pendingWorkerApproval,
    SessionStatus.declined,
    SessionStatus.suggested,
    SessionStatus.cancelledByClient,
    SessionStatus.cancelledByAdmin,
    SessionStatus.cancelledByWorker,
  ]);

  query.matchesQuery('client', clientQuery);
  query.descending('createdAt');
  query = addIncludes(query);
  query.limit(10_000);
  return query.find();
}

export function GetSupportSessionsForWorker(workerId: string) {
  let query = new Parse.Query('SupportSession');
  const workerQuery = new Parse.Query('Worker');
  workerQuery.equalTo('objectId', workerId);

  query.matchesQuery('worker', workerQuery);
  query.descending('scheduledStartDate');
  query = addIncludes(query);
  query.limit(10_000);
  return query.find();
}


export function GetSupportSessionRequestsForWorker(workerId: string) {
  let query = new Parse.Query('SupportSessionRequest');
  const workerQuery = new Parse.Query('Worker');
  workerQuery.equalTo('objectId', workerId);
  query.containedIn('status', [
    SessionStatus.pendingClientApproval,
    SessionStatus.pendingWorkerApproval,
    SessionStatus.declined,
    SessionStatus.suggested,
    SessionStatus.cancelledByClient,
    SessionStatus.cancelledByAdmin,
    SessionStatus.cancelledByWorker,
  ]);

  query.matchesQuery('worker', workerQuery);
  query.descending('createdAt');
  query = addIncludes(query);
  query.limit(10_000);
  return query.find();
}

export function GetSupportSessionsWithSupportNeeds(sessionIds: string[]): Promise<Parse.Object[]> {
  const query = new Parse.Query('SupportSession');
  query.containedIn('objectId', sessionIds);
  query.include('supportNeed');
  query.limit(10_000);
  query.ascending('scheduledStartDate');
  return query.find();
}

export function GetSupportSessionsMatchingRequest(requestId: string) {
  const requestQuery = new Parse.Query('SupportSessionRequest');
  requestQuery.equalTo('objectId', requestId);
  const query = new Parse.Query('SupportSession');
  query.matchesQuery('request', requestQuery);
  query.limit(10_000);
  query.ascending('scheduledStartDate');
  return query.find();
}