import React from 'react';
import { Selector } from '../../components/Selector';
import { getSupportNeedModelTypeDescription, SupportNeedModelType } from '../../enums/SupportNeedModelType';

interface AddSupportNeedProps {
  options: SupportNeedModelType[];
  onAdded: (option: SupportNeedModelType) => void;
}

export class AddSupportNeed extends React.Component<AddSupportNeedProps> {
  state = {
    selectedOption: this.props.options[0],
  };

  render() {
    return (
      <div className="row">
        <div className="col-2" />
        <Selector
          initial={this.state.selectedOption}
          options={this.props.options.map(option => ({ key: option, name: getSupportNeedModelTypeDescription(option) }))}
          onChange={e => this.setState({ selectedOption: parseInt(e.target.value) as SupportNeedModelType })}
        />
        <button
          type="button"
          className="btn btn-primary col-2 ml-3"
          onClick={e => this.props.onAdded(this.state.selectedOption as SupportNeedModelType)}
        >Add</button>
      </div>
    );
  }
}