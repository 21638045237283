import React, { useEffect, useState } from 'react';
import { getAdminUsers } from '../../managers/AdminUsers';
import JobPostManager from '../../managers/JobPosts';
import { AdminUser } from '../../models/AdminUser';
import { JobPostModel } from '../../models/JobPost';
import { JobPostsView } from '../../views/JobPosts';


export const JobPostsContainer: React.FC = () => {
  const [jobPosts, setJobPosts] = useState<JobPostModel[]>();
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const downloadJobPosts = async () => {
      const jobs = await JobPostManager.instance().getJobPosts();
      setJobPosts(jobs);
    };

    const loadAdmins = async () => {
      const admins = await getAdminUsers();
      setAdminUsers(admins);
    };

    setIsLoading(true);
    void downloadJobPosts().then(() => setIsLoading(false));
    void loadAdmins();
  }, []);

  return (
    <JobPostsView
      key={jobPosts?.length}
      jobPosts={jobPosts}
      adminUsers={adminUsers}
      isLoading={isLoading}
    />
  );

};
