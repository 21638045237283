import moment, { Moment } from 'moment';
import { SessionsSortDirection, SessionsSortMode } from '../containers/Sessions';
import { billingPath, sessionsPath } from '../Routes';
import { sessionStatusFilterOptions } from './sessionsAndRequests';

export const PastDay = (minusDays: number): Date => {
  const weekAgoDate = new Date();
  weekAgoDate.setDate(weekAgoDate.getDate() - minusDays);
  return weekAgoDate;
};

export const FutureDay = (addDays: number): Date => {
  const weekAgoDate = new Date();
  weekAgoDate.setDate(weekAgoDate.getDate() + addDays);
  return weekAgoDate;
};

export const DefaultPast = PastDay(7); // A week
export const DefaultFuture = FutureDay(7);


export function isBetweenDates(afterDate: Date | undefined, beforeDate: Date | undefined, targetDate: Date): boolean {
  return (afterDate ? afterDate <= targetDate : true) && (beforeDate ? targetDate <= beforeDate : true);
}

/* Formatting and parsing dates as params */
export function buildSessionsPath(
  after: Date | Moment | undefined,
  before:Date | Moment | undefined,
  statuses: string[],
  search?: string,
  sortMode?: SessionsSortMode,
  sortDirection?: SessionsSortDirection,
): string {
  const params = new URLSearchParams();

  if (after) {
    params.set('after', formatDateParam(after));
  }
  if (before) {
    params.set('before', formatDateParam(before));
  }
  if (statuses && statuses.length > 0) {
    params.set('statuses', statuses.join(','));
  }
  if (search) {
    params.set('search', search);
  }
  if (sortMode) {
    params.set('sortMode', sortMode);
  }
  if (sortDirection) {
    params.set('sortDirection', sortDirection);
  }

  return `${sessionsPath}?${params.toString()}`;
}

export function buildBillingPath(
  filter: string,
  after: Date | Moment | undefined,
  before:Date | Moment | undefined,
  search?: string,
): string {
  const params = new URLSearchParams();
  if (after) {
    params.set('after', formatDateParam(after));
  }
  if (before) {
    params.set('before', formatDateParam(before));
  }
  if (search) {
    params.set('search', search);
  }

  return `${billingPath}/${filter}?${params.toString()}`;
}

export function formatDateParam(date: Moment | Date): string {
  return moment(date).toISOString();
}

export function parseDateParam(paramDate?: string | null): Date | undefined {
  if (!paramDate) { return undefined; }
  return moment(paramDate).toDate();
}

export function parseBoolParam(paramBool: string | null| undefined, fallback: boolean): boolean {
  if (!paramBool) { return fallback; }
  return paramBool === 'true';
}

/**
 * Parses query param for a list of session status IDs
 * 
 * @param paramStatuses - query param in the format "pending,accepted,declined" etc
 */
export function parseStatusesParam(paramStatuses: string | undefined): Set<string> {
  const res = new Set<string>();
  if (paramStatuses) {
    const split = paramStatuses.split(',');
    split.forEach(val => res.add(val));
  }
  return res;
}

/**
 * This function is used to return a set with all selected status options,
 * used on the sessions screen if no query params were found as a default
 */
export function getFullStatusSet(): Set<string> {
  const res = new Set<string>();
  sessionStatusFilterOptions.forEach(opt => res.add(opt.value));
  return res;
}


/* Local Storage Utils */

export function dateToStorage(date: Date | undefined, key: string): void {
  if (!date) {
    localStorage.removeItem(key);
    return;
  }
  return localStorage.setItem(key, date.toISOString());
}

export function dateFromStorage(key: string): Date | undefined {
  const str = window.localStorage.getItem(key);
  if (str) {
    return new Date(str); // from ISO string
  }
  return undefined;
}

export function boolToStorage(bool: boolean, key: string): void {
  return localStorage.setItem(key, JSON.stringify(bool));
}

export function boolFromStorage(key: string): boolean | undefined {
  const str = window.localStorage.getItem(key);
  if (str) {
    return JSON.parse(str); // from JSON
  }
  return undefined;
}

export function toStorage(str: string, key: string): void {
  localStorage.setItem(key, str);
}

export function fromStorage(key: string): string | undefined {
  return window.localStorage.getItem(key) || undefined;
}