export enum AnalyticsEventType {
  unknown = 'unknown',
  clientGoalCreated = 'client_goal_created',
  clientGoalEdited = 'client_goal_edited',
  clientGoalDeleted = 'client_goal_deleted',
  clientContactAdded = 'client_contact_added',
  clientContactEdited = 'client_contact_edited',
  clientContactDeleted = 'client_contact_deleted',
  clientSafetyPlanEdited = 'client_safety_plan_edited',
  clientProfileCreated = 'client_profile_created',
  clientProfileEdited = 'client_profile_edited',

  // App opened is not used by the dashoard
  appOpened = 'app_opened',
}