import _ from 'lodash';
import moment from 'moment';
import React, { CSSProperties, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { ToastManager } from '../../Modals/Toasts/manager';
import Session from '../../Session';
import { OverlappingAvatars } from '../../components/OverlappingAvatars';
import { Radio } from '../../components/Radio';
import { Selector } from '../../components/Selector';
import { SessionFrequency, SessionFrequencyCases, getSessionFrequencyDescription } from '../../enums/SessionFrequency';
import { SessionOption, SessionOptionCases, getSessionOptionDescription } from '../../enums/SessionOption';
import { SupportNeedModelType, getSupportNeedModelTypeDescription } from '../../enums/SupportNeedModelType';
import { combineDateAndTime, formatMoney } from '../../helpers';
import { isNeedAndDateValidForPlan } from '../../helpers/plansAndNeeds';
import { CreateSessionState, CreateSupportSessionResponse, validateBudgetsForSessionRequest } from '../../managers/Sessions';
import { ClientModel } from '../../models/Client';
import { SupportNeedModel } from '../../models/SupportNeed';
import { SupportPlanModel } from '../../models/SupportPlan';
import { WorkerModel } from '../../models/Worker';
import { SaveButton } from '../Client/SaveButton';
import { useSearchParams } from '../Hooks/useSearchParams';
import { FormItem } from '../Worker/Tabs/Details/FormItem';

interface CreateSessionViewProps {
  workers: WorkerModel[];
  clients: ClientModel[];
  onCreate: (request: CreateSessionState) => Promise<CreateSupportSessionResponse | null>;
  onConfirm: (request: CreateSessionState) => Promise<void>;
}

export const CreateSessionView: React.FC<CreateSessionViewProps> = ({ onCreate, onConfirm, workers, clients }) => {
  const clientIdParam = useSearchParams('clientId');
  const workerIdParam = useSearchParams('workerId');

  const [plans, setPlans] = useState<SupportPlanModel[] | undefined>();
  const [needs, setNeeds] = useState<SupportNeedModel[] | undefined>();

  const initialClient = clientIdParam ? clients.find((client) => client.id() === clientIdParam) : undefined;
  const initialWorker = workerIdParam ? workers.find((worker) => worker.id() === workerIdParam) : undefined;

  const [selectedClient, setSelectedClient] = useState<ClientModel | undefined>(initialClient);
  const [selectedWorker, setSelectedWorker] = useState<WorkerModel | undefined>(initialWorker);
  const [selectedSupportPlan, setSelectedSupportPlan] = useState<SupportPlanModel | undefined>();
  const [selectedSupportNeed, setSelectedSupportNeed] = useState<SupportNeedModel | undefined>();

  const [date, setDate] = useState<Date | undefined>();
  const [startTime, setStartTime] = useState<Date | undefined>();
  const [endTime, setEndTime] = useState<Date | undefined>();

  const [frequency, setFrequency] = useState<SessionFrequency>(SessionFrequency.Single);
  const [totalSessions, setTotalSessions] = useState<number | undefined>();
  const [option, setOption] = useState<SessionOption>(SessionOption.SendRequestToWorker);


  const [disableInteraction, setDisableInteraction] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  const history = useHistory();



  useEffect(() => {
    const downloadData = async () => {
      let supportPlans = await selectedClient?.supportPlans();

      if (supportPlans) {
        supportPlans = supportPlans.sort((d1, d2) => {
          const date1 = d1.endDate()?.getTime() ?? 0;
          const date2 = d2.endDate()?.getTime() ?? 0;
          return date2 - date1;
        }).filter((plan) => !plan.isDeleted());
      }

      setPlans(supportPlans);
      setSelectedSupportNeed(undefined);
      setSelectedSupportPlan(undefined);
    };
    void downloadData();
  }, [selectedClient]);

  useEffect(() => {
    const downloadData = async () => {
      let planNeeds = await selectedSupportPlan?.supportNeeds();
      planNeeds = planNeeds?.filter(need => !need.isTransportType() && !need.isDeleted());
      setNeeds(planNeeds);
    };
    void downloadData();
  }, [selectedSupportPlan]);

  const resetState = () => {
    setSelectedClient(undefined);
    setSelectedWorker(undefined);
    setSelectedSupportPlan(undefined);
    setSelectedSupportNeed(undefined);
    setDate(undefined);
    setStartTime(undefined);
    setEndTime(undefined);
    setFrequency(SessionFrequency.Single);
    setTotalSessions(undefined);
    setOption(SessionOption.SendRequestToWorker);
  };

  const formatPlanName = (plan: SupportPlanModel) => {
    return 'Ending ' + moment(plan.endDate()).format('DD/MM/YYYY');
  };

  const formatNeed = (need: SupportNeedModel) => {
    const type = need.type();
    return type ? getSupportNeedModelTypeDescription(type) : 'Warning: Unknown type?';
  };

  const formatTime = (date: Date) => {
    return moment(date).format('HH:mm');
  };

  const formatDateForServer = (date: Date) => {
    return moment(date).format('DD/MM/yyyy');
  };

  const canSave = () => {
    if (disableInteraction) { return false; }
    if (!selectedWorker || !selectedClient || !date || !startTime || !endTime || !selectedSupportNeed || !selectedSupportNeed.id()) {
      return false;
    }
    if (frequency && !totalSessions) { return false; }
    return true;
  };

  const validateSelectedSupportNeed = async (date: Date, needType: SupportNeedModelType | undefined): Promise<void> => {
    if (selectedSupportPlan && needType && needs && date) {
      const currentNeed = needType ?? SupportNeedModelType.Unknown;
      const isValid = await isNeedAndDateValidForPlan(selectedSupportPlan, needs, currentNeed, date, selectedClient?.australianState());

      console.log(`checking date and need: ${date} / ${needType}`);
      console.log('isValid: ', isValid);

      if (!isValid) {
        setSelectedSupportNeed(undefined);
      }
    }
  };

  const handleChangeDate = async (newDate: Date): Promise<void> => {
    setDate(newDate);
    if (endTime) { setEndTime(combineDateAndTime(date, endTime)); }
    if (startTime) { setStartTime(combineDateAndTime(date, startTime)); }
    void validateSelectedSupportNeed(newDate, selectedSupportNeed?.type());
  };

  const handleChangeStartTime = (newDate: Date): void => {
    const combinedDate = combineDateAndTime(date, newDate);

    // Removed this, don't change the end time if it's been set
    // if ((combinedDate && endTime) && (combinedDate > endTime)) {
    //   setEndTime(combineDateAndTime(date, combinedDate));
    // }

    setStartTime(combineDateAndTime(date, combinedDate));

    // If the end time is empty, default it to be start + 2 hours for better UX
    if (!endTime) {
      const newEndTime = moment(combinedDate).add('2', 'hours');
      handleChangeEndTime(newEndTime.toDate());
    }
  };

  const handleChangeEndTime = (newDate: Date): void => {
    setEndTime(combineDateAndTime(date, newDate));
  };

  // Disable UI when loading and saving
  const containerStyle: CSSProperties = disableInteraction ? { pointerEvents: 'none', opacity: 0.5 } : {};

  return (
    <div
      className="m-4"
      style={containerStyle}
    >
      <div className="row">

        <div className="col-1 mx-auto">
          <OverlappingAvatars
            bottomUrl={selectedClient?.photoUrl() ?? `${window.location.origin}/logo192.png`}
            topUrl={selectedWorker?.photo() ?? `${window.location.origin}/logo192.png`}
            size={80}
          />
        </div>


        <div className="col-12">
          <h2 className="font-weight-bold mb-0 text-center">{`${(selectedClient && selectedWorker) ? `${selectedClient.fullName()} with ${selectedWorker.fullName()}` : 'Create Session'}`}</h2>
        </div>
      </div>

      <hr />

      <div className="row ">
        <FormItem
          title="Client"
          readOnly={!clients}
          valueNode={
            <Selector
              options={[{ key: '-1', name: 'Select a client', disabled: true }, ...(clients ?? []).map(client => ({ name: client.fullName(), key: client.id() }))]}
              initial={selectedClient?.id() ?? '-1'}
              onChange={e => setSelectedClient(clients?.find(client => client.id() === e.target.value))}
            />
          }
        />

        <FormItem
          title="Worker"
          readOnly={!workers}
          valueNode={
            <Selector
              options={[{ key: '-1', name: 'Select a worker', disabled: true }, ...(workers ?? []).map(worker => ({ name: worker.fullName(), key: worker.id() }))]}
              initial={selectedWorker?.id() ?? '-1'}
              onChange={e => setSelectedWorker(workers?.find(worker => worker.id() === e.target.value))}
            />
          }
        />
      </div>

      <div className="row pt-2">
        <FormItem
          title="Support plan"
          readOnly={!plans}
          valueNode={
            <Selector
              options={[{ key: '-1', name: 'Select a support plan', disabled: true }, ...(plans ?? []).map(plan => ({ name: formatPlanName(plan), key: plan.id() }))]}
              initial={selectedSupportPlan?.id() ?? '-1'}
              disabled={!selectedClient}
              onChange={e => setSelectedSupportPlan(plans?.find(plan => plan.id() === e.target.value))}
            />
          }
        />

        <FormItem
          title="Support need"
          readOnly={!needs}
          valueNode={
            <Selector
              options={[{ key: '-1', name: 'Select a support need', disabled: true }, ...(needs ?? []).map(need => ({ name: formatNeed(need), key: need.id() }))]}
              initial={selectedSupportNeed?.id() ?? '-1'}
              disabled={!selectedSupportPlan}
              onChange={e => setSelectedSupportNeed(needs?.find(need => need.id() === e.target.value))}
            />
          }
        />
      </div>

      <div className="row pt-2">
        <FormItem
          title="Date"
          valueNode={
            <div className="col-4 form-control border">
              <DatePicker
                className="w-100 border-0 text-secondary"
                onChange={handleChangeDate}
                selected={moment(date).isValid() ? date : undefined}
                dateFormat="d/M/yyyy"
                maxDate={selectedSupportPlan?.endDate() ?? moment().add(1, 'y').toDate()}
                showYearDropdown
                placeholderText="Select a date"
              />
            </div>
          }
        />
      </div>

      <div className="row pt-2">
        <FormItem
          title="Start"
          valueNode={
            <div className="col-4 form-control border">

              <DatePicker
                className="w-100 border-0 text-secondary"
                onChange={handleChangeStartTime}
                openToDate={new Date(new Date().setHours(7, 15))}
                selected={startTime}
                dateFormat="hh:mm a"
                minDate={moment('1900-01-01').toDate()}
                timeIntervals={5}
                showTimeSelectOnly
                showTimeSelect
                placeholderText="Select a start time"
              />
            </div>
          }
        />
        <FormItem
          title="End"
          valueNode={
            <div className="col-4 form-control border">

              <DatePicker
                className="w-100 border-0 text-secondary"
                onChange={handleChangeEndTime}
                selected={endTime}
                dateFormat="hh:mm a"
                openToDate={new Date(new Date().setHours(7, 15))}
                timeIntervals={5}
                filterTime={time => {
                  const compareTime = combineDateAndTime(startTime, time);
                  return compareTime.getTime() >= (startTime ?? moment().set('h', 0).set('minute', 0).toDate()).getTime();
                }}
                showTimeSelectOnly
                showTimeSelect
                placeholderText="Select an end time"
              />
            </div>
          }
        />
      </div>

      <div className="row mt-2">
        <FormItem
          title="Frequency"
          valueNode={
            <div className="col-4 mt-1">
              {SessionFrequencyCases.map(frequencyCase => {
                return <Radio
                  key={'frequency-' + frequencyCase}
                  label={getSessionFrequencyDescription(frequencyCase)}
                  value={frequency}
                  onChange={() => {
                    setFrequency(frequencyCase);
                    if (frequencyCase === SessionFrequency.Single) {
                      setTotalSessions(undefined);
                    }
                  }}
                  isSelected={frequency === frequencyCase}
                />;
              })}
            </div>
          }
        />
        {frequency !== SessionFrequency.Single &&
          <FormItem
            title="End after"
            readOnly={!plans}
            valueNode={
              <Selector
                options={[{ key: '-1', name: 'Select repeats', disabled: true }, ...(_.range(2, frequency === SessionFrequency.Fortnightly ? 27 : 53)).map(number => ({ name: `${number} ${frequency === SessionFrequency.Fortnightly ? 'fortnights' : 'weeks'}`, key: number }))]}
                initial={totalSessions ?? '-1'}
                onChange={e => setTotalSessions(parseInt(e.target.value))}
              />
            }
          />
        }
      </div>

      <div className="row mt-2">
        <FormItem
          title="Options"
          valueNode={
            <div className="col-4 mt-1">
              {SessionOptionCases.map(optionCase => {
                return <Radio
                  key={'option-' + optionCase}
                  label={getSessionOptionDescription(optionCase)}
                  value={option}
                  onChange={() => setOption(optionCase)}
                  isSelected={option === optionCase}
                />;
              })}
            </div>
          }
        />
      </div>
      <hr />

      <SaveButton
        onSave={async () => {

          if (!selectedWorker || !selectedClient || !date || !startTime || !endTime || !selectedSupportNeed || !selectedSupportNeed.id()) { return; }

          // Ensure the session length is at least 5 minutes, to catch edge cases where
          // it's possible for users to manually set an earlier end time than start time
          const sessionLengthMins = moment(endTime).diff(moment(startTime), 'minutes');
          if (sessionLengthMins < 5) {
            window.alert('Session length must be at least 5 minutes.');
            setDisableInteraction(false);
            return;
          }
          
          setDisableInteraction(true);

          const data: CreateSessionState = {
            workerId: selectedWorker.id(),
            clientId: selectedClient.id(),
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            supportNeedId: selectedSupportNeed.id()!,
            supportNeedType: selectedSupportNeed.type() ?? SupportNeedModelType.Unknown,
            scheduleDirectly: option === SessionOption.ScheduleSessionDirectly,
            request: {
              date: formatDateForServer(date),
              startTime: formatTime(startTime),
              endTime: formatTime(endTime),
              frequency,
              totalSessions: totalSessions ?? 0,
              createdByUserId: Session.getUser()?.user.id ?? '',
            },
          };

          setIsSaving(true);
          const response = await onCreate(data);

          if (!response) {
            setDisableInteraction(false);
            setIsSaving(false);
            return;
          }

          const workerImpossibleCount = response?.workerSessions?.impossible?.length || 0;
          const workerPossibleCount = response?.workerSessions?.possible?.length || 0;
          const clientImpossibleCount = response?.clientSessions?.impossible?.length || 0;
          const clientPossibleCount = response?.clientSessions?.possible?.length || 0;

          if (workerPossibleCount === 0) {
            window.alert('This support worker is unavailable on the requested session date(s).');
            setDisableInteraction(false);
            setIsSaving(false);
            return;
          }

          if (response && workerImpossibleCount > 0) {
            // Assumption is that both start and end dates are the same date.
            const impossibleSessions = response?.workerSessions.impossible.map(sessionWindow => moment(sessionWindow.startDate).format('d/M/YY'));
            const message = `This support worker is unavailable on ${impossibleSessions.join(',')}. These dates will be excluded from the booking. Continue?`;
            if (!window.confirm(message)) {
              setDisableInteraction(false);
              setIsSaving(false);
              return;
            }
          }

          if (response && clientImpossibleCount > 0) {
            // Assumption is that both start and end dates are the same date.
            // const impossibleSessions = response?.clientSessions.impossible.map(sessionWindow => moment(sessionWindow.startDate).format('d/M/YY'));

            const message = clientImpossibleCount > 1 ? 
              `This client already has scheduled sessions or pending requests for one or more of these times. Continue?` :
              `This client already has a scheduled session or pending request for this time. Continue?`;
            if (!window.confirm(message)) {
              setDisableInteraction(false);
              setIsSaving(false);
              return;
            }
          }

          // NOTE: The call to `validateBudgetsForSessionRequest` must be made here, after calling
          // `createSessionRequest`. This is because the budget validation does not take into account
          // worker availability and that is checked previously before we get to this point.
          const budgetResult = await validateBudgetsForSessionRequest(data);
          
          if (budgetResult.needBudgetAmountExcess > 0) {
            const sessionsText = data.request.totalSessions > 1 ? 'these sessions' : 'this session';
            if (!window.confirm(`Booking ${sessionsText} will cause the client to be ${formatMoney(budgetResult.needBudgetAmountExcess)} over their budget for this support need. Continue?`)) {
              setDisableInteraction(false);
              setIsSaving(false);
              return;
            }
          }

          await onConfirm(data);

          setDisableInteraction(false);
          ToastManager.shared().show('Session(s) created.');
          resetState();
          setIsSaving(false);
        }}

        onCancel={() => {
          history.goBack();
        }}

        disableCancel={disableInteraction}
        disableSave={!canSave()}
        isSaving={isSaving}
      />
    </div>
  );
};
