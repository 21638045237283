import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { UserHeader } from '../../components/UserHeader';
import { AdminManagementContainer } from '../../containers/AdminManagement';
import { UserOpensContainer, UserType } from '../../containers/UserOpens';
import { WorkerContainer } from '../../containers/Worker';
import { WorkerAvailabilityContainer } from '../../containers/Worker/Availability';
import { WorkerDetailsContainer } from '../../containers/Worker/Details';
import { WorkerMessagesContainer } from '../../containers/Worker/Messages';
import { WorkerSessionsContainer } from '../../containers/Worker/Sessions';
import { WorkerContext } from '../../contexts/worker';
import { WorkerModel } from '../../models/Worker';
import { loginPath, workersPath } from '../../Routes';
import session from '../../Session';
import { WorkerTabs } from './Tabs';


export const Worker: React.FC = () => {
  const history = useHistory();

  if (!session.isUserLoggedIn()) {
    history.push(loginPath);
  }

  const match = useRouteMatch({ path: `${workersPath}/:id` });
  const id = (match?.params as any)?.id;

  console.info('Loading worker ', id);
  return <WorkerContainer workerId={(id as string)} />;
};

interface WorkerViewProps {
  isLoading: boolean;
  worker: WorkerModel;
}

export const WorkerView: React.FC<WorkerViewProps> = ({ worker, isLoading }) => {
  const [firstName, setFirstName] = useState(worker.firstName());
  const [lastName, setLastName] = useState(worker.lastName());
  const [photoUrl, setPhotoUrl] = useState(worker.photo());
  const [workerState] = useState(worker.australianState());

  const handleChildKeyChange = (key: string, value: string) => {
    switch (key) {
      case 'firstName': {
        setFirstName(value);
        break;
      }
      case 'lastName': {
        setLastName(value);
        break;
      }
      case 'photoUrl': {
        setPhotoUrl(value);
        break;
      }
    }
  };

  return (
    <div className="container mt-2">
      <UserHeader
        firstName={firstName}
        lastName={lastName}
        photoUrl={photoUrl}
        userState={workerState}
      />
      <WorkerContext.Provider value={worker.id()}>
        <WorkerTabs
          details={
            <WorkerDetailsContainer
              worker={worker}
              isLoading={isLoading}
              onKeyChange={(key, value) => handleChildKeyChange(key, value)}
            />
          }
          sessions={
            <WorkerSessionsContainer
              worker={worker}
            />
          }
          messages={
            <WorkerMessagesContainer
              worker={worker}
            />
          }
          availability={
            <WorkerAvailabilityContainer
              worker={worker}
            />
          }
          userOpens={
            <UserOpensContainer
              userType={UserType.worker}
              userId={worker.userId()}
            />
          }
          adminManagement={
            <AdminManagementContainer
              person={worker}
            />
          }
        />
      </WorkerContext.Provider>
    </div>
  );

};