import React, { useEffect, useState } from 'react';
import { AdminType } from '../../enums/AdminType';
import { getAdminUsers } from '../../managers/AdminUsers';
import { CancellationAlertsResponse, EngagementAlertsResponse, PlanExpiryAlertsResponse, getCancellationAlerts, getEngagementAlerts, getPlanExpiryAlerts } from '../../managers/Alerts';
import ClientManager from '../../managers/ClientManager';
import { AdminUser } from '../../models/AdminUser';
import { ClientModel } from '../../models/Client';
import { MonitoringView } from '../../views/Monitoring';

export const MonitoringContainer: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([]);
  const [clients, setClients] = useState<ClientModel[]>([]);
  const [expiryAlerts, setExpiryAlerts] = useState<Record<string, PlanExpiryAlertsResponse>>({});
  const [engagementAlerts, setEngagementAlerts] = useState<Record<string, EngagementAlertsResponse>>({});
  const [cancellationAlerts, setCancellationAlerts] = useState<Record<string, CancellationAlertsResponse>>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingExpiryAlerts, setIsLoadingExpiryAlerts] = useState(false);
  const [isLoadingEngagementAlerts, setIsLoadingEngagementAlerts] = useState(false);
  const [isLoadingCancellationAlerts, setIsLoadingCancellationAlerts] = useState(false);

  /**
   * On mount, load all admin users
   */
  useEffect(() => {
    const loadAdmins = async () => {
      const admins = await getAdminUsers();
      setAdminUsers(admins);
    };

    setIsLoading(true);
    void loadAdmins().then(() => setIsLoading(false));
  }, []);

  /**
   * Callback to load clients when requested from the view
   */
  async function loadClients(adminId: string, adminType: AdminType): Promise<void> {
    let clientsRes: ClientModel[] = [];
    setIsLoading(true);

    if (adminType === AdminType.caseManager) {
      clientsRes = await ClientManager.getClientsForCaseManagerId(adminId);
    } else if (adminType === AdminType.experienceOfficer) {
      clientsRes = await ClientManager.getClientsForExperienceOfficerId(adminId); 
    }
    
    setClients(clientsRes);
    setIsLoading(false);
  }

  /**
   * Callback to load all clients as a GM user
   */
  async function loadAllClients(): Promise<void> {
    setIsLoading(true);
    setClients(await ClientManager.getClientsAsync(true));
    setIsLoading(false);
  }

  /**
   * Callback to load all unassigned clients
   */
  async function loadUnassignedClients(): Promise<void> {
    setIsLoading(true);
    setClients(await ClientManager.getUnassignedClientsAsync(true));
    setIsLoading(false);
  }

  /**
   * Callback to load alerts for clients
   */
  async function loadAlerts(clientIds: string[]): Promise<void> {
    setIsLoadingExpiryAlerts(true);
    setIsLoadingEngagementAlerts(true);
    setIsLoadingCancellationAlerts(true);

    await getPlanExpiryAlerts(clientIds).then(planAlerts => {
      setExpiryAlerts(planAlerts);
      setIsLoadingExpiryAlerts(false);
    });

    await getEngagementAlerts(clientIds).then(engagementAlerts => {
      setEngagementAlerts(engagementAlerts);
      setIsLoadingEngagementAlerts(false);
    });

    await getCancellationAlerts(clientIds).then(cancellationAlerts => {
      setCancellationAlerts(cancellationAlerts);
      setIsLoadingCancellationAlerts(false);
    });
  }

  return (
    <MonitoringView
      key="monitoring-view"
      adminUsers={adminUsers}
      clients={clients}
      expiryAlerts={expiryAlerts}
      engagementAlerts={engagementAlerts}
      cancellationAlerts={cancellationAlerts}
      loadingState={{
        loading: isLoading,
        loadingExpiryAlerts: isLoadingExpiryAlerts,
        loadingEngagementAlerts: isLoadingEngagementAlerts,
        loadingCancellationAlerts: isLoadingCancellationAlerts,
      }}
      loadClients={loadClients}
      loadAllClients={loadAllClients}
      loadUnassignedClients={loadUnassignedClients}
      loadAlerts={loadAlerts}
    />
  );

};
