import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import { combineDateAndTime } from '../../helpers';


interface EditableLabelledValueProps {
  title: string;
  value: string | number | undefined;
  onChange:(changed: string) => void;
}

export const EditableLabelledValue: React.FC<EditableLabelledValueProps> = ({ title, value, onChange }) => (
  <>
    <label
      htmlFor={title}
      className="col-2 col-form-label text-right mb-1"
    >{title}</label>
    <input
      className="col-4 form-control mb-1"
      type="text"
      value={value}
      id={title}
      onChange={e => onChange(e.target.value)}
    />
  </>
);


interface EditableTimeLabelledValueProps {
  title: string;
  value: Date | undefined;
  ianaTimeZone: string;
  onChange:(date: Date) => void;
}

export const EditableTimeLabelledValue: React.FC<EditableTimeLabelledValueProps> = ({ title, value, onChange, ianaTimeZone }) => {
  // Ugh: https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-591680951
  const dateHack = (value: Date) => {
    const userDate = moment(value);
    const suitsMeDate = moment(value).tz(ianaTimeZone);

    //debugger;
    const modifiedDate = moment(value);
    modifiedDate.subtract(userDate.utcOffset() - suitsMeDate.utcOffset(), 'minutes');
    return modifiedDate.valueOf();
  };

  const dateHackReverse = (value: Date) => {
    const userDate = moment(value);
    const suitsMeDate = moment(value).tz(ianaTimeZone);

    //debugger;
    const modifiedDate = moment(value);
    modifiedDate.add(userDate.utcOffset() - suitsMeDate.utcOffset(), 'minutes');
    return modifiedDate.valueOf();
  };

  /**
   * When the time is changed manually using the keyboard, the date picker
   * component sets the date to the current day (looks like a bug). So, to
   * counteract that, we use the `combineDateAndTime` helper to set the date
   * to match the date given to this component as its original value.
   */
  const handleChangeTime = (newTime: Date): void => {
    const dateValue = combineDateAndTime(value, newTime);
    onChange(new Date(dateHackReverse(dateValue)));
  };

  return (
    <>
      <label
        htmlFor={title}
        className="col-2 col-form-label text-right mb-1"
      >{title}</label>
      <div className="col-4 form-control border">

        <DatePicker
          selected={value ? new Date(dateHack(value)) : undefined}
          className="w-100 border-0 text-secondary"
          onChange={handleChangeTime}
          dateFormat="hh:mm a"
          minDate={moment('1900-01-01').toDate()}
          maxDate={new Date()}
          timeIntervals={5}
          showTimeSelectOnly
          showTimeSelect
        />
      </div>
    </>
  );
};
