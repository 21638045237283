import React from 'react';
import { InsightHR } from '../../../../components/InsightHR';
import { BasicInsights } from '../../../../managers/Insights';
import { InsightRow, InsightTitle } from '../Common';

export interface ClientBasicInsightsViewProps  {
  allTimeBasicInsights: BasicInsights;
  last30BasicInsights:BasicInsights;
}

const InsightBlock = ({ insights }: {insights: BasicInsights}) => {
  return (
    <>
      <InsightRow
        title="Confirmed"
        value={insights.sessions.booked}
      />
      <InsightRow
        title="Completed"
        value={insights.sessions.completed}
      />
      <InsightRow
        title="Declined"
        value={insights.sessions.declined}
      />
      <InsightRow
        title="Cancelled by Worker"
        value={insights.sessions.cancelledByWorker}
      />
      <InsightRow
        title="Cancelled by Client"
        value={insights.sessions.cancelledByClient}
      />
    </>
  );
};



export const ClientBasicInsightsView: React.FC<ClientBasicInsightsViewProps> = (props) => {

  return (
    <>
      <InsightTitle title="Sessions: Last 30 days" />
      <InsightBlock insights={props.last30BasicInsights} />
      <InsightHR />
      <InsightTitle title="Sessions: All time" />
      <InsightBlock insights={props.allTimeBasicInsights} />
    </>
  );
};
