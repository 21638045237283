import React, { useEffect, useState } from 'react';
import { ToastManager } from '../../Modals/Toasts/manager';
import { Loading } from '../../components/Loading';
import { SupportSessionRequest } from '../../components/SupportSessionRequest';
import { SupportSessionRequestCancel } from '../../components/SupportSessionRequestCancel';
import { getNameOfUser } from '../../helpers/getNameOfUser';
import useParseConfig from '../../hooks/useParseConfig';
import sessionsManager, { UpdateSessionRequest, UpdateSessionRequestCancellation } from '../../managers/Sessions';
import { GetPlanForSessionRequest, GetPlansForClient, findCurrentSupportPlan } from '../../managers/SupportPlans';
import { SessionStatus } from '../../models/Sessions';
import { SupportSessionRequestModel } from '../../models/Sessions/SupportSessionRequest';
import { SupportNeedModel } from '../../models/SupportNeed';
import { SupportPlanModel } from '../../models/SupportPlan';
import { useIsMounted } from '../../views/Hooks/useIsMounted';

interface SessionRequestsContainerProps {
  id: string;
}

export const SessionRequestsContainer:React.FC<SessionRequestsContainerProps> = ({ id }) => {
  const [session, setSession] = useState<SupportSessionRequestModel | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [plans, setPlans] = useState<SupportPlanModel[] | undefined>();
  const [plan, setPlan] = useState<SupportPlanModel | undefined>();
  const [need, setNeed] = useState<SupportNeedModel | undefined>();
  const [createdByName, setCreatedByName] = useState<string>();

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isMounted = useIsMounted();
  const { isConfigLoaded, config, loadConfig } = useParseConfig();

  const [isShowCancelState, setIsShowCancelState] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const getData = async () => {
      // Load the PFConfig
      loadConfig();

      const session = await sessionsManager.getSessionRequest(id);
      if (!session) { return; }
      if (isMounted) {
        setSession(session);
      }

      const plansResult = await GetPlansForClient(session.clientId());
      const createdByNameResult = await getNameOfUser(session.createdBy());
      const activePlanForSession = findCurrentSupportPlan(plansResult, new Date(session.firstDateStart()));
      const activePlanNeeds = await activePlanForSession.supportNeeds();
      const needType = session.getNeedType();
      
      let needModel: SupportNeedModel | undefined = undefined;
      activePlanNeeds.forEach(need => {
        if (need.type() === needType) {
          needModel = need;
        }
      });

      if (isMounted) {
        setPlan(activePlanForSession);
        setNeed(needModel);
        setPlans(plansResult);
        setCreatedByName(createdByNameResult);
      }
    };

    void getData().then(() => {
      if (isMounted) {
        setIsLoading(false);
      }
    });

  }, [id, isMounted]);

  return (
    <>
      {isLoading || !isConfigLoaded &&
        <Loading />
      }

      {/* Renders the SupportSessionRequest component view, when not in cancel mode */}
      {(!isLoading && session && plans && plan && !isShowCancelState) &&
        <SupportSessionRequest
          session={session}
          plans={plans}
          plan={plan}
          need={need}
          disableInteraction={isSaving}
          createdByUserName={createdByName}
          postcodes={config?.postcodes ?? []}
          onSwitchToCancelState={() => setIsShowCancelState(true)}
          onSave={async state => {
            setIsSaving(true);
            const updated = await UpdateSessionRequest(session, state);
            const newPlan = await GetPlanForSessionRequest(id);
            setPlan(newPlan);
            ToastManager.shared().show('Session request updated.');
            setIsSaving(false);
            return updated;
          }}
        />
      }

      {/* Renders the SupportSessionRequestCancel component view, when in cancel mode */}
      {(!isLoading && session && plans && plan && isShowCancelState) &&
        <SupportSessionRequestCancel
          request={session}
          disableInteraction={isSaving}
          createdByUserName={createdByName}
          onSaveRequestCancellation={async (
            request: SupportSessionRequestModel,
            newStatus: SessionStatus,
          ) => {
            setIsSaving(true);
            const updated = await UpdateSessionRequestCancellation(request, newStatus);
            ToastManager.shared().show('Session request cancelled.');
            setIsSaving(false);
            return updated;
          }}
          onGoBack={() => setIsShowCancelState(false)}
        />
      }
    </>
  );
};
