import React from 'react';
import { useHistory } from 'react-router-dom';
import { CancellationAlertsResponse } from '../../../../managers/Alerts';
import { ClientModel } from '../../../../models/Client';
import { InsightRow, InsightTitle } from '../Common';

export interface ClientBillableCancellationsInsightsViewProps  {
  data: CancellationAlertsResponse;
  client: ClientModel;
}

export const ClientBillableCancellationsInsightsView: React.FC<ClientBillableCancellationsInsightsViewProps> = (props) => {
  const history = useHistory();  
  
  return (
    <>
      <InsightTitle title="Billable cancellations" />
      <InsightRow
        title="Last 60 days"
        value={props.data.cancellationsCount}
        // onClick={() => {
        //   history.push(buildSessionsPath('billable', true, true, moment(new Date()).subtract(60, 'days'), moment(new Date()), props.client.id()));
        // }}
      />
    </>
  );
};
