import React, { useEffect, useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { lastActiveInsight } from '../../../../managers/Insights';
import { ClientModel } from '../../../../models/Client';
import { ClientLastActiveInsightsView } from '../../../../views/Client/Insights/LastActive';
import { DEFAULT_DELAY, useCanLoad } from '../../util';


export interface ClientLastActiveInsightsContainerProps {
  client?: ClientModel;
}

export const ClientLastActiveInsightsContainer: React.FC<ClientLastActiveInsightsContainerProps> = ({ client }) => {
  const [date, setDate] = useState<Date | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const canLoad = useCanLoad('insights', DEFAULT_DELAY);

  useEffect(() => {
    if (!canLoad || !client) { return; }
    setIsLoading(true);
    void lastActiveInsight(client.id()).then(results => {
      setDate(results);
      setIsLoading(false);
    });
  }, [canLoad, client]);



  return (
    <>
      {isLoading &&
        <Loading />
      }

      {date &&
        <ClientLastActiveInsightsView
          date={date}
        />
      }
    </>
  );
};