import React, { useContext } from 'react';
import { AlertContainer } from '../../../../containers/Alert';
import { ClientContext } from '../../../../contexts/client';
import { getSupportNeedModelTypeDescription } from '../../../../enums/SupportNeedModelType';
import { formatMoney } from '../../../../helpers';
import { AlertMuteAffectedUserType, AlertTypes } from '../../../../managers/Alerts';
import { ClientBudgetInsights } from '../../../../managers/Insights';
import { InsightRow, InsightTitle } from '../Common';

export interface ClientBudgetInsightsViewProps  {
  insights: ClientBudgetInsights[];
}


export const NeedTitle = ({ title }: {title: string}): JSX.Element => {
  return (
    <>
      <h5 className="text-center mt-3">{title}</h5>
      <hr
        className="mt-0"
        style={{ width: '20%' }}
      />
    </>
  );
};


const NeedInsightBlock = ({ insights }: {insights: ClientBudgetInsights}) => {

  return (
    <>
      <NeedTitle title={getSupportNeedModelTypeDescription(insights.need.type)} />
      <InsightRow
        title="Starting budget"
        value={formatMoney(insights.starting)}
      />

      <InsightRow
        title="Expected spend per week"
        value={formatMoney(insights.expectedSpendPerWeek)}
      />

      <InsightRow
        title="Actual spend per week"
        value={formatMoney(insights.actualSpendPerWeek)}
      />


      <InsightRow
        title="Expected spend to date"
        value={formatMoney(insights.expectedSpendToDate)}
      />

      <InsightRow
        title="Actual spend to date"
        value={formatMoney(insights.actualSpendToDate)}
      />

      <InsightRow
        title="Variance"
        value={formatMoney(insights.variance)}
        errorValue={Math.abs(insights.variance) >= 1000 * 100}
      />
    </>
  );
};


export const ClientBudgetInsightsView: React.FC<ClientBudgetInsightsViewProps> = (props) => {
  const clientId = useContext(ClientContext);
  return (
    <>
      <InsightTitle
        title="Budgets"
        badge="BETA"
        alert={
          <AlertContainer
            userType={AlertMuteAffectedUserType.client}
            userId={clientId!}
            alertTypes={[AlertTypes.budgetOverdrawn]}
            subscribe={true}
          />
        }
      />

      {props.insights.map(insight => {

        return <NeedInsightBlock
          key={insight.need.needId}
          insights={insight}
        />;

      })}
    </>
  );
};
