import Parse from 'parse';
import { BudgetAdjustmentType } from '../enums/BudgetAdjustmentType';
import { BudgetAdjustmentState } from '../managers/BudgetAdjustments';
import { SupportNeedModel } from './SupportNeed';
import { SupportPlanModel } from './SupportPlan';
import { ParseObject } from './util/parseTypes';

/**
 * BudgetAdjustmentModel class
 */
export class BudgetAdjustmentModel {
  constructor(public object: Parse.Object) {}

  static new(): BudgetAdjustmentModel {
    return new BudgetAdjustmentModel(new (Parse.Object.extend('BudgetAdjustment'))());
  }

  id(): string | undefined {
    return this.object.id;
  }

  clientId(): string | undefined {
    return this.object.get('client')?.id;
  }

  supportNeedId(): string | undefined {
    return this.object.get('supportNeed')?.id;
  }
  
  amount(): number {
    return this.object.get('amount') ?? 0;
  }

  type(): BudgetAdjustmentType | undefined {
    return this.object.get('type') as BudgetAdjustmentType | undefined;
  }

  notes(): string {
    return this.object.get('notes') ?? '';
  }

  deletedAt(): Date | undefined {
    return this.object.get('deletedAt') ?? undefined;
  }

  createdAt(): Date | undefined {
    return this.object.get('createdAt');
  }

  updatedAt(): Date | undefined {
    return this.object.get('updatedAt');
  }

  supportPlanSync(): SupportPlanModel {
    const supportPlan: ParseObject = this.object.get('supportNeed').get('supportPlan');
    return new SupportPlanModel(supportPlan);
  }

  supportNeedSync(): SupportNeedModel {
    const supportNeed: ParseObject = this.object.get('supportNeed');
    return new SupportNeedModel(supportNeed);
  }

  loadState(state: BudgetAdjustmentState): BudgetAdjustmentModel {
    this.object.set('amount', state.amount);
    this.object.set('type', state.type ?? BudgetAdjustmentType.Other);
    this.object.set('notes', state.notes);
    this.object.set('deletedAt', state.deletedAt ?? undefined);

    if (state.supportNeedId) {
      const supportNeedPtr = new Parse.Object('SupportNeed');
      supportNeedPtr.id = state.supportNeedId;
      this.object.set('supportNeed', supportNeedPtr);
    }

    if (state.clientId) {
      const clientPtr = new Parse.Object('Client');
      clientPtr.id = state.clientId;
      this.object.set('client', clientPtr);
    }

    return this;
  }

  async save(): Promise<BudgetAdjustmentModel> {
    await this.object.save();
    return this;
  }
}
