export enum AdminType {
  manager = 1,
  caseManager = 2,
  experienceOfficer = 3,
  unknown = 0,
}

export function getAdminTypeDescription(status: AdminType | undefined): string {
  switch (status) {
    case AdminType.manager: return 'Manager';
    case AdminType.caseManager: return 'Case Manager';
    case AdminType.experienceOfficer: return 'Experience Officer';
    case AdminType.unknown: return 'Unknown';
    default: return 'Unknown';
  }
}