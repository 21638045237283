import React, { useEffect, useState } from 'react';
import WorkerManager from '../../managers/Workers';
import { WorkerModel } from '../../models/Worker';
import { WorkerView } from '../../views/Worker';

interface WorkerContainerProps {
  workerId: string;
}

export const WorkerContainer: React.FC<WorkerContainerProps> = ({ workerId }) => {
  const [worker, setWorker] = useState<WorkerModel | undefined>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const downloadWorkers = async () => {
      const worker = await WorkerManager.instance().getWorker(workerId);
      setWorker(worker);
    };

    setIsLoading(true);
    void downloadWorkers().then(() => setIsLoading(false));

  }, [workerId]);

  return (
    <>
      {worker &&
        <WorkerView
          worker={worker}
          isLoading={isLoading}
        />
      }
    </>
  );

};
