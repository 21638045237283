import React from 'react';
import { useHistory } from 'react-router-dom';
import { SupportNeedModelType } from '../../../../enums/SupportNeedModelType';
import { formatDistance, formatMoney, formatMoneyWithNegativeCheck } from '../../../../helpers';
import { NeedBudgetSummaryResult, NeedBudgetTransportSummaryResult, NeedSummaryResult } from '../../../../managers/ClientNeedBudgets';
import { clientsPath } from '../../../../Routes';
import { SupportNeedFormRow } from './SupportNeedRow';

interface NeedBudgetSummaryProps {
  summary: NeedSummaryResult & {
    details:  NeedBudgetTransportSummaryResult | NeedBudgetSummaryResult;
  };
  supportPlanPageUrl: string;
  hideAdjustments: boolean;
  clientId: string;
  /** V3 changes require this property to include "/needs" in the URL */
  needUrlSuffix?: string;
}

export const NeedBudgetSummary: React.FC<NeedBudgetSummaryProps> = ({ summary, supportPlanPageUrl, clientId, hideAdjustments = false, needUrlSuffix = undefined }) => {
  const history = useHistory();

  function handleClickAdjustments(): void {
    if (needUrlSuffix) {
      history.push([clientsPath, clientId, supportPlanPageUrl, needUrlSuffix, summary.needId, 'adjustments'].join('/'));
    } else {
      history.push([clientsPath, clientId, supportPlanPageUrl, summary.needId, 'adjustments'].join('/'));
    }
  }

  return (
    <>
      <SupportNeedFormRow colsAfter={4}>
        {{
          label: <label className="col-2 col-form-label text-right mb-1">Spent</label>,
          input: <input
            className="col-4 form-control"
            disabled
            value={formatMoney(summary.details.spent)}
          />,
        }}
      </SupportNeedFormRow>

      {summary.supportType === SupportNeedModelType.Transport &&
        <SupportNeedFormRow colsAfter={4}>
          {{
            label: <label className="col-2 col-form-label text-right mb-1">Remaining Kms</label>,
            input: <input
              className="col-4 form-control"
              disabled
              value={formatDistance((summary.details as NeedBudgetTransportSummaryResult).remainingKm)}
            />,
          }}
        </SupportNeedFormRow>
      }

      {summary.supportType !== SupportNeedModelType.Transport &&
        <SupportNeedFormRow colsAfter={4}>
          {{
            label: <label className="col-2 col-form-label text-right mb-1">Booked</label>,
            input: <input
              className="col-4 form-control"
              disabled
              value={formatMoney((summary.details as NeedBudgetSummaryResult).booked)}
            />,
          }}
        </SupportNeedFormRow>
      }

      {
        !hideAdjustments && (
          <SupportNeedFormRow colsAfter={3}>
            {{
              label: <label className="col-2 col-form-label text-right mb-1">Adjustments</label>,
              input: <input
                className="col-4 form-control"
                disabled
                value={formatMoneyWithNegativeCheck(summary.details.adjustments)}
              />,
              actionButton: (
                <button
                  type="button"
                  className="btn btn-primary col-1 form-control ml-2"
                  style={{
                    maxWidth: '3.4rem',
                    width: 'auto',
                    padding: '0 0.2rem',
                  }}
                  onClick={handleClickAdjustments}
                >
                  ●●●
                </button>
              ),
            }}
          </SupportNeedFormRow>
        )
      }

      <SupportNeedFormRow colsAfter={4}>
        {{
          label: <label className="col-2 col-form-label text-right mb-1">Remaining</label>,
          input: <input
            className="col-4 form-control"
            disabled
            value={formatMoney(summary.details.remaining)}
          />,
        }}
      </SupportNeedFormRow>
    </>
  );

};