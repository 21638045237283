/* eslint-disable @typescript-eslint/no-non-null-assertion */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useHistory } from 'react-router-dom';
import { AustralianState, getAustralianStateAbbreviation } from '../../enums/AustralianState';
import { ClientCancellationReason } from '../../enums/ClientCancellationReason';
import { formatDateWithState } from '../../helpers';
import { SessionCost, getSessionCostForCancellation } from '../../managers/SessionCost';
import { GetSupportSessionsMatchingRequest } from '../../managers/Sessions/queries';
import { SessionStatus, getSimpleCancelledByDescription, getStatusDescription } from '../../models/Sessions';
import { SupportSessionModel } from '../../models/Sessions/SupportSession';
import { SaveButton } from '../../views/Client/SaveButton';
import { Loading } from '../Loading';
import { OverlappingAvatars } from '../OverlappingAvatars';
import { Selector } from '../Selector';
import { getBillabilityReason } from '../SupportSession/BillabilityReason';
import { SimpleLabelledValue } from '../SupportSession/SimpleLabelledValue';

interface SupportSessionCancelProps {
  session: SupportSessionModel;
  disableInteraction: boolean;
  createdByUserName?: string;
  onSaveCancellation: (
    session: SupportSessionModel,
    relatedSessionIds: string[],
    newStatus: SessionStatus,
    reason: string,
    cancelledDate: Date | undefined
  ) => Promise<SupportSessionModel>;
  onGoBack(): void;
}

export const SupportSessionCancel: React.FC<SupportSessionCancelProps> = ({
  session,
  disableInteraction,
  createdByUserName,
  onSaveCancellation,
  onGoBack,
}) => {
  const history = useHistory();
  const [cancelledBy, setCancelledBy] = useState(SessionStatus.unknown);
  const [cancelledByReason, setCancelledByReason] = useState<string>(session.cancellationReason() ?? '');
  const [cancelledAtDateTime, setCancelledAtDateTime] = useState<Date>(session.cancelledDate() ?? new Date());
  const [relatedSessions, setRelatedSessions] = useState<SupportSessionModel[]>([]);
  const [selectedRelatedSessions, setSelectedRelatedSessions] = useState<Map<string, boolean>>(new Map());
  const [isLoadingRelated, setIsLoadingRelated] = useState(false);
  const [isCancelledByError, setIsCancelledByError] = useState(false);
  const [sessionCost, setSessionCost] = useState<SessionCost | undefined>(undefined);
  const [isLoadingPricing, setIsLoadingPricing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const clientState = session.clientSync().australianState() ?? AustralianState.wa;

  useEffect(() => {
    if ([SessionStatus.cancelledByAdmin, SessionStatus.cancelledByClient, SessionStatus.cancelledByWorker, SessionStatus.noShow].includes(session.status())) {
      setCancelledBy(session.status());
    }
  }, []);

  /**
   * Handle change to the cancelled by field
   */
  const handleChangeCancelledBy = (value: number) => {
    setCancelledBy(value);
  };

  /**
   * Handle change to the cancelled date field
   */
  const handleChangeDate = (date: Date | undefined) => {
    if (date) {
      const newDate = new Date(date);
      if (cancelledAtDateTime) {
        newDate.setHours(cancelledAtDateTime.getHours());
        newDate.setMinutes(cancelledAtDateTime.getMinutes());
      }
      setCancelledAtDateTime(newDate);
    }
  };

  /**
   * Handle change to the cancelled time field
   */
  const handleChangeTime = (date: Date | undefined) => {
    if (date) {
      const newDate = new Date(cancelledAtDateTime ?? new Date());
      newDate.setHours(date.getHours());
      newDate.setMinutes(date.getMinutes());
      setCancelledAtDateTime(newDate);
    }
  };

  /**
   * This effect loads all related (recurring) sessions based on the original
   * session request ID for the currently selected session
   */
  useEffect(() => {
    const load = async (reqId: string) => {
      setIsLoadingRelated(true);
      // get all sessions matching the session.request id
      const parseSessions = await GetSupportSessionsMatchingRequest(reqId);
      const otherSessions = parseSessions.map((session) => new SupportSessionModel(session));
      const newMap = new Map();

      // filter where status is not one of the cancelled ones
      const related = otherSessions.filter((rs) => {
        return ![
          SessionStatus.cancelledByClient,
          SessionStatus.cancelledByWorker,
          SessionStatus.cancelledByAdmin,
          SessionStatus.noShow,
        ].includes(rs.status()) && rs.id() !== session.id();
      });

      setRelatedSessions(related);
      relatedSessions.forEach((sid) => newMap.set(sid, false));
      setIsLoadingRelated(false);
    };

    const requestId = session.requestSync()?.id();

    if (session.status() === SessionStatus.confirmed && requestId && session.frequency() !== undefined) {
      void load(requestId);
    }
  }, [session]);

  /**
   * Toggles a checkbox for related session
   */
  const toggleRelatedId = (id: string): void => {
    const newMap = new Map(selectedRelatedSessions);
    const value = selectedRelatedSessions.get(id) ?? false;
    newMap.set(id, !value);
    setSelectedRelatedSessions(newMap);
  };

  /**
   * Select or deselect all related session items
   */
  const handleSelectAllChange = (selected: boolean) => {
    const newMap = new Map(selectedRelatedSessions);
    relatedSessions.forEach((rs) => {
      newMap.set(String(rs.id()), selected);
    });
    setSelectedRelatedSessions(newMap);
  };

  /**
   * Validate cancelled by and reason selections
   */
  const validateFields = () => {
    const isCancelledByError = cancelledBy === SessionStatus.unknown;
    setIsCancelledByError(isCancelledByError);
    return !isCancelledByError;
  };

  /**
   * If the status is not cancelled by client, reset the state for the cancellation reason,
   * and if the status is no show, reset the state of the cancelled at date field
   */
  useEffect(() => {
    if (cancelledBy !== SessionStatus.cancelledByClient) {
      setCancelledByReason('');
    }

    if (cancelledBy === SessionStatus.noShow) {
      setCancelledAtDateTime(new Date());
    }
  }, [cancelledBy]);

  /**
   * If the cancelled status or cancellation date is changed, re-load the pricing costs
   * but only if the status is cancelled by client
   */
  useEffect(() => {
    const sessionId = session.id();

    if (isLoadingPricing || !sessionId || cancelledBy !== SessionStatus.cancelledByClient) {
      return;
    }

    const loadPricing = async () => {
      console.log(`[debug] calculating pricing...`);
      setIsLoadingPricing(true);

      const cost = await getSessionCostForCancellation(sessionId, cancelledBy, cancelledAtDateTime as Date);
      console.log(`[debug] cost`, cost);
      setSessionCost(cost);
      setIsLoadingPricing(false);
    };

    void loadPricing();
  }, [cancelledBy, cancelledAtDateTime]);

  return (
    <div className="m-4">
      <div className="row">
        <div
          className="col-1 mx-auto"
          style={{ marginBottom: -28 }}
        >
          <OverlappingAvatars
            bottomUrl={session.clientPhotoUrl()}
            topUrl={session.workerPhotoUrl()}
            size={80}
          />
        </div>
        <div className="col-12">
          <h2 className="font-weight-bold mb-0 text-center">{`${session.clientName()} with ${session.workerName()}`}</h2>
        </div>
      </div>

      <hr />

      <div className="row">
        <SimpleLabelledValue
          title="ID"
          value={session.id()}
        />

        <SimpleLabelledValue
          title="Status"
          value={session.statusDescription()}
        />

        <SimpleLabelledValue
          title="Date"
          value={formatDateWithState(session.scheduledStart(), 'dddd, Do MMMM, YYYY', session.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title="Created by"
          value={createdByUserName ?? 'Unknown'}
        />

        <SimpleLabelledValue
          title="Requested"
          value={formatDateWithState(session.requestSync()?.createdAt() ?? '', 'dddd, Do MMMM, YYYY', session.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title="Accepted"
          value={formatDateWithState(session.createdAt() ?? '', 'dddd, Do MMMM, YYYY', session.clientSync().australianState())}
        />

        {session.frequency() &&
          <div className="col-4 offset-md-2 pl-0">
            <p
              className="text-left font-weight-bold"
              style={{ width: 'fit-content' }}
            >{session.frequency()}</p>
          </div>

        }
        <div className="col-6" />
      </div>

      <hr />

      <div className="row">
        <SimpleLabelledValue
          title={`Scheduled start ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
          value={formatDateWithState(session.scheduledStart(), 'hh:mm A', session.clientSync().australianState())}
        />

        <SimpleLabelledValue
          title={`Scheduled end ${clientState === AustralianState.wa ? '' : ` (${getAustralianStateAbbreviation(clientState)} Time)`}`}
          value={formatDateWithState(session.scheduledEnd(), 'hh:mm A', session.clientSync().australianState())}
        />
      </div>

      <hr />

      <div className="row">
        <div className="col-12 text-center text-danger">
          <h3>Cancel session</h3>
        </div>
      </div>

      <div className="row">
        <>
          <label
            className="col-2 col-form-label text-right mb-1"
          >Cancelled by</label>
          <div className="col-8">
            <Selector
              options={[
                {
                  name: 'Please select...',
                  key: '',
                },
                {
                  name: getSimpleCancelledByDescription(SessionStatus.cancelledByClient),
                  key: SessionStatus.cancelledByClient,
                },
                {
                  name: getSimpleCancelledByDescription(SessionStatus.cancelledByWorker),
                  key: SessionStatus.cancelledByWorker,
                },
                {
                  name: getSimpleCancelledByDescription(SessionStatus.cancelledByAdmin),
                  key: SessionStatus.cancelledByAdmin,
                },
                {
                  name: getStatusDescription(SessionStatus.noShow),
                  key: SessionStatus.noShow,
                },
              ]}
              initial={cancelledBy}
              isValid={!isCancelledByError}
              disabled={disableInteraction}
              onChange={e => { handleChangeCancelledBy(Number(e.target.value)); }}
            />
          </div>
        </>
      </div>

      {
        cancelledBy === SessionStatus.cancelledByClient &&  (
          <div className="row">
            <label
              className="col-2 col-form-label text-right mb-1"
            >Reason</label>
            <div className="col-8">
              <Selector
                options={[
                  {
                    name: 'Please select...',
                    key: '',
                  },
                  {
                    name: 'Client not well',
                    key: String(ClientCancellationReason.clientNotWell),
                  },
                  {
                    name: 'Client family issues',
                    key: String(ClientCancellationReason.clientFamilyIssues),
                  },
                  {
                    name: 'Other client issue',
                    key: String(ClientCancellationReason.clientOtherReason),
                  },
                ]}
                initial={cancelledByReason}
                disabled={disableInteraction}
                onChange={e => { setCancelledByReason(e.target.value); }}
              />
            </div>
          </div>
        )
      }

      {
        cancelledBy !== SessionStatus.unknown && cancelledBy !== SessionStatus.noShow && (
          <div className="row">
            <label
              htmlFor="cancelledAtDate"
              className="col-2 col-form-label text-right mb-1"
            >Cancelled at</label>
            <div
              className="col-2"
              style={{ minWidth: 311 }}
            >
              <DatePicker
                id="cancelledAtDate"
                className="form-control"
                onChange={date => handleChangeDate(date as Date)}
                selected={cancelledAtDateTime ?? null}
                dateFormat="d/M/yyyy"
                showYearDropdown
                placeholderText=""
                disabled={disableInteraction}
              />
            </div>
            <DatePicker
              className="form-control"
              onChange={date => handleChangeTime(date as Date)}
              openToDate={new Date()}
              selected={cancelledAtDateTime}
              dateFormat="hh:mm a"
              timeIntervals={5}
              showTimeSelectOnly
              showTimeSelect
              disabled={disableInteraction}
            />
          </div>
        )
      }

      {
        isLoadingPricing && (
          <div className="row">
            <div className="col-1 ml-2" />
            <div className="d-flex flex-direction-row mt-2 mb-0">
              <div style={{ transform: 'scale(0.4)', height: 0, marginTop: 4, marginLeft: -10 }}>
                <div className="spinner-border text-grey mb-0 pb-0" />
              </div>
              <div>Loading billable status...</div>
            </div>
          </div>
        )
      }

      {/* If not cancelled by client, just render the basic billable reason */}
      {
        !isLoadingPricing && cancelledBy !== SessionStatus.cancelledByClient && (
          <div className="row">
            <div className="col-1 ml-2" />
            <p className="font-weight-bold text-right mt-2">
              {getBillabilityReason(cancelledBy, 0, 0, [])}
            </p>
          </div>
        )
      }
      {
        sessionCost && !isLoadingPricing && cancelledBy === SessionStatus.cancelledByClient && (
          <div className="row">
            <div className="col-1 ml-2" />
            <p className="font-weight-bold text-right mt-2">
              {getBillabilityReason(cancelledBy, sessionCost.billedTime, sessionCost.billedProportion, sessionCost.billedRebookedSessions)}
            </p>
          </div>
        )
      }

      <hr />

      {
        isLoadingRelated && (
          <div className="text-center">
            <Loading />
          </div>
        )
      }
      {
        relatedSessions.length > 0 && (
          <div
            className="d-flex flex-row"
            style={{ marginLeft: 100 }}>
            <h4 style={{ marginTop: 14 }}>Also cancel</h4>
            <button
              className="btn btn-link m-1 ml-3 font-weight-bold"
              disabled={disableInteraction}
              onClick={() => handleSelectAllChange(true)}
            >Select all
            </button>
            <button
              className="btn btn-link m-1 font-weight-bold"
              disabled={disableInteraction}
              onClick={() => handleSelectAllChange(false)}
            >Deselect all</button>
          </div>
        )
      }
      <div
        key="related_sessions"
        className="form-check mb-1 row"
        style={{ marginLeft: 110 }}
      >
        <div
          className="row col-10"
        >
          {
            relatedSessions.map((rs, index) => {
              // Get date format
              const date = moment(rs.scheduledStart()).format('dddd Do MMMM, YYYY');

              return (
                <div
                  key={rs.id()}
                  className="col-4 mb-2"
                >
                  <input
                    id={rs.id()}
                    className="form-check-input"
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    type="checkbox"
                    name={rs.id()}
                    value={rs.id()}
                    checked={selectedRelatedSessions.get(String(rs.id())) === true}
                    disabled={disableInteraction}
                    onChange={e => toggleRelatedId(e.target.value)}
                  />
                  <label
                    htmlFor={rs.id()}
                    style={{ cursor: 'pointer', userSelect: 'none' }}
                    className="form-check-label font-weight-normal"
                  >{`${date}`}</label>
                </div>
              );
            })
          }
        </div>
      </div>

      <div>
        <SaveButton
          onSave={async () => {
            if (!validateFields()) {
              return;
            }

            setIsSaving(true);
            
            // Get list of related session IDs that are selected
            const relatedIds: string[] = [];
            Array.from(selectedRelatedSessions).forEach((rs) => {
              if (rs[1] === true) {
                relatedIds.push(rs[0]);
              }
            });

            // Save the session changes, and pass related session IDs to also be updated
            let dateTime: Date | undefined = cancelledAtDateTime;
            if (cancelledBy === SessionStatus.noShow) {
              dateTime = undefined;
            }

            await onSaveCancellation(
              session,
              relatedIds,
              cancelledBy,
              cancelledByReason,
              dateTime,
            );
            setIsSaving(false);
            onGoBack();
          }}
          onCancel={onGoBack}
          cancelText="Back"
          disableCancel={disableInteraction}
          disableSave={disableInteraction}
          isSaving={isSaving}
          isSaveDestructive
        />
      </div>
    </div>
  );
};