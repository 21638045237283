import Parse from 'parse';
import { ClientModel } from './Client';
import { ParseObject } from './util/parseTypes';
import { WorkerModel } from './Worker';

export class MessageModel {

  constructor(public object: Parse.Object) { }

  static new(): MessageModel {
    return new MessageModel(new (Parse.Object.extend('Message'))());
  }

  id(): string { return this.object.id; }
  text(): string  { return this.object.get('text'); }
  createdAt(): Date { return this.object.get('createdAt'); }
  deletedAt(): Date | undefined { return this.object.get('deletedAt'); }
  deletedBy(): string | undefined { return this.object.get('deletedBy'); }
  
  setDeleted(isDeleted: boolean, deletedByAdminId: string, allowRestoreUserDeletedMessages: boolean): Date | undefined {
    if (isDeleted && !this.object.get('deletedAt')) {
      this.object.set('deletedAt', new Date());
      this.object.set('deletedBy', deletedByAdminId);
    } else {
      // Only restore messages deleted by an admin
      if (this.deletedAt() !== undefined) {
        const deletedBy = this.deletedBy() ?? '';

        if (allowRestoreUserDeletedMessages || !deletedBy || (deletedBy !== this.worker().id() && deletedBy !== this.client().id())) {
          this.object.unset('deletedAt');
          this.object.unset('deletedBy');
        }
      }
    }
    return this.object.get('deletedAt');
  }

  worker(): WorkerModel {
    return new WorkerModel(this.object.get('worker'));
  }

  client(): ClientModel {
    return new ClientModel(this.object.get('client'));
  }

  isClientAuthored(): boolean {
    return this.object.get('senderId') === this.object.get('client').id;
  }

  async save(): Promise<MessageModel> {
    await this.object.save();
    return this;
  }

  rawObject(): ParseObject {
    return this.object;
  }
}