import React, { useEffect, useState } from 'react';
import { Loading } from '../../../../components/Loading';
import { CancellationAlertsResponse } from '../../../../managers/Alerts';
import { billableCancellationInsight } from '../../../../managers/Insights';
import { ClientModel } from '../../../../models/Client';
import { ClientBillableCancellationsInsightsView } from '../../../../views/Client/Insights/BillableCancellations';
import { DEFAULT_DELAY, useCanLoad } from '../../util';


export interface ClientBillableCancellationsInsightsContainerProps {
  client?: ClientModel;
}

export const ClientBillableCancellationsInsightsContainer: React.FC<ClientBillableCancellationsInsightsContainerProps> = ({ client }) => {
  const [data, setData] = useState<CancellationAlertsResponse | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const canLoad = useCanLoad('insights', DEFAULT_DELAY);

  useEffect(() => {
    if (!canLoad || !client) { return; }
    setIsLoading(true);
    void billableCancellationInsight(client.id()).then(result => {
      setData(result);
      setIsLoading(false);
    });
  }, [canLoad, client]);


  return (
    <>
      {isLoading &&
        <Loading />
      }

      {(data && client) &&
        <ClientBillableCancellationsInsightsView
          data={data}
          client={client}
        />
      }
    </>
  );
};