import { PubSub } from 'pubsub-ts';
import React, { useEffect, useState } from 'react';
import { AlertIndicator } from '../../components/AlertIndicator';
import { publisher } from '../../core/pubsub';
import { Alert, AlertMuteAffectedUserType, AlertTypes, CLIENT_MUTES_TOPIC, WORKER_MUTES_TOPIC, createMute, getMutesForWorker, getWorkerAlerts } from '../../managers/Alerts';
import { AlertMuteModel } from '../../models/AlertMute';


type AlertContainerProps = {
  userType: AlertMuteAffectedUserType;
  userId: string;
  alertTypes?: AlertTypes[];
  subscribe: boolean;
  description?: string | JSX.Element;
};

export const AlertContainer: React.FC<AlertContainerProps> = ({ userType, userId, alertTypes, subscribe, description }) => {
  const [alerts, setAlerts] = useState<Alert[]>([]);
  const [mutes, setMutes] = useState<AlertMuteModel[]>([]);
  const [filteredAlerts, setFilteredAlerts] = useState<Alert[]>([]);
  const subscriber: PubSub.Subscriber = new PubSub.Subscriber();

  useEffect(() => {
    const getData = async () => {
      if (userType === AlertMuteAffectedUserType.client) {
        // Disabled for optimisation
        // const [alerts, mutes] = await Promise.all([getClientAlerts(userId), getMutesForClient(userId)]);
        // setMutes(mutes);
        // setAlerts(alerts);
      } else if (userType === AlertMuteAffectedUserType.worker) {
        const [alerts, mutes] = await Promise.all([getWorkerAlerts(userId), getMutesForWorker(userId)]);
        setMutes(mutes);
        setAlerts(alerts);
      }
    };

    void getData();
  }, [userType, userId, alertTypes]);

  useEffect(() => {
    if (!subscribe) { return; }
    const mutesTopic = userType === AlertMuteAffectedUserType.client ? CLIENT_MUTES_TOPIC : WORKER_MUTES_TOPIC;

    subscriber.on(mutesTopic, (notification: PubSub.Notification) => {
      setMutes(notification.body);
    });

    publisher.add(subscriber);

    subscriber.start();
    return () => {
      subscriber.off(mutesTopic);
      publisher.delete(subscriber);
    };
  }, [subscribe, subscriber]);

  useEffect(() => {
    const filtered = alerts.filter(alert => {
      const applicableMutes = mutes.filter(mute => {
        if (mute.type() !== alert.type) {
          return false;
        }
        const effectiveUntil = mute.effectiveUntil();
        if (!effectiveUntil) { return true; }
        return effectiveUntil > new Date();
      });

      return applicableMutes.length === 0 && (alertTypes ? alertTypes.includes(alert.type) : true);
    });

    setFilteredAlerts(filtered);
  }, [alerts, mutes, alertTypes]);

  async function handleMute(type: AlertTypes, duration: number | undefined) {
    let mute: AlertMuteModel | null = null;
    if (userType === AlertMuteAffectedUserType.client) {
      mute = await createMute(userId, AlertMuteAffectedUserType.client, type, duration);
    } else if (userType === AlertMuteAffectedUserType.worker) {
      mute = await createMute(userId, AlertMuteAffectedUserType.worker, type, duration);
    }

    if (mute) {
      setMutes([...mutes, mute]);
    }
  }

  return (
    <>
      {filteredAlerts.length > 0 &&
        <>
          <AlertIndicator
            alerts={filteredAlerts}
            popover={filteredAlerts.length === 1 ? 'mutes' : 'counts'}
            didMute={handleMute}
          />
          {description}
        </>
      }
    </>
  );
};