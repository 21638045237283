
import React from 'react';
import { useHistory } from 'react-router-dom';
import { clientsPath } from '../../Routes';
import { getIncidentReportTypeDescription } from '../../enums/WorkerReportType';
import { BrandColors, formatDate } from '../../helpers';
import { WorkerReportModel } from '../../models/WorkerReport';

interface WorkerReportProps {
  report: WorkerReportModel;
}

export const WorkerReport: React.FC<WorkerReportProps> = ({ report }) => {
  const photoUrl = report.getWorkerSync().photo();
  const history = useHistory();
  const client = report.getClientSync();

  return (
    <div className="px-1 mt-2">
      <h2>Worker Report</h2>
      <div
        className="w-50 p-1 text-left"
        id={report.id()}
      >
        <div
          className="p-1 d-flex align-items-center"
          style={{ backgroundColor: '#eef2f7', borderRadius: '0.25em 0.25em 0 0', borderWidth: '1px 1px 0px 1px', borderColor: '#eef2f7', borderStyle: 'solid' }}
        >
          <img
            src={photoUrl}
            alt="avatar"
            style={{ width: 56, height: 56, borderRadius: '50%', objectFit: 'cover' }}
          />
          <div
            className="d-flex flex-column ml-2"
          >
            <h5 className="mb-0">{report.getWorkerSync().fullName()}</h5>
            <p className="mb-1">{formatDate(report.createdAt(), 'dddd, Do MMMM, YYYY')}</p>
          </div>
        </div>
        <div
          className="d-flex flex-column p-1"
          style={{ backgroundColor: 'white', borderRadius: '0 0 0.25em 0.25em', borderWidth: '0px 1px 1px 1px', borderColor: '#eef2f7', borderStyle: 'solid' }}
        >

          <p className="mb-0 font-weight-bold">{getIncidentReportTypeDescription(report.type())}</p>
          <p className="mb-0">{report.text()}</p>
          {client && <div>
            <hr />
            <p className="mb-0 font-weight-bold">Client</p>
            <p
              className="mb-0 font-weight-bold"
              style={{ color: BrandColors.orange }}
            >
              <a href={`${clientsPath}/${client.id()}`}>{client.fullName()}</a>
            </p>
          </div>}
          {client?.caseManagerDetailsSync() && <div>
            <hr />
            <p className="mb-0 font-weight-bold">Case Manager</p>
            <p className="mb-0 font-weight-bold">{client.caseManagerDetailsSync().name}</p>
          </div>}
        </div>
      </div>
      <div className="w-50 d-flex flex-row-reverse">
        <div
          className="col-1 btn offset-md-11 brand-orange"
          style={{ color: BrandColors.orange }}
          onClick={() => history.goBack()}
        >Done</div>
      </div>
    </div>

  );
};