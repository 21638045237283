import moment from 'moment';
import Parse from 'parse';
import React from 'react';
import DatePicker from 'react-datepicker';
import { ModalManager } from '../../../Modals/Error/manager';
import { AtsiStatus, AtsiStatusCases, getAtsiStatusDescription } from '../../../enums/AtsiStatus';
import { AustralianState, getAustralianStateDescription } from '../../../enums/AustralianState';
import { CovidVaccinationStatus, CovidVaccinationStatusCases, getCovidVaccinationStatusDescription } from '../../../enums/CovidVaccinationStatus';
import { Gender, GenderCases, getGenderDescription, getGenderDescriptionForDisallowedWorkers } from '../../../enums/Gender';
import { Hobby, HobbyCases, getHobbyDescription } from '../../../enums/Hobby';
import { Language, LanguageCasesAlphabetical, getLanguageDescription } from '../../../enums/Language';
import { UserStatus, getUserStatusDescription } from '../../../enums/UserStatus';
import { resizeFile } from '../../../helpers';
import { LastEvent } from '../../../hooks/useAnalytics';
import { ClientDetailsSave, ClientModel } from '../../../models/Client';
import { SaveButton } from '../SaveButton';
import { AddressInput } from './addressInput';
import { pluralizeWithoutQuantity } from './util';

export interface ClientDetailsViewProps {
  client: ClientModel;
  lastProfileEvent?: LastEvent;
  onKeyChanged: (key:string, value: unknown) => void;
  onSave: (client: ClientModel, state: ClientDetailsSave) => Promise<void>;

  onReset: () => void;
  disableInteraction: boolean;
  isSaving: boolean;
}

export class ClientDetailsView extends React.Component<ClientDetailsViewProps> {
  private photoInputFileRef = React.createRef<HTMLInputElement>();

  client(): ClientModel {
    return this.props.client;
  }

  state = {
    client: this.client(),
    password: '',
    email: this.client().email() || '',
    firstName: this.client().firstName() || '',
    lastName: this.client().lastName() || '',
    dateOfBirth: this.client().dateOfBirth() || moment(''),
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
    gender: this.client().gender() === undefined ? -1 : this.client().gender()!,
    address: this.client().address() || '',
    postcode: this.client().postcode() || '',
    suburb: this.client().suburb() || '',
    australianState: this.client().australianState() || 0,
    addressCoordinates: this.client().addressCoordinates(),
    addressGoogleId: this.client().addressGoogleId(),

    phoneNumber: this.client().phoneNumber() || '',
    photoUrl: this.client().photoUrl(),
    userStatus: this.client().status() || UserStatus.Active,
    languages: this.client().languages() || new Set(),
    hobbies: this.client().hobbies() || new Set(),
    importantToKnow: this.client().importantToKnow() || '',
    bio: this.client().bio() || '',
    covidVaccinationStatus: this.client().covidVaccinationStatus(),
    atsiStatus: this.client().atsiStatus(),

    disallowedWorkerGenders: this.client().disallowedWorkerGenders() || new Set(),
    disallowedWorkerVaccinationStatuses: this.client().disallowedWorkerVaccinationStatuses() || new Set(),
    dirtyKeys: new Set(),
    formWasValidated: false,
    isFirstSave: false,
    photoBytes: undefined,
  };

  updateState(key: string, value: any) {
    this.setState({
      isDirty: this.state.dirtyKeys.add(key),
      [key]: value,
    });
  }

  updateDate(date: Date | [Date, Date] | null, key: string) {
    const momentDate = moment(date as Date);
    if (!momentDate.isValid()) { return; }
    this.updateState(key, momentDate);
  }

  toggleLanguage(language: Language) {
    this.state.languages.has(language) ? this.state.languages.delete(language) : this.state.languages.add(language);
    this.updateState('languages', this.state.languages);
  }

  toggleHobby(hobby: Hobby) {
    this.state.hobbies.has(hobby) ? this.state.hobbies.delete(hobby) : this.state.hobbies.add(hobby);
    this.updateState('hobbies', this.state.hobbies);
  }

  toggleDisallowedWorkerGender(gender: Gender) {
    this.state.disallowedWorkerGenders.has(gender) ? this.state.disallowedWorkerGenders.delete(gender) : this.state.disallowedWorkerGenders.add(gender);
    this.updateState('disallowedWorkerGenders', this.state.disallowedWorkerGenders);
  }

  toggleDisallowedWorkerVaccinationStatuses(status: CovidVaccinationStatus) {
    this.state.disallowedWorkerVaccinationStatuses.has(status) ? this.state.disallowedWorkerVaccinationStatuses.delete(status) : this.state.disallowedWorkerVaccinationStatuses.add(status);
    this.updateState('disallowedWorkerVaccinationStatuses', this.state.disallowedWorkerVaccinationStatuses);
  }

  isCreatingNew(): boolean { return this.props.client.id() === undefined; }

  isPasswordValid(): boolean { return this.state.password.trim().length >= 8; }
  passwordValidClassName(): string { return this.getValidClassName(this.isPasswordValid()); }

  isEmailValid(): boolean {
    const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return regex.test(this.state.email.trim());
  }
  emailValidClassName(): string { return this.getValidClassName(this.isEmailValid()); }

  isFirstNameValid(): boolean { return this.state.firstName.trim().length > 0; }
  firstNameValidClassName(): string { return this.getValidClassName(this.isFirstNameValid()); }

  isLastNameValid(): boolean { return this.state.lastName.trim().length > 0; }
  lastNameValidClassName(): string { return this.getValidClassName(this.isLastNameValid()); }

  isDateOfBirthValid(): boolean {
    if (!this.state.dateOfBirth.isValid()) { return false; }
    const now = moment();
    return this.state.dateOfBirth.isBefore(now);
  }
  dateOfBirthValidClassName(): string { return this.state.formWasValidated && !this.isDateOfBirthValid() ? 'border-danger' : ''; }

  isGenderValid(): boolean { return GenderCases.includes(this.state.gender); }
  genderValidClassName(): string { return this.getValidClassName(this.isGenderValid()); }

  isAddressValid(): boolean { return this.state.address.trim().length > 0; }
  addressValidClassName(): string { return this.getValidClassName(this.isAddressValid()); }

  isPhoneNumberValid(): boolean {
    const val = `${this.state.phoneNumber}`.replaceAll(' ', '').trim();
    return /^\d{8,12}$/.test(val);
  }
  phoneNumberValidClassName(): string { return this.getValidClassName(this.isPhoneNumberValid()); }


  isUserStatusValid(): boolean {
    const validStatus = [UserStatus.Active, UserStatus.PendingApproval, UserStatus.Declined, UserStatus.Blocked, UserStatus.Removed, UserStatus.PendingReapproval];
    return validStatus.includes(this.state.userStatus);
  }
  userStatusValidClassName(): string { return this.state.formWasValidated && !this.isUserStatusValid() ? 'text-danger' : ''; }

  getValidClassName(isValid: boolean): string { return this.state.formWasValidated && !isValid ? 'is-invalid' : ''; }

  isDirty(): boolean { return this.state.dirtyKeys.size > 0; }

  choosePhotoFile() {
    this.photoInputFileRef.current?.click();
  }

  async onPhotoFileSelected(files: FileList | null) {
    if (files == null) { return; }
    let file = files.item(0);
    if (file == null) { return; }
    file = await resizeFile(file);

    const urlReader = new FileReader();
    urlReader.readAsDataURL(file);
    urlReader.onloadend = e => {
      const url = e.target?.result as string;
      // url is in the format 'data:image/png;base64,...' or 'data:image/jpeg;base64,...'

      if (url.startsWith('data:image/') && file != null) {
        this.updateState('photoUrl', url);
        this.props.onKeyChanged('photoUrl', url);

        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);
        fileReader.onload = e => {
          const result = e.target?.result as ArrayBuffer;
          if (result == null) { return; }
          const array = new Uint8Array(result);
          const bytes = [];
          for (let i = 0; i < array.length; i++) {
            bytes.push(array[i]);
          }
          this.updateState('photoBytes', bytes);
        };
      } else {
        ModalManager.shared().show('Unsuitable photo file',  'Please choose an image file.');
      }
    };
  }

  removePhoto() {
    this.updateState('photoUrl', undefined);
    this.updateState('photoBytes', undefined);
  }

  revertPhoto() { // Revert back to current photo stored in backend
    this.updateState('photoUrl', this.state.client?.photoUrl());
    this.updateState('photoBytes', undefined);
  }

  validateForm(): { title: string; message: string } | undefined {
    this.setState({ formWasValidated: true });

    const validationResults = [
      this.isEmailValid(),
      this.isFirstNameValid(),
      this.isLastNameValid(),
      this.isDateOfBirthValid(),
      this.isGenderValid(),
      this.isAddressValid(),
      this.isPhoneNumberValid(),
      this.isUserStatusValid(),
    ];

    if (this.isCreatingNew()) {
      validationResults.push(this.isPasswordValid());
    }
    const failureCount = validationResults.filter(result => !result).length;
    if (failureCount === 0) { return undefined; }
    const fields = pluralizeWithoutQuantity(failureCount, 'field');
    const invalidFields = failureCount === 1 ? `an invalid ${fields}` : `invalid ${fields}`;
    return {
      title: `You have ${invalidFields}`,
      message: `Please fix the highlighted ${fields} before proceeding`,
    };
  }

  clearError() { this.setState({ error: undefined }); }

  async saveClient() {
    const error = this.validateForm();
    if (error != null) {
      ModalManager.shared().show(error.title, error.message);
      return;
    }

    const client = this.state.client;
    if (!client) { return; }

    this.setState({ dirtyKeys: new Set(), formWasValidated: false });
    await this.props.onSave(client, this.state);
  }

  renderSpinner(): JSX.Element | undefined {
    return (
      <div className="d-flex justify-content-center m-5">
        <div
          className="spinner-grow text-info"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  renderSelector(options: [string, number][], htmlId: string, className: string, stateKey: string, selectedValue: number): JSX.Element {
    const elements = options.map(option => <option
      key={option[1]}
      value={option[1]}
    >{option[0]}</option>);
    return (
      <select
        id={htmlId}
        className={`${className} form-control`}
        value={selectedValue}
        onChange={e => this.updateState(stateKey, Number(e.target.value))}
      >
        {elements}
      </select>
    );
  }

  renderGenderSelector(htmlId: string, className: string): JSX.Element {
    const options: [string, number][] = GenderCases.map(c => [getGenderDescription(c), c]);
    if (this.state.gender === -1) {
      // We only add the 'Please select' option if gender is undefined for the ClientModel
      options.unshift(['Please select', -1]);
    }
    return this.renderSelector(options, htmlId, className, 'gender', this.state.gender);
  }

  renderCovidVaccinationStatusRadio(status: CovidVaccinationStatus): JSX.Element {
    const id = `vaccinationStatus-${status}`;
    return (
      <div className="form-check mb-1">
        <input
          id={id}
          className="form-check-input"
          type="radio"
          name={id}
          value={status}
          checked={this.state.covidVaccinationStatus === status}
          onChange={() => this.updateState('covidVaccinationStatus', status)}
        />
        <label
          htmlFor={id}
          className="form-check-label font-weight-normal"
        >{getCovidVaccinationStatusDescription(status)}</label>
      </div>
    );
  }

  renderAtsiStatusRadio(status: AtsiStatus): JSX.Element {
    const id = `atsiStatus-${status}`;
    return (
      <div className="form-check mb-1">
        <input
          id={id}
          className="form-check-input"
          type="radio"
          name={id}
          value={status}
          checked={this.state.atsiStatus === status}
          onChange={() => this.updateState('atsiStatus', status)}
        />
        <label
          htmlFor={id}
          className="form-check-label font-weight-normal"
        >{getAtsiStatusDescription(status)}</label>
      </div>
    );
  }


  renderUserStatusRadio(status: UserStatus): JSX.Element {
    const id = `userStatus-${status}`;
    return (
      <div className="form-check mb-1">
        <input
          id={id}
          className="form-check-input"
          type="radio"
          name={id}
          value={status}
          checked={this.state.userStatus === status}
          onChange={() => this.updateState('userStatus', status)}
        />
        <label
          htmlFor={id}
          className="form-check-label font-weight-normal"
        >{getUserStatusDescription(status)}</label>
      </div>
    );
  }

  renderLanguageCheckbox(language: Language): JSX.Element {
    const id = `language-${language}`;
    return (
      <div
        key={language}
        className="form-check mb-1"
      >
        <input
          id={id}
          className="form-check-input"
          type="checkbox"
          name={id}
          value={language}
          checked={this.state.languages.has(language)}
          onChange={e => this.toggleLanguage(Number(e.target.value))}
        />
        <label
          htmlFor={id}
          className="form-check-label font-weight-normal"
        >{getLanguageDescription(language)}</label>
      </div>
    );
  }

  renderHobbyCheckbox(hobby: Hobby): JSX.Element {
    const id = `hobby-${hobby}`;
    return (
      <div
        key={hobby}
        className="form-check mb-1"
      >
        <input
          id={id}
          className="form-check-input"
          type="checkbox"
          name={id}
          value={hobby}
          checked={this.state.hobbies.has(hobby)}
          onChange={e => this.toggleHobby(Number(e.target.value))}
        />
        <label
          htmlFor={id}
          className="form-check-label font-weight-normal"
        >{getHobbyDescription(hobby)}</label>
      </div>
    );
  }

  renderIdField(): JSX.Element {
    return (<>
      <label className="col-2 col-form-label text-right">ID</label>
      <input
        type="text"
        className="col-4 form-control"
        disabled={true}
        value={this.state.client?.object.id}
      />
      <span /> {/* this <span/> element is here to stop the Email label below look disabled because of the above's <input/> disabled state */}
    </>);
  }

  renderPasswordField(): JSX.Element {
    return (<>
      <label className="col-2 col-form-label text-right">Password</label>
      <input
        type="text"
        className={`col-4 form-control ${this.passwordValidClassName()}`}
        value={this.state.password}
        onChange={e => this.updateState('password', e.target.value)}
      />
    </>);
  }

  renderGenderCheckbox(gender: Gender): JSX.Element {
    const id = 'gender-disallow-' + gender;
    return (
      <div
        key={gender}
        className="form-check mb-1"
      >
        <input
          id={id}
          className="form-check-input"
          type="checkbox"
          name={id}
          value={gender}
          checked={this.state.disallowedWorkerGenders.has(gender)}
          onChange={e => this.toggleDisallowedWorkerGender(Number(e.target.value))}
        />
        <label
          htmlFor={id}
          className="form-check-label font-weight-normal"
        >{getGenderDescriptionForDisallowedWorkers(gender)}</label>
      </div>
    );
  }

  renderDisallowedGenders(): JSX.Element {
    return (
      <>
        <label
          htmlFor="disallowedWorkerGenders"
          className="col-2 col-form-label text-right mt-2"
        >Disallowed worker genders</label>
        <div className="row col-10 mt-2">
          <div className="col-6">
            {[0, 1, 2, 3].map(gender => this.renderGenderCheckbox(gender))}
          </div>
          <div className="col-6">
            {[4, 5, 6, 7].map(gender => this.renderGenderCheckbox(gender))}
          </div>
        </div>
      </>
    );
  }

  renderDisallowedWorkerVaccinationStatusesCheckbox(status: CovidVaccinationStatus): JSX.Element {
    const id = 'covid-disallow-' + status;
    return (
      <div
        key={status}
        className="form-check mb-1"
      >
        <input
          id={id}
          className="form-check-input"
          type="checkbox"
          name={id}
          value={status}
          checked={this.state.disallowedWorkerVaccinationStatuses.has(status)}
          onChange={e => this.toggleDisallowedWorkerVaccinationStatuses(Number(e.target.value))}
        />
        <label
          htmlFor={id}
          className="form-check-label font-weight-normal"
        >{getCovidVaccinationStatusDescription(status)}</label>
      </div>
    );
  }

  renderDisallowedWorkerVaccinationStatuses(): JSX.Element {
    return (
      <>
        <label
          htmlFor="disallowedWorkerGenders"
          className="col-2 col-form-label text-right mt-2"
        >Disallowed worker vaccination statuses</label>
        <div className="row col-10 mt-2">
          <div className="col-12">
            {CovidVaccinationStatusCases.map(status => this.renderDisallowedWorkerVaccinationStatusesCheckbox(status))}
          </div>
        </div>
      </>
    );
  }

  renderForm(): JSX.Element {
    // if (!this.state.client) {
    //   return (
    //     <div className="container">
    //       {this.renderErrorModal()}
    //     </div>
    //   );
    // }

    const idOrPasswordField = this.isCreatingNew() ? this.renderPasswordField() : this.renderIdField();
    const showRemovePhotoButton = (this.state.photoUrl !== undefined) || (this.state.photoBytes !== undefined);
    const showRevertPhotoButton = (this.state.client?.photoUrl() !== undefined) && (this.state.client?.photoUrl() !== this.state.photoUrl);

    return (
      <div className="container pb-2 pt-4">

        <form>
          <div className="row form-group">
            {idOrPasswordField}

            <label
              htmlFor="email"
              className="col-2 col-form-label text-right"
            >Email</label>
            <input
              id="email"
              type="email"
              className={`col-4 form-control ${this.emailValidClassName()}`}
              value={this.state.email}
              onChange={e => this.updateState('email', e.target.value)}
              disabled={!this.isCreatingNew()}
            />
          </div>

          <div className="row form-group">
            <label
              htmlFor="firstName"
              className="col-2 col-form-label text-right"
            >First name</label>
            <input
              id="firstName"
              type="text"
              className={`col-4 form-control ${this.firstNameValidClassName()}`}
              value={this.state.firstName}
              onChange={e => {
                this.updateState('firstName', e.target.value);
                this.props.onKeyChanged('firstName', e.target.value);
              }}
            />

            <label
              htmlFor="lastName"
              className="col-2 col-form-label text-right"
            >Last name</label>
            <input
              id="lastName"
              type="text"
              className={`col-4 form-control ${this.lastNameValidClassName()}`}
              value={this.state.lastName}
              onChange={e => {
                this.updateState('lastName', e.target.value);
                this.props.onKeyChanged('lastName', e.target.value);
              }}
            />
          </div>

          <div className="row form-group">
            <label
              htmlFor="dateOfBirth"
              className="col-2 col-form-label text-right"
            >Date of birth</label>
            <div className={`col-4 form-control border ${this.dateOfBirthValidClassName()}`}>
              <DatePicker
                className="w-100 border-0 text-secondary"
                onChange={date => this.updateDate(date, 'dateOfBirth')}
                selected={this.state.dateOfBirth.isValid() ? this.state.dateOfBirth.toDate() : undefined}
                dateFormat="d/M/yyyy"
                minDate={moment('1900-01-01').toDate()}
                maxDate={new Date()}
                showYearDropdown
              />
            </div>

            <label
              htmlFor="gender"
              className="col-2 col-form-label text-right"
            >Gender</label>
            {this.renderGenderSelector('gender', `col-4 form-control ${this.genderValidClassName()}`)}
          </div>

          <div className="row form-group">
            <label
              htmlFor="address"
              className="col-2 col-form-label text-right"
            >Address</label>
            <AddressInput
              startingAddress={this.state.address}
              onChange={address => {
                this.updateState('address', address.address);
                this.updateState('australianState', address.state);
                this.updateState('suburb', address.suburb);
                this.updateState('postcode', address.postcode);
                this.updateState('addressCoordinates', new Parse.GeoPoint(address.geo.lat, address.geo.lon));
                this.updateState('addressGoogleId', address.id);
              }}
            />

            <label
              htmlFor="postcode"
              className="col-2 col-form-label text-right"
            >Postcode</label>
            <input
              id="postcode"
              type="text"
              className="col-4 form-control"
              value={this.state.postcode}
              readOnly
            />
          </div>

          <div className="row form-group">
            <label
              htmlFor="state"
              className="col-2 col-form-label text-right"
            >State</label>
            <input
              id="state"
              type="text"
              className="col-4 form-control"
              readOnly
              value={getAustralianStateDescription(this.state.australianState as AustralianState)}
            />
            <label
              htmlFor="suburb"
              className="col-2 col-form-label text-right"
            >Suburb</label>
            <input
              id="suburb"
              type="text"
              className="col-4 form-control"
              value={this.state.suburb}
              readOnly
            />
          </div>

          <div className="row form-group">
            <label
              htmlFor="addressCoordinates"
              className="col-2 col-form-label text-right"
            >Coords</label>
            <input
              id="addressCoordinates"
              type="text"
              className="col-4 form-control"
              readOnly
              value={this.state.addressCoordinates ? `${this.state.addressCoordinates.latitude}, ${this.state.addressCoordinates.longitude}` : ''}
            />
          </div>

          <div className="row form-group">
            <label
              htmlFor="phoneNumber"
              className="col-2 col-form-label text-right"
            >Phone</label>
            <input
              id="phoneNumber"
              type="text"
              className={`col-4 form-control ${this.phoneNumberValidClassName()}`}
              value={this.state.phoneNumber}
              onChange={e => this.updateState('phoneNumber', e.target.value)}
            />

            <label className="col-2 col-form-label text-right">Photo</label>
            <div className="col-4 form-control border-0 bg-transparent pl-0">
              <div style={{ display: 'flex' }}>
                <img
                  hidden={!this.state.photoUrl}
                  src={this.state.photoUrl}
                  className="pr-1 bg-transparent"
                  alt=""
                  style={{ width: 35, height: 35, objectFit: 'cover' }}
                />
                <button
                  type="button"
                  className="btn btn-outline-secondary py-1"
                  onClick={() => { this.choosePhotoFile(); }}
                >Choose a photo</button>
                <input
                  ref={this.photoInputFileRef}
                  type="file"
                  onChange={e => this.onPhotoFileSelected(e.target.files)}
                  style={{ display: 'none' }}
                />
              </div>
            </div>

            <div className="col-4 offset-8 pl-0 mt-1">
              <button
                hidden={!showRemovePhotoButton}
                type="button"
                className="btn btn-link pt-1 pl-0"
                onClick={() => this.removePhoto()}
              >Remove photo</button>
              <button
                hidden={!showRevertPhotoButton}
                type="button"
                className="btn btn-link pt-1 pl-0"
                onClick={() => this.revertPhoto()}
              >Revert photo</button>
            </div>
          </div>
          <hr />

          <div className="row form-group">
            <label className={`col-2 col-form-label text-right ${this.userStatusValidClassName()}`}>Status</label>
            <div className="col-3 col-form-label">
              {this.renderUserStatusRadio(UserStatus.Active)}
              {this.renderUserStatusRadio(UserStatus.PendingApproval)}
              {this.renderUserStatusRadio(UserStatus.Declined)}
            </div>
            <div className="col-3 col-form-label">
              {this.renderUserStatusRadio(UserStatus.Blocked)}
              {this.renderUserStatusRadio(UserStatus.Removed)}
              {this.renderUserStatusRadio(UserStatus.PendingReapproval)}
            </div>
          </div>

          <hr />

          <div className="row form-group">
            <label className="col-2 col-form-label text-right">COVID vaccinated</label>
            <div className="col-3 col-form-label">
              {CovidVaccinationStatusCases.map(status => this.renderCovidVaccinationStatusRadio(status))}
            </div>

          </div>
          <hr />

          <div className="row form-group">
            <label className="col-2 col-form-label text-right">ATSI Status</label>
            <div className="col-3 col-form-label">
              {AtsiStatusCases.map(status => this.renderAtsiStatusRadio(status))}
            </div>

          </div>
          <hr />

          <div className="row form-group">
            <label className="col-2 col-form-label text-right"><h4 className="mb-0">Profile</h4></label>
            <div className="col-10" />

            <label className="col-2 col-form-label text-right">Language</label>
            <div className="col-4 col-form-label">
              {LanguageCasesAlphabetical().map(languageCase => this.renderLanguageCheckbox(languageCase))}
            </div>

            <label className="col-1 col-form-label text-right">Hobbies</label>
            <div className="col-5 col-form-label">
              {HobbyCases.map(hobbyCase => this.renderHobbyCheckbox(hobbyCase))}
            </div>

            <label
              htmlFor="importantToKnow"
              className="col-2 col-form-label text-right"
            >Important to know</label>
            <textarea
              id="importantToKnow"
              className="col-10 form-control mb-2"
              rows={4}
              value={this.state.importantToKnow}
              onChange={e => this.updateState('importantToKnow', e.target.value)}
            />

            <label
              htmlFor="bio"
              className="col-2 col-form-label text-right"
            >About me</label>
            <textarea
              id="bio"
              className="col-10 form-control"
              rows={4}
              value={this.state.bio}
              onChange={e => this.updateState('bio', e.target.value)}
            />

            {this.renderDisallowedGenders()}

            {this.renderDisallowedWorkerVaccinationStatuses()}

          </div>


          <hr />

          <SaveButton
            onSave={() => this.saveClient()}
            onCancel={() => this.props.onReset()}
            disableCancel={this.props.disableInteraction}
            disableSave={this.props.disableInteraction || !this.isDirty()}
            saveText={this.isCreatingNew() ? 'Create' : 'Save'}
            isSaving={this.props.isSaving}
          />

        </form>

        {
          this.props.lastProfileEvent && (
            <div
              className="alert alert-info mt-2 text-center"
              role="alert"
            >
              Profile last updated {moment(this.props.lastProfileEvent.date).format(`DD MMM YYYY, [at] h:mma`)}, by {this.props.lastProfileEvent.userName}
            </div>
          )
        }
      </div>
    );
  }

  render(): JSX.Element | undefined {
    return this.renderForm();
  }
}
