import Parse from 'parse';
import { SessionStatus } from '../../models/Sessions';

export interface SessionCost {
  dayType: string;
  /** If the rate day type does not match because a fallback was used */
  isDayTypeMismatch: boolean;

  /** MS */
  billedTime: number;
  /** Percentage */
  billedProportion: number;
  /** Cents per hour */
  hourlyRate?: number;
  /** Cents */
  billedHourlyCost?: number;

  /** Cents per km */
  kmRate?: number;
  /** Cents */
  billedTransportKm?: number;
  /** KM */
  loggedTransport?: number;
  /** Cents */
  loggedParking?: number;

  /** Cents */
  totalFromNeedBudget: number;

  /** Cents */
  totalFromTransportBudget: number;
  
  /** Rebooked sessions that are billable */
  billedRebookedSessions: string[];
}

export async function getSessionCost(sessionId: string, customPostcode?: string | undefined, customSuburb?: string | undefined): Promise<SessionCost> {
  if (customPostcode && customSuburb) {
    const result = await Parse.Cloud.run('pricing-sessionCost', {
      sessionId,
      customPostcode,
      customSuburb,
    });

    return result;
  }

  const result = await Parse.Cloud.run('pricing-sessionCost', {
    sessionId,
  });

  return result;
}

/**
 * Gets the session cost from the cloud function with a custom status and cancelledAt date
 */
export async function getSessionCostForCancellation(sessionId: string, newStatus: SessionStatus, cancelledAtDate: Date): Promise<SessionCost> {
  const result = await Parse.Cloud.run('pricing-sessionCost', {
    sessionId,
    customSessionStatus: newStatus,
    customCancelledAt: cancelledAtDate,
  });

  return result;
}


export interface PredictedSessionCost {
  dayType: string;

  /** MS */
  expectedDuration: number;
  /** Cents per hour */
  expectedHourlyRate?: number;
  /** Cents */
  expectedCost?: number;
}

export async function getPredictedCost(sessionId: string, customPostcode?: string | undefined, customSuburb?: string | undefined): Promise<PredictedSessionCost> {
  if (customPostcode && customSuburb) {
    const result = await Parse.Cloud.run('pricing-predictedCost', {
      sessionId,
      customPostcode,
      customSuburb,
    });
    
    return result;
  }
  
  const result = await Parse.Cloud.run('pricing-predictedCost', {
    sessionId,
  });

  return result;
}
