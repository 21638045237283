import Parse from 'parse';
import { ClientSafetyPlanAccessType } from '../enums/ClientSafetyPlanAccessType';
import { saveEnumField, saveStringFieldToObject } from './util/parseSaving';

export interface SafetyPlanState {
  id?: string;
  becomingUnwellNotes: string;
  unwellActions: string;
  unwellNotes: string;
  emergencyActions: string;
  otherNotes: string;
  accessType: ClientSafetyPlanAccessType;
}

export class SafetyPlanModel {
  constructor(public object: Parse.Object) { }

  static new(): SafetyPlanModel {
    return new SafetyPlanModel(new (Parse.Object.extend('SafetyPlan'))());
  }

  id(): string { return this.object.id; }

  becomingUnwellNotes(): string | undefined { return this.object.get('becomingUnwellNotes'); }
  unwellActions(): string | undefined { return this.object.get('unwellActions'); }
  unwellNotes(): string | undefined { return this.object.get('unwellNotes'); }
  emergencyActions(): string | undefined { return this.object.get('emergencyActions'); }
  otherNotes(): string | undefined { return this.object.get('otherNotes'); }
  accessType(): ClientSafetyPlanAccessType { return this.object.get('accessType') ?? ClientSafetyPlanAccessType.Hidden; }

  static fromState(state: SafetyPlanState, clientId: string): SafetyPlanModel  {
    const obj = new Parse.Object('SafetyPlan');

    // Heads up: The assigning of an id populates the attributes of the object.
    // Guessing some 'smart' caching inside the parse SDK.
    // Be sure to made changes after this.
    if (state.id) {
      obj.id = state.id;
    }

    saveStringFieldToObject(state.becomingUnwellNotes, 'becomingUnwellNotes', obj);
    saveStringFieldToObject(state.unwellActions, 'unwellActions', obj);
    saveStringFieldToObject(state.unwellNotes, 'unwellNotes', obj);
    saveStringFieldToObject(state.emergencyActions, 'emergencyActions', obj);
    saveStringFieldToObject(state.otherNotes, 'otherNotes', obj);
    saveEnumField(state.accessType, 'accessType', obj);
    
    const client = new Parse.Object('Client');
    client.id = clientId;
    obj.set('client', client);

    return new SafetyPlanModel(obj);
  }

  async save(): Promise<Parse.Object> {
    await this.object.save();
    return this.object;
  }
}